import React, { FC } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { CardButton, RadioButton } from '../../../../components'
import { NavigationButtons } from '../NavigationButtons'
import { useDispatch, useSelector } from 'react-redux'
import { levelsSelector, stepsDataSelector } from '../../../../redux/slices/constructor/selectors'
import { thunks } from '../../../../redux/redusers'
import { Category, Entity, Level, Product } from '../../../../redux/slices/constructor/model/dto'

interface Props {
  step: Category
}

export const CategoryStep: FC<Props> = ({ step }) => {
  const dispatch = useDispatch()
  const stepsData = useSelector(stepsDataSelector)
  const levels = useSelector(levelsSelector)

  const handleChange = (value: Entity) => () => {
    dispatch(
      thunks.constructor.handleStepsData({
        entity: step,
        entityValue: value,
      }),
    )
  }

  const radioOptionsFactory = (entities: Category[] | Product[]) => {
    const options = entities.map((item: Entity, index: number, { length }: Array<Entity>) => (
      <Row key={item.name} className={index + 1 !== length ? 'mb-4' : undefined}>
        <RadioButton
          labelName={item.name}
          checked={stepsData.get(step) === item}
          onChange={handleChange(item)}
        />
      </Row>
    ))

    return <Container fluid>{options}</Container>
  }

  const cardOptionsFactory = (categories: Category[] | Product[]) => {
    return categories.map((item: Entity, index: number, { length }: Array<Entity>) => (
      <Col
        key={item.name}
        xs="auto-fit"
        className={'pl-0 mb-3 ' + (index + 1 !== length ? 'pr-3' : 'pr-0')}
      >
        <CardButton
          label={item.name}
          selected={stepsData.get(step) === item}
          onClick={handleChange(item)}
        />
      </Col>
    ))
  }

  const optionsFactoryMap = {
    radio: radioOptionsFactory,
    card: cardOptionsFactory,
  }

  let currentLevel: Pick<Level, 'action_label' | 'button_type'> | undefined
  if (levels) {
    currentLevel = levels[String(step.level + 1)]
  }

  const entities = step.products ?? step.children_data

  const options = optionsFactoryMap[currentLevel?.button_type ?? 'card'](entities)

  return (
    <Container className="p-0 constructor__category-step" fluid>
      <Row>
        <h2>{currentLevel ? currentLevel.action_label : 'Выберите тип товара'}</h2>
      </Row>
      <Row className="mb-4">{options}</Row>
      <div className="constructor__nav-buttons-container">
        <NavigationButtons />
      </div>
    </Container>
  )
}
