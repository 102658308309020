import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const useBlockRoute = (openDialog: () => void, isBlocked = false) => {
  const [isRouteBlocked, setIsRouteBlocked] = useState(isBlocked)
  const [currentPath, setCurrentPath] = useState('')
  const history = useHistory()

  useEffect(() => {
    let unblock
    if (isRouteBlocked) {
      unblock = history.block((location, action) => {
        openDialog()
        setCurrentPath(location.pathname)
        return false
      })
    } else {
      unblock = history.block(() => {})
    }
    return unblock
  }, [history, isRouteBlocked])

  const leavePage = useCallback(() => {
    history.block(() => {})
    setIsRouteBlocked(false)
    history.push(currentPath)
  }, [currentPath, history])

  const blockRoute = () => setIsRouteBlocked(true)
  const unblockRoute = () => setIsRouteBlocked(false)

  return { blockRoute, unblockRoute, leavePage }
}
