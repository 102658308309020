import React, { FC } from 'react'
import { Container, Row } from 'reactstrap'
import { AttributeBuilder } from './AttributeBuilder'
import { AttributeDataHandler } from '../../model'
import { AttributeGroup } from '../../../../redux/slices/constructor/model/dto'

interface Props extends AttributeDataHandler {
  group: AttributeGroup
  focusNextRef: (element: HTMLInputElement) => void
  groupName: string
}

export const AttributeGroupBuilder: FC<Props> = ({
  group,
  data,
  handler,
  focusNextRef,
  groupName,
}) => {
  const attributes = group.configurable_attributes.map((item) => (
    <Row key={item.attribute_id} className="mb-4">
      <AttributeBuilder
        attribute={item}
        data={data}
        handler={handler}
        focusNextRef={focusNextRef}
        groupName={groupName}
      />
    </Row>
  ))

  return (
    <Container className="p-0" fluid>
      <Row>
        <h2>{group.group_name}</h2>
      </Row>
      {attributes}
    </Container>
  )
}
