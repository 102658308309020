import { useCallback, useMemo, useState } from 'react'

export function useSwitcher(defaultValue = false) {
  const [isOn, setIsOn] = useState(defaultValue)
  const on = useCallback(() => setIsOn(true), [])
  const off = useCallback(() => setIsOn(false), [])
  const toggle = useCallback(() => setIsOn((prev) => !prev), [])

  return useMemo(
    () => ({
      isOn,
      on,
      off,
      toggle,
    }),
    [isOn, off, on, toggle],
  )
}
