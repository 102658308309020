import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { productHelper, searchHelper } from '../../../helpers'
import { productShape } from '../../../constants'

function ProductSuggestions({ items, query }) {
  if (!items?.length) {
    return null
  }

  return (
    <div className="search-suggestions-products">
      {items.map((item) => (
        <Link
          key={item.id}
          to={`/product/${item.sku}`}
          className="text-decoration-none suggestion d-flex align-items-center h-auto"
        >
          <img src={productHelper.getImage(item)} alt="" height={60} className="border" />
          <div className="ellipsis">
            <div className="suggestion-title">{searchHelper.markMatch(item.name, query)}</div>
            <div className="suggestion-description">
              {productHelper.getTextFromDescription(item)}
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

const mapStateToProps = (state) => ({
  query: state.search.query,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSuggestions)

ProductSuggestions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(productShape)).isRequired,
  query: PropTypes.string.isRequired,
  setProduct: PropTypes.func.isRequired,
}
