import React from 'react'
import { formatPriceRu } from '../../helpers/currency'
import clsx from 'clsx'

interface Props {
  title: string
  price: number
  isTitleBold?: boolean
  titleClassName?: string
  className?: string
}

export function CartTotalLine({
  title,
  price,
  titleClassName,
  className,
  isTitleBold = true,
}: Props) {
  return (
    <div className={clsx('d-flex justify-content-between', className)}>
      <div className={clsx(titleClassName, { 'font-weight-bold': isTitleBold })}>{title}</div>
      <div className="d-flex justify-content-end">
        {formatPriceRu(price, false)} <span className="currency" />
      </div>
    </div>
  )
}
