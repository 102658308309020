import { useEffect, useState, useCallback } from 'react'
import { commonErrorText } from '../constants'

export function useAuth({ onAuth }) {
  const [token, setToken] = useState()

  useEffect(() => {
    if (!token) {
      onAuth()
        .then((result) => {
          if (result.auth_token) {
            setToken(result.auth_token)
          } else {
            console.log(commonErrorText)
            setToken(null)
          }
        })
        .catch((e) => {
          console.error(e)
          setToken(null)
        })
    }
  }, [onAuth, token])

  const refreshToken = useCallback(() => setToken(null), [])

  return {
    token,
    refreshToken,
  }
}
