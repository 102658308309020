import React, { ChangeEvent, FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '../../../components/Layout/form/Form'
import { Block } from '../../../components/Layout/Block/Block'
import { Button } from '../../../components/Layout/Button/Button'
import { Col, Row } from 'reactstrap'
import { InputField } from '../../../components/Layout/form/InputField'
import { DatePickerField } from '../../../components/Layout/form/DatePickerField'
import { CheckboxField } from '../../../components/Layout/form/CheckboxField'
import { SelectField } from '../../../components/Layout/form/SelectField'
import { mainInfoStepSchema } from '../schemas'
import { passportNumberMask, passportSeriesMask, phoneMask } from '../../../constants'

interface Props {
  onNext: () => void
  onBack: () => void
}

interface FieldValues {
  staffId: string
  lastname: string
  firstname: string
  middlename: string
  missingMiddlename: boolean
  birthPlace: string
  passportIssuedBy: string
  passportCode: string
  email: string
  addressRegistration: string
  addressLiving: string
  isSameWithAddressRegistration: boolean
  incomeAmount: string
  birthDate: Date
  passportSeries: string
  passportNumber: string
  passportDate: Date
  passportPrevSeries: string
  passportPrevNumber: string
  mobilePhone: string
  workPhone: string
  homePhone: string
  registrationDate: Date
  incomeType: string
}

export const MainInfoStep: FC<Props> = ({ onNext, onBack }) => {
  const [isLivingAddressRequired, setIsLivingAddressRequired] = useState(true)
  const [isMiddlenameMissing, setIsMiddlenameMissing] = useState(false)

  const form = useForm<FieldValues>({
    resolver: yupResolver(mainInfoStepSchema),
    defaultValues: {
      birthDate: new Date('1992-06-15'),
      // здесь и далее по формам процесса проставлены дефолты для текстовых инпутов с масками
      passportSeries: '',
      passportNumber: '',
      passportDate: '',
      passportPrevSeries: '',
      passportPrevNumber: '',
      mobilePhone: '',
      workPhone: '',
      homePhone: '',
    },
  })

  const clearError = (name: keyof FieldValues) => {
    form.clearErrors(name)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'missingMiddlename') {
      form.setValue('middlename', undefined)
      setIsMiddlenameMissing(event.target.checked)
      clearError('middlename')
      return
    }
    if (event.target.name === 'isSameWithAddressRegistration') {
      form.setValue('addressLiving', undefined)
      setIsLivingAddressRequired(!event.target.checked)
      clearError('addressLiving')
    }
  }

  function onSubmit(values: FieldValues) {
    onNext()
  }

  return (
    <Form<FieldValues> form={form} onSubmit={onSubmit}>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>} />
      <Block mb={0} title="ОСНОВНЫЕ СВЕДЕНИЯ">
        <Row>
          <Col xs={4}>
            <InputField name="staffId" label="Код сотрудника" />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <InputField required name="lastname" label="Фамилия" />
          </Col>
          <Col xs={4}>
            <InputField required name="firstname" label="Имя" />
          </Col>
          <Col xs={4}>
            <InputField
              required={!isMiddlenameMissing}
              name="middlename"
              label="Отчество"
              disabled={isMiddlenameMissing}
            />
          </Col>
          <Col xs={4} className="ml-auto">
            <CheckboxField
              name="missingMiddlename"
              labelName="Отчество отсутствует"
              onChange={handleCheckboxChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <DatePickerField required label="Дата рождения" showYearDropdown name="birthDate" />
          </Col>
          <Col xs={8}>
            <InputField required name="birthPlace" label="Место рождения" />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="ПАСПОРТ">
        <Row>
          <Col xs={4}>
            <InputField
              name="passportSeries"
              required
              label="Серия паспорта"
              mask={passportSeriesMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              name="passportNumber"
              required
              label="Номер паспорта"
              mask={passportNumberMask}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <InputField name="passportIssuedBy" required label="Кем выдан" />
          </Col>
          <Col xs={4}>
            <InputField name="passportCode" required label="Код подразделения" />
          </Col>
          <Col xs={4}>
            <DatePickerField required label="Дата выдачи" showYearDropdown name="passportDate" />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <InputField
              name="passportPrevSeries"
              label="Серия предыдущего паспорта"
              mask={passportSeriesMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              name="passportPrevNumber"
              label="Номер предыдущего паспорта"
              mask={passportNumberMask}
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="КОНТАКТЫ">
        <Row>
          <Col xs={4}>
            <InputField required label="E-mail" type="text" name="email" />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Мобильный номер телефона"
              type="tel"
              name="mobilePhone"
              mask={phoneMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Рабочий номер телефона"
              type="tel"
              name="workPhone"
              mask={phoneMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              label="Домашний номер телефона"
              type="tel"
              name="homePhone"
              mask={phoneMask}
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="АДРЕСА">
        <Row>
          <Col xs={8}>
            <InputField required name="addressRegistration" label="Адрес регистрации" />
          </Col>
          <Col xs={4}>
            <DatePickerField required name="registrationDate" label="Дата регистрации" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputField
              required={isLivingAddressRequired}
              disabled={!isLivingAddressRequired}
              name="addressLiving"
              label="Место жительства"
            />
          </Col>
          <Col xs={4} className="mr-auto">
            <CheckboxField
              name="isSameWithAddressRegistration"
              labelName="Совпадает с адресом регистрации"
              onChange={handleCheckboxChange}
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="ДОХОД">
        <Row>
          <Col xs={4}>
            <SelectField
              required
              name="incomeType"
              label="Тип дохода"
              options={[
                { name: 'Зарплата', id: '1' },
                { name: 'Казино', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Уровень дохода"
              type="number"
              name="incomeAmount"
              step="0.01"
              min="0"
            />
          </Col>
        </Row>
      </Block>
      <Block pt={4}>
        <div className="d-flex">
          <Button size="lg" type="submit" className="height-40" color="secondary" onClick={onBack}>
            Назад
          </Button>
          <Button size="lg" type="submit" color="primary" className="ml-auto height-40">
            Далее
          </Button>
        </div>
      </Block>
    </Form>
  )
}
