import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'

import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { connectWS } from './websocket/connectWS'
import ScrollToTop from './components/Layout/ScrollToTop'
import { UserProvider } from './contexts/UserContext'
import { NotificationProvider } from './contexts/NotificationProvider/NotificationProvider'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { loggerUrl } from './api/api'
import { isSentryEnable } from './helpers/isSentryEnable'
import { TerminalProvider } from './contexts/TerminalProvider/TerminalProvider'

if (isSentryEnable()) {
  Sentry.init({
    dsn: loggerUrl,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

connectWS(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <UserProvider>
          <NotificationProvider>
            <TerminalProvider>
              <App />
            </TerminalProvider>
          </NotificationProvider>
        </UserProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
