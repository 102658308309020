import * as actions from '../types'
import { remove } from '../../api/storage'
import { cartId } from '../../constants/localStorageItems'
import { cartType, customerType } from '../../constants'

const initState = {
  count: 0,
  price: 0,
  service_price: 0,
  delivery_price: 0,
  requested_delivery_price: 0,
  min_requested_delivery_price: 0,
  promo_price: 0,
  total: 0,
  total_with_discount: 0,
  items: [],
  waiting_items: [],
  services: [],
  offers: [],
  promo: [],
  cartId: null,
  delivery_address: null,
  desired_date: null,
  selected_delivery: null,
  comment: null,
  customer: {
    result: {},
    isLoading: false,
    isCreating: false,
    isCreated: false,
    isEditing: false,
    isEdited: false,
    error: '',
    createError: '',
    editError: '',
    customerCorrelationId: null,
  },

  correlationId: null,
  isUpdateRequestInProgress: false,
  isLoading: false,
  isServicesLoading: false,
  error: '',
}

export default function (state = initState, action) {
  if (action.type === actions.resetCreateCustomer) {
    return {
      ...state,
      error: '',
      customer: {
        ...state.customer,
        isCreating: false,
        isCreated: false,
      },
    }
  }
  if (action.type === actions.beginCreateCustomer) {
    return {
      ...state,
      error: action?.error || '',
      customer: {
        ...state.customer,
        isCreating: true,
        isCreated: false,
      },
    }
  }
  // сейчас мы создаем пользователя только на корзине
  if (action.type === actions.endCreateCustomer) {
    return {
      ...state,
      customer: {
        ...state.customer,
        result: action.error ? state.customer.result : action.data,
        isCreating: false,
        isCreated: true,
        createError: action.error,
        customerCorrelationId: action?.data?.correlationId,
      },
    }
  }
  if (action.type === actions.setCart) {
    return {
      ...state,
      ...action.data,
    }
  }
  if (action.type === actions.setCartCustomer) {
    const { customer, cart = {}, correlationId, delivery_address } = action?.data || {}
    return {
      ...state,
      ...cart,
      customer: {
        ...state.customer,
        ...(customer && { result: customer }),
        isCreating: false,
      },
      correlationId,
      delivery_address,
      isLoading: false,
    }
  }
  if (action.type === actions.setCustomerContact) {
    return {
      ...state,
      contact_person: action.data,
    }
  }
  if (action.type === actions.setCustomerLinkType) {
    return {
      ...state,
      contact_link_type: action.data,
    }
  }

  if (action.type === actions.beginUpdateCartCustomer) {
    return {
      ...state,
      error: action?.error || '',
      customer: {
        result: {
          id: action.data?.customer_id,
        },
      },
      isLoading: true,
    }
  }

  if (action.type === actions.endUpdateCartCustomer) {
    return {
      ...state,
      customer: {
        result: action.error ? state.customer.result : action.data,
        isEditing: false,
        isEdited: true,
        editError: action.error,
      },
      isLoading: false,
    }
  }

  if (action.type === actions.resetCreateError) {
    return {
      ...state,
      customer: {
        ...state.customer,
        createError: '',
      },
    }
  }

  if (action.type === actions.resetCartError) {
    return {
      ...state,
      error: action?.error || '',
    }
  }

  if (action.type === actions.startAddItemToCart) {
    return {
      ...state,
      error: action?.error || '',
      isLoading: true,
    }
  }

  if (action.type === actions.startAddWaitingItemToCart) {
    return {
      ...state,
      error: action?.error || '',
      isLoading: true,
    }
  }

  /**
   * Cart block
   */
  if (action.type === actions.endAddItemToCart) {
    const { customer = {}, cart = {}, correlationId } = action?.data || {}
    return {
      ...state,
      ...cart,
      error: action?.error || '',
      customer: {
        ...state.customer,
        result: customer,
      },
      correlationId,
      isLoading: false,
    }
  }

  if (action.type === actions.endAddWaitingItemToCart) {
    const { customer = {}, cart = {}, correlationId } = action?.data || {}
    return {
      ...state,
      ...cart,
      error: action?.error || '',
      customer: {
        ...state.customer,
        result: customer,
      },
      correlationId,
      isLoading: false,
    }
  }

  if (
    action.type === actions.removeItemFromCart ||
    action.type === actions.removeWaitingItemFromCart ||
    action.type === actions.removeAllWaitingItemsFromCart
  ) {
    const { customer = {}, cart = {}, correlationId } = action?.data || {}
    return {
      ...state,
      ...cart,
      error: action?.error || '',
      customer: {
        ...state.customer,
        result: customer,
      },
      correlationId,
      delivery_variants: state.delivery_variants ? [...state.delivery_variants] : null,
      isLoading: false,
    }
  }

  if (action.type === actions.endCartUpdate) {
    const { customer = {}, cart = {}, correlationId } = action?.data || {}
    return {
      ...state,
      ...cart,
      error: action?.error || '',
      customer: {
        ...state.customer,
        result: customer,
      },
      correlationId,
      isLoading: false,
      isServicesLoading: false,
    }
  }

  if (action.type === actions.endCartSubmit) {
    const { cart = {}, correlationId } = action?.data || {}
    return {
      ...state,
      ...cart,
      error: action?.error || '',
      correlationId,
      isLoading: false,
    }
  }

  if (action.type === actions.removeCart) {
    remove(cartId)
    return initState
  }

  if (action.type === actions.serverMessageApp && action.data?.payload?.type === cartType) {
    return {
      ...state,
      ...(action.data?.payload?.data || {}),
      isLoading: false,
    }
  }

  if (action.type === 'UNEXPECTED_MESSAGE' && action.data?.payload?.type === cartType) {
    if (action.data?.payload?.data?.delivery_variants) {
      return {
        ...state,
        delivery_variants: action.data.payload.data.delivery_variants,
      }
    }
  }

  if (action.type === 'UNEXPECTED_MESSAGE' && action.data?.payload?.type === customerType) {
    if (action.data?.payload?.data) {
      return {
        ...state,
        contact_person: action.data.payload.data.guid,
      }
    }
  }

  if (
    action.type === actions.serverMessageApp &&
    action.data?.payload?.type === 'customer' &&
    action.data?.correlation_id === state.customer.customerCorrelationId
  ) {
    const customer_id = action.data?.payload?.data?.customer_id
    return {
      ...state,
      customer: {
        isCreated: customer_id ? state.customer.isCreated : false,
        isLoading: false,
        isEditing: false,
        isEdited: false,
        result: customer_id
          ? {
              ...state.customer.result,
              customer_id,
            }
          : {},
      },
    }
  }

  if (action.type === actions.startCartUpdate) {
    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === actions.startServicesUpdate) {
    return {
      ...state,
      isServicesLoading: true,
    }
  }

  if (action.type === actions.startCartUpdateRequest) {
    return {
      ...state,
      isUpdateRequestInProgress: true,
    }
  }

  if (action.type === actions.endCartUpdateRequest) {
    return {
      ...state,
      isUpdateRequestInProgress: false,
    }
  }

  if (action.type === actions.startCartInit) {
    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === actions.endCartInit) {
    return {
      ...state,
      isLoading: false,
    }
  }

  return { ...state }
}
