import React, { ChangeEvent, FC } from 'react'
import { Props as InputProps } from '../Input/Input'
import { PhoneInput } from '../Input/PhoneInput'
import { useFormContext, useWatch } from 'react-hook-form'
type Props = {
  defaultValue?: string
} & InputProps
export const PhoneInputField: FC<Props> = ({ name, ...props }) => {
  const context = useFormContext()
  const { setValue, errors, register, control, trigger, clearErrors } = context
  const value = useWatch({ control, name, defaultValue: '' })
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 11) {
      setValue(name, e.target.value.slice(0, 11))
    } else {
      setValue(name, e.target.value)
    }
    trigger(name)
    clearErrors(name)
  }
  return (
    <PhoneInput
      {...register(name)}
      {...props}
      name={name}
      onChange={handleChange}
      errorMessage={errors?.[name]?.message}
      value={value}
      onBlur={props?.handleBlur}
    />
  )
}
