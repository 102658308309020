import React, { FC, useCallback, useEffect } from 'react'
import { Row } from 'reactstrap'
import { Summary } from '../Summary'
import { useFocusNext } from '../../../../hooks/useFocusNext'
import { AttributeGroupBuilder } from '../builders/AttributeGroupBuilder'
import { useDispatch, useSelector } from 'react-redux'
import {
  isSummaryActiveSelector,
  parametersDataSelector,
  parametersTreeSelector,
  priceSelector,
  waitingItemSelector,
} from '../../../../redux/slices/constructor/selectors'
import { Attribute, AttributeOption, Product } from '../../../../redux/slices/constructor/model/dto'
import { actions, thunks } from '../../../../redux/redusers'
import { Loader } from '../../../../components'
import { addWaitingItem } from '../../../../redux/actions/cart'

interface Props {
  step: Product
}

export const ParametersStep: FC<Props> = ({ step }) => {
  const dispatch = useDispatch()
  const parametersTree = useSelector(parametersTreeSelector)
  const parametersData = useSelector(parametersDataSelector)
  const waitingItem = useSelector(waitingItemSelector)
  const price = useSelector(priceSelector)
  const isSummaryActive = useSelector(isSummaryActiveSelector)
  const focusNextRef = useFocusNext()

  useEffect(() => {
    if (!parametersTree) dispatch(thunks.constructor.fetchParameters(step.sku))
  }, [])

  const parametersDataHandler = useCallback(
    (attribute: Attribute, attributeOption: AttributeOption) => {
      dispatch(
        thunks.constructor.handleParametersData({
          attribute,
          attributeOption,
        }),
      )
    },
    [],
  )

  useEffect(() => {
    if (parametersTree) {
      parametersTree.configurable_attribute_groups.forEach((groupItem) => {
        groupItem.configurable_attributes.forEach((attributesItem) => {
          dispatch(
            thunks.constructor.handleParametersData({
              attribute: attributesItem,
              attributeOption: attributesItem.options[0],
            }),
          )
        })
      })
    }
  }, [parametersTree, dispatch])

  if (!parametersTree) {
    return <Loader />
  }

  const handlePreviousStep = () => {
    dispatch(actions.constructor.previousStep())
  }

  const groups = parametersTree?.configurable_attribute_groups.map((item) => (
    <AttributeGroupBuilder
      key={item.group_name}
      data={parametersData}
      handler={parametersDataHandler}
      group={item}
      focusNextRef={focusNextRef}
      groupName={item.group_name}
    />
  ))

  const handleAddClick = () => {
    dispatch(addWaitingItem(waitingItem))
  }

  return (
    <>
      <Row>
        <h2>Выберите параметры</h2>
      </Row>
      {groups}
      <Row className="mt-5">
        {isSummaryActive && (
          <Summary
            title={parametersTree.name}
            price={price}
            onSubmit={handleAddClick}
            onPreviousStep={handlePreviousStep}
          />
        )}
      </Row>
    </>
  )
}
