import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../../components'

export function ProductAddedToCartModal({ isOpen }) {
  return (
    <Modal
      withIcon={false}
      renderIcon={() => {}}
      isOpenExternal={isOpen}
      buttonToolbar={null}
      message={
        <div className="item-cart-success show">
          <span className="lnr lnr-checkmark-circle display-4 text-success" />
          <h5 className="mt-2">
            Товар добавлен <br /> в корзину
          </h5>
        </div>
      }
    />
  )
}

ProductAddedToCartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}
