import React from 'react'
import PropTypes from 'prop-types'
import { addressShape as addressShapeBase } from '../../constants'
import { TableList } from '../../components/TableList/TableList'

function formatAddress({ address, postcode, city, street, extension_attributes }) {
  const block = extension_attributes.block ? `корп.${extension_attributes.block}` : ''
  const building = extension_attributes.building ? `стр.${extension_attributes.building}` : ''
  const house = extension_attributes.house ? `д.${extension_attributes.house}` : ''
  const apartment = extension_attributes.apartment ? `кв.${extension_attributes.apartment}` : ''
  return (
    address ||
    [
      postcode,
      city,
      Array.isArray(street) ? street.join(', ') : street,
      house,
      block,
      building,
      apartment,
    ]
      .filter(Boolean)
      .join(', ')
  )
}

function formatAdditionalInfo(address) {
  return [
    address.extension_attributes.entrance_number ? (
      <>
        <b>подъезд</b> {address.extension_attributes.entrance_number}
      </>
    ) : null,
    address.extension_attributes.floor ? (
      <>
        <b>этаж</b> {address.extension_attributes.floor}
      </>
    ) : null,
    address.extension_attributes.intercom_code ? (
      <>
        <b>домофон</b> {address.extension_attributes.intercom_code}
      </>
    ) : null,
    <>
      <b>лифт</b> {address.extension_attributes.lift_availability ? 'есть' : 'нет'}
    </>,
  ]
    .filter(Boolean)
    .reduce((nodeWithCommas, node, index) => [...nodeWithCommas, index !== 0 ? ', ' : '', node], [])
}

export function Address({ address, titleColWidth, titleColumnClass, contentColWidth }) {
  return (
    <div>
      <TableList
        titleColWidth={titleColWidth}
        contentColWidth={contentColWidth}
        titleColumnClass={titleColumnClass}
        items={[
          { title: 'Регион: ', content: address.region },
          {
            title: 'Адрес',
            content: (
              <div>
                <div>{formatAddress(address)}</div>
                <div>{formatAdditionalInfo(address)}</div>
              </div>
            ),
          },
          { title: 'Комментарий: ', content: address.extension_attributes.comment },
        ]}
      />
    </div>
  )
}

const addressShape = {
  ...addressShapeBase,
  street: PropTypes.arrayOf(PropTypes.string),
}

Address.propTypes = {
  address: PropTypes.shape(addressShape).isRequired,
  titleColWidth: PropTypes.number,
  contentColWidth: PropTypes.number,
}
