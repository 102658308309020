import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Select } from '../../components'
import { productShape } from '../../constants'
import { materialVariantKeys } from '../../helpers/product'
import clsx from 'clsx'
import style from './Product.module.scss'

function ProductVariant({
  additionalInfo,
  comment,
  onSelect,
  options,
  label,
  valiantKey,
  onClickChange,
  material,
  activeVariant,
}) {
  const [selected, setSelected] = useState(null)

  const handleSelect = useCallback(
    (id) => {
      onSelect(id)
      setSelected(id)
    },
    [onSelect],
  )

  const content = (() => {
    if (materialVariantKeys.includes(valiantKey)) {
      if (!material) {
        return null
      }

      return (
        <div className="text-right">
          <div>{material.collection}</div>
          <div className="d-flex">
            <div className={clsx(style.materialImgWrapperSmall, 'mr-2')}>
              <img className="w-100 object-fit-cover" src={material.link} alt={material.name} />
            </div>
            <div>{material.name}</div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="value d-flex">
          {comment && <div className="mr-2">{comment}</div>}
          <Select
            key={activeVariant}
            initialValueId={activeVariant}
            options={options}
            onChange={handleSelect}
            withRotateIcon
          />
        </div>
        {additionalInfo && selected && (
          <div className="additional text-right">
            {additionalInfo}: {selected.additionalValue}
          </div>
        )}
      </div>
    )
  })()

  return (
    <div className="d-flex justify-content-between my-3">
      <div>
        <div className="name">
          <span>{label}:</span>
        </div>
        {materialVariantKeys.includes(valiantKey) && (
          <div className="text-info cursor-pointer mt-2">
            <span className="mr-2" onClick={onClickChange}>
              Изменить
            </span>
          </div>
        )}
      </div>
      {content}
    </div>
  )
}

export default ProductVariant

ProductVariant.propTypes = {
  item: PropTypes.shape(productShape),
  label: PropTypes.string,
  valiantKey: PropTypes.string,
  comment: PropTypes.string,
  activeVariant: PropTypes.string,
  additionalInfo: PropTypes.string,
  onSelect: PropTypes.func,
  onClickChange: PropTypes.func,
  material: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
}
