import React from 'react'
import PropTypes from 'prop-types'

import { Modal, RadioButton } from '../index'
import { addressShape } from '../../constants'

function Address({ address, onRemove, onSetDefaultAddress }) {
  return (
    <div className="address-block">
      <div className="d-flex align-items-start">
        <RadioButton
          disabled={!onSetDefaultAddress}
          checked={address.is_default}
          name="is_default"
          inputClassName="pl-0"
          onChange={() => onSetDefaultAddress(address.id)}
        />
        <div className="title-with-icon">Регион доставки</div>
        <div>{address.region}</div>
      </div>
      <div className="d-flex align-items-start">
        <div className="title-without-icon">Адрес</div>
        <div>{address.address}</div>
      </div>
      {Boolean(address.floor || address.intercom_code || address.entrance_number) && (
        <div className="d-flex mt-3">
          <div className="title-without-icon" />
          <div>
            {Boolean(address.entrance_number) && <span>подъезд {address.entrance_number}</span>}
            {Boolean(address.floor) && <span>, этаж {address.floor}</span>}
            {Boolean(address.intercom_code) && <span>, домофон {address.intercom_code}</span>}
            {Boolean(address.floor || address.intercom_code || address.entrance_number) && (
              <span>, </span>
            )}
            <span>лифт {address.lift_availability ? 'есть' : 'нет'}</span>
          </div>
        </div>
      )}
      {address.comment && (
        <div className="d-flex mt-3">
          <div className="title-without-icon">Комментарий</div>
          <div>{address.comment}</div>
        </div>
      )}
      <div className="divider" />
      {onRemove && (
        <Modal
          color="warning"
          renderIcon={(toggle) => (
            <span className="lnr lnr-trash cursor-pointer remove-address-button" onClick={toggle} />
          )}
          title="Внимание!"
          message="Выбранный адрес будет удален из карточки Клиента . Вы уверены, что хотите продолжить?"
          buttonToolbar={(toggle) => (
            <>
              <button className="btn btn-secondary modal_cancel height-40" onClick={toggle}>
                Отмена
              </button>
              <button
                className="btn btn-warning modal_ok height-40"
                color="warning"
                onClick={() => {
                  toggle()
                  onRemove(address.id)
                }}
              >
                Удалить
              </button>
            </>
          )}
        />
      )}
    </div>
  )
}

Address.propTypes = {
  address: PropTypes.shape(addressShape).isRequired,
  onSetDefaultAddress: PropTypes.func,
  onRemove: PropTypes.func,
}
Address.defaultProps = {
  onRemove: null,
  onSetDefaultAddress: null,
}

export default Address
