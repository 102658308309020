import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import Checkbox, { Props as CheckboxProps } from '../Checkbox'

interface Props extends CheckboxProps {
  name: string
}

export const CheckboxField: FC<Props> = ({ name, ...props }) => {
  const { register } = useFormContext()

  return <Checkbox {...props} name={name} innerRef={register} />
}
