import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Item } from '../../api/dto/CartBasicTypes'
import { getLabel } from '../../helpers/product'
import { CartProductAttribute } from './CartProductAttribute'
import classNames from './Cart.module.scss'

type Props = {
  item: Item
}

export function CartProductAttributes({
  item,
  item: { configurable_attributes: attributes = [] },
}: Props) {
  const [expanded, setExpanded] = useState(false)
  const expandedAttributes = [...attributes].filter((attr) => {
    return !['color', 'length', 'width', 'height'].includes(attr.code) && getLabel(attr.code)
  })
  const getColor = () => {
    const color = attributes.find((el) => el.code === 'color')?.value
    if (color) {
      return <CartProductAttribute item={item} label={getLabel('color')} value={color} />
    }
  }

  const getSize = () => {
    const size = [
      attributes.find((el) => el.code === 'length')?.value,
      attributes.find((el) => el.code === 'width')?.value,
      attributes.find((el) => el.code === 'height')?.value,
    ]
      .map((el) => {
        return el ? parseInt(el.toString()) : ''
      })
      .filter(Boolean)
      .join('x')
    return size && <CartProductAttribute item={item} label={'Размер'} value={size} />
  }

  const getExpandedAttributes = () => {
    return expandedAttributes.map((attr) => {
      return (
        <CartProductAttribute
          key={attr.code}
          item={item}
          label={getLabel(attr.code)}
          value={attr.value}
          image={attr.image_url}
        />
      )
    })
  }

  return (
    <div className="cart-product-list-line-attributes">
      <div className="main-attributes">
        {getColor()}
        {getSize()}
      </div>
      {expanded && <div className="expanded-attributes">{getExpandedAttributes()}</div>}
      {expandedAttributes.length > 0 && (
        <button
          className={`${
            expanded ? classNames.attributesToggleOpened : classNames.attributesToggleClosed
          } ${classNames.attributesToggleButton}`}
          onClick={() => setExpanded((prev) => !prev)}
        >
          {expanded ? 'Скрыть' : 'Подробнее'}
        </button>
      )}
    </div>
  )
}

CartProductAttributes.propTypes = {
  item: PropTypes.object.isRequired,
}
