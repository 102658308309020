import * as yup from 'yup'
import addonsEmailMask from 'text-mask-addons/dist/emailMask'

export * from './propTypesShapes'

export const searchTypes = {
  products: 'products',
  orders: 'orders',
  customers: 'customers',
}
export const searchSuggestionsCount = 3
export const searchPageSize = 6

export const productProperties = {
  stiffness: 'Жёсткость',
  height: 'Высота матраса',
  max_weight: 'Макс. вес на место',
  size: 'Размер',
  color: 'Цвет',
  material: 'Материал',
}

/**
 * API constants
 */
export const apiPrefix = '/rest'
export const apiVersion = '/V1'

/**
 * Customer bonus balance constants
 */
export const smsCodeLength = 4

/**
 * Input masks
 */
export const phoneMask = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const extraPhoneMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const dobMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]

export const passportSeriesMask = [/\d/, /\d/, /\d/, /\d/]

export const passportNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const yearMask = [/\d/, /\d/, /\d/, /\d/]

export const emailMask = addonsEmailMask

/**
 * Dadata constants
 */
export const dadataUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
export const dadataToken = 'f5a66ae83cd102643ff2c2ac166c6dc13114a52a'

export const newId = 0
export const emptyAddress = {
  localId: '1',
  fias: {
    region: '',
    city: '',
    street: '',
    house: '',
  },
  address: '',
  zip: null,
  country: '',
  region: '',
  city: '',
  street: '',
  house: '',
  block: '',
  apartment: '',
  floor: '',
  lift_availability: false,
  intercom_code: null,
  entrance_number: null,
  comment: '',
  is_default: false,
}

export const customerSchema = yup.object().shape({
  surname: yup
    .string()
    .transform((value) => (!value ? null : value))
    .nullable()
    .matches(/^[a-zA-ZА-Яа-я\s-]+$/g, 'Допустимы только буквы, пробелы и тире')
    .max(70, 'Максимум 70 символов'),
  given_name: yup
    .string()
    .required('Это поле является обязательным')
    .matches(/^[a-zA-ZА-Яа-я\s-]+$/g, 'Допустимы только буквы, пробелы и тире')
    .max(70, 'Максимум 70 символов'),
  middle_name: yup
    .string()
    .transform((value) => (!value ? null : value))
    .nullable()
    .matches(/^[a-zA-ZА-Яа-я\s-]+$/g, 'Допустимы только буквы, пробелы и тире')
    .max(70, 'Максимум 70 символов'),
  phone: yup
    .string()
    .required('Это поле является обязательным')
    .test('len', 'Введите номер телефона полностью', (val) => val.replace(/\D/g, '').length === 11),
  email: yup.string().when('is_fake_email', {
    is: false,
    then: yup
      .string()
      .nullable()
      .required('Это поле является обязательным')
      .email('Введите адрес электронной почты в правильном формате')
      .matches(
        /^[a-zA-Z\d_.@-]+$/gi,
        'Допустимы только символы латинского алфавита, цифры, подчеркивание, дефис, точка и @',
      ),
    otherwise: yup.string().nullable(),
  }),
  is_fake_email: yup.boolean(),
  birth_date: yup.string().nullable(),
  extra_phone: yup.string().nullable(),
  subscriptions: yup.object().nullable().shape({
    sms: yup.boolean(),
    email: yup.boolean(),
    phone: yup.boolean(),
  }),
})

export const addressValidationSchema = yup.object().shape({
  addresses: yup.array().of(
    yup.object().shape({
      is_default: yup.boolean(),
      localId: yup.string(),
      fias: yup.object().shape({
        region: yup.string().required(),
        city: yup.string().required(),
        street: yup.string().required(),
        house: yup.string().required(),
      }),
      region: yup.string().required(),
      city: yup.string().required(),
      street: yup.string().required(),
      house: yup.string().required(),
      block: yup.string(),
      apartment: yup.string(),
      entrance_number: yup.string().nullable(),
      intercom_code: yup.string().nullable(),
      floor: yup.string().nullable(),
      lift_availability: yup.string(),
      comment: yup.string(),
      zip: yup.string().nullable(),
    }),
  ),
})

export const emptyCustomer = {
  id: undefined,
  surname: undefined,
  given_name: undefined,
  middle_name: undefined,
  birth_date: undefined,
  phone: undefined,
  extra_phone: undefined,
  email: undefined,
  is_fake_email: undefined,
}

/**
 * deprecated
 * @type {RegExp}
 */
export const phoneRegexp = /^([78])?([1-9]\d{2})(\d{3})(\d{2})(\d{2})$/

export const phoneRegexpWithPlus = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/

export const phoneOrEmptyStringRegexp = /^$|^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/

export const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const cartMode = 'cart'

export const contactMode = 'contact'

export const DELIVERY_OPTION_DELIVERY = 'delivery'
export const deliveryOptionPickup = 'pickup'

export const deliveryOptions = [
  {
    id: '1',
    name: 'Доставка',
    value: DELIVERY_OPTION_DELIVERY,
  },
  {
    id: '2',
    name: 'Продажа с ТТ',
    value: deliveryOptionPickup,
  },
]

export const getDeliveryOptionName = (type) => {
  return deliveryOptions.find((item) => item.value === type)?.name
}

export const cartType = 'cart'
export const customerType = 'customer'
