import React, { FC, useState } from 'react'
import {
  BanksStep,
  ChooseSignatureStep,
  ConditionStep,
  DocumentsCreditStep,
  FilesStep,
  FinalStep,
  FullMainInfoStep,
  MainInfoStep,
} from './steps/'

enum Steps {
  CONDITION_STEP = 'CONDITION_STEP',
  MAIN_INFO_STEP = 'MAIN_INFO_STEP',
  FULL_MAIN_INFO_STEP = 'FULL_MAIN_INFO_STEP',
  FILES_INFO_STEP = 'FILES_INFO_STEP',
  BANKS_STEP = 'BANKS_STEP',
  SIGNATURE_STEP = 'SIGNATURE_STEP',
  DOCUMENTS_CREDIT_STEP = 'DOCUMENTS_CREDIT_STEP',
  FINAL_STEP = 'FINAL_STEP',
}

const useSteps = () => {
  const [step, setStep] = useState<Steps>(Steps.CONDITION_STEP)

  function navigate(step: Steps) {
    setStep(step)
  }

  return {
    step,
    navigate,
  }
}

export const Credit: FC = () => {
  const { step, navigate } = useSteps()

  switch (step) {
    case Steps.CONDITION_STEP: {
      return <ConditionStep onNext={() => navigate(Steps.MAIN_INFO_STEP)} />
    }
    case Steps.MAIN_INFO_STEP: {
      return (
        <MainInfoStep
          onBack={() => navigate(Steps.CONDITION_STEP)}
          onNext={() => navigate(Steps.FULL_MAIN_INFO_STEP)}
        />
      )
    }
    case Steps.FULL_MAIN_INFO_STEP: {
      return (
        <FullMainInfoStep
          onBack={() => navigate(Steps.MAIN_INFO_STEP)}
          onNext={() => navigate(Steps.FILES_INFO_STEP)}
        />
      )
    }
    case Steps.FILES_INFO_STEP: {
      return (
        <FilesStep
          onBack={() => navigate(Steps.FULL_MAIN_INFO_STEP)}
          onNext={() => navigate(Steps.BANKS_STEP)}
        />
      )
    }
    case Steps.BANKS_STEP: {
      return (
        <BanksStep
          onBack={() => navigate(Steps.FILES_INFO_STEP)}
          onNext={() => navigate(Steps.SIGNATURE_STEP)}
        />
      )
    }
    case Steps.SIGNATURE_STEP: {
      return (
        <ChooseSignatureStep
          onBack={() => navigate(Steps.BANKS_STEP)}
          onNext={() => navigate(Steps.DOCUMENTS_CREDIT_STEP)}
        />
      )
    }
    case Steps.DOCUMENTS_CREDIT_STEP: {
      return (
        <DocumentsCreditStep
          onBack={() => navigate(Steps.SIGNATURE_STEP)}
          onNext={() => navigate(Steps.FINAL_STEP)}
        />
      )
    }
    case Steps.FINAL_STEP: {
      return <FinalStep onBack={() => navigate(Steps.DOCUMENTS_CREDIT_STEP)} onNext={() => {}} />
    }
    default:
      return null
  }
}
