import { formatPriceRu } from './currency'
import { groupBy, uniqWith } from 'lodash-es'
import fromEntries from 'fromentries'

export const getPrice = (item) => {
  if (item.price && item.price > 0) {
    return item.price
  }

  const prices = item?.simples?.map((simple) => parseFloat(simple.price)) || []
  return Math.min(...prices) || 0
}

export const formatPrice = (price) => formatPriceRu(price, false)

export const getYoutubeThumbnail = (videoUrl) => {
  const videoId = videoUrl?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)?.pop() || ''
  if (videoId.length === 11) {
    return '//img.youtube.com/vi/' + videoId + '/0.jpg'
  }
  return 'https://dummyimage.com/480x360/99cccc/ffffff.gif&text=Video'
}

/**
 * От бэка нам может прийти некая дичь, поэтому из описания берем только текст
 */
export const getTextFromDescription = ({ description }) => {
  const descriptionNode = document.createElement('div')
  descriptionNode.innerHTML = description || ''
  return descriptionNode.innerText
}

export const getImage = (item) =>
  item?.media?.photogallery?.length ? item?.media?.photogallery[0] : item.image_url

export const getColors = (item) => {
  if (item?.colors) {
    return item.colors
  }

  return [...new Set(item?.simples?.map((simple) => simple.color))]
}
/**
 * @Todo: заглушка, пока не будут возвращаться коды цветов от бэка: выводим придуманный нами цвет или генерим случайный
 */
export const getFakeColor = (colorName) => {
  const colors = {
    'Iris 511': '236952',
    'Iris 512': 'b0db17',
    'Iris 513': '863165',
    Green: '49ffca',
  }
  return colors[colorName] || Math.floor(Math.random() * 16777215).toString(16)
}

export const materialVariantKeys = ['material_up', 'material_bottom', 'material_side']
export const sizeVariantKeys = ['width', 'height', 'length', 'size', 'depth', 'diameter']
export const textileVariantsKeys = [
  'textile',
  'textile1',
  'textile2',
  'textile3',
  'upholstery_fabric',
  'textil_panel_back',
  'textil_pillow',
  'textil_framing_panel',
  'textil_insert',
  'textil_panel',
  'textil_back',
  'textil_footing',
  'bottom_element',
  'equipment',
  'top_element',
  'additional_textile',
  'allowed_textile',
  'base_textile',
  'element_product',
  'fabric_table',
  'textile_combo',
]

export const getLabel = (key) => {
  const labels = {
    length: 'Длина',
    width: 'Ширина',
    height: 'Высота',
    diameter: 'Диаметр',
    size: 'Размер',
    color: 'Цвет',
    facade_color: 'Цвет фасада',
    material: 'Материал', // TODO: временно для дева
    body_color: 'Цвет корпуса',
    body_material: 'Материал корпуса',
    facade_material: 'Материал фасада',
    textile: 'Ткань',
    textile1: 'Ткань1',
    textile2: 'Ткань2',
    textile3: 'Ткань3',
    upholstery_fabric: 'Ткань обивки',
    rigidity: 'Жесткость',
    addition: 'Дополнение',
    base_type: 'Тип основания',
    type_back: 'Спинка кровати',
    type_legs: 'Тип ножек',
    depth: 'Глубина',
    compl_bed: 'Комплектация кровати',
    volume: 'Объем',
    accessories: 'Аксессуары дивана',
    additional_textile: 'Верх основания',
    allowed_textile: 'Разрешённые ткани',
    back_cover_material: 'Материал задней крышки',
    back_width: 'Ширина спинки',
    base_textile: 'Всё изделие или только боковины и низ основания',
    bottom_element: 'Всё изделие или корпус/низ пуфа',
    color_legs: 'Цвет ножки лампы',
    decor: 'Декор',
    element_product: 'Подушка, подголовник, приспинка или иное дополнение к товару',
    equipment: 'Комплектация',
    fabric_table: 'Ткань стола',
    footing: 'Основание к кровати',
    gender: 'Пол',
    headboard_insert: 'Вставка на спинку из материала',
    panel: 'Модель царг',
    textil_all_product: 'Всё изделие в одной ткани',
    textil_back: 'Спинка',
    textil_footing: 'Основание',
    textil_framing_panel: 'Обрамление+Царга',
    textil_insert: 'Вставка',
    textil_panel: 'Царга',
    textil_panel_back: 'Царга+Спинка',
    textil_pillow: 'Подушка',
    textile_combo: 'Ткань',
    top_element: 'Верхний элемент',
  }

  return labels[key]
}

export const getPossibleVariantsFromSimples = (simples = []) => {
  const variants = getSimplesVariants(simples)
  const groupedVariants = groupBy(variants, 'code')

  // Фильтруем служебный атрибут
  const { binding: _binding, ...filteredGroupedVariants } = groupedVariants

  return Object.entries(filteredGroupedVariants).map(([key, variants]) => {
    return {
      key,
      label: getLabel(key),
      values: variants,
    }
  })
}

export function getSimplesVariants(simples) {
  const allVariants = simples.reduce(
    (attributesAcc, { attributes }) => [...attributesAcc, ...attributes],
    [],
  )

  return uniqWith(allVariants, (variant1, variant2) => {
    return variant1.code + variant1.option_id === variant2.code + variant2.option_id
  })
}

export function getSuitableSimples(simples, selectedVariantsMap, notFilterKey) {
  return simples.filter((simple) => {
    const selectedVariantsEntries = Object.entries(selectedVariantsMap).filter(([variantKey]) =>
      notFilterKey ? variantKey !== notFilterKey : true,
    )

    return selectedVariantsEntries.every(([variantKey, option_id]) => {
      return getSimplesVariants([simple]).some(
        (attribute) => attribute.option_id === option_id && attribute.code === variantKey,
      )
    })
  })
}

export function getPossibleVariantValues(variantsValues, simples, variantKey) {
  return variantsValues.filter((variantValue) => {
    return getSimplesVariants(simples).some((attribute) => {
      return attribute.option_id === variantValue.option_id && attribute.code === variantKey
    })
  })
}

export function getMaterials({ variants, simples, selectedVariantsMap, materialsMap, variantKey }) {
  const materialAllVariantValues = variants.find((variant) => variant.key === variantKey).values
  const suitableSimplesForMaterial = getSuitableSimples(simples, selectedVariantsMap, variantKey)
  const materialPossibleVariantValues = getPossibleVariantValues(
    materialAllVariantValues,
    suitableSimplesForMaterial,
    variantKey,
  )
  return materialPossibleVariantValues.map((variantValue) => materialsMap[variantValue?.option_id])
}

export function filterSelectedVariants(selectedVariantsMap, filterPriorityMap, key) {
  return fromEntries(
    Object.entries(selectedVariantsMap).filter(
      ([selectedVariantsMapKey]) =>
        filterPriorityMap[key] <= filterPriorityMap[selectedVariantsMapKey],
    ),
  )
}
