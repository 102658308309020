import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yup } from '../../../yup'
import { Form } from '../../../components/Layout/form/Form'
import { InputField } from '../../../components/Layout/form/InputField'
import { Button } from '../../../components/Layout/Button/Button'
import Error from './Error'

const schema = yup.object().shape({
  card: yup.string().min(9, ''),
})

export function EnterCard({ programName, hint, onSubmit }) {
  const [error, setError] = useState(false)

  const form = useForm({
    resolver: yupResolver(schema),
  })

  function handleSubmit(values) {
    onSubmit(values.card)
    setError(false)
  }

  return (
    <>
      {error && <Error text="Участник Программы Лояльности 900 000 001 не определён. " />}
      <div className="askonaWidget-loyaltyProgram-enterCard">
        <div className="askonaWidget-loyaltyProgram-enterCard-title">
          Введите номер карты <br /> {programName}
        </div>
        <div className="askonaWidget-loyaltyProgram-enterCard-hint">{hint}</div>
        <div className="askonaWidget-loyaltyProgram-enterCard-form">
          <Form form={form} onSubmit={handleSubmit}>
            <InputField name="card" />
            <Button type="submit" className="height-40" color="primary">
              Добавить
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}

EnterCard.propTypes = {
  programName: PropTypes.string.isRequired,
  hint: PropTypes.string,
  onSubmit: PropTypes.func,
}
