import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ProductImageGallery } from '../../../components'
import { getYoutubeThumbnail } from '../../../helpers/product'
import { productShape } from '../../../constants'
import Lightbox from 'react-image-lightbox'

function MediaTab({ item }) {
  const images = item?.media?.photogallery ?? []
  const [lightboxData, setLightboxData] = useState({ imageIndex: 0, isOpen: false })

  function getPreview() {
    if (item.media?.video?.length > 0) {
      return (
        <div className="video mt-4">
          <a href={item.media.video} target="_blank" rel="noopener noreferrer">
            <img
              className="img-fluid"
              src={getYoutubeThumbnail(item.media.video)}
              alt={item.name}
            />
          </a>
        </div>
      )
    }

    if (images[0]) {
      return (
        <div
          className="mt-4 cursor-pointer"
          onClick={() => setLightboxData({ isOpen: true, imageIndex: 0 })}
        >
          <img className="img-fluid" src={images[0]} alt={item.name} />
        </div>
      )
    }

    return null
  }

  return (
    <div className="w-100">
      <ProductImageGallery images={images} onClickItem={setLightboxData} />
      {getPreview()}
      {item.media?.documents?.length > 0 && (
        <div className="files d-flex mt-4">
          {item.media.documents.map((file) => (
            <a
              key={file.title}
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <div className="d-flex flex-column">
                <div className="image d-flex align-items-center justify-content-center">
                  <span className="lnr lnr-paperclip" />
                </div>
                <div className="title">{file.title}</div>
              </div>
            </a>
          ))}
        </div>
      )}
      {lightboxData.isOpen && (
        <Lightbox
          mainSrc={images[lightboxData.imageIndex]}
          nextSrc={images[(lightboxData.imageIndex + 1) % images.length]}
          prevSrc={images[(lightboxData.imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxData({ ...lightboxData, isOpen: false })}
          onMovePrevRequest={() =>
            setLightboxData({
              ...lightboxData,
              imageIndex: (lightboxData.imageIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            setLightboxData({
              ...lightboxData,
              imageIndex: (lightboxData.imageIndex + 1) % images.length,
            })
          }
        />
      )}
    </div>
  )
}

export default MediaTab

MediaTab.propTypes = {
  item: PropTypes.shape(productShape),
}
