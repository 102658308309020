import React from 'react'
import { useTerminal } from './TerminalProvider'
import { ReactComponent as TerminalActive } from '../../images/icons/terminal-active.svg'
import { ReactComponent as TerminalInactive } from '../../images/icons/terminal-inactive.svg'

export const TerminalHealth = () => {
  const { isTerminalActive } = useTerminal()

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div>{isTerminalActive ? <TerminalActive /> : <TerminalInactive />}</div>
    </div>
  )
}
