import { api } from './api'
import { ErrorInfo, CustomerErrorType } from './dto'

export const postErrorInfo = (customer: CustomerErrorType, appId: string): Promise<ErrorInfo> => {
  return api.post({
    path: `/post/error/errorService`,
    data: {
      application_id: appId,
      correlation_id: customer.correlation_id,
      source_system_name: customer.source_system_name,
      payload: {
        type: 'customer',
        data: {
          ...customer,
        },
      },
    },
  })
}
