import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LinkWrapper } from '../../index'
import { formatPhoneNumber, searchHelper } from '../../../helpers'

function OrderSuggestions({ items, query }) {
  return (
    <div className="search-suggestions-customers">
      {items.map((item) => (
        <LinkWrapper
          key={item.id}
          to={`/order/${item.id}`}
          className="text-decoration-none suggestion d-flex flex-column h-auto"
        >
          <div className="ellipsis">
            <div className="suggestion-title">Заказ {item.increment_id}</div>
            <div className="suggestion-description">
              {searchHelper.markMatch(
                [item.full_name || '', formatPhoneNumber(item.number) || '']
                  .filter((value) => Boolean(value))
                  .join(' / '),
                query,
              )}
            </div>
          </div>
        </LinkWrapper>
      ))}
    </div>
  )
}
const mapStateToProps = (state) => ({
  query: state.search.query,
})
export default connect(mapStateToProps)(OrderSuggestions)

OrderSuggestions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  query: PropTypes.string.isRequired,
}
