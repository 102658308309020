import React, { FC, ReactNode } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../redux/model'

interface Props {
  children: ReactNode
  sidebarIsOpened: boolean
}

const Content: FC<Props> = ({ children, sidebarIsOpened }) => {
  return (
    <div className={`container-fluid flex-fill main-content ${sidebarIsOpened ? 'open' : ''}`}>
      {children}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  sidebarIsOpened: state.app.sidebarIsOpened,
})

export default connect(mapStateToProps)(Content)
