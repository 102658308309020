import { useEffect, useState } from 'react'
import axios from 'axios'
import { commonErrorText } from '../constants'

export function useBalance({
  gateway,
  token,
  refreshToken,
  loyaltyProgramId,
  shopCode,
  terminalId,
  purchaseDate,
  mobilePhone,
  cardNumber,
  amount,
  products,
  onError,
}) {
  const [bonusBalance, setBonusBalance] = useState({})
  const [isNotFound, setIsNotFound] = useState(false)

  useEffect(() => {
    if (token && loyaltyProgramId && shopCode && terminalId) {
      axios
        .post(
          `${gateway}/api/LoyaltyService/getInfo`,
          {
            shopCode,
            terminalId,
            date: purchaseDate,
            loyaltyProgram: loyaltyProgramId,
            client: {
              mobilePhone,
              cardNumber,
            },
            amount: amount * 100,
            products,
          },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((r) => {
          if (r.status === 401) {
            refreshToken()
          } else {
            return r.data
          }
        })
        .then((r) => {
          onError(null)
          setBonusBalance(r.data ?? {})
        })
        .catch((e) => {
          if (e.response?.data?.error?.length) {
            setBonusBalance({})
            if (e.response.data.error.includes('Exception: client_not_found')) {
              setIsNotFound(true)
            } else {
              onError(e.response.data.error[0])
            }
          } else {
            onError(commonErrorText)
          }
        })
    }
  }, [
    loyaltyProgramId,
    shopCode,
    terminalId,
    amount,
    cardNumber,
    gateway,
    mobilePhone,
    products,
    purchaseDate,
    token,
    onError,
    refreshToken,
  ])

  return {
    bonusBalance,
    isNotFound,
  }
}
