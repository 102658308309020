import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Layout/Modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yup } from '../../yup/index'
import { Button } from '../Layout/Button/Button'
import style from './TechSupport.module.scss'
import { InputField } from '../Layout/form/InputField'
import { Form } from '../Layout/form/Form'
import { ImageInputField } from '../Layout/form/ImageInputField'
import { usePostFeedbackMutation } from '../../api/feedback'
import clsx from 'clsx'
import Loader from '../Layout/Loader'

const schema = yup.object().shape({
  message: yup.string().required().min(3).max(1000),
})

export function TechSupport({ isOpen, toggle, close }) {
  const [isFormSuccess, setIsFormSuccess] = useState(false)
  const { mutate, isSuccess, isLoading, isError } = usePostFeedbackMutation()

  const form = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setIsFormSuccess(isSuccess)
  }, [isSuccess])

  useEffect(() => {
    if (isFormSuccess) {
      setTimeout(close, 2000)
      setTimeout(() => {
        setIsFormSuccess(false)
      }, 3000)
    }
  }, [close, isFormSuccess])

  const onSubmit = ({ message, images }) => {
    const data = {
      message,
      name: '',
      email: '',
      images: images.map(({ base64, value }) => {
        return {
          base64_encoded_data: base64,
          type: value.type,
          name: value.name,
        }
      }),
    }

    mutate(data)
  }

  const content = (() => {
    if (isLoading) {
      return <Loader />
    }

    if (isFormSuccess) {
      return (
        <div>
          <span className={clsx('lnr lnr-checkmark-circle text-success', style.icon)} />
          <h1 className="mt-2">Сообщение отправлено</h1>
        </div>
      )
    }

    return (
      <Form form={form} onSubmit={onSubmit}>
        <InputField
          required
          label="Ваше сообщение"
          type="textarea"
          name="message"
          className={style.input}
        />
        <div className="text-left mt-3 mb-3">
          <ImageInputField name="images" previewPosition="top" />
        </div>
        {isError && <div className="text-danger mb-2">Ошибка сервера, повторите запрос</div>}
        <Button className="height-40" type="submit" color="primary" block>
          {isError ? 'Повторить' : 'Отправить'}
        </Button>
      </Form>
    )
  })()

  return (
    <Modal
      withIcon={false}
      isOpenExternal={isOpen}
      onToggle={toggle}
      title={isSuccess ? '' : 'Техподдержка'}
      buttonToolbar={() => {}}
      message={content}
    />
  )
}

TechSupport.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}
