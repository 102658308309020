import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { SearchForm, SearchResult } from '../components'
import { actions, thunks } from '../redux/redusers'

export const groupSearchCriteria = (isInStock) => {
  const filters = [
    {
      field: 'is_saleable',
      value: true,
    },
  ]

  if (isInStock) {
    filters.push({
      field: 'is_in_stock',
      value: true,
    })
  }

  return [
    {
      name: 'products',
      search_criteria: {
        filter_groups: [
          {
            filters,
          },
        ],
      },
    },
  ]
}

function Search({
  requestId,
  page,
  query,
  setQuery,
  setPage,
  getSearchResult,
  clearSearch,
  isInStock,
}) {
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const q = searchParams.get('q')

  useEffect(() => {
    if (q) {
      getSearchResult({
        query: q,
        groupSearchCriteria: groupSearchCriteria(isInStock),
      })
    }
  }, [isInStock, q, getSearchResult])

  if (q && !requestId) {
    clearSearch()
    setQuery(q)
    getSearchResult({ query: q, groupSearchCriteria: groupSearchCriteria(isInStock) })
  }

  const handleShowMore = (activeTab) => {
    const currentPage = {
      ...page,
      [activeTab]: page[activeTab] + 1,
    }
    setPage(currentPage)
    getSearchResult({
      query,
      page: currentPage,
      group: activeTab,
      mergeResult: true,
      groupSearchCriteria: groupSearchCriteria(isInStock),
    })
  }

  return (
    <div className="search h-100">
      <SearchForm />
      <SearchResult onShowMore={handleShowMore} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  requestId: state.search.requestId,
  result: state.search.result,
  query: state.search.query,
  page: state.search.page,
  isLoadingMore: state.search.isLoadingMore,
  isInStock: state.search.isInStock,
})

const mapDispatchToProps = {
  setQuery: actions.search.setSearchQuery,
  clearSearch: actions.search.clearSearch,
  setPage: actions.search.setSearchPage,
  getSearchResult: thunks.search.getSearchResult,
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)

Search.propTypes = {
  requestId: PropTypes.string,
  result: PropTypes.shape({ request_id: PropTypes.string }),
  query: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  getSearchResult: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
}
