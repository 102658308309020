import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  CreateNewCustomer,
  CartCustomer,
  CustomerSearch,
  Loader,
  Modal,
} from '../../index'
import { customer, cart } from '../../../redux/actions'
import {
  customerShape,
  cartMode,
  deliveryOptionPickup,
  DELIVERY_OPTION_DELIVERY,
} from '../../../constants'
import {
  CUSTOMER_UPDATED_STATE,
  CUSTOMER_WAIT_STATE,
  STATUS_CHANGED_EXTERNAL,
  STATUS_SUCCESS,
} from '../../../api/ws/customer/constants'
import { Button } from 'reactstrap'
import { connect, useDispatch } from 'react-redux'
import { getCartForFetch } from '../../../redux/selectors/cart'
import { useHistory } from 'react-router-dom'
import { useSubmitQuery } from '../../../api/cart'
import { customerHelper } from '../../../helpers'
import { removeAllWaitingItems } from '../../../redux/actions/cart'
import { useNotification } from '../../../contexts/NotificationProvider/NotificationProvider'
import { useSwitcher } from '../../../hooks/useSwitcher'
import EditCartCustomer from './EditCartCustomer'
import * as actions from '../../../redux/types'

function CartSelectCustomer({
  nextStep,
  customer,
  setCartCustomer,
  createCustomer,
  cart,
  instanceId,
  customerIsCreating,
  customerIsCreated,
  clearCart,
  responseStatus,
  resetLastResponseStatus,
  resetCustomerEdited,
  customerState,
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { showSuccess } = useNotification()
  const [unregister, setUnregister] = useState(false)
  const [isNewCustomer, setIsNewCustomer] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const hasWaitingItems = cart.waiting_items && cart.waiting_items.length !== 0
  const proceedModalSwitcher = useSwitcher()
  const dangerModalSwitcher = useSwitcher()
  const removingWaitingItemsSwitcher = useSwitcher()
  const editingCartCustomer = useSwitcher()

  const showEditCartCustomer = () => {
    if (customerIsCreating) {
      setLoading(true)
    } else {
      setLoading(false)
    }

    editingCartCustomer.toggle()
  }

  const onSetCustomer = (item) => {
    setLoading(true)
    setIsNewCustomer(false)
    setCartCustomer(customerHelper.searchToCustomerMapping(item))
  }

  const onSetNewCustomer = () => {
    setLoading(true)
    setIsNewCustomer(true)
  }

  const onSetNewCustomerCancel = () => {
    setLoading(false)
    setIsNewCustomer(false)
  }

  const saveNewCustomer = (data) => {
    createCustomer(data)
    dispatch({ type: actions.setCartCustomer, data: {} })
  }

  useEffect(() => {
    if (customer?.customer_id) {
      setLoading(false)
      setIsNewCustomer(false)
      setUnregister(false)
    }
  }, [customer?.customer_id])

  const handleSetUnRegister = () => {
    if (!unregister) {
      setCartCustomer({})
    }
    setUnregister(!unregister)
  }

  const hasDelivery = useMemo(
    () =>
      cart.items.every(({ is_in_stock, delivery_type }) => {
        if (!delivery_type) {
          return false
        }
        if (delivery_type === deliveryOptionPickup) {
          return is_in_stock
        }
        return true
      }),
    [cart.items],
  )

  const allItemsHasPickup = useMemo(
    () => cart.items.every(({ delivery_type }) => delivery_type === deliveryOptionPickup),
    [cart.items],
  )

  const allItemsHasPickupOrNotSet = useMemo(
    () => cart.items.every(({ delivery_type }) => delivery_type !== DELIVERY_OPTION_DELIVERY),
    [cart.items],
  )

  useEffect(() => {
    if (responseStatus === STATUS_SUCCESS) {
      resetLastResponseStatus()
      editingCartCustomer.off()
    }
  }, [responseStatus])

  useEffect(() => {
    if (!allItemsHasPickup && unregister) {
      setUnregister(false)
    }
  }, [allItemsHasPickup, unregister, setUnregister])

  useEffect(() => {
    if (customerIsCreated && Boolean(customer?.customer_id)) {
      setCartCustomer(customer)
    }
  }, [customerIsCreated, setCartCustomer, customer])

  useEffect(() => {
    if (customerIsCreated && customer?.customer_id) {
      showSuccess({
        body: 'Клиент успешно создан',
      })
    }
  }, [customer?.customer_id, customerIsCreated, showSuccess])

  useEffect(() => {
    if (customerState === CUSTOMER_UPDATED_STATE && responseStatus === STATUS_SUCCESS) {
      showSuccess({
        title: 'Внимание',
        body: 'Данные успешно сохранены',
      })
      resetCustomerEdited()
    }
  }, [customerState, responseStatus])

  const submitQuery = useSubmitQuery()

  const handleCreateOrder = () => {
    if (hasWaitingItems) {
      proceedModalSwitcher.on()
    }
    if (!hasWaitingItems) {
      submitQuery.mutate({ cart, appId: instanceId })
    }
  }

  const handleNextStep = () => {
    if (hasWaitingItems) {
      proceedModalSwitcher.on()
    }
    if (!hasWaitingItems) {
      nextStep()
    }
  }

  const handleProceed = async () => {
    proceedModalSwitcher.off()
    removingWaitingItemsSwitcher.on()
    await dispatch(removeAllWaitingItems())
    removingWaitingItemsSwitcher.off()
    if (allItemsHasPickup) {
      submitQuery.mutate({ cart, appId: instanceId })
    }
    if (!allItemsHasPickup) {
      nextStep()
    }
  }

  useEffect(() => {
    if ((submitQuery.isSuccess && !submitQuery.data) || submitQuery.isError) {
      dangerModalSwitcher.on()
    }
  }, [submitQuery.isSuccess, submitQuery.data, submitQuery.isError])

  if (customerState === CUSTOMER_WAIT_STATE) {
    return <Loader />
  }

  if (submitQuery.isLoading || removingWaitingItemsSwitcher.isOn) {
    return <Loader />
  }

  if (submitQuery.isSuccess && submitQuery.data) {
    const {
      cart: { orderId },
    } = submitQuery.data
    clearCart()
    history.push(`/order/${orderId}`)
  }

  return (
    <div className="cart-wizard-select-customer">
      <div className="bg-secondary br-5 cart-wizard-body">
        <h2 className="text-gray d-flex align-items-center ">Шаг 1. Выбор Клиента</h2>

        <CustomerSearch
          onSetCustomer={onSetCustomer}
          onSetNewCustomer={onSetNewCustomer}
          needButton={true}
        />

        {allItemsHasPickupOrNotSet && (
          <Checkbox
            checked={unregister}
            name="unregister"
            onChange={handleSetUnRegister}
            labelName="Обезличенная продажа?"
            labelClassName="ml-1"
            wrapperClassName="mt-3"
            disabled={!allItemsHasPickupOrNotSet}
          />
        )}

        {isNewCustomer && (
          <CreateNewCustomer
            mode={cartMode}
            createCustomer={saveNewCustomer}
            cancel={onSetNewCustomerCancel}
          />
        )}

        {isLoading && !isNewCustomer && !editingCartCustomer.isOn && <Loader />}

        {Boolean(customer.given_name) &&
          !isNewCustomer &&
          customer?.customer_id &&
          (!editingCartCustomer.isOn ? (
            <CartCustomer showEditCartCustomer={showEditCartCustomer} />
          ) : (
            <EditCartCustomer showEditCartCustomer={showEditCartCustomer} />
          ))}
      </div>
      <div className="mt-3 cart-wizard-footer">
        {!allItemsHasPickup ? (
          <Button
            className="float-right height-40"
            color="primary"
            onClick={handleNextStep}
            disabled={!hasDelivery || !customer?.customer_id}
          >
            Оформить доставку
          </Button>
        ) : (
          <Button
            className="float-right height-40"
            color="primary"
            onClick={handleCreateOrder}
            disabled={!unregister && !customer?.customer_id}
          >
            Оформить заказ
          </Button>
        )}
        <Modal
          color="warning"
          btnClasses="btn btn-outline-primary"
          needRenderIcon={false}
          onToggle={() => proceedModalSwitcher.toggle()}
          isOpenExternal={proceedModalSwitcher.isOn}
          title="Внимание!"
          message="Создаваемые товары будут удалены из корзины. Вы уверены, что хотите продолжить?"
          buttonToolbar={(toggle) => (
            <>
              <button
                className="btn btn-secondary modal_cancel height-40"
                onClick={() => proceedModalSwitcher.off()}
              >
                Отмена
              </button>
              <button
                className="btn btn-warning modal_ok height-40"
                color="warning"
                onClick={handleProceed}
              >
                Продолжить
              </button>
            </>
          )}
        />
      </div>
      {responseStatus === STATUS_CHANGED_EXTERNAL && (
        <Modal
          color="warning"
          renderIcon={() => {}}
          isOpenExternal
          title="Внимание"
          message={
            <div>
              В карточку клиента были внесены изменения другими пользователями.
              <br /> <br />
              Пожалуйста, посмотрите измененные данные и при необходимости отредактируйте их
              повторно.
            </div>
          }
          onToggle={() => {
            resetLastResponseStatus()
            editingCartCustomer.off()
          }}
          buttonToolbar={(toggle) => (
            <Button className="btn-primary height-40" onClick={toggle} color="primary">
              Перейти к клиенту
            </Button>
          )}
        />
      )}
      <Modal
        color="danger"
        renderIcon={() => {}}
        isOpenExternal={dangerModalSwitcher.isOn}
        title="Ошибка"
        message={
          <div>
            Заказ не оформлен
            <br /> <br />
            {`Ошибка сервера`}
          </div>
        }
        onToggle={() => {
          dangerModalSwitcher.toggle()
        }}
        buttonToolbar={() => {}}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  customer: state.cart.customer.result,
  customerIsCreating: state.cart.customer.isCreating,
  customerIsCreated: state.cart.customer.isCreated,
  instanceId: state.app.instanceId,
  cart: getCartForFetch(state),
  deliveryAddressId: state.cart.delivery_address,
  customerState: state.customer.state,
  responseStatus: state.customer.responseStatus,
})
const mapDispatchToProps = {
  createCustomer: customer.create,
  clearCart: cart.remove,
  setCartCustomer: cart.setCartCustomer,
  startFastOrder: cart.startFastOrder,
  resetCustomerEdited: customer.resetCustomerEdited,
  removeAllWaitingItemsFromCart: cart.removeAllWaitingItems,
  resetLastResponseStatus: customer.resetLastResponseStatus,
}

CartSelectCustomer.propTypes = {
  nextStep: PropTypes.func.isRequired,
  customer: PropTypes.shape(customerShape).isRequired,
  customerIsCreated: PropTypes.bool,
  setCartCustomer: PropTypes.func.isRequired,
  createCustomer: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  startFastOrder: PropTypes.func.isRequired,
  clearCart: PropTypes.func.isRequired,
  instanceId: PropTypes.string.isRequired,
  customerState: PropTypes.string.isRequired,
  resetCustomerEdited: PropTypes.func.isRequired,
  deliveryAddressId: PropTypes.number,
}

CartSelectCustomer.defaultProps = {
  deliveryAddressId: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartSelectCustomer)
