import React, { useEffect, useState } from 'react'
import { cart } from '../../redux/actions'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CartProductList, CartTotals, Modal, CartWizard, Loader } from '../../components'
import pdf from './cart-doc.pdf'
import { DocumentModal } from '../../components/DocumentModal/DocumentModal'
import { useSwitcher } from '../../hooks/useSwitcher'
import { CartAdditionalServices } from '../../components/Cart/CartAdditionalServices'
import {
  getHasDelivery,
  getCartCustomer,
  selectCartCustomerIsCreated,
} from '../../redux/selectors/cart'
import { useNotification } from '../../contexts/NotificationProvider/NotificationProvider'
import { STEP_CART_SELECT_CUSTOMER } from '../../components/Cart/CartWizard/CartWizard'

function Cart({ updateCartCustomer, cart, customerId, isFetchCustomerInProgress, updateCart }) {
  const hasDelivery = useSelector(getHasDelivery)
  const { customer } = useSelector(getCartCustomer)
  const customerIsCreated = useSelector(selectCartCustomerIsCreated)
  const { items, waiting_items: waitingItems, count, cartId, isLoading } = cart
  const cartDocumentSwitcher = useSwitcher()
  const { showError, showSuccess } = useNotification()
  const [step, setStep] = useState(STEP_CART_SELECT_CUSTOMER)
  const nextStep = () => setStep(step + 1)
  const previousStep = () => setStep(step - 1)
  const resetSteps = () => setStep(STEP_CART_SELECT_CUSTOMER)
  useEffect(() => {
    return () => {
      if (!count && cartId) {
        updateCartCustomer()
      }
    }
  }, [updateCartCustomer, count, cartId])

  useEffect(() => {
    if (customerIsCreated && !customer?.customer_id) {
      const message = (
        <>
          Новый клиент{' '}
          <b>
            {[customer.surname, customer.given_name, customer.middle_name]
              .filter(Boolean)
              .join(' ')}
          </b>{' '}
          принят к сохранению
        </>
      )

      showSuccess({
        body: message,
      })
    }
  }, [
    customer?.surname,
    customer?.given_name,
    customer?.middle_name,
    customer?.customer_id,
    customerIsCreated,
    showSuccess,
  ])

  useEffect(
    function sendNotificationCartError() {
      if (cart?.error) {
        showError({ title: 'Error during update cart', body: cart.error })
        updateCart()
      }
    },
    [cart.error, showError, updateCart],
  )
  if (items.length === 0 && waitingItems.length === 0) {
    return (
      <div className="bg-secondary br-5 empty-cart-block d-flex align-items-center justify-content-center flex-column">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h1 className="mx-2 ">Ваша корзина пуста.</h1>
            <div className="text-lg">
              Воспользуйтесь <Link to="/">поиском</Link>, чтобы найти все, что нужно
            </div>
          </>
        )}
      </div>
    )
  }
  return (
    <>
      <div className="cart">
        <div className="cart-header d-flex align-items-center justify-content-between">
          <h1 className="d-flex align-items-center m-0">Корзина POS-{cartId}</h1>
          <div className="d-flex align-items-center">
            <Modal
              color="warning"
              btnClasses="btn btn-outline-primary height-40"
              btnText="Очистить корзину"
              title="Внимание!"
              message="Все позиции корзины будут удалены. Вы уверены, что хотите продолжить?"
              buttonToolbar={(toggle) => (
                <>
                  <button className="btn btn-secondary modal_cancel height-40" onClick={toggle}>
                    Отмена
                  </button>
                  <button
                    className="btn btn-warning modal_ok height-40"
                    color="warning"
                    onClick={() => {
                      updateCartCustomer()
                      toggle()
                    }}
                  >
                    Очистить
                  </button>
                </>
              )}
            />
          </div>
        </div>
        <div className="bg-secondary br-5">
          <CartProductList resetSteps={resetSteps} />
          {hasDelivery && (
            <CartAdditionalServices
              isUpdateRequestInProgress={
                cart.isUpdateRequestInProgress || isFetchCustomerInProgress
              }
            />
          )}
          <CartTotals />
        </div>
        <CartWizard step={step} nextStep={nextStep} previousStep={previousStep} />
      </div>
      <DocumentModal
        subtitle="Соглашение о намерениях"
        name="Соглашение о намерениях (предзаказ).pdf"
        isOpen={cartDocumentSwitcher.isOn}
        toggle={cartDocumentSwitcher.toggle}
        pathname={pdf}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  customerId: state.cart.customer.result?.customer_id,
  cart: state.cart,
  isFetchCustomerInProgress: state.search.isLoading,
})
const mapDispatchToProps = {
  updateCartCustomer: cart.remove,
  updateCart: cart.refetchCart,
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)

Cart.propTypes = {
  updateCartCustomer: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
