import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProduct, productParametersDataSelector } from '../../redux/selectors/product'

import { thunks } from '../../redux/redusers'
import { AttributeGroupBuilder } from '../../screens/Constructor/components/builders/AttributeGroupBuilder'
import { useFocusNext } from '../../hooks/useFocusNext'
import {
  Attribute,
  AttributeGroup,
  AttributeOption,
} from '../../redux/slices/constructor/model/dto'
import { Container } from 'reactstrap'

export const ProductParameters = () => {
  const dispatch = useDispatch()
  const item = useSelector(getProduct)
  const parametersDataProduct = useSelector(productParametersDataSelector)
  const focusNextRef = useFocusNext()

  const attributeHandler = useCallback(
    (attribute: Attribute, attributeOption: AttributeOption, groupName?: string) => {
      dispatch(
        thunks.product.handleParametersData({
          attribute,
          attributeOption,
        }),
      )
      if (attribute?.extension_attributes?.dependence?.attribute) {
        const dependenceAttribute = item.configurable_attribute_groups
          .find((group: AttributeGroup) => group.group_name === groupName)
          .configurable_attributes.find(
            (attr: Attribute) =>
              attr.attribute_code === attribute?.extension_attributes?.dependence?.attribute,
          )

        const dependenceAttributeOption = dependenceAttribute.options.find(
          (option: AttributeOption) =>
            Number(option.value) ===
            Number(attributeOption.value) +
              Number(attribute?.extension_attributes?.dependence?.difference),
        )

        dispatch(
          thunks.product.handleParametersData({
            attribute: dependenceAttribute,
            attributeOption: dependenceAttributeOption,
          }),
        )
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (item) {
      // TODO убрать ts-ignore после того, как будет типизирован редюсер продукта
      // @ts-ignore
      item.configurable_attribute_groups?.forEach((groupItem: AttributeGroup) => {
        groupItem.configurable_attributes?.forEach((attributesItem) => {
          dispatch(
            thunks.product.handleParametersData({
              attribute: attributesItem,
              attributeOption:
                [...attributesItem.options].find((option) => {
                  return Number(option.option_id) === Number(attributesItem.default_option_id)
                }) ||
                [...attributesItem.options].sort((a, b) => {
                  if (isNaN(Number(a.value)) || isNaN(Number(b.value))) return 0
                  return Number(a.value) - Number(b.value)
                })[0],
            }),
          )
        })
      })
    }
  }, [item, dispatch])

  // TODO убрать ts-ignore после того, как будет типизирован редюсер продукта
  // @ts-ignore
  const groups = item?.configurable_attribute_groups?.map((item: AttributeGroup) => (
    <AttributeGroupBuilder
      key={item.group_name}
      data={parametersDataProduct}
      handler={attributeHandler}
      group={item}
      focusNextRef={focusNextRef}
      groupName={item.group_name}
    />
  ))

  return <Container>{groups}</Container>
}
