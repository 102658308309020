import React, { FC } from 'react'
import { Block } from '../../../components/Layout/Block/Block'
import { Form } from '../../../components/Layout/form/Form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '../../../components/Layout/Button/Button'
import { Col, Row } from 'reactstrap'
import { ImageInputField } from '../../../components/Layout/form/ImageInputField'
import { filesStepSchema } from '../schemas'
import { ImageInput } from '../model'

interface Props {
  onNext: () => void
  onBack: () => void
}

interface FieldValues {
  passportGeneralPhoto: ImageInput[]
  passportRegistrationPhoto: ImageInput[]
  photo: ImageInput[]
}

export const FilesStep: FC<Props> = ({ onBack, onNext }) => {
  const form = useForm<FieldValues>({
    resolver: yupResolver(filesStepSchema),
    defaultValues: {},
  })

  function onSubmit(values: FieldValues) {
    onNext()
  }

  return (
    <Form<FieldValues> form={form} onSubmit={onSubmit}>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>} />
      <Block mb={0} title="БИОМЕТРИЯ">
        <Row>
          <Col xs={4}>
            <ImageInputField name="photo">
              <Button className="pointer-events-none height-40" color="outline-primary">
                Фото клиента
              </Button>
            </ImageInputField>
          </Col>
          <Col xs={4}>
            <ImageInputField name="passportGeneralPhoto">
              <Button className="pointer-events-none height-40" color="outline-primary">
                Фото паспорта (2 и 3 страницы)
              </Button>
            </ImageInputField>
          </Col>
          <Col xs={4}>
            <ImageInputField name="passportRegistrationPhoto">
              <Button className="pointer-events-none height-40" color="outline-primary">
                Фото паспорта (регистрация)
              </Button>
            </ImageInputField>
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="ЗАЯВЛЕНИЕ">
        <Button
          color="outline-primary"
          className="mr-4 height-40"
          icon={<span className="lnr lnr-eye" />}
        >
          Просмотр
        </Button>
        <Button
          color="outline-primary"
          className="mr-4 height-40"
          icon={<span className="lnr lnr-printer" />}
        >
          Печать
        </Button>
        <Button
          color="outline-primary"
          className="mr-4 height-40"
          icon={<span className="lnr lnr-download" />}
        >
          Вложить файл
        </Button>
      </Block>
      <Block pt={4}>
        <div className="d-flex">
          <Button
            size="lg"
            type="submit"
            color="outline-dark"
            className="height-40"
            onClick={onBack}
          >
            Назад
          </Button>
          <Button size="lg" type="submit" color="primary" className="ml-auto height-40">
            Далее
          </Button>
        </div>
      </Block>
    </Form>
  )
}
