import React from 'react'
import PropTypes from 'prop-types'

export default function Error({ text }) {
  return <div className="askonaWidget-loyaltyProgram-error">{text}</div>
}

Error.propTypes = {
  text: PropTypes.string,
}
