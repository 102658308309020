import React from 'react'
import { searchTypes, searchSuggestionsCount, productProperties } from '../constants'

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const getTotals = (result) => {
  const totals = { all: getTotal(result) }
  if (result?.results?.length) {
    for (const resultItem of result.results) {
      totals[resultItem.metadata?.group_id] = resultItem.metadata?.total || 0
    }
  }
  return totals
}

export const getSortedResults = (result, sortOrder) => {
  const results = []
  if (result?.results?.length) {
    for (const group of sortOrder) {
      const resultItem = result.results.find((item) => item?.metadata?.group_id === group)
      if (resultItem) {
        results.push(resultItem)
      }
    }
  }
  return results
}

export const getTotal = (result) =>
  result?.results?.reduce((prev, cur) => prev + cur?.metadata?.total, 0) || 0

export const getGroup = (result, type) =>
  result?.results?.find((result) => result?.metadata?.group_id === type) || {}

export const getSuggestions = (result) => {
  const suggestions = {}
  const sortedResults = getSortedResults(result, [
    searchTypes.products,
    searchTypes.customers,
    searchTypes.orders,
  ])
  let added = 0

  if (sortedResults?.length) {
    for (const resultItem of sortedResults) {
      const group = resultItem.metadata?.group_id
      if (resultItem.items) {
        for (const item of resultItem.items) {
          if (!suggestions?.[group]) {
            suggestions[group] = []
          }
          if (added === searchSuggestionsCount) {
            break
          }
          if (Object.keys(item || {}).length) {
            suggestions[group].push(item)
            added++
          }
        }
      }
    }
  }

  return suggestions
}

export const isProductType = (type) => type === searchTypes.products

export const markMatch = (text, query) => {
  if (!text) {
    return null
  }
  const textArray = text
    .split(new RegExp(`(${escapeRegExp(query)})`, 'ig'))
    .filter((item) => item.length > 0)
  return textArray?.map((part, index) => {
    if (part.toLowerCase() === query.toLowerCase()) {
      return <b key={index}>{part}</b>
    }
    return <span key={index}>{part}</span>
  })
}

export const getFullName = ({ lastname, firstname, middlename, fullname }) => {
  if (fullname) {
    return fullname
  }
  return `${lastname || ''} ${firstname || ''} ${middlename || ''}`
}

export const getPropertyName = (property) => productProperties[property] || ''

export const mergeSearchResults = (oldData, newData) => {
  const mergedData = { id: newData.id, request_id: newData.request_id, suggests: newData.suggests }

  if (!oldData?.results?.length) {
    mergedData.results = newData.results
  } else {
    mergedData.results =
      oldData.results?.map((oldItem) => {
        const newItem = newData.results.find(
          (item) => item?.metadata?.group_id === oldItem?.metadata?.group_id,
        )
        return newItem
          ? {
              metadata: newItem.metadata,
              items: [...(oldItem.items || []), ...newItem.items],
            }
          : oldItem
      }) || []
  }

  return mergedData
}
