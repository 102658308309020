import React from 'react'
import { connect } from 'react-redux'
import { format, isValid } from 'date-fns'
import PropTypes from 'prop-types'

import { BonusBalance, AddressesList, CustomerSubscriptions } from '../'
import { customerShape } from '../../constants'
import { formatPhoneNumber } from '../../helpers'

function CustomerProfile({ onEdit, customer }) {
  const birthDate = new Date(customer.birth_date)
  const birthDateFormatted =
    customer.birth_date && isValid(birthDate) ? format(birthDate, 'dd.MM.yyyy') : 'Не указана'
  return (
    <div className="customer-profile ">
      <div className="row">
        <h3 className="col-9 title">
          {customer.surname} {customer.given_name} {customer.middle_name}
        </h3>
        <div className="col">
          <button
            className="btn btn-outline-primary form-control btn-edit height-40"
            onClick={onEdit}
          >
            Редактировать
          </button>
        </div>
      </div>
      <div className="row pt-25">
        <div className="col">
          <div className="bg-secondary info-block">
            <p className="text-uppercase">Контактная информация</p>
            <div className="d-flex justify-content-start">
              <div className="w-25">Телефон</div>
              <div>
                <img
                  src={require(`../../images/icons/${
                    customer.is_confirmed_phone ? 'confirmed.svg' : 'unconfirmed.svg'
                  }`)}
                  alt=""
                />
                {formatPhoneNumber(customer.phone)}
              </div>
            </div>
            {/*<div className="d-flex justify-content-between mt-3">*/}
            {/*  <div>Доп. телефон</div>*/}
            {/*  <div>{customer.extra_phone}</div>*/}
            {/*</div>*/}
            {!customer.is_fake_email && (
              <div className="d-flex justify-content-start mt-4">
                <div className="w-25">E-mail</div>
                <div>
                  <img
                    src={require(`../../images/icons/${
                      customer.is_confirmed_email ? 'confirmed.svg' : 'unconfirmed.svg'
                    }`)}
                    alt=""
                  />
                  {customer.email}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-start mt-4">
              <div className="w-25">Рассылка</div>
              <CustomerSubscriptions subscriptions={customer.subscriptions} disabled />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="bg-secondary info-block d-flex flex-column">
            <BonusBalance />
          </div>
        </div>
      </div>

      <div className="row pt-25">
        <div className="col">
          <div className="bg-secondary info-block">
            <p className="text-uppercase">ИНФОРМАЦИЯ О КЛИЕНТЕ</p>
            <div className="d-flex mt-3">
              <div className="info-block-title">Дата рождения</div>
              <div>{birthDateFormatted}</div>
            </div>
            <div className="d-flex mt-3">
              <div className="info-block-title">Статус клиента</div>
              <div>{customer.status}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-25">
        <div className="col">
          <div className="bg-secondary info-block overflow-auto">
            <AddressesList addresses={customer.addresses} />
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  customer: state.customer.result,
})

CustomerProfile.propTypes = {
  customer: PropTypes.shape(customerShape).isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(CustomerProfile)
