import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Col, Container, Input, Row } from 'reactstrap'
import 'rc-slider/assets/index.css'
import { AttributeProps } from '../../model'
import { getProduct } from '../../../../redux/selectors/product'
import { useSelector } from 'react-redux'
import {
  Attribute,
  AttributeGroup,
  AttributeOption,
} from '../../../../redux/slices/constructor/model/dto'

export const SliderAttribute: FC<AttributeProps> = ({
  attribute,
  data,
  handler,
  focusNextRef,
  groupName,
}) => {
  const values = attribute.options.map((item) => Number(item.value)).sort((a, b) => a - b)
  const item = useSelector(getProduct)
  if (attribute?.extension_attributes?.dependence?.attribute) {
    const dependenceAttribute = item.configurable_attribute_groups
      .find((group: AttributeGroup) => group.group_name === groupName)
      .configurable_attributes.find(
        (attr: Attribute) =>
          attr.attribute_code === attribute?.extension_attributes?.dependence?.attribute,
      )
    values.filter((value) => {
      return dependenceAttribute.options.find(
        (option: AttributeOption) => Number(option.value) === Number(value),
      )
    })
  }

  const marks: Record<number, number> = {}
  values.forEach((item) => (marks[item] = item))

  const min = values[0]
  const max = values[values.length - 1]
  const initialValue = data.get(attribute)?.value ?? min

  const [inputValue, setInputValue] = useState(initialValue)

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  const handleInputOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value)
    const closestValue = getClosestValue(value, values)
    setInputValue(String(closestValue))
  }

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    setInputValue(value)
  }

  return (
    <Container className="constructor-size" fluid>
      <Row>
        <p className="font-weight-bold">{attribute.frontend_label}</p>
      </Row>
      <Row className="align-items-center">
        <Col xs="3" className="pl-0">
          <div>
            {min}-{max}
          </div>
        </Col>
        <Col xs="3" className="pl-0 input-container">
          <Input
            type="number"
            value={inputValue}
            onBlur={handleInputOnBlur}
            onChange={handleInputOnChange}
            innerRef={focusNextRef}
            disabled={attribute?.extension_attributes?.disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}

const getClosestValue = (value: number, values: number[]) => {
  return values.reduce((acc, cur) => (Math.abs(cur - value) < Math.abs(acc - value) ? cur : acc))
}
