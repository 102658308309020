import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from '../'

function CustomerSubscriptions({ disabled, subscriptions, onChange, emailState }) {
  return (
    <div className="d-flex flex-column">
      <Checkbox
        disabled={disabled}
        checked={subscriptions?.sms}
        name="sms"
        onChange={onChange}
        labelName="SMS"
        labelClassName="ml-1"
      />
      <Checkbox
        disabled={disabled || emailState}
        checked={subscriptions?.email}
        name="email"
        onChange={onChange}
        labelName="E-mail"
        wrapperClassName="mt-3"
        labelClassName="ml-1"
      />
      <Checkbox
        disabled={disabled}
        checked={subscriptions?.phone}
        name="phone"
        onChange={onChange}
        labelName="Звонки"
        wrapperClassName="mt-3"
        labelClassName="ml-1"
      />
    </div>
  )
}

CustomerSubscriptions.propTypes = {
  disabled: PropTypes.bool,
  subscriptions: PropTypes.shape({
    sms: PropTypes.bool,
    phone: PropTypes.bool,
    email: PropTypes.bool,
  }),
  onChange: PropTypes.func,
}
CustomerSubscriptions.defaultProps = {
  disabled: false,
  subscriptions: [],
  onChange: null,
}

export default CustomerSubscriptions
