import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { fetchProductBySku } from '../../../../api/product'
import { ProductResponse } from '../model'

export const getProductBySku = createAsyncThunk<ProductResponse, string, AppThunkApiConfig>(
  'product/getProductBySku',
  async (sku: string) => {
    const result = await fetchProductBySku(sku)
    return {
      result: result,
      additional: {},
    }
  },
)
