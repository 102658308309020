import React from 'react'

import { CartSelectCustomer, CartRegistrationOfDelivery, CartAdditionalInfo } from '../../'

export const STEP_CART_SELECT_CUSTOMER = 1
export const STEP_CART_DELIVERY_REGISTRATION = 2
export const STEP_CART_ADDITIONAL_INFO = 3

function CartWizard({ step, nextStep, previousStep }) {
  return (
    <div className="mt-3 cart-wizard">
      {step === STEP_CART_SELECT_CUSTOMER && <CartSelectCustomer nextStep={nextStep} />}
      {step === STEP_CART_DELIVERY_REGISTRATION && (
        <CartRegistrationOfDelivery nextStep={nextStep} previousStep={previousStep} />
      )}
      {step === STEP_CART_ADDITIONAL_INFO && <CartAdditionalInfo previousStep={previousStep} />}
    </div>
  )
}

export default CartWizard
