import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classnames from './Cart.module.scss'
import { Modal } from '../../components'
import clsx from 'clsx'
import { getLabel } from '../../helpers/product'
import clockIcon from '../../images/icons/arrow-clock.svg'
import dangerIcon from '../../images/icons/danger.svg'
import { formatPriceRu } from '../../helpers/currency'
import { Item } from '../../api/dto/CartBasicTypes'

type Props = {
  item: Item
  onDelete: (item: Item) => void
}

export const CartWaitingProductLine: FC<Props> = ({ item, onDelete }) => {
  const isInProcess = item.status === 'waiting'

  return (
    <div className={classnames.waitingItem}>
      <div className="main d-flex align-items-start justify-content-between">
        <div className="col-5 d-flex align-items-start pr-0">
          <img src={item.image} alt={item.name} className={classnames.waitingItemImage} />
          <div className="ml-md-4 mr-2">
            <Link
              to={`/product/${item.master_product_sku}`}
              className={clsx('text-decoration-none', classnames.waitingItemLink)}
            >
              {item.name}
            </Link>
            <div>
              {item.configurable_attributes.map((attr) => {
                if (attr.code && attr.value) {
                  return <div>{`${getLabel(attr.code).toLowerCase()}: ${attr.value}`}</div>
                }
                return null
              })}
            </div>
          </div>
        </div>

        <div className="col-7 d-flex align-items-start justify-content-end px-0">
          {isInProcess ? (
            <div className={clsx(classnames.statusAlert, classnames.statusAlertProcess)}>
              <img className={classnames.statusAlertIcon} src={clockIcon} />
              Идет создание товара
            </div>
          ) : (
            <div className={clsx(classnames.statusAlert, classnames.statusAlertError)}>
              <img className={classnames.statusAlertIcon} src={dangerIcon} />
              Ошибка создания товара
            </div>
          )}
          <div className={clsx('flex-column col-4 mt-1 text-right', classnames.waitingItemPrice)}>
            {formatPriceRu(item.final_amount)}
          </div>

          <div className="delete ml-4 mt-1 text-right">
            {onDelete && (
              <Modal
                color="warning"
                renderIcon={(toggle) => (
                  <span
                    className={clsx('lnr lnr-trash', classnames.trashButton)}
                    onClick={toggle}
                  />
                )}
                btnClasses="btn btn-primary form-control"
                title="Требуется подтверждение!"
                message="Выбранный товар будет удален из корзины. Вы уверены, что хотите продолжить?"
                buttonToolbar={(toggle) => (
                  <>
                    <button className="btn btn-secondary modal_cancel height-40" onClick={toggle}>
                      Отмена
                    </button>
                    <button
                      className="btn btn-warning modal_ok height-40"
                      color="warning"
                      onClick={() => {
                        toggle()
                        onDelete(item)
                      }}
                    >
                      Удалить
                    </button>
                  </>
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
