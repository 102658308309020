import { StartPayRequest } from './dto/StartPayRequest'
import { captureException, SentryMessageTags } from './sentryCaptures'
import { paymentAxios, START_PAY_URL } from './paymentAxiosInit'

export async function startPay(apiUrl: string, data: StartPayRequest, tags: SentryMessageTags) {
  try {
    const response = await paymentAxios.post(apiUrl + START_PAY_URL, data)
    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
      }
    }
    captureException(tags)
    return {
      success: false,
      errorMessage: `статус запроса ${response.status}`,
    }
  } catch (error) {
    captureException(tags)
    return {
      success: false,
      errorMessage: error.toString(),
    }
  }
}
