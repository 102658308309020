import React, { useCallback, useEffect, useState } from 'react'
import Modal from '../Layout/Modal'
import { Button } from '../Layout/Button/Button'
import PropTypes from 'prop-types'
import { useSwitcher } from '../../hooks/useSwitcher'
import { SuccessCircleCheck } from '../Layout/icons/SuccessCircleCheck'
import { api } from '../../api/api'

export function DocumentModal({ isOpen, toggle, subtitle, pathname, name, onSendEmail }) {
  const emailSuccessSwitcher = useSwitcher()

  useEffect(() => {
    setTimeout(() => {
      if (emailSuccessSwitcher.isOn) {
        emailSuccessSwitcher.off()
      }
    }, 2000)
  }, [emailSuccessSwitcher])

  const [isLoading, setIsLoading] = useState(false)

  const downloadPdf = useCallback(async (withPrint = false) => {
    setIsLoading(true)
    const blob = await api.downloadBlob({ path: pathname })
    setIsLoading(false)
    const blobURL = URL.createObjectURL(blob)
    const tab = window.open(blobURL)
    if (withPrint) {
      tab.onload = () => {
        tab.print()
      }
    }
  }, [])

  return (
    <Modal
      size="large"
      withIcon={false}
      renderIcon={() => {}}
      isOpenExternal={isOpen}
      onToggle={toggle}
      title="Формирование документов"
      titleAlign="left"
      buttonToolbar={() => {
        return (
          <div className="d-flex flex-grow-1 justify-content-between">
            <Button
              className="height-40"
              color="primary"
              onClick={() => downloadPdf()}
              disabled={isLoading}
            >
              Предпросмотр
            </Button>
            <Button
              className="height-40"
              color="primary"
              onClick={() => downloadPdf(true)}
              disabled={isLoading}
            >
              Распечатать
            </Button>
            {emailSuccessSwitcher.isOn ? (
              <div className="d-flex align-items-center">
                <SuccessCircleCheck />
                <span className="ml-2">Отравлено</span>
              </div>
            ) : (
              <Button
                className="height-40"
                color="primary"
                onClick={() => (onSendEmail ? onSendEmail() : emailSuccessSwitcher.on())}
              >
                Отправить на email
              </Button>
            )}
          </div>
        )
      }}
      message={
        <div>
          <h5 className="text-left">{subtitle}</h5>
          <p className="font-weight-light text-left">{name}</p>
        </div>
      }
    />
  )
}

DocumentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  pathname: PropTypes.string,
  name: PropTypes.string,
  onSendEmail: PropTypes.func,
}
