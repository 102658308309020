import React from 'react'
import PropTypes from 'prop-types'
import classNames from './Cart.module.scss'

type Props = {
  label: string
  value: string
  expanded?: boolean
  image?: string
}

export function CartProductAttribute({ label, value, expanded = false, image }: Props) {
  return label ? (
    <div className={classNames.attribute}>
      <span className={classNames.attributeLabel}>{label}: </span>
      {image && <img src={image} width="20" height="20" className={classNames.attributeImage} />}
      <span
        className={`${classNames.attributeValue} ${
          expanded ? classNames.attributeValueExpanded : ''
        }`}
      >
        {value}
      </span>
    </div>
  ) : null
}

CartProductAttribute.propTypes = {
  item: PropTypes.object.isRequired,
}
