import { PrintDetailType } from './PrintDetailType'

export enum ReceiptType {
  PAYMENT,
  RETURN,
}

export type StartPayRequest = {
  requestId: string
  sum: number
  additionalInfo?: string
  paymentType: number
  receiptType: number
  discountSum: number
  advanceSum: number
  startText?: string
  recipientName?: string
  recipientInn?: string
  operatorName: string
  isElectronically?: boolean
  target?: string
  printDetails?: PrintDetailType[]
}
