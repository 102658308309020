import React from 'react'
import { Button } from '../../../components/Layout/Button/Button'
import icon from '../icons/lnr-cross-circle.svg'

type Props = {
  repeatOperation: (() => void) | (() => Promise<void>)
  title: string
  errorComment: React.ReactNode
  buttonText: string
}

export function Error({ repeatOperation, title, errorComment, buttonText }: Props) {
  return (
    <>
      <div className="askona-widget-payment__notify big">
        <div className="askona-widget-payment__title">{title}</div>
        <div className="icon">
          <img src={icon} alt="" />
        </div>
        <div>{errorComment}</div>
      </div>
      <div className="button">
        <Button color="primary" className="height-40" onClick={repeatOperation}>
          {buttonText}
        </Button>
      </div>
    </>
  )
}
