import React, { FC } from 'react'
import { Loader } from '../index'
import { ErrorInfo } from '../../api/dto'

interface Props {
  errorInfo?: ErrorInfo
}

export const ErrorServiceMessage: FC<Props> = ({ errorInfo }) => {
  const content = (() => {
    // TODO: временно убран errorMessage, так как происходит скачок из-за разных сообщений об ошибке
    // if (!errorInfo) return errorMessage
    if (!errorInfo) return ''
    if (!errorInfo[2] || !errorInfo[1]) {
      if (errorInfo[0]) {
        return <p>{errorInfo[0]}</p>
      }
      return <p>Неизвестная ошибка</p>
    }
    const textArray = errorInfo[2].split(errorInfo[1])
    return (
      <p>
        {textArray[0]}
        <a href={'/customer/' + errorInfo[0]}>{errorInfo[1]}</a>
        {textArray[1]}
      </p>
    )
  })()

  return (
    <div>
      {!errorInfo && (
        <div>
          <Loader />
        </div>
      )}
      {content}
    </div>
  )
}
