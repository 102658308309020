import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { productHelper } from '../../helpers'
import { productShape } from '../../constants'
import { actions } from '../../redux/redusers'

function ProductServices({ item, setProductSimple }) {
  if (!item?.services?.length) {
    return null
  }
  /**
   * TODO ASK-181
   * нужно переделать под бэк
   */
  const handleSelectService = (service) => {
    const services = item.services.map((s) => {
      if (s.id !== service.id) return { ...s }
      s.checked = !service.checked
      return { ...s }
    })
    setProductSimple({ ...item, services })
  }
  return (
    <div className="d-flex justify-content-between services">
      <div className="name text-lg">Сервисы:</div>
      <div className="value d-flex flex-wrap">
        {item.services.map((service) => (
          <div key={service.id} className="d-flex align-items-center mb-2">
            <span>
              {service.title}: {productHelper.formatPrice(service.price)}
            </span>
            <span className="currency" />
            <button
              className={`btn btn-plus d-flex align-items-center justify-content-center ml-4 ${
                service.checked && 'inverse-btn'
              }`}
              onClick={() => handleSelectService(service)}
            >
              <span className="lnr lnr-plus-circle text-primary" />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setProductSimple: actions.product.setSimple,
}

export default connect(null, mapDispatchToProps)(ProductServices)

ProductServices.propTypes = {
  item: PropTypes.shape(productShape),
  setProductSimple: PropTypes.func.isRequired,
}
