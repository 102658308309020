import { useRuntimeConfig as getRuntimeConfig } from '../hooks/useRuntimeConfig'
import { actions } from '../redux/redusers'
import * as actionList from '../redux/types'
import { subscribe as authSubscribe, useAuth as getAuth } from '../api/auth'
import { isLocalhost } from '../helpers/isLocalhost'

/**
 * Connects to MQTT-Broker via websocket channel
 * @param store is a Redux store
 */
export const connectWS = (store) => {
  const worker = new Worker('/ws-client.js')
  worker.onmessage = (e) => {
    console.log('Message received from worker:')
    console.log({ ...e.data, data: JSON.parse(e.data.data) })
    const rootTopic = 'pos'
    let { topic, data } = e.data
    const {
      app: { instanceId: appInstanceId },
      correlationIds: { transactions },
    } = store.getState()
    const { userId } = getAuth()
    try {
      data = JSON.parse(data)
    } catch (e) {
      console.log(e)
    }

    const correlationId = data?.correlation_id ?? data?.correlationId

    switch (topic) {
      case `${rootTopic}/${userId}/${appInstanceId}`:
        if (!correlationId || transactions.has(correlationId)) {
          store.dispatch({ type: actionList.serverMessageApp, data })
        } else {
          // TODO: WARNING!!! Its fast decision for backends bug
          store.dispatch({ type: 'UNEXPECTED_MESSAGE', data })
        }
        break
      case `${rootTopic}/${userId}`:
        store.dispatch({ type: actionList.serverMessageUser, data })
        break
      case rootTopic:
        store.dispatch({ type: actionList.serverMessageAll, data })
        break
      default:
        break
    }
  }

  const { wsHost } = getRuntimeConfig()
  /* 'ws://localhost:4400' */
  const wsServer = isLocalhost() ? wsHost : `wss://${window.location.host}/ws/`
  let appInstanceIdCache
  let userIdCache

  const updateClient = () => {
    const {
      app: { instanceId: appInstanceId },
    } = store.getState()
    const { userId } = getAuth()
    if (appInstanceIdCache !== appInstanceId || userIdCache !== userId) {
      worker.postMessage({ appInstanceId, userId, wsServer })
      appInstanceIdCache = appInstanceId
      userIdCache = userId
    }
  }

  store.subscribe(updateClient)
  authSubscribe(() => {
    store.dispatch(actions.app.newAppInstanceId())
    updateClient()
  })

  const { isUserLogged } = getAuth()
  if (isUserLogged) {
    store.dispatch(actions.app.newAppInstanceId())
  }
}
