import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { customerShape, smsCodeLength } from '../../constants'
import { customer } from '../../redux/actions'
import { Loader } from '../index'
import { customerHelper } from '../../helpers'
import { useNotification } from '../../contexts/NotificationProvider/NotificationProvider'

function RegistrationInLoyaltyProgram({
  onRegistration,
  onSendSmsCode,
  isLoading,
  isRegistered,
  customer,
}) {
  const [smsCode, setSmsCode] = useState('')

  const { showSuccess } = useNotification()

  const handleSetSmsCode = ({ target }) => {
    setSmsCode(target?.value)
  }
  const handleRegistration = () => {
    const customerData = customerHelper.customerMapping(customer)
    const data = {
      ...customerData,
      ...customerHelper.registrationInLoyaltyProgramMapping,
    }
    const message = (
      <>
        Регистрация клиента в ПЛ{' '}
        <b>
          {[customer.surname, customer.given_name, customer.middle_name].filter(Boolean).join(' ')}
        </b>{' '}
        приняты к сохранению
      </>
    )
    showSuccess({ body: message })
    onRegistration(data)
  }

  const handleSendSmsCode = () => {
    const customerData = customerHelper.customerMapping(customer)
    const data = {
      ...customerData,
      ...customerHelper.smsCodeInLoyaltyProgramMapping(smsCode).custom_attributes,
    }
    onSendSmsCode(data)
  }

  if (isLoading) {
    return <Loader />
  }

  let listRequired = [
    {
      name: 'Фамилия',
      val: customer.surname,
    },
    {
      name: 'Имя',
      val: customer.given_name,
    },
    {
      name: 'Отчество',
      val: customer.middle_name,
    },
    {
      name: 'Телефон',
      val: customer.phone,
    },
    {
      name: 'Дата рождения',
      val: customer.birth_date,
    },
  ].filter(({ val }) => !val)

  return (
    <div className="mt-3">
      {!isRegistered && (
        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary btn-lg form-control common-button height-40"
              onClick={handleRegistration}
              disabled={listRequired.length > 0}
            >
              Зарегистрировать
            </button>
            {listRequired.length > 0 && (
              <div className="mt-2">
                Для регистрации в программе лояльности заполните:
                <span className="pl-1">{listRequired.map(({ name }) => name).join(', ')}</span>
              </div>
            )}
          </div>
        </div>
      )}

      {isRegistered && (
        <>
          <div className="col">
            <input
              type="number"
              value={smsCode}
              onChange={handleSetSmsCode}
              className="form-control bg-secondary border-dark square h-100"
              placeholder="SMS-код"
            />
          </div>
          <div className="col">
            <button
              className="btn btn-outline-primary form-control common-button square height-40"
              onClick={handleSendSmsCode}
              disabled={smsCode.length < smsCodeLength}
            >
              Подтвердить
            </button>
          </div>
        </>
      )}
    </div>
  )
}

RegistrationInLoyaltyProgram.propTypes = {
  onSendSmsCode: PropTypes.func.isRequired,
  onRegistration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  customer: PropTypes.shape(customerShape).isRequired,
}

const mapStateToProps = (state) => ({
  onSendSmsCode: customer.sendSmsCodeInLoyaltyProgram,
  isLoading: state.loyaltyProgram.isLoading,
  isRegistered: state.loyaltyProgram.isRegistered,
  customer: state.customer.result,
})

const mapDispatchToProps = {
  onRegistration: customer.registrationInLoyaltyProgram,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationInLoyaltyProgram)
