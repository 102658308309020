import React from 'react'
import PropTypes from 'prop-types'

type Item = {
  qty: number
  id: string
}

type Props = {
  item: Item
  onChangeCount: (item: Item) => void
  minValue?: number
  isDisabled?: boolean
}

function CartProductCount({ item, onChangeCount, minValue = 1, isDisabled = false }: Props) {
  const setCount = (qty: number) => {
    onChangeCount({ id: item.id, qty })
  }

  const increment = () => {
    setCount(item.qty + 1)
  }

  const decrement = () => {
    setCount(item.qty - 1)
  }

  return (
    <div className="cart-count">
      <button className="btn" onClick={decrement} disabled={item.qty <= minValue || isDisabled}>
        <img style={{ width: '12px' }} src={require('../../images/icons/minus.svg')} alt="отнять" />
      </button>
      <label className="cart-count-label">{item.qty}</label>
      <button className="btn" onClick={increment} disabled={isDisabled}>
        <img
          style={{ width: '12px' }}
          src={require('../../images/icons/plus.svg')}
          alt="добавить"
        />
      </button>
    </div>
  )
}

export default CartProductCount

CartProductCount.propTypes = {
  item: PropTypes.object.isRequired,
  onChangeCount: PropTypes.func.isRequired,
  minValue: PropTypes.number,
}
