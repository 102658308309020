import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { maskArray } from 'react-text-mask'
import { Input, Props as InputProps } from '../Input/Input'
import { InputWithMask } from '../Input/InputWithMask'

interface Props extends InputProps {
  mask?: maskArray | ((value: string) => maskArray)
}

export const InputField: FC<Props> = ({ name, mask, isErrorMessageHide = false, ...props }) => {
  const { register, errors, control } = useFormContext()

  if (mask) {
    return (
      <Controller
        name={name}
        control={control}
        mask={mask}
        render={({ onChange, value }) => (
          <InputWithMask
            {...props}
            name={name}
            className="form-control"
            mask={mask}
            onChange={onChange}
            innerRef={register}
            errorMessage={errors[name]?.message}
          />
        )}
      />
    )
  }

  return (
    <Input
      {...props}
      name={name}
      innerRef={register}
      isErrorMessageHide={isErrorMessageHide}
      errorMessage={errors[name]?.message}
      onBlur={props?.handleBlur}
    />
  )
}
