import React, { FC, ReactNode, useState } from 'react'

interface Tab {
  id: string
  title: string
}

interface Props {
  tabs: Tab[]
  content: ReactNode[]
  initialActiveTabId?: string
  onSetActiveTab?: (id: string) => void
  fill?: boolean
  additionalContentForTabs?: Record<string, ReactNode>
}

const Tabs: FC<Props> = ({
  tabs,
  content,
  initialActiveTabId,
  onSetActiveTab,
  fill = false,
  additionalContentForTabs,
}) => {
  const [activeTab, setActiveTab] = useState(initialActiveTabId || tabs[0]?.id)

  if (!tabs?.length || !content?.length) {
    return null
  }

  return (
    <>
      <ul className="nav nav-tabs border-dark justify-content-center text-lg">
        {tabs.map((item) => (
          <li
            key={item.id}
            className={`nav-item cursor-pointer text-center ${fill ? 'col p-0' : ''}`}
          >
            <span
              className={`nav-link ${activeTab === item.id ? 'active' : ''}`}
              onClick={() => {
                if (onSetActiveTab) {
                  onSetActiveTab(item.id)
                }
                setActiveTab(item.id)
              }}
            >
              {item.title}
            </span>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {additionalContentForTabs && additionalContentForTabs[activeTab]}
        {content.map((item) =>
          React.isValidElement(item) && item.key === activeTab ? (
            <div key={item.key} className="tab-pane fade show active d-flex flex-wrap">
              {item}
            </div>
          ) : null,
        )}
      </div>
    </>
  )
}

export default Tabs
