import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { searchTypes } from '../../../constants'
import { mergeSearchResults } from '../../../helpers/search'
import { Page, State } from './model'
import * as thunks from './thunks'
import { Search, SearchProductItem, SearchResult } from '../../../api/dto'

const initialPage = {
  products: 1,
  customers: 1,
  orders: 1,
}

const initialState: State = {
  requestId: undefined,
  result: undefined,
  isLoading: false,
  isLoadingMore: false,
  error: false,
  query: '',
  page: initialPage,
  isInStock: false,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch: () => {
      return initialState
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
    setSearchPage: (state, action: PayloadAction<Page>) => {
      state.page = {
        ...state.page,
        ...action.payload,
      }
    },
    setSearchRequestId: (state, action: PayloadAction<string>) => {
      state.requestId = action.payload
    },
    setIsInStock: (state, action: PayloadAction<boolean>) => {
      state.isInStock = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getSearchResult.pending, (state, action) => {
      state.error = false
      state.isLoading = true
      state.isLoadingMore = action.meta.arg.mergeResult || false
    })
    builder.addCase(thunks.getSearchResult.fulfilled, (state, action) => {
      let result = state.result
      /**
       * Проверка на совпадение запроса и ответа. Если не совпадают - ответ в state не меняем
       */
      if (action.payload.additional.requestId === state.requestId) {
        result = action.meta.arg.mergeResult
          /** TODO: убрать "as unknown as Search" после типизации src/helpers/search.ts */
          ? (mergeSearchResults(state.result, action.payload.result) as unknown as Search)
          : action.payload.result

        state.result = result
        state.page = action.meta.arg.mergeResult ? state.page : initialPage
        state.isLoading = false
        state.isLoadingMore = false
      }
    })
    builder.addCase(thunks.getSearchResult.rejected, (state) => {
      state.error = true
      state.isLoading = false
      state.isLoadingMore = false
    })
  },
})

export default {
  ...searchSlice,
  thunks,
}