import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import DatePicker, { Props as DatePickerProps } from '../DatePicker'

export interface Props extends Omit<DatePickerProps, 'onChange'> {
  name: string
  showYearDropdown?: boolean
}

export const DatePickerField: FC<Props> = ({ name, ...props }) => {
  const { control, errors } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <DatePicker
          {...props}
          selected={value}
          onChange={onChange}
          errorMessage={errors[name]?.message}
          name={name}
          popperPlacement="bottom-start"
        />
      )}
    />
  )
}

