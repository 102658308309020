import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yup } from '../../../yup'
import { Form } from '../../../components/Layout/form/Form'
import { InputField } from '../../../components/Layout/form/InputField'
import { Button } from '../../../components/Layout/Button/Button'
import { useSwitcher } from '../../../hooks/useSwitcher'
import { SmsConfirm } from './SmsConfirm/SmsConfirm'

const schema = yup.object().shape({
  amount: yup.string().required(''),
})

export default function Withdrawal({
  label,
  isSumValid,
  onWithdraw,
  onConfirmLoyaltyProgram,
  onPaymentChange,
}) {
  const [title, setTitle] = useState(label)
  const [error, setError] = useState(false)
  const smsConfirmSwitcher = useSwitcher(false)

  const form = useForm({
    resolver: yupResolver(schema),
  })

  function onSubmit() {
    onConfirmLoyaltyProgram(smsConfirmSwitcher.on)
  }

  const smsConfirmHandleSubmit = (smsCode) => {
    const values = form.getValues()
    return onWithdraw(parseInt(values.amount), smsCode)
  }

  useEffect(() => {
    if (error) {
      setTitle('Превышено количество бонусов для списания по заказу.\nПовторите попытку')
    } else {
      setTitle(label)
    }
  }, [error, setTitle, label])

  return (
    <>
      <div className="askonaWidget-loyaltyProgram-withdrawal">
        <div className="askonaWidget-loyaltyProgram-withdrawal-label">{title}</div>
        <div className="askonaWidget-loyaltyProgram-withdrawal-form">
          <Form form={form} onSubmit={onSubmit}>
            <InputField
              name="amount"
              type="number"
              min={1}
              className={error ? 'error' : ''}
              onChange={(e) => setError(isSumValid && !isSumValid(e.target.value))}
              placeholder="Сумма"
            />
            <Button color="primary" className="height-40" type="submit" disabled={error}>
              Списать
            </Button>
          </Form>
        </div>
      </div>
      <div className="askonaWidget-loyaltyProgram-change-btn">
        <Button color="outline-primary" className="height-40" onClick={onPaymentChange}>
          Изменить сумму платежа
        </Button>
      </div>
      {smsConfirmSwitcher.isOn && (
        <SmsConfirm
          isOpenExternal={smsConfirmSwitcher.isOn}
          onSubmit={smsConfirmHandleSubmit}
          onToggle={smsConfirmSwitcher.toggle}
        />
      )}
    </>
  )
}

Withdrawal.propTypes = {
  label: PropTypes.string,
  isSumValid: PropTypes.func,
  onPaymentChange: PropTypes.func,
  onWithdraw: PropTypes.func,
  onConfirmLoyaltyProgram: PropTypes.func,
}
