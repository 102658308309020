import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { actions } from '../../../redusers'
import * as api from '../../../../api/constructor'
import { Parameters } from '../model/dto'

export const fetchParameters = createAsyncThunk<void, string, AppThunkApiConfig>(
  'constructor/fetchParameters',
  async (sku, { dispatch }) => {
    const response = await api.fetchParameters(sku) as Parameters
    dispatch(actions.constructor.setParametersTree(response))
  },
)
