import React, { FC, useState } from 'react'
import { Block } from '../../../components/Layout/Block/Block'
import { TableList } from '../../../components/TableList/TableList'
import { Button } from '../../../components/Layout/Button/Button'
import { Col, Row } from 'reactstrap'
import style from '../Credit.module.scss'
import clsx from 'clsx'

enum Status {
  LOADING_STATUS = 'LOADING_STATUS',
  CANCEL_STATUS = 'CANCEL_STATUS',
  SUCCESS_STATUS = 'SUCCESS_STATUS',
}

interface Props {
  onNext: () => void
  onBack: () => void
}

export const FinalStep: FC<Props> = ({ onBack, onNext }) => {
  const [creditStatus] = useState<Status>(Status.LOADING_STATUS)

  const statusBlock = (() => {
    switch (creditStatus) {
      case Status.LOADING_STATUS: {
        return (
          <>
            <div
              className={clsx(
                style.statusIcon,
                style.statusIconLoading,
                'lnr lnr-sync mb-4 text-primary',
              )}
            />
            <b>
              Идет оформление кредита: документы отправлены. Максимальное время проверки
              сканированных документов в банке — 15 минут.
            </b>
          </>
        )
      }
      case Status.CANCEL_STATUS: {
        return (
          <>
            <div className={clsx(style.statusIcon, 'lnr lnr-cross-circle mb-4 text-danger')} />
            <b>Кредит отменен. Выполнена процедура отмены кредита.</b>
          </>
        )
      }
      case Status.SUCCESS_STATUS: {
        return (
          <>
            <div className={clsx(style.statusIcon, 'lnr lnr-checkmark-circle mb-4 text-success')} />
            <b>Кредит успешно выдан! Сумма, которая вернется из банка: 62 000 ₽.</b>
          </>
        )
      }
    }
  })()

  return (
    <>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>}>
        <Row>
          <Col xs={6}>
            <TableList
              isTitleBold={false}
              items={[
                { title: <b>КРЕДИТ</b>, content: <b>275084</b> },
                { title: 'ЗАКАЗ', content: 'тт93041-10' },
                { title: 'КЛИЕНТ', content: 'Иванов Иван Иванович' },
                { title: 'МЕНЕДЖЕР', content: 'Петров Константин Юрьевич' },
                { title: 'КРЕДИТНЫЙ ПРОДУКТ', content: 'ВТБ 10% годовых' },
              ]}
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} className="text-center">
        {statusBlock}
      </Block>
      <Block pt={4}>
        <div className="d-flex">
          <Button
            size="lg"
            type="submit"
            className="height-40"
            color="outline-dark"
            onClick={onBack}
          >
            Возврат кредита
          </Button>
          <Button
            size="lg"
            type="submit"
            color="primary"
            className="ml-auto height-40"
            onClick={onNext}
          >
            Готово
          </Button>
        </div>
      </Block>
    </>
  )
}
