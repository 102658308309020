import React, { useState } from 'react'
import QrReader from 'react-qr-reader'

export function QRScanner() {
  const [result, setResult] = useState('No result')

  const handleScan = (data) => {
    setResult(data)
  }

  const handleError = (err) => {
    console.error(err)
  }

  return (
    <div>
      <QrReader
        delay={100}
        style={{
          height: 500,
          width: '100%',
        }}
        onError={handleError}
        onScan={handleScan}
        onLoad={() => console.log('load')}
      />
      <p>{result}</p>
    </div>
  )
}
