import React from 'react'
import icon from '../icons/lnr-checkmark-circle.svg'

type Props = {
  amount?: number
  formatSum?: ((value: number, withCurrency?: boolean) => string) | null
}

export function Success({ amount = 0, formatSum = null }: Props) {
  return (
    <div className="askona-widget-payment__notify big">
      <div className="askona-widget-payment__title">Платёж успешно осуществлён</div>
      <div className="icon">
        <img src={icon} alt="" />
      </div>
      <div className="askona-widget-payment__amount">{formatSum ? formatSum(amount) : amount}</div>
    </div>
  )
}
