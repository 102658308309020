import React, { CSSProperties, useEffect, useRef } from 'react'
import { useSwitcher } from '../../hooks/useSwitcher'
import clsx from 'clsx'
import classnames from './NotificationMessage.module.scss'

const DELAY_START_HIDDEN = 3_000
const HIDDEN_TIME = 3_000
const START_DECREASE = 500

const notificationHiddenStyle: CSSProperties = {
  opacity: 0,
  transition: `all ${HIDDEN_TIME}ms ease-out`,
}

export enum SeverityType {
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const severityTypeToImage = {
  [SeverityType.WARNING]: 'lnr-flag text-warning',
  [SeverityType.SUCCESS]: 'lnr-checkmark-circle text-success',
  //todo: edit image to actual
  [SeverityType.ERROR]: 'lnr-cross-circle text-danger',
}

const styles = {
  ['light']: { background: classnames.notificationLightBackground, fontColor: '' },
  ['isNew']: { background: classnames.notificationMediumBackground, fontColor: '' },
  ['dark']: { background: classnames.notificationDarkBackground, fontColor: 'text-white' },
}

type NotificationMessageProps = {
  isOpen: boolean
  title?: string
  message: React.ReactNode
  severity: SeverityType
  onClose?: (isCloseAutomatically?: boolean) => void
  className?: string
  autoClose?: boolean
  defaultStyle?: 'light' | 'isNew' | 'dark'
}

export const NotificationMessage = ({
  isOpen,
  title,
  message,
  severity,
  onClose,
  className,
  autoClose = false,
  defaultStyle = 'dark',
  ...props
}: NotificationMessageProps & React.HTMLProps<HTMLDivElement>) => {
  const onCloseRef = useRef(onClose)
  onCloseRef.current = onClose
  const startHidden = useSwitcher()
  const startHeightDecreaser = useSwitcher()

  useEffect(() => {
    if (onCloseRef.current !== undefined && autoClose) {
      // Add delay for hidden effect
      const hiddenTimeoutId = setTimeout(() => startHidden.on(), DELAY_START_HIDDEN)
      const heightTimeoutId = setTimeout(
        () => startHeightDecreaser.on(),
        DELAY_START_HIDDEN + START_DECREASE,
      )
      const delayClose = DELAY_START_HIDDEN + HIDDEN_TIME + START_DECREASE
      const closeTimeoutId = setTimeout(() => {
        if (onCloseRef.current !== undefined) onCloseRef.current(true)
      }, delayClose)
      return () => {
        clearTimeout(hiddenTimeoutId)
        clearTimeout(heightTimeoutId)
        clearTimeout(closeTimeoutId)
      }
    }
  }, [])

  const handleClick = () => {
    if (onCloseRef.current !== undefined) {
      onCloseRef.current()
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <div
      style={startHidden.isOn ? notificationHiddenStyle : undefined}
      className={clsx(
        'd-flex p-0',
        styles[defaultStyle].background,
        className,
        { [classnames.notificationHeightDecreaser]: startHeightDecreaser.isOn },
        classnames.notificationField,
      )}
      {...props}
    >
      <div className={clsx('d-flex m-0', classnames.descriptionField)}>
        <span className={clsx('lnr', severityTypeToImage[severity], classnames.notificationIcon)} />
        <div
          className={clsx(
            'pl-3 m-0',
            styles[defaultStyle].fontColor,
            classnames.notificationDescription,
          )}
        >
          {title ?? <div className="font-weight-bold">{title}</div>}
          <div className="font-weight-light">{message}</div>
        </div>
      </div>
      <div className={clsx('pt-1', styles[defaultStyle].fontColor, classnames.notificationCloser)}>
        <span className="lnr lnr-cross cursor-pointer" aria-label="Close" onClick={handleClick} />
      </div>
    </div>
  )
}
