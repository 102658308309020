import React from 'react'
import {
  Redirect,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
  generatePath,
} from 'react-router-dom'
import { ORDER_KEY, useOrderQuery } from '../../api/order'
import { OrderPage } from './OrderPage'
import { getProductMediaUrl, useProductMediaQueries } from '../../api/product'
import { Loader } from '../../components'
import { formatPriceRu } from '../../helpers/currency'
import { formatTime } from '../../helpers/time'
import { Payment } from './Payment'
import { useCustomerQuery } from '../../api/customer'
import { useQueryClient } from 'react-query'

export function Order() {
  const history = useHistory()
  const { id } = useParams()
  const { path: basePath } = useRouteMatch()
  const path = generatePath(basePath, { id })

  const infoPath = path + '/info'
  const paymentPath = path + '/payment'

  const queryClient = useQueryClient()
  const orderQuery = useOrderQuery(id)
  const products =
    orderQuery.data?.items.filter((product) => product.product_type !== 'configurable') ?? []
  const configurableProducts =
    orderQuery.data?.items.filter((product) => product.product_type === 'configurable') ?? []
  const productSkus = products.map(({ sku }) => sku)
  const additionalServices = orderQuery.data?.items.filter(
    (item) =>
      item.extension_attributes?.is_service &&
      !item.extension_attributes?.is_delivery &&
      !item.extension_attributes?.service_parent_guid,
  )
  const customerQuery = useCustomerQuery(orderQuery.data?.customer_id)

  const productMediaQueries = useProductMediaQueries(productSkus)

  const productsWithImages = configurableProducts
    .filter(
      (product) =>
        !(
          product.extension_attributes?.is_service ||
          product.extension_attributes?.is_delivery ||
          product?.parent_item
        ),
    )
    .map((product, index) => {
      const file = productMediaQueries[index]?.data?.[0]?.file
      const img = file ? getProductMediaUrl(file) : null
      const attributes = product.extension_attributes
      const parentAttributes = product.parent_item?.extension_attributes ?? {
        final_amount: null,
        amount: null,
      }
      const total = formatPriceRu(attributes.final_amount || parentAttributes.final_amount)
      const totalWithoutDiscount = formatPriceRu(attributes.amount || parentAttributes.amount)
      const discount = formatPriceRu(
        product.discount_amount || product.parent_item?.discount_amount,
      )
      const price = formatPriceRu(
        product.extension_attributes.special_price ||
          product.parent_item?.extension_attributes.special_price ||
          product.extension_attributes.price ||
          product.parent_item?.extension_attributes.price,
      )

      const regexFromMasterProductName = new RegExp(
        product.parent_item?.name.replace(/\s/g, '|'),
        'gi',
      )
      const productParamsString = product.name.replace(regexFromMasterProductName, '').trim()

      const productServices = orderQuery.data?.items.filter(
        (item) =>
          item.extension_attributes?.service_parent_guid ===
          product.parent_item?.extension_attributes?.guid,
      )

      return {
        img,
        total,
        totalWithoutDiscount,
        discount,
        price,
        qty: product.qty_ordered,
        id: product.item_id,
        masterProductName: product.parent_item?.name,
        paramsString: productParamsString,
        services: productServices,
        deliveryType: product.parent_item?.extension_attributes.delivery_type,
        configurable_attributes: product?.extension_attributes?.configurable_attributes,
      }
    })

  if (queryClient.isFetching()) {
    return <Loader />
  }

  if (orderQuery.error || customerQuery.isLoading || !orderQuery.data) {
    return <h1>Ошибка, повторите запрос позже.</h1>
  }

  const address = orderQuery.data.billing_address

  const clientName = [
    orderQuery.data.customer_lastname,
    orderQuery.data.customer_firstname,
    orderQuery.data.customer_middlename,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      <Redirect exact from={path} to={infoPath} />
      <Route exact path={infoPath}>
        <OrderPage
          staff={orderQuery.data.manager_fio}
          payments={orderQuery.data.payments}
          shopName={orderQuery.data.extension_attributes.shop}
          isCompleted={orderQuery.data.status === 'complete' || orderQuery.data?.total_due === 0}
          address={address}
          discountAmount={orderQuery.data.extension_attributes.discount_amount}
          clientName={clientName}
          clientEmail={customerQuery.data?.is_fake_email ? '' : orderQuery.data.customer_email}
          entity_id={orderQuery.data.entity_id}
          orderId={orderQuery.data.increment_id}
          status={statusLabelMap[orderQuery.data?.status]}
          statusPayment={statusPaymentLabelMap[orderQuery.data?.state]}
          createdAt={formatTime(
            new Date(orderQuery.data.created_at.replace(/-/g, '/')),
            'dd.MM.yyyy',
          )}
          dueDate={
            orderQuery.data.due_date ? formatTime(new Date(orderQuery.data?.due_date)) : undefined
          }
          products={productsWithImages}
          grandTotal={orderQuery.data.grand_total}
          promo={orderQuery.data.extension_attributes?.promo}
          deliveryPrice={orderQuery.data.extension_attributes?.delivery_price}
          productTotal={orderQuery.data.extension_attributes.product_total}
          onClickPay={() => history.push(paymentPath)}
          additionalServices={additionalServices}
          servicePrice={orderQuery.data.extension_attributes?.service_price}
          comment={orderQuery.data.customer_note}
          contract={orderQuery.data.contract}
        />
      </Route>
      <Route exact path={paymentPath}>
        <Payment
          products={products?.map(
            ({ qty_ordered, sku, price, name, parent_item, extension_attributes }) => {
              // TODO: временный костыль (пока бек не начнет это делать сам) для определения стоимости доставки
              const totalPrice = extension_attributes.is_delivery
                ? orderQuery.data.shipping_amount
                : price || parent_item?.price
              return {
                qty: qty_ordered,
                sku,
                name,
                price: totalPrice,
                total: totalPrice,
                isService: extension_attributes?.is_service,
                isDelivery: extension_attributes?.is_delivery,
              }
            },
          )}
          total={orderQuery.data.grand_total}
          total_due={orderQuery.data.total_due}
          subtotal={orderQuery.data.subtotal}
          orderId={id}
          publicOrderId={orderQuery.data.increment_id}
          onClickBack={() => {
            queryClient.invalidateQueries(ORDER_KEY)
            history.push(infoPath)
          }}
          clientPhone={customerQuery.data?.phone}
          clientEmail={customerQuery.data?.is_fake_email ? '' : orderQuery.data.customer_email}
          clientId={orderQuery.data.customer_id}
          isOrderWithDelivery={
            orderQuery.data.payment?.extension_attributes?.min_amount_to_capture !==
            orderQuery.data.total_due
          }
          minPaySum={orderQuery.data.payment?.extension_attributes?.min_amount_to_capture}
          isLoyaltyProgramAvailable={
            !orderQuery.data?.total_paid ||
            orderQuery.data?.total_paid === 0 ||
            orderQuery.data?.can_use_bonuses
          }
        />
      </Route>
    </>
  )
}

const statusLabelMap = {
  pending: 'Оформление',
  processing: 'Обработка',
  complete: 'Выполнен',
}

const statusPaymentLabelMap = {
  new: 'Ожидает оплаты',
  processing: 'Обработка',
  complete: 'Оплачен',
}
