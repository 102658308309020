export enum PayStatus {
  PROGRESSING,
  SUCCESS,
  ERROR,
}

export enum DeviceType {
  KKM,
  SBER_TERMINAL,
}

export type PayStatusResult = {
  code: number
  description: string
  success: boolean
  data: {
    status: PayStatus
    errorCode: number
    errorDescription: string
    errorDeviceType: DeviceType
  }
}
