import { captureException, captureMessage, SentryMessageTags } from './sentryCaptures'
import { CONFIRM_PAY_STATUS_URL, paymentAxios } from './paymentAxiosInit'

export async function confirmPayStatus(apiUrl: string, requestId: string, tags: SentryMessageTags) {
  try {
    const response = await paymentAxios.post(apiUrl + CONFIRM_PAY_STATUS_URL, { requestId })
    if (response.status === 200) {
      captureMessage(tags)
      return {
        success: true,
        data: response.data,
      }
    }
    captureException(tags)
    return {
      success: false,
      errorMessage: `статус запроса ${response.status}`,
    }
  } catch (error) {
    captureException(tags)
    return {
      success: false,
      errorMessage: error.toString(),
    }
  }
}
