import { format } from 'date-fns'

export function formatTime(time, formatString = 'dd.MM.yyyy') {
  return format(time, formatString)
}

export function timeForRequest(time) {
  const date = new Date(time)
  date.toUTCString()
  return `${format(date, 'yyyy-MM-dd')}T00:00:00.0000000`
}
