import React, { FC, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Button } from '../../../components/Layout/Button/Button'
import { Loader, ProductAddedToCartModal } from '../../../components'
import { formatPriceRu } from '../../../helpers/currency'

interface Props {
  title: string
  price?: number
  onPreviousStep?: () => void
  onSubmit?: () => void
}

export const Summary: FC<Props> = ({ title, price, onPreviousStep, onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!price) {
    return (
      <Container className="constructor__summary p-4" fluid>
        <Loader />
      </Container>
    )
  }

  const handleAddToCart = () => {
    if (onSubmit) {
      onSubmit()
      blinkModal()
    }
  }

  const blinkModal = async () => {
    setIsModalOpen(true)
    await new Promise((resolve) => setTimeout(resolve, 2000))
    setIsModalOpen(false)
  }

  return (
    <>
      <Container className="constructor__summary p-4" fluid>
        <Row>
          <Col>
            <h2 className="title">{title}</h2>
            <h1 className="price">{formatPriceRu(price)}</h1>
          </Col>
          <Col>
            <Row>
              <Col xs="4" className="p-0">
                <Button
                  color="outline-primary-grey"
                  className="height-40"
                  onClick={onPreviousStep}
                  block
                >
                  Назад
                </Button>
              </Col>
              <Col xs="auto" className="flex-grow-1">
                <Button color="primary" className="height-48" onClick={handleAddToCart} block>
                  Добавить в корзину
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ProductAddedToCartModal isOpen={isModalOpen} />
    </>
  )
}
