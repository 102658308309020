import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { customerShape } from '../../constants'
import { BalanceInfo, RegistrationInLoyaltyProgram } from '../'

function BonusBalance({ customer }) {
  return (
    <>
      <div className="text-uppercase">Программа лояльности</div>
      {customer?.is_status_pl ? (
        <BalanceInfo bonusBalance={customer.bonus_balance} />
      ) : (
        <RegistrationInLoyaltyProgram />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  customer: state.customer.result,
})

BonusBalance.propTypes = {
  customer: PropTypes.shape(customerShape).isRequired,
}

export default connect(mapStateToProps)(BonusBalance)
