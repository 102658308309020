import { searchPageSize } from '../constants'
import { api } from './api'
import { GetSearchResultPayload } from '../redux/slices/search/model'
import { Search } from './dto'

export const fetchSearch = (
  payload: GetSearchResultPayload,
  requestId: string,
): Promise<Search> => {
  const page = (payload.group && payload.page?.[payload.group]) || 1

  const data = {
    data: {
      id: requestId,
      query: payload.query,
      barcode: '',
      mode: 0,
      groups: payload.group ? [payload.group] : [],
      limit: searchPageSize,
      offset: (page - 1) * searchPageSize + 1,
      date: new Date(),
      group_search_criteria: payload.groupSearchCriteria,
    },
  }

  return api.post({
    path: '/pos/search',
    data,
  })
}
