import React, { FC } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Attribute, AttributeOption, OnChangeArgs } from '../../ProductAttributeGroupComponent'
import { ProductSwatchElement } from './ProductSwatchElement'
import { Button } from '../../../Layout/Button/Button'
import { ProductSwatchModal } from './ProductSwatchModal'
import { useSwitcher } from '../../../../hooks/useSwitcher'

interface Props {
  attribute: Attribute
  options: AttributeOption[]
  onChange: (args: OnChangeArgs) => void
  activeVariant?: string
}

export const ProductSwatchAttribute: FC<Props> = ({
  attribute,
  options,
  activeVariant,
  onChange,
}) => {
  const selectedOption = options.find((item) => item.option_id === activeVariant)

  const { isOn, toggle } = useSwitcher()

  const handleSubmitModal = (optionId: string) => {
    const selectedOption = options.find((item) => item.option_id === optionId)
    if (selectedOption) {
      onChange({ key: selectedOption.code, value: selectedOption.option_id })
    }
  }

  return (
    <>
      <Container className="swatch-attribute" fluid>
        <Row>
          <Col xs="4" className="pl-0 mb-2">
            <p className="mb-0">{attribute.label}</p>
          </Col>
        </Row>
        <Row>
          <Col xs="3" className="p-0 mx-auto">
            {selectedOption ? (
              <ProductSwatchElement option={selectedOption} size="small" />
            ) : (
              <p className="mb-0 text-muted">Не выбрано</p>
            )}
          </Col>
          <Col xs="4" className="pl-0 pr-0">
            {selectedOption ? (
              <Button color="outline-primary-grey" className="height-40" onClick={toggle} block>
                Изменить
              </Button>
            ) : (
              <Button color="primary" className="height-40" onClick={toggle} block>
                Выбрать
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      <ProductSwatchModal
        open={isOn}
        toggle={toggle}
        options={options}
        onSubmit={handleSubmitModal}
        selectedOptionId={selectedOption?.option_id}
        title={attribute.label}
      />
    </>
  )
}
