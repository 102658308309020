import React, { FC } from 'react'

interface Props {
  wrapClassName?: string
}

const Loader: FC<Props> = ({ wrapClassName = '' }) => {
  return (
    <div className={`load ${wrapClassName}`}>
      <div className="load-icon-wrap">
        <svg className="load-icon">
          <path fill="#27979D" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  )
}

export default Loader
