import * as actions from '../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  addAddress,
  fetchAddressesByCustomerId,
  fetchBonusBalanceByCustomerId,
  fetchCustomerById,
} from '../../api/customer'
import { fetchCart, fetchCreateCart, fetchUpdateCart, submit } from '../../api/cart'
import { useRuntimeConfig as getRuntimeConfig } from '../../hooks/useRuntimeConfig'
import { remove as removeFromStorage } from '../../api/storage'
import { getCorrelationId } from '../../helpers'
import { debounce, omit } from 'lodash-es'

export const cartMapping = ({
  cartId,
  customer_id,
  customer,
  correlationId,
  isLoading,
  isServicesLoading,
  error,
  ...cart
}) => {
  return omit(
    { ...cart, customer_id: customer?.result?.customer_id || null, cart_id: cartId },
    'isUpdateRequestInProgress',
  )
}

export const cartItemWrap = ({
  id,
  guid,
  name,
  qty,
  sku,
  price,
  special_price,
  services,
  correlation_id,
  masterProductName,
  masterProductSku,
  image,
  stocks,
  delivery_type: deliveryType,
  configurable_attributes: masterProductAttributes,
  attributes: simpleProductAttributes,
}) => {
  return {
    id,
    name,
    qty,
    sku,
    price,
    special_price,
    services,
    guid: null,
    correlation_id,
    master_product_name: masterProductName,
    master_product_sku: masterProductSku,
    image,
    stocks,
    configurable_attributes: masterProductAttributes,
    ...(!deliveryType && { delivery_type: null }),
    ...(!masterProductAttributes && {
      configurable_attributes: [...simpleProductAttributes],
    }),
  }
}

const attributesAdapter = (item) => ({
  ...item,
  configurable_attributes: item.configurable_attributes.map((attr) => ({
    attribute_id: attr.attribute_id,
    option_id: attr.option_id,
  })),
})

const updateCart = async ({
  dispatch,
  getState,
  newValue,
  customer_id,
  actionBeforeRequest,
  actionAfterRequest,
  actionEnd,
  errorMessage,
  correlationId,
}) => {
  if (actionBeforeRequest) {
    dispatch({ type: actionBeforeRequest })
  }

  try {
    const {
      cart: { cart_id: cartId, ...cart },
    } = await fetchUpdateCart(newValue, getState().app.instanceId, correlationId)

    const customer = customer_id ? await fetchCustomerById(customer_id) : {}
    const addressesData = customer_id ? await fetchAddressesByCustomerId(customer_id) : {}
    const bonusBalance =
      customer_id && customer?.is_status_pl ? await fetchBonusBalanceByCustomerId(customer_id) : {}

    if (actionAfterRequest) {
      dispatch({ type: actionAfterRequest })
    }
    const addresses = omit(addressesData, ['correlationId', 'correlation_id'])
    if (actionEnd) {
      dispatch({
        type: actionEnd,
        data: {
          correlationId,
          cart: {
            ...(cart || {}),
            cartId,
          },
          ...(String(customer.customer_id) === String(customer_id) && {
            customer: {
              ...customer,
              addresses: Object.values(addresses),
              bonus_balance: {
                id: bonusBalance?.bonus_balance_id,
                current_amount: bonusBalance?.active_balance,
                total_amount: bonusBalance?.total_balance,
                due_date: bonusBalance?.due_date,
                reg_date: bonusBalance?.reg_date,
              },
            },
          }),
        },
      })
    }
  } catch (e) {
    if (actionAfterRequest) {
      dispatch({
        type: actionAfterRequest,
        data: {},
        error: e?.message || errorMessage,
      })
    }
    dispatch({
      type: actionEnd ?? actions.endCartUpdate,
      data: {},
      error: e?.message || errorMessage,
    })
  }
}

const debouncedUpdateCart = debounce(updateCart, 1000)

const abstractUpdate = ({
  dispatch,
  isDebounced = false,
  getState,
  value,
  actionBegin,
  actionBeforeRequest,
  actionAfterRequest,
  actionEnd,
  errorMessage = 'Пожалуйста, проверьте правильность заполнения всех полей.',
  correlationId = getCorrelationId(),
}) => {
  const newValue = {
    ...value,
    items: value.items.map(attributesAdapter),
    waiting_items: value.waiting_items.map(attributesAdapter),
  }

  if (actionBegin) {
    dispatch({ type: actionBegin, data: { correlationId, ...newValue } })
  }

  const { customer_id } = newValue || {}

  if (isDebounced) {
    return debouncedUpdateCart({
      dispatch,
      getState,
      newValue,
      customer_id,
      actionBeforeRequest,
      actionAfterRequest,
      actionEnd,
      errorMessage,
      correlationId,
    })
  }

  updateCart({
    dispatch,
    getState,
    newValue,
    customer_id,
    actionBeforeRequest,
    actionAfterRequest,
    actionEnd,
    errorMessage,
    correlationId,
  })
}

const addItemToCart = (cartId, cart, item) => {
  const data = cartMapping(cart)
  const itemIndex = cart.items.findIndex((cartItem) => cartItem.id === item.id)
  let cartItem
  const items = [...cart.items]
  if (itemIndex === -1) {
    cartItem = cartItemWrap(item)
    cartItem.qty = (cartItem.qty || 0) + 1
    items.push(cartItem)
  } else {
    cartItem = cart.items[itemIndex]
    cartItem.qty = (cartItem.qty || 0) + 1
    items[itemIndex] = cartItem
  }

  return {
    ...data,
    items,
    count: cart.count + 1,
    cart_id: cartId,
  }
}

const addWaitingItemToCart = (cartId, cart, waitingItem) => {
  const data = cartMapping(cart)
  const waiting_items = [...cart.waiting_items, waitingItem]

  return {
    ...data,
    waiting_items,
    count: cart.count + 1,
    cart_id: cartId,
  }
}

const removeItemFromCart = (cart, item) => {
  const data = cartMapping(cart)
  return {
    ...data,
    count: cart.count - item.qty || 1,
    items: cart.items.filter((cartItem) => cartItem.id !== item.id),
  }
}

const removeWaitingItemFromCart = (cart, waitingItem) => {
  const data = cartMapping(cart)
  return {
    ...data,
    count: cart.count - 1,
    waiting_items: cart.waiting_items.filter(
      (cartWaitingItem) => cartWaitingItem.id !== waitingItem.id,
    ),
  }
}

const removeAllWaitingItemsFromCart = (cart) => {
  const data = cartMapping(cart)
  return {
    ...data,
    count: cart.count - cart.waiting_items?.length,
    waiting_items: [],
  }
}

const getAddingThunk = ({ addToCart, startAction, endAction }) => {
  return (addingThing) => (dispatch, getState) => {
    const correlationId = getCorrelationId()
    const { cart } = getState()
    dispatch({ type: startAction, data: { correlationId } })

    if (cart.cartId) {
      const data = addToCart(cart.cartId, cart, addingThing)
      abstractUpdate({
        dispatch,
        getState,
        value: data,
        actionBegin: actions.startCartUpdate,
        actionEnd: endAction,
        correlationId,
      })
    } else {
      fetchCreateCart()
        .then((cartId) => {
          const data = addToCart(cartId, cart, addingThing)
          abstractUpdate({
            dispatch,
            getState,
            value: data,
            actionBegin: actions.startCartUpdate,
            actionEnd: endAction,
            correlationId,
          })
        })
        .catch((e) => {
          dispatch({
            type: endAction,
            error: e?.message || 'Ошибка создания корзины',
          })
        })
    }
  }
}

export const addItem = getAddingThunk({
  addToCart: addItemToCart,
  startAction: actions.startAddItemToCart,
  endAction: actions.endAddItemToCart,
})

export const addWaitingItem = getAddingThunk({
  addToCart: addWaitingItemToCart,
  startAction: actions.startAddWaitingItemToCart,
  endAction: actions.endAddWaitingItemToCart,
})

export const setCartCustomer = (newCartCustomer) => (dispatch, getState) => {
  const { cart } = getState()
  const { customer_id: cId, correlationId, customer, ...cartObj } = cart
  const newCart = cartMapping(cartObj)
  newCart.customer_id = newCartCustomer.customer_id
  newCart.delivery_address = null
  abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.beginUpdateCartCustomer,
    actionEnd: actions.setCartCustomer,
    actionBeforeRequest: actions.startCartUpdateRequest,
    actionAfterRequest: actions.endCartUpdateRequest,
  })
}

const getRemovingThunk = ({ actionEnd, removeFromCart }) => {
  return (removingThing) => (dispatch, getState) => {
    const { cart } = getState()
    const newCart = removeFromCart({ ...cart }, removingThing)
    abstractUpdate({
      dispatch,
      getState,
      value: newCart,
      actionBegin: actions.startCartUpdate,
      actionEnd: actionEnd,
    })
  }
}

export const removeAllWaitingItems = createAsyncThunk(
  'cart/removeAllWaitingItems',
  async (_, { dispatch, getState }) => {
    const { cart } = getState()
    const newValue = removeAllWaitingItemsFromCart(cart)
    const { customer_id } = newValue || {}
    return updateCart({
      dispatch,
      getState,
      newValue,
      customer_id,
      actionEnd: actions.removeAllWaitingItemsFromCart,
    })
  },
)

export const removeItem = getRemovingThunk({
  actionEnd: actions.removeItemFromCart,
  removeFromCart: removeItemFromCart,
})

export const removeWaitingItem = getRemovingThunk({
  actionEnd: actions.removeWaitingItemFromCart,
  removeFromCart: removeWaitingItemFromCart,
})

export const changeService = ({ sku, ...attr }, isDebounced = true) => (dispatch, getState) => {
  const { cart } = getState()
  const newCart = cartMapping(cart)
  const serviceIndex = cart.services.findIndex((service) => service.sku === sku)

  if (serviceIndex !== -1) {
    const item = newCart.services[serviceIndex]
    newCart.services[serviceIndex] = { ...item, ...attr }
  }

  const correlationId = getCorrelationId()

  abstractUpdate({
    isDebounced,
    dispatch,
    getState,
    value: newCart,
    correlationId,
    actionBegin: actions.startServicesUpdate,
    actionBeforeRequest: actions.startCartUpdateRequest,
    actionAfterRequest: actions.endCartUpdateRequest,
    actionEnd: actions.endCartUpdate,
  })
}

export const setItemAttr = ({ id, ...attr }, isDebounced = true) => (dispatch, getState) => {
  const { cart } = getState()
  const newCart = cartMapping(cart)
  const itemIndex = cart.items.findIndex((cartItem) => cartItem.id === id)

  if (itemIndex !== -1) {
    const item = newCart.items[itemIndex]
    newCart.items[itemIndex] = { ...item, ...attr }
  }

  const correlationId = getCorrelationId()

  abstractUpdate({
    isDebounced,
    dispatch,
    getState,
    value: newCart,
    correlationId,
    actionBegin: actions.startCartUpdate,
    actionBeforeRequest: actions.startCartUpdateRequest,
    actionAfterRequest: actions.endCartUpdateRequest,
    actionEnd: actions.endCartUpdate,
  })
}

export const setDeliveryAddress = (deliveryAddressId) => (dispatch, getState) => {
  const { cart } = getState()
  const newCart = cartMapping({ ...cart, delivery_address: deliveryAddressId })
  abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.startCartUpdate,
    actionEnd: actions.removeItemFromCart,
    errorMessage: 'Не удалось установить адрес',
  })
}

export const postDeliveryPrice = (deliveryPrice) => (dispatch, getState) => {
  const { cart } = getState()
  const newCart = cartMapping({
    ...cart,
    requested_delivery_price: deliveryPrice,
    desired_date: null,
    selected_delivery: null,
    delivery_variants: null,
  })
  return abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.startCartUpdate,
    actionEnd: actions.removeItemFromCart,
    errorMessage: 'Услуга доставки не обновлена',
  })
}

export const postDeliveryDate = (deliveryDate, selectedDelivery) => (dispatch, getState) => {
  const { cart } = getState()
  const newCart = cartMapping({
    ...cart,
    desired_date: deliveryDate,
    selected_delivery: selectedDelivery,
  })
  return abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.startCartUpdate,
    actionEnd: actions.removeItemFromCart,
    errorMessage: 'Услуга доставки не обновлена',
  })
}

export const addContactLinkType = (contact_link_type) => (dispatch, getState) => {
  const { cart } = getState()
  const newCart = cartMapping({
    ...cart,
    contact_link_type,
  })
  return abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.startCartUpdate,
    actionEnd: actions.removeItemFromCart,
    errorMessage: 'Не удалось добавить тип контактного лица',
  })
}

export const resetCreateError = () => (dispatch) => {
  dispatch({ type: actions.resetCreateError, data: {} })
}

export const resetCartError = () => (dispatch) => {
  dispatch({ type: actions.resetCartError, data: {} })
}

export const submitCart = (cart) => (dispatch, getState) => {
  const newCart = cartMapping({ ...cart })
  submit(newCart, getState().app.instanceId).then((data) => {
    dispatch({ type: actions.endCartSubmit, data })
  })
}

export const startFastOrder = () => (dispatch, getState) => {
  const { cart } = getState()
  const { retailClient } = getRuntimeConfig()
  let newCart = cartMapping({ ...cart })
  if (!newCart.customer_id) {
    newCart = { ...newCart, customer_id: retailClient }
  }
  submit(newCart, getState().app.instanceId).then((data) => {
    dispatch({ type: actions.endCartSubmit, data })
  })
}

export const setPromocode = (promocode) => (dispatch, getState) => {
  const { cart } = getState()
  const newCart = cartMapping({ ...cart, promocode })
  abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.startCartUpdate,
    actionEnd: actions.removeItemFromCart,
  })
}

export const setCart = (cart) => (dispatch) => {
  const newCart = cartMapping({ ...cart })
  dispatch({ type: actions.setCart, data: { ...newCart } })
}

export const remove = () => (dispatch) => {
  removeFromStorage('cart')
  dispatch({
    type: actions.removeCart,
  })
}

export const addNewAddress = (value) => async (dispatch, getState) => {
  try {
    const { cart } = getState()
    await addAddress(value)
    const { customer_id } = value
    const addressesData = await fetchAddressesByCustomerId(customer_id)
    const { correlationId, correlation_id, ...addresses } = addressesData
    const delivery_address =
      Object.values(addresses).find((item) => {
        return (
          item.region === value.region &&
          item.city === value.city &&
          item.street === value.street[0] &&
          item.house === value.address_attributes[5].value &&
          item.block === value.address_attributes[6].value &&
          item.apartment === value.address_attributes[7].value
        )
      })?.id ?? undefined
    const customer = getState().cart.customer
    const newCart = cartMapping({
      ...cart,
      customer: {
        ...customer,
        result: { ...customer.result, addresses: Object.values(addresses) },
      },
      delivery_address,
    })

    abstractUpdate({
      dispatch,
      getState,
      value: newCart,
      actionBegin: actions.startCartUpdate,
      actionEnd: actions.endCartUpdate,
    })
  } catch (e) {
    dispatch({
      type: actions.endGetCustomer,
      data: {},
      error: true,
    })
  }
}

export const initializeCart = (cartId) => async (dispatch, getState) => {
  dispatch({ type: actions.startCartInit, data: {} })
  const { cart } = await fetchCart(cartId)
  dispatch({ type: actions.endCartInit, data: {} })

  const newCart = {
    cartId: cart.cart_id,
    ...cart,
  }

  abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.beginUpdateCartCustomer,
    actionEnd: actions.setCartCustomer,
  })
}

export const refetchCart = () => async (dispatch, getState) => {
  const state = getState().cart
  const { cart } = await fetchCart(state.cartId)
  const newCart = {
    cartId: cart.cart_id,
    ...cart,
  }

  abstractUpdate({
    dispatch,
    getState,
    value: newCart,
    actionBegin: actions.beginUpdateCartCustomer,
    actionEnd: actions.setCartCustomer,
  })
}
