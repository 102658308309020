import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { actions } from '../../redux/redusers'
import CartIcon from '../Cart/CartIcon'
import { signOut } from '../../api/auth'
import { useUser } from '../../contexts/UserContext'
import { Button } from 'reactstrap'
import { NotificationHistory } from '../../contexts/NotificationProvider/NotificationHistory'
import { TerminalHealth } from '../../contexts/TerminalProvider/TerminalHealth'

function Header({ sidebarIsOpened, toggleSidebar }) {
  const { username } = useUser()
  const [menuOpen, setMenuOpen] = useState(false)

  const menuToggle = () => setMenuOpen(!menuOpen)

  return (
    <header className={`header bg-light w-100 ${sidebarIsOpened ? 'open' : ''}`}>
      <div className="header-wrapper">
        <div className="header-left">
          <button
            className="header-button d-flex justify-content-center align-items-center p-0"
            type="button"
            onClick={() => {
              if (!sidebarIsOpened) {
                toggleSidebar()
              }
            }}
          >
            <span className="lnr lnr-fs-24 lnr-menu" />
          </button>
          <Link to="/">
            <img src={require('../../images/logo.svg')} alt="Askona" height={60} />
          </Link>
          <NotificationHistory />
          <TerminalHealth />
        </div>
        <div className="header-right justify-content-end align-items-center">
          <CartIcon />
          <div className="user h-100 d-flex align-items-center">
            <button className="user-button h-100 bg-light" onClick={menuToggle}>
              <div className="user-icon">
                <span className="lnr lnr-user text-lg" />
              </div>
              <span className="mr-2">{username}</span>
              {menuOpen ? (
                <img src={require('../../images/icons/arrow-up.svg')} alt="скрыто" />
              ) : (
                <img src={require('../../images/icons/arrow-down.svg')} alt="раскрыто" />
              )}
            </button>
            <div className={`header-menu bg-light ${menuOpen ? 'show' : ''}`}>
              <Button color="link" className="header-menu-link" onClick={signOut}>
                <span className="lnr lnr-exit" />
                <span className="ml-2">Выйти</span>
              </Button>
            </div>
            {menuOpen && <button className="back" onClick={menuToggle} />}
          </div>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => ({
  sidebarIsOpened: state.app.sidebarIsOpened,
})

const mapDispatchToProps = {
  toggleSidebar: actions.app.toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

Header.propTypes = {
  sidebarIsOpened: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
}
