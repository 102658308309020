import * as actions from '../types'
import {
  CUSTOMER_WAIT_STATE,
  CUSTOMER_DEFAULT_STATE,
  CUSTOMER_UPDATED_STATE,
} from '../../api/ws/customer/constants'
/* eslint-disable no-undef */

const initState = {
  result: {},
  relationTypes: [],
  isLoading: false,
  isEditing: false,
  isEdited: false,
  error: '',
  editError: '',
  responseStatus: null,
  hasVersionMismatch: false,
  state: CUSTOMER_DEFAULT_STATE,
}

export default function (state = initState, action) {
  if (action.type === actions.beginGetCustomer) {
    return {
      ...state,
      ...initState,
      isLoading: true,
    }
  }
  if (action.type === actions.endGetCustomer || action.type === actions.endInvalidateCustomer) {
    return {
      ...state,
      result: action.data || {},
      isLoading: false,
      error: action.error || (!Object.keys(action.data || {}).length && 'Нет данных'),
    }
  }
  if (
    action.type === actions.beginUpdateCustomer ||
    action.type === actions.beginInvalidateCustomer
  ) {
    return {
      ...state,
      isEditing: true,
      isEdited: false,
      state: CUSTOMER_WAIT_STATE,
    }
  }
  if (
    action.type === actions.endUpdateCustomer ||
    action.type === actions.endSendSmsCodeInLoyaltyProgram
  ) {
    const result = action.error
      ? state.result
      : {
          ...action.data,
          addresses: state.result?.addresses,
          is_status_pl: state.result?.is_status_pl,
          bonus_balance: state.result?.bonus_balance,
        }
    return {
      ...state,
      result,
      isEditing: false,
      isEdited: true,
      editError: action.error,
      responseStatus: action.responseStatus,
      state: action.error ? CUSTOMER_DEFAULT_STATE : CUSTOMER_UPDATED_STATE,
    }
  }
  if (action.type === actions.resetCustomerEdited || action.type === actions.cancelConfirmUpdate) {
    return {
      ...state,
      isEdited: false,
      state: CUSTOMER_DEFAULT_STATE,
    }
  }
  if (action.type === actions.resetCustomerVersionMismatch) {
    return {
      ...state,
      hasVersionMismatch: false,
    }
  }
  if (action.type === actions.resetLastResponseStatus) {
    return {
      ...state,
      responseStatus: null,
    }
  }
  if (action.type === actions.setDefaultState) {
    return {
      ...state,
      state: CUSTOMER_DEFAULT_STATE,
    }
  }
  if (action.type === actions.getCustomerRelationTypes) {
    return {
      ...state,
      editError: action.error,
      relationTypes: action.data || [],
    }
  }
  if (
    action.type === actions.endRegistrationInLoyaltyProgram ||
    action.type === actions.endCreateCustomer
  ) {
    return {
      ...state,
      error: action.error || '',
      result: {
        ...state.result,
        extra_phone: action.data?.extra_phone || null,
        version_id: action.data?.version_id || null,
        correlation_id: action.data?.correlation_id || null,
      },
    }
  }

  if (action.type === actions.serverMessageApp && action.data.payload.type === 'customer') {
    return {
      ...state,
      ...((action.data?.payload?.data && {
        result: {
          ...action.data?.payload?.data,
          addresses: state.result?.addresses,
          extra_phone: state.result?.extra_phone,
          is_status_pl: state.result?.is_status_pl,
          bonus_balance: state.result?.bonus_balance,
          version_id: action.data?.payload?.data?.version_id ?? state.result?.version_id,
        },
      }) ||
        {}),
      ...(action.data?.payload?.data?.contact_state_name && {
        state: action.data?.payload?.data?.contact_state_name,
      }),
    }
  }
  return { ...state }
}
/* eslint-enable no-undef */
