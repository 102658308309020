import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { searchHelper, productHelper } from '../../helpers'
import { formatPriceRu } from '../../helpers/currency'

function ProductItem({ item, query, className, onClick }) {
  if (!Object.keys(item || {}).length) {
    return null
  }

  const isWithOutlets = item.is_in_stock

  return (
    <div className={className}>
      <div className="product product-card d-flex flex-column justify-content-between align-items-center">
        <Link
          className="w-100"
          to={`/product/${item.sku}`}
          onClick={() => {
            if (onClick) {
              onClick()
            }
          }}
        >
          <div className="img-container d-flex align-items-center justify-content-center border">
            <img src={productHelper.getImage(item)} alt="" className="img-fluid" />
          </div>
          <div className="d-flex align-items-center title-wrap">
            <div className="title text-primary w-100">
              {query ? searchHelper.markMatch(item.name, query) : <b>{item.name}</b>}
            </div>
          </div>
        </Link>
        <div className="w-100 d-flex justify-content-end align-items-center">
          <div className="price text-danger d-flex align-items-center text-nowrap">
            {formatPriceRu(item.minimal_price)}
          </div>
        </div>
        {isWithOutlets ? (
          <div className=" w-100 d-flex align-items-center text-primary">
            <div className="bg-primary rounded-circle" style={{ width: 5, height: 5 }} />
            &nbsp;<span className="text-primary fw-bold">В наличии</span>
          </div>
        ) : (
          <div className=" w-100 d-flex align-items-center text-black-50">
            <div className="bg-dark rounded-circle" style={{ width: 5, height: 5 }} />
            &nbsp;<span className="text-black-50 fw-bold">Под заказ</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductItem

ProductItem.propTypes = {
  item: PropTypes.object.isRequired,
  query: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

ProductItem.defaultProps = {
  query: null,
  className: '',
}
