import { api } from './api'
import { useMutation, useQuery } from 'react-query'
import { DiscountCard } from './loyaltyProgram'

export const ORDER_KEY = 'order'

export const fetchGetOrder = (id) => {
  return api.get({ path: `/orders/${id}` })
}

export function useOrderQuery(id) {
  return useQuery([ORDER_KEY, id], () => fetchGetOrder(id))
}

export const payBonuses = (data) =>
  pay({
    guid: data.guid,
    state: 'bonus_add',
    orderId: data.orderId,
    sum: data.sum,
    bonusSum: data.bonusesAmount,
    phoneNumber: data.phone,
    // TODO add all type discountCardType
    discountCardType: DiscountCard.DISCOUNT_CARD_TYPE_ASKONA,
    discountCardNumber: '',
    email: data.email,
    createdBy: data.username,
    pinCode: data.smsCode,
  })

export const draftPay = (data) => {
  return pay({
    guid: data.guid,
    state: 'draft_add',
    orderId: data.orderId,
    sum: data.sum,
    bonusSum: data.bonusesAmount,
    phoneNumber: data.phone,
    discountCardNumber: '',
    email: data.email,
    createdBy: data.username,
    transaction_id: data.transactionId,
  })
}

export const payMoney = (data) => {
  const isFinal = data.isFinal ?? true

  return pay({
    guid: data.guid,
    state: 'payment_add',
    orderId: data.orderId,
    sum: data.amount,
    bonusSum: data.bonusesAmount,
    discountSum: 0,
    phoneNumber: data.phone,
    paymentType: parseInt(data.paymentType),
    discountCardType: data.bonusesAmount
      ? DiscountCard.DISCOUNT_CARD_TYPE_ASKONA
      : DiscountCard.DISCOUNT_CARD_TYPE_VOID,
    discountCardNumber: '',
    paymentCardNumber: '', //Из ответа терминала
    paymentCardMaskedPAN4: data?.maskedPan4, //Из ответа терминала
    paymentCardIdType: null, //Из ответа терминала
    plTerminalId: '', //Из настроек вместе с шоп кодом
    parentId: '', //?? Кто ето?
    isFinal,
    cbCreditId: '',
    email: data.email,
    createdBy: data.username,
    termNum: data.termNum,
    merchNum: data.merchNum,
    footer: data.footer,
    transaction_id: data.transactionId,
  })
}

const pay = (data) =>
  api.post({
    path: '/pos/order/payment',
    data,
  })

export const postOrderEmail = (data) =>
  api.post({
    path: `/contract/mail`,
    data,
  })

export const usePostOrderEmailMutation = () => {
  const { isSuccess, isError, ...postOrderEmailMutation } = useMutation(postOrderEmail)

  return {
    ...postOrderEmailMutation,
    isSuccess: isSuccess && postOrderEmailMutation.data?.status === true,
    isError: isError || postOrderEmailMutation.data?.status === false,
  }
}
