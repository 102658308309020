import { api } from './api'
import { useMutation } from 'react-query'
import { omit } from 'lodash-es'
import { CART_UPDATE_SUCCESS_STATUS } from './constatns'

export const fetchCreateCart = () => api.post({ path: '/carts' })

export const fetchCart = (id) => api.get({ path: `/cart/${id}/init` })

export const fetchUpdateCart = async (cart, appId, correlationId) => {
  const response = await api.post({
    path: '/cart/update',
    data: { cart },
    appId,
    correlationId,
    withStatusCode: true,
  })
  if (response.responseStatus !== CART_UPDATE_SUCCESS_STATUS) {
    throw new Error(
      `Error during cart update. ${response.responseStatus}: ${response.responseStatusText}`,
    )
  }
  return response
}

export const submit = async (cart, appId) => {
  let newCart = { ...cart }
  if (!cart.customer_id) {
    newCart = { ...cart, customer_id: null }
    await fetchUpdateCart(newCart, appId)
  }
  delete newCart.selected_delivery
  return api.post({
    path: '/cart/submit',
    data: { cart: newCart },
  })
}

export function useSubmitQuery() {
  return useMutation(({ cart, appId }) => {
    return submit(omit({ ...cart }, ['isUpdateRequestInProgress', 'isServicesLoading']), appId)
  })
}
