import React from 'react'
import { CartProductCount } from '../index'
import { Col, Row } from 'reactstrap'
import { formatPriceRu } from '../../helpers/currency'

export type AdditionalProduct = {
  sku: string
  title: string
  price: number
  qty: number
  checked: boolean
}

type CartAdditionalProductLineProps = {
  item: AdditionalProduct
  onChangeCount: (val: number) => void
  isDisabled?: boolean
}

export function CartAdditionalProductLine({
  item,
  onChangeCount,
  isDisabled = false,
}: CartAdditionalProductLineProps) {
  const handleChangeCount = onChangeCount
    ? (item: { qty: number }) => {
        onChangeCount(item.qty)
      }
    : undefined
  return (
    <Row className="mt-4 mr-4 ml-1">
      <Col xs={5} className="d-flex align-items-center ellipsis">
        {item.title}
      </Col>
      <Col xs={3} className="d-flex align-items-center m-0 p-0">
        {formatPriceRu(item.price, true)}
        /шт
      </Col>
      <Col xs={2}>
        {handleChangeCount && (
          <CartProductCount
            item={{ ...item, id: item.sku }}
            isDisabled={isDisabled}
            onChangeCount={handleChangeCount}
            minValue={0}
          />
        )}
      </Col>
      <Col xs={2} className="d-flex align-items-center mr-5 d-flex justify-content-end">
        {formatPriceRu(item.price * item.qty, true)}
      </Col>
    </Row>
  )
}
