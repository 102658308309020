import axios from 'axios'
import { isSentryEnable } from '../../../helpers/isSentryEnable'
import * as Sentry from '@sentry/react'
import { ExtendedError } from '@sentry/types/dist/error'

export const BASE_PAYMENT_API_URL = '/payapi/2.0.0'
export const START_PAY_URL = '/StartPay'
export const GET_PAY_STATUS_URL = '/GetPayStatus'
export const CONFIRM_PAY_STATUS_URL = '/ConfirmPayStatus'
export const GET_PAY_INFO_URL = '/GetPayInfo'
export const HEALTH_URL = '/health'

export const paymentAxios = axios.create()

if (isSentryEnable()) {
  paymentAxios.interceptors.request.use(
    function (config) {
      Sentry.addBreadcrumb({
        category: 'payment request info',
        data: config,
        level: Sentry.Severity.Info,
      })
      return config
    },
    function (error) {
      Sentry.addBreadcrumb({
        category: 'payment request error',
        data: error,
        level: Sentry.Severity.Error,
      })
      const fetchError: ExtendedError = new Error(`Failed to payment fetch`)
      fetchError.cause = error
      Sentry.captureException(fetchError)
      return Promise.reject(error)
    },
  )

  paymentAxios.interceptors.response.use(
    function (response) {
      Sentry.addBreadcrumb({
        category: 'payment response info',
        data: response,
        level: Sentry.Severity.Info,
      })
      return response
    },
    function (error) {
      Sentry.addBreadcrumb({
        category: 'payment response error',
        data: error,
        level: Sentry.Severity.Error,
      })
      const fetchError: ExtendedError = new Error(`Failed to payment fetch`)
      fetchError.cause = error
      Sentry.captureException(fetchError)
      return Promise.reject(error)
    },
  )
}
