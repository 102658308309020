import React, { FC, useEffect, useState } from 'react'
import { Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { Button } from '../../../../components/Layout/Button/Button'
import { AttributeOption } from '../../ProductAttributeGroupComponent'
import { ProductSwatchModalCategory } from './ProductSwatchModalCategory'

interface Props {
  open: boolean
  title: string
  toggle: () => void
  options: AttributeOption[]
  selectedOptionId?: string
  onSubmit: (optionId: string) => void
}

export const ProductSwatchModal: FC<Props> = ({
  open,
  title,
  toggle,
  options,
  selectedOptionId: optionId,
  onSubmit,
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState(optionId)

  useEffect(() => {
    setSelectedOptionId(optionId)
  }, [open])

  const handleModalToggle = () => toggle()

  const handleClick = (optionId: string) => () => setSelectedOptionId(optionId)

  const handleSubmit = () => {
    if (selectedOptionId) {
      onSubmit(selectedOptionId)
      toggle()
    }
  }

  const categories = options.reduce<Record<string, AttributeOption[]>>((acc, value) => {
    if (value.category) {
      if (!acc[value.category]) {
        acc[value.category] = []
      }
      acc[value.category].push(value)
    }
    // TODO убрать после того, как options для тканей станут приходить с категориями
    if (!value.category) {
      if (!acc['Вне категорий']) {
        acc['Вне категорий'] = []
      }
      acc['Вне категорий'].push(value)
    }
    return acc
  }, {})

  const elements = Object.entries(categories).map(([key, value]) => (
    <ProductSwatchModalCategory
      key={key}
      category={key}
      options={value}
      selectedOptionId={selectedOptionId}
      handleClick={handleClick}
    />
  ))

  return (
    <Modal isOpen={open} toggle={handleModalToggle} className="constructor__swatch-modal">
      <ModalHeader tag="h1" toggle={handleModalToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>{elements}</Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!selectedOptionId}
          color="primary"
          classname="height-40"
          onClick={handleSubmit}
        >
          Выбрать
        </Button>
      </ModalFooter>
    </Modal>
  )
}
