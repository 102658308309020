import React, { FC } from 'react'
import { Container, Row } from 'reactstrap'
import { Attribute, AttributeOption, OnChangeArgs } from '../ProductAttributeGroupComponent'
import { Select } from '../../../components'

interface Props {
  attribute: Attribute
  options: AttributeOption[]
  onChange: (args: OnChangeArgs) => void
  activeVariant?: string
  isLabelHidden?: boolean
}

export const ProductAttributeSelect: FC<Props> = ({
  attribute,
  options,
  onChange,
  activeVariant,
  isLabelHidden,
}) => {
  const handleChange = (id: string) => {
    const attributeOption = options.find((item) => item.option_id === id)
    if (attributeOption) {
      onChange({ key: attributeOption.code, value: attributeOption.option_id })
    }
  }

  return (
    <Container fluid>
      {!isLabelHidden && (
        <Row className="mb-2">
          <p className="mb-0 font-weight-bold">{attribute.label}</p>
        </Row>
      )}
      <Row>
        <Select
          selectContainerClass="attribute-select-wrapper"
          selectClass="attribute-select"
          placeholderClass="attribute-select__placeholder bg-white"
          dropdownClass="attribute-select__dropdown bg-white"
          withoutSelectionClass="attribute-select__placeholder-without-selection"
          key={activeVariant}
          initialValueId={activeVariant}
          options={options.map((item) => ({
            id: item.option_id,
            name: item.value || item.option_id,
          }))}
          onChange={handleChange}
        />
      </Row>
    </Container>
  )
}
