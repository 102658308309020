import { combineReducers } from 'redux'
import app from '../slices/app'
import search from '../slices/search'
import constructor from '../slices/constructor'
import { dadata } from '../slices/dadata'
import { product } from '../slices/product'
import customer from './customer'
import cart from './cart'
import loyaltyProgram from './loyaltyProgram'
import correlationIds from './correlationIds'
import * as productThunks from '../thunks/product'
import { enableMapSet } from 'immer'

// необходимо для Map.set
// https://redux.js.org/style-guide/style-guide#do-not-put-non-serializable-values-in-state-or-actions
// https://github.com/reduxjs/redux-toolkit/issues/466
enableMapSet()

export default combineReducers({
  app: app.reducer,
  search: search.reducer,
  itemConstructor: constructor.reducer,
  product: product.reducer,
  dadata: dadata.reducer,
  customer,
  cart,
  loyaltyProgram,
  correlationIds,
})

export const actions = {
  app: app.actions,
  search: search.actions,
  constructor: constructor.actions,
  dadata: dadata.actions,
  product: product.actions,
}

export const thunks = {
  search: search.thunks,
  constructor: constructor.thunks,
  product: { ...productThunks, ...product.thunks },
  dadata: dadata.thunks,
}
