import React, { FC, useMemo } from 'react'
import { Block } from '../../../components/Layout/Block/Block'
import { Form } from '../../../components/Layout/form/Form'
import { RadioField } from '../../../components/Layout/form/RadioField'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yup } from '../../../yup'
import { Button } from '../../../components/Layout/Button/Button'

const getSchema = () =>
  yup.object().shape({
    signature: yup.string().required(),
  })

interface Props {
  onBack: () => void
  onNext: () => void
}

interface FieldValues {
  signature: 'usual' | 'paper' | 'digital'
}

export const ChooseSignatureStep: FC<Props> = ({ onBack, onNext }) => {
  const schema = useMemo(getSchema, [])
  const form = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      signature: 'usual',
    },
  })

  function onSubmit(values: FieldValues) {}

  return (
    <Form<FieldValues> form={form} onSubmit={onSubmit}>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>} />
      <Block mb={0} title="ВЫБОР ТИПА ПОДПИСАНИЯ ДОКУМЕНТОВ">
        <div className="mb-3 mt-3">Тип подписания</div>
        <div className="mb-2">
          <RadioField name="signature" value="usual" labelName="Обычная подпись" />
        </div>
        <div className="mb-2">
          <RadioField name="signature" value="paper" labelName="Бумажная электронная подпись" />
        </div>
        <div className="mb-2">
          <RadioField
            name="signature"
            value="digital"
            labelName="Безбумажная электронная подпись"
          />
        </div>
      </Block>
      <Block pt={4}>
        <div className="d-flex">
          <Button
            size="lg"
            type="submit"
            className="height-40"
            color="outline-dark"
            onClick={onBack}
          >
            Отменить
          </Button>
          <Button
            size="lg"
            type="submit"
            color="primary"
            className="ml-auto height-40"
            onClick={onNext}
          >
            Далее
          </Button>
        </div>
      </Block>
    </Form>
  )
}
