import { AxiosError } from 'axios'
import store from '../redux/store'
import { actions } from '../redux/redusers'

export const errorHandler = (error: AxiosError) => {
  if (error?.response?.status === 403) {
    store.dispatch(
      actions.app.setAuthErrorMessage(
        error.response.data.message ||
          `Ваш ip не соответствует сетевым маскам торговых точек. Обратитесь в поддержку.`,
      ),
    )
  }
  console.error(error)
  throw error
}
