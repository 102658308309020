import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { Loader, ProductItem } from '../components'
import { productShape } from '../constants'
import { thunks } from '../redux/redusers'

function ProductOffer({ item, isLoading, error, getProductBySku }) {
  const { id, productId } = useParams()
  const history = useHistory()
  const [isOpenCondition, setIsOpenCondition] = useState(false)

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return (
      <div className="product-card">Ничего не найдено. Попробуйте воспользоваться поиском.</div>
    )
  }

  if (productId !== item.sku) {
    getProductBySku(productId)
  }

  if (!Object.keys(item || {}).length) {
    return null
  }

  const offer = item?.offers?.find((offerItem) => offerItem.id === id)
  if (!offer) {
    history.push('/product/' + productId)
    return null
  }
  return (
    <div className="offer">
      <h1>Акция {offer.title}</h1>
      <div className={`condition mb-2 ${isOpenCondition ? 'open' : ''}`}>
        <div onClick={() => setIsOpenCondition(!isOpenCondition)}>Условия акции</div>
        {isOpenCondition && <div>{offer.condition}</div>}
      </div>
      {offer.products?.length > 0 && (
        <div className="bg-secondary br-5 py-4 px-2">
          <div className="d-flex flex-wrap">
            {offer.products?.map((product) => (
              <ProductItem key={product.id} item={product} className="col-4" />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.product.isLoading,
  error: state.product.error,
  item: state.product.result,
})

const mapDispatchToProps = {
  getProductBySku: thunks.product.getBySku,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductOffer)

ProductOffer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  item: PropTypes.shape(productShape).isRequired,
  error: PropTypes.bool.isRequired,
  getProductBySku: PropTypes.func.isRequired,
}
