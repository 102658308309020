import React, { ReactText, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import 'react-datepicker/dist/react-datepicker.css'

import { CreateNewCustomerContact, Loader, Modal, Select, Switcher } from '../../index'
import { connect, ConnectedProps } from 'react-redux'
import { newId } from '../../../constants'
import { customerHelper } from '../../../helpers'
import { useSubmitQuery } from '../../../api/cart'
import { getCartForFetch } from '../../../redux/selectors/cart'
import { cart } from '../../../redux/actions'
import { Contact } from '../../../api/dto/CustomerBasicType'
import { AppState } from '../../../redux/model'
import { useSwitcher } from '../../../hooks/useSwitcher'

type Props = ConnectedProps<typeof connector> & {
  previousStep: () => void
}

const NEW_CONTACT_ID = '0'

function CartAdditionalInfo({
  previousStep,
  customer,
  customerEditError,
  instanceId,
  cart,
  clearCart,
  setCart,
  isCartUpdate,
  contactPerson,
  contactLinkType,
}: Props) {
  const history = useHistory()
  const [localComment, setLocalComment] = useState('')
  const [isNewContact, setIsNewContact] = useState(false)
  const [isContact, setIsContact] = useState(false)
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(
    customer?.contacts?.[0],
  )
  const dangerModalSwitcher = useSwitcher()

  useEffect(() => {
    const last = [...(customer.contacts || [])].pop()
    setSelectedContact(last)
    setIsNewContact(false)
  }, [customer.contacts])

  /**
   * Контакты для селектора
   * с возможностью добавить нового кастомера по id: 0
   */
  const contacts: { id: string; name: ReactText }[] =
    customer?.contacts?.map((c) => ({
      id: c.guid,
      name: customerHelper.getFullName(c),
    })) || []
  contacts.push({ id: newId.toString(), name: 'Добавить новое контактное лицо' })

  const handleSelectContact = (contactId: string) => {
    if (contactId === NEW_CONTACT_ID) {
      setIsNewContact(true)
    } else {
      setSelectedContact(customer.contacts.find((a) => a?.guid === contactId))
    }
  }

  const handleChangeComment = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) =>
    setLocalComment(target.value)

  const handleBlurComment = () => {
    setCart({ ...cart, comment: localComment })
  }

  const submitQuery = useSubmitQuery()

  useEffect(() => {
    if ((submitQuery.isSuccess && !submitQuery.data) || submitQuery.isError) {
      dangerModalSwitcher.on()
    }
  }, [submitQuery.isSuccess, submitQuery.data, submitQuery.isError])

  const handleCreateOrder = () => {
    // TODO: порешать с этой ошибкой
    // @ts-ignore
    submitQuery.mutate({ cart, appId: instanceId })
  }

  if (submitQuery.isLoading) {
    return <Loader />
  }

  if (submitQuery.isSuccess && submitQuery.data) {
    const {
      cart: { orderId },
    } = submitQuery.data
    clearCart()
    history.push(`/order/${orderId}`)
  }

  return (
    <div className="cart-wizard-additional-info">
      <div className="bg-secondary br-5 cart-wizard-body">
        <h2 className="text-gray d-flex align-items-center">Шаг 3. Дополнительная информация</h2>
        <div className="row">
          <div className="col">
            {/* {customerIsEditing && <Loader wrapClassName='transparent-loader' />} */}

            <Switcher
              checked={isContact}
              onChange={({ target }) => setIsContact(target.checked)}
              id="changeContact"
              labelChildren={
                <>
                  <div className="ml-4">Получает другой человек</div>
                  <div className="font-weight-light ml-4">
                    Укажите контактные данные другого получателя
                    {isContact && isNewContact && (
                      <>
                        &nbsp;или воспользуйтесь
                        <br /> поиском по существующим клиентам
                      </>
                    )}
                  </div>
                </>
              }
            />
            {isContact && !isNewContact && (
              <div className="col mt-2">
                <Select
                  options={contacts}
                  initialValueId={selectedContact?.guid}
                  dropdownClass="bg-white align-items-start"
                  placeholderClass="pl-3 align-self-start w-100 justify-content-between"
                  selectClass="bg-white height-40 pr-3"
                  onChange={handleSelectContact}
                />
              </div>
            )}
            {isContact && isNewContact && <CreateNewCustomerContact />}
            <div className="divider" />
            <div className="d-flex align-items-start mt-2 row">
              <div className="col-2">Комментарий к заказу</div>
              <div className="col-10">
                <textarea
                  rows={5}
                  onChange={handleChangeComment}
                  value={localComment}
                  onBlur={handleBlurComment}
                />
              </div>
            </div>
          </div>
        </div>
        {customerEditError && (
          <Modal
            color="warning"
            needRenderIcon={false}
            isOpenExternal
            title="Ошибка сохранения данных!"
            message={customerEditError}
            buttonToolbar={(toggle) => (
              <button className="btn btn-secondary modal_cancel height-40" onClick={toggle}>
                Ок
              </button>
            )}
          />
        )}
        <Modal
          color="danger"
          isOpenExternal={dangerModalSwitcher.isOn}
          title="Ошибка"
          message={
            <div>
              Заказ не оформлен
              <br /> <br />
              {`Ошибка сервера`}
            </div>
          }
          onToggle={() => {
            dangerModalSwitcher.toggle()
          }}
        />
      </div>

      <div className="mt-3 cart-wizard-footer">
        <button
          className="btn btn-primary form-control float-left height-40"
          onClick={previousStep}
        >
          Вернуться назад
        </button>
        <button
          className="btn btn-primary form-control  float-right height-40"
          disabled={
            isCartUpdate || (isContact && !contactPerson) || (isContact && !contactLinkType)
          }
          onClick={handleCreateOrder}
        >
          Оформить заказ
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  customer: state.cart.customer.result,
  customerIsEditing: state.cart.customer.isEditing,
  customerEditError: state.cart.customer.editError,
  cart: getCartForFetch(state),
  instanceId: state.app.instanceId,
  isCartUpdate: state.cart.isLoading,
  contactPerson: state.cart.contact_person,
  contactLinkType: state.cart.contact_link_type,
})

const mapDispatchToProps = {
  clearCart: cart.remove,
  setCart: cart.setCart,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CartAdditionalInfo)
