import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Layout/Modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yup } from '../../yup/index'
import { Button } from '../Layout/Button/Button'
import { InputField } from '../Layout/form/InputField'
import { Form } from '../Layout/form/Form'

const schema = yup.object().shape({
  code: yup.string().required().min(3),
})

export function CheckSmsCode({ isOpen, checkSmsCode, retrySending, close }) {
  const form = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => {
    checkSmsCode(data.code)
  }

  return (
    <Modal
      renderIcon={() => {}}
      withIcon
      isOpenExternal={isOpen}
      onToggle={close}
      buttonToolbar={() => (
        <Form form={form} onSubmit={onSubmit}>
          <InputField required placeholder="SMS-код" name="code" />
          <div className="form-group">
            <span className="text-info cursor-pointer" onClick={retrySending}>
              Отправить код повторно
            </span>
          </div>
          <Button className="btn btn-secondary height-40" onClick={close}>
            Отмена
          </Button>
          <Button type="submit" color="info" className="height-40">
            Продолжить
          </Button>
        </Form>
      )}
      message="Введите код из SMS-сообщения"
    />
  )
}

CheckSmsCode.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  checkSmsCode: PropTypes.func.isRequired,
  retrySending: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}
