import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State, AdditionalInfoPayload } from './model'

import { randomId } from '../../../helpers'
import { isLocalhost } from '../../../helpers/isLocalhost'

const initialState: State = {
  instanceId: '',
  sidebarIsOpened: false,
  shopName: '',
  shopCode: '',
  terminalId: '',
  paymentApiHost: '',
  value: 0,
  errorMessage: '',
  isServerUnavailable: false,
}

export default createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarIsOpened = !state.sidebarIsOpened
    },
    newAppInstanceId: (state) => {
      state.instanceId = randomId()
    },
    endGetToken: (state) => {
      state.instanceId = randomId()
    },
    additionalInfo: (state, action: PayloadAction<AdditionalInfoPayload>) => {
      state.shopName = action.payload.data?.name
      state.shopCode = action.payload.data?.shopCode
      state.terminalId = action.payload.data?.terminalId
      state.paymentApiHost = isLocalhost()
        ? '/rest/V1'
        : action.payload.data?.extension_attributes?.sales_point?.host
        ? action.payload.data?.extension_attributes?.sales_point?.host
        : action.payload.data?.host
    },
    setServerStatus: (state, action: PayloadAction<boolean>) => {
      state.isServerUnavailable = action.payload
    },
    setAuthErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
  },
})
