import React from 'react'

export function Reports() {
  return (
    <iframe
      title="report"
      src="https://cognosbitest.askona.ru/analytics/bi/?pathRef=.public_folders%2F%25D0%259E%25D1%2582%25D1%2587%25D0%25B5%25D1%2582%25D1%258B%2FPOS%2F%25D0%259F%25D1%2580%25D0%25B5%25D0%25B4%25D1%2581%25D1%2582%25D0%25B0%25D0%25B2%25D0%25BB%25D0%25B5%25D0%25BD%25D0%25B8%25D0%25B5%2B%25D0%25BE%25D1%2582%25D1%2587%25D0%25B5%25D1%2582%25D0%25B0%2BPOS_balance&amp;ui_appbar=false&amp;ui_navbar=false&amp;shareMode=embedded&amp;action=run&amp;format=HTML&amp;prompt=false&amp;promptParameters=%5B%7B%22name%22%3A%22%D0%A1%D0%BA%D0%BB%D0%B0%D0%B4%22%2C%22value%22%3A%5B%5D%7D%2C%7B%22name%22%3A%22%D0%9F%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%804%22%2C%22value%22%3A%5B%5D%7D%2C%7B%22name%22%3A%22%D0%9F%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%803%22%2C%22value%22%3A%5B%5D%7D%5D"
      width="320"
      height="200"
      frameBorder="0"
      allow="encrypted-media"
      allowFullScreen=""
    />
  )
}
