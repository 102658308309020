import React, { FC } from 'react'
import clsx from 'clsx'
import classnames from './Order.module.scss'
import { Col, Row } from 'reactstrap'
import { formatPriceRu } from '../../helpers/currency'
import { OrderItem } from '../../api/dto/OrderItem'

interface Props {
  services: OrderItem[]
}

export const OrderSevices: FC<Props> = ({ services }) => {
  return (
    <div className={clsx(classnames.services, 'pb-3 pt-3')}>
      <div className="text-lg font-weight-bold mb-2">Дополнительные сервисы</div>
      {services.map((item) => (
        <Row className="mb-2" key={item.extension_attributes.guid}>
          <Col xs={4}>
            <div className="text-left">{item.name}</div>
          </Col>
          <Col xs={4}>
            <div className="text-center">{item.qty_ordered}&nbsp;шт.</div>
          </Col>
          <Col xs={4}>
            <div className="text-right">{formatPriceRu(item.row_total)}</div>
          </Col>
        </Row>
      ))}
    </div>
  )
}
