export const formatPriceRu = (price: number, withCurrency = true) => {
  const formattedPrice = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(price)
  if (formattedPrice === 'не число') {
    return '-'
  }

  return withCurrency ? formattedPrice : formattedPrice.replace(/\u00A0₽/, '')
}

export const formatStringNumber = (numString: string, fractionPartLength = 2) => {
  const hasFractionPart = /\./g.test(numString)
  if (hasFractionPart) {
    return parseFloat(numString).toFixed(fractionPartLength)
  }
  return numString
}

export const formatToString = (value: number, fractionPartLength = 2) => {
  const formattedValue = parseFloat(value.toFixed(fractionPartLength))
  return formattedValue.toString()
}
