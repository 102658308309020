import React, { FC, memo } from 'react'
import { SliderAttribute } from '../attributes/SliderAttribute'
import { SwatchAttribute } from '../attributes/Swatch/SwatchAttribute'
import { RadioAttribute } from '../attributes/RadioAttribute'
import { AttributeProps } from '../../model'
import { SelectAttribute } from '../attributes/SelectAttribute'

enum AttributeType {
  Slider = 'slider',
  Swatch = 'swatch',
  Radio = 'radio',
  Select = 'select',
}

export const AttributeBuilder: FC<AttributeProps> = memo(
  (props) => {
    const {
      attribute: { frontend_type },
    } = props

    if (frontend_type === AttributeType.Slider) {
      return <SliderAttribute {...props} />
    }

    if (frontend_type === AttributeType.Swatch) {
      return <SwatchAttribute {...props} />
    }

    if (frontend_type === AttributeType.Radio) {
      return <RadioAttribute {...props} />
    }

    if (frontend_type === AttributeType.Select) {
      return <SelectAttribute {...props} />
    }

    return null
  },
  (prevProps, nextProps) => {
    return prevProps.data.get(prevProps.attribute) === nextProps.data.get(nextProps.attribute)
  },
)
