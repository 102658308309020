import React from 'react'
import PropTypes from 'prop-types'

function DadataSuggestions({ suggestions, onSelect, isOpen }) {
  if (!suggestions?.length || !isOpen) {
    return null
  }

  return (
    <div className="dadata-suggestions bg-light border border-dark">
      {suggestions.map((suggestion) => (
        <div
          key={suggestion.data.fias_id}
          onMouseDown={(e) => {
            // Клик на подсказку должен сработать раньше, чем onBlur инпута
            e.stopPropagation()
            if (onSelect) {
              onSelect(suggestion.data)
            }
          }}
        >
          {suggestion.value}
        </div>
      ))}
    </div>
  )
}

export default DadataSuggestions

DadataSuggestions.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({ fias_id: PropTypes.string }),
      unrestricted_value: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
}

DadataSuggestions.defaultProps = {
  suggestions: [],
  onSelect: null,
  isOpen: false,
}
