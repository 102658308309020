import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { getAppData } from '../../redux/selectors/app'
import classnames from './AuthInfoErrorModal.module.scss'

export const AuthInfoErrorModal: FC = () => {
  const { errorMessage } = useSelector(getAppData)

  return (
    <Modal isOpen={Boolean(errorMessage)}>
      <ModalHeader className={classnames.modalHeader}>Торговая точка не определена</ModalHeader>
      <ModalBody>{errorMessage}</ModalBody>
    </Modal>
  )
}
