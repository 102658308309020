import React, { FC, ReactNode } from 'react'
import { FormGroup, Label } from 'reactstrap'
import clsx from 'clsx'
import classnames from './Input.module.scss'
import { InputProps } from 'reactstrap/lib/Input'
import MaskedInput, { maskArray } from 'react-text-mask'

export interface Props extends InputProps {
  name: string
  label?: ReactNode
  required?: boolean
  errorMessage?: string
  className?: string
  mask: maskArray | ((value: string) => maskArray)
  isShortInput?: boolean
}

export const InputWithMask: FC<Props> = ({
  label,
  required = false,
  type,
  errorMessage,
  innerRef,
  name,
  mask,
  color,
  placeholder = '',
  className,
  onChange,
  value,
  isShortInput = false,
  ...props
}) => {
  return (
    <FormGroup
      className={clsx('text-left mb-0', {
        [classnames.input]: !isShortInput,
        [classnames.shortInput]: isShortInput,
        [classnames.withError]: !!errorMessage,
      })}
    >
      {label && (
        <Label for={name}>
          {label} {required ? <span className="text-red">*</span> : ''}
        </Label>
      )}
      <MaskedInput
        color={color}
        type={type}
        mask={mask}
        name={name}
        id={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={clsx(className, { error: Boolean(errorMessage) })}
        {...props}
      />
      {errorMessage && (
        <span className={clsx('text-red', classnames.errorMessage)}>{errorMessage}</span>
      )}
    </FormGroup>
  )
}
