import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Modal, RadioButton, Select } from '../index'
import DadataSuggestions from './DadataSuggestions'
import { debounce } from '../../helpers'
import { useFormContext, useWatch } from 'react-hook-form'
import { actions, thunks } from '../../redux/redusers'
import { PopoverWrapper } from '../Layout/PopoverWrapper/PopoverWrapper'
import clsx from 'clsx'

const liftValues = [
  {
    id: 1,
    name: 'есть',
    value: true,
  },
  {
    id: 2,
    name: 'нет',
    value: false,
  },
]

const typeToNameMapper = {
  region: 'регион',
  city: 'населенный пункт',
  street: 'улицу',
  house: 'дом',
}

function EditAddress({
  onRemove,
  dadataSearch,
  dadataResult,
  clearDadataSuggestions,
  name,
  localId,
}) {
  const [suggestionsOpen, setSuggestionsOpen] = useState({})
  const context = useFormContext()
  const { setValue, errors, register, control, trigger } = context
  const fieldNameStart = `${name}[${localId}]`
  const values = useWatch({ control, name: fieldNameStart, defaultValue: {} })
  const { region, city, street, house } = values
  register({ name: `${fieldNameStart}.fias` }, { region: '', city: '', street: '', house: '' })
  const defaultId = useWatch({ control, name: 'defaultId' })
  const fias = useWatch({ control, name: `${fieldNameStart}.fias` })

  const switchSuggestions = (field, value) => {
    setSuggestionsOpen({ ...suggestionsOpen, [field]: value })
  }

  const hasError = (type) => {
    return errors?.[name]?.[localId]?.[type] || errors?.[name]?.[localId]?.fias?.[type]
  }

  const getFieldClass = (type) => {
    const isDanger = hasError(type)
    return clsx('form-control square', {
      'border-danger': isDanger,
      'border-dark': !isDanger,
    })
  }

  //eslint-disable-next-line
  const suggestions = useCallback(
    debounce((query, type) => {
      dadataSearch({
        params: values.fias ?? {},
        addressId: String(localId),
        query,
        type,
      })
    }, 500),
    [dadataSearch, values.fias, localId],
  )

  const clearForm = () => {
    setValue(`${fieldNameStart}.fias`, { region: '', city: '', street: '', house: '' })
    setValue(`${fieldNameStart}.region`, '')
    setValue(`${fieldNameStart}.city`, '')
    setValue(`${fieldNameStart}.street`, '')
    setValue(`${fieldNameStart}.house`, '')
  }

  const setRegion = (region) => {
    setValue(`${fieldNameStart}.region`, region.region_with_type)
    setValue(`${fieldNameStart}.city`, '')
    setValue(`${fieldNameStart}.street`, '')
    setValue(`${fieldNameStart}.house`, '')
    setValue(`${fieldNameStart}.fias`, { ...fias, region: region.fias_id })
    trigger(`${fieldNameStart}.fias.region`)
  }

  const setCity = (city) => {
    setValue(`${fieldNameStart}.city`, city.city_with_type || city.city)
    setValue(`${fieldNameStart}.street`, '')
    setValue(`${fieldNameStart}.house`, '')
    setValue(`${fieldNameStart}.fias`, { ...fias, city: city.fias_id, street: '' })
    trigger(`${fieldNameStart}.fias.city`)
  }

  const setStreet = (street) => {
    setValue(`${fieldNameStart}.street`, street.street_with_type)
    setValue(`${fieldNameStart}.house`, '')
    // Если клиент вдруг начал заполнять с улицы, поможем ему и подставим город автоматически
    if (!values.fias?.city) {
      setValue(`${fieldNameStart}.city`, street.city || street.settlement_with_type)
      setValue(`${fieldNameStart}.fias`, { ...fias, city: street.city_fias_id })
      trigger(`${fieldNameStart}.fias.city`)
    }
    setValue(`${fieldNameStart}.fias`, { ...fias, street: street.fias_id })
    trigger(`${fieldNameStart}.fias.street`)
  }

  const setHouse = (house) => {
    setValue(`${fieldNameStart}.house`, house.house)
    setValue(`${fieldNameStart}.fias`, { ...fias, house: house.fias_id })
    trigger(`${fieldNameStart}.fias.house`)
  }

  const getErrorMessage = (type) => {
    if (errors?.[name]?.[localId]?.[type]) {
      return `Необходимо ввести ${typeToNameMapper[type]}`
    }
    if (errors?.[name]?.[localId]?.fias?.[type]) {
      return `Необходимо выбрать ${typeToNameMapper[type]} из предложенных вариантов`
    }
    return ''
  }

  useEffect(
    function regionChanged() {
      suggestions(region, 'region')
      if (!region) {
        clearForm()
        clearDadataSuggestions()
      }
    },
    // нельзя добавлять suggestions
    //eslint-disable-next-line
    [region],
  )

  useEffect(
    function cityChanged() {
      suggestions(city, 'city')
    },
    //eslint-disable-next-line
    [city],
  )

  useEffect(
    function streetChanged() {
      suggestions(street, 'street')
    },
    //eslint-disable-next-line
    [street],
  )

  useEffect(
    function houseChanged() {
      suggestions(house, 'house')
    },
    //eslint-disable-next-line
    [house],
  )

  if (!values) {
    return null
  }

  return (
    <div className="address-block">
      <div className="d-flex mt-3">
        <RadioButton
          checked={values.localId === defaultId}
          name="newDefaultId"
          value={values.localId}
          onChange={() => setValue('defaultId', values.localId)}
          inputClassName="pl-0"
        />
        <div className="title-with-icon">Регион доставки</div>
        <div className="input-row">
          <PopoverWrapper
            popoverEnabled={hasError('region')}
            popoverText={getErrorMessage('region')}
          >
            <input
              ref={register}
              autoComplete="newRegion"
              name={`${fieldNameStart}.region`}
              onFocus={() => switchSuggestions('region', true)}
              onBlur={() => switchSuggestions('region', false)}
              className={getFieldClass('region')}
              placeholder="Определите регион доставки"
            />
            <DadataSuggestions
              isOpen={suggestionsOpen.region}
              suggestions={dadataResult.region || []}
              onSelect={(region) => {
                setRegion(region)
                switchSuggestions('region', false)
              }}
            />
          </PopoverWrapper>
        </div>
      </div>
      <div className="d-flex">
        <div className="title-without-icon">Адрес</div>
        <div className="input-row">
          <PopoverWrapper popoverEnabled={hasError('city')} popoverText={getErrorMessage('city')}>
            <input
              ref={register}
              autoComplete="newCity"
              disabled={!values.fias?.region}
              name={`${fieldNameStart}.city`}
              onFocus={() => switchSuggestions('city', true)}
              onBlur={() => switchSuggestions('city', false)}
              className={getFieldClass('city')}
              placeholder="Введите название населенного пункта"
            />
            <DadataSuggestions
              isOpen={suggestionsOpen.city}
              suggestions={dadataResult.city || []}
              onSelect={(city) => {
                setCity(city)
                switchSuggestions('city', false)
              }}
            />
          </PopoverWrapper>
        </div>
      </div>
      <div className="d-flex">
        <div className="title-without-icon" />
        <div className="input-row">
          <PopoverWrapper
            popoverEnabled={hasError('street')}
            popoverText={getErrorMessage('street')}
          >
            <input
              ref={register}
              autoComplete="newStreet"
              disabled={!values.fias?.region}
              name={`${fieldNameStart}.street`}
              onFocus={() => switchSuggestions('street', true)}
              onBlur={() => switchSuggestions('street', false)}
              className={getFieldClass('street')}
              placeholder="Введите название улицы"
            />
            <DadataSuggestions
              isOpen={suggestionsOpen.street}
              suggestions={dadataResult.street || []}
              onSelect={(street) => {
                setStreet(street)
                switchSuggestions('street', false)
              }}
            />
          </PopoverWrapper>
        </div>
      </div>
      <div className="d-flex">
        <div className="title-without-icon" />
        <div className="input-row d-flex justify-content-between">
          <div className="address-detail">
            <PopoverWrapper
              popoverEnabled={hasError('house')}
              popoverText={getErrorMessage('house')}
            >
              <input
                ref={register}
                autoComplete="newHouse"
                disabled={!values.fias?.region}
                name={`${fieldNameStart}.house`}
                onFocus={() => switchSuggestions('house', true)}
                onBlur={() => switchSuggestions('house', false)}
                className={getFieldClass('house')}
                placeholder="Дом"
              />
              <DadataSuggestions
                isOpen={suggestionsOpen.house}
                suggestions={dadataResult.house || []}
                onSelect={(house) => {
                  setHouse(house)
                  switchSuggestions('house', false)
                }}
              />
            </PopoverWrapper>
          </div>
          <div className="address-detail">
            <input
              ref={register}
              disabled={!values.fias?.region}
              name={`${fieldNameStart}.block`}
              className={getFieldClass('block')}
              placeholder="Корпус"
            />
          </div>
          <div className="address-detail">
            <input
              ref={register}
              disabled={!values.fias?.region}
              name={`${fieldNameStart}.building`}
              className={getFieldClass('building')}
              placeholder="Строение"
            />
          </div>
          <div className="address-detail">
            <input
              ref={register}
              disabled={!values.fias?.region}
              name={`${fieldNameStart}.apartment`}
              className={getFieldClass('apartment')}
              placeholder="Квартира"
            />
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="title-without-icon" />
        <div className="input-row d-flex justify-content-between">
          <div className="address-detail">
            <input
              ref={register}
              disabled={!values.fias?.region}
              type="number"
              value={values.entrance_number || ''}
              name={`${fieldNameStart}.entrance_number`}
              className={getFieldClass('entrance_number')}
              placeholder="Подъезд"
            />
          </div>
          <div className="address-detail">
            <input
              ref={register}
              disabled={!values.fias?.region}
              type="number"
              value={values.intercom_code || ''}
              name={`${fieldNameStart}.intercom_code`}
              className={getFieldClass('intercom_code')}
              placeholder="Домофон"
            />
          </div>
          <div className="address-detail">
            <input
              ref={register}
              disabled={!values.fias?.region}
              type="number"
              name={`${fieldNameStart}.floor`}
              className={getFieldClass('floor')}
              placeholder="Этаж"
            />
          </div>
          <div className="address-detail">
            <Select
              disabled={!values.fias?.region}
              options={liftValues}
              name={`${fieldNameStart}.lift_availability`}
              placeholder="Лифт"
              onChange={(id) => {
                const { value } = liftValues.find((e) => e.id === id) || {}
                setValue(`${fieldNameStart}.lift_availability`, value)
              }}
              selectClass={'address-elevator'}
              dropdownClass={'bg-white'}
              withoutWidthCustomization={true}
            />
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="title-without-icon">Комментарий</div>
        <div className="input-row">
          <input
            ref={register}
            disabled={!values.fias?.region}
            name={`${fieldNameStart}.comment`}
            className="form-control bg-secondary border-dark square"
            placeholder="Дополнительная информация по адресу доставки"
          />
        </div>
      </div>
      <div className="divider" />
      {onRemove && (
        <Modal
          color="warning"
          renderIcon={(toggle) => (
            <span className="lnr lnr-trash cursor-pointer remove-address-button" onClick={toggle} />
          )}
          title="Внимание!"
          message="Выбранный адрес будет удален из карточки Клиента . Вы уверены, что хотите продолжить?"
          buttonToolbar={(toggle) => (
            <>
              <button className="btn btn-secondary modal_cancel height-40" onClick={toggle}>
                Отмена
              </button>
              <button
                className="btn btn-warning modal_ok height-40"
                color="warning"
                onClick={() => {
                  toggle()
                  clearDadataSuggestions(localId)
                  onRemove()
                }}
              >
                Удалить
              </button>
            </>
          )}
        />
      )}
    </div>
  )
}
const mapStateToProps = (state, ownProps) => ({
  dadataResult: state.dadata.result[String(ownProps?.localId)] || {},
})

const mapDispatchToProps = {
  dadataSearch: thunks.dadata.getDadataResult,
  clearDadataSuggestions: actions.dadata.clearDadataSuggestions,
}

EditAddress.propTypes = {
  localId: PropTypes.number.isRequired,
  dadataSearch: PropTypes.func.isRequired,
  dadataResult: PropTypes.object.isRequired,
  clearDadataSuggestions: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  name: PropTypes.string.isRequired,
}
EditAddress.defaultProps = {
  onRemove: null,
  dadataResult: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress)
