import { update } from '../../api/storage'
import store from '../store'
import { cartId as cart } from '../../constants/localStorageItems'

let prevCartId: number | undefined

export const updateCartFromLocalStorage = () => {
  const { cartId } = store.getState().cart
  if (cartId && prevCartId !== cartId) {
    update(cart, cartId)
    prevCartId = cartId
  }
}
