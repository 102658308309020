import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import Search from './screens/Search'
import Product from './screens/Product'
import Customer from './screens/Customer'
import Cart from './screens/Cart/Cart'
import ProductOffer from './screens/ProductOffer'
import { Content, CustomerModals, Footer, Header, Sidebar } from './components'

import './styles/app.scss'
import { Credit } from './widgets/Credit/Credit'
import { Order } from './screens/Order/Order'
import { getAppParams } from './redux/actions'
import { QRScanner } from './components/QRScanner/QRScanner'
import { Reports } from './screens/Reports'
import { Constructor } from './screens/Constructor/components/Constructor'
import { find } from './api/storage'
import { cartId } from './constants/localStorageItems'
import { initializeCart } from './redux/actions/cart'
import { AuthInfoErrorModal } from './components/ErrorService/AuthInfoErrorModal'
import { AddClient } from './screens/AddClient'
import { getAppData } from './redux/selectors/app'
import { UpdateErrorMessage } from './components/ErrorService/UpdateErrorMessage'

export const App = () => {
  const dispatch = useDispatch()
  const { isServerUnavailable } = useSelector(getAppData)

  const [isOnline, setIsOnline] = useState(true)

  const queryClient = useMemo(
    () =>
      new QueryClient({
        cache: new QueryCache(),
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
    [],
  )

  const location = useLocation()

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true))
    window.addEventListener('offline', () => setIsOnline(false))
  }, [])

  const getParams = useCallback(() => {
    dispatch(getAppParams())
  }, [dispatch])

  useEffect(() => {
    if (isServerUnavailable) {
      const interval = setInterval(() => {
        getParams()
      }, 60000)
      return () => clearInterval(interval)
    }
  }, [isServerUnavailable, getParams])

  useEffect(() => {
    let interaction = window?.newrelic?.interaction()
    if (interaction) {
      interaction.setName(window.location.url)
      interaction.save()
    }
  }, [location])

  useEffect(() => {
    if (isOnline) {
      dispatch(getAppParams())
    }
  }, [dispatch, isOnline])

  useEffect(() => {
    if (isOnline) {
      const cartIdFromStorage = find(cartId)
      if (cartIdFromStorage) {
        dispatch(initializeCart(cartIdFromStorage))
      }
    }
  }, [dispatch, isOnline])

  return (
    <QueryClientProvider client={queryClient}>
      {isServerUnavailable ? (
        <UpdateErrorMessage />
      ) : (
        <div div className="container-fluid p-0 h-100 d-flex flex-column">
          <Header />
          <CustomerModals />
          <Sidebar />
          <AuthInfoErrorModal />
          <Content>
            <Switch>
              <Route path="/" exact component={Search} />
              <Route path="/cart" exact component={Cart} />
              <Route path="/customer/:id" exact component={Customer} />
              <Route path="/product/:sku" exact component={Product} />
              <Route path="/product/:productId/offer/:id" exact component={ProductOffer} />
              <Route path="/order/credit" exact component={Credit} />
              {/*<Route path="/order/preorder" component={PreOrder} />*/}
              <Route path="/order/:id" component={Order} />
              <Route path="/constructor" component={Constructor} />
              <Route path="/add-client" component={AddClient} />
              <Route path="/qr" component={QRScanner} />
              <Route path="/reports" exact component={Reports} />
              <Redirect from="*" to="/" />
            </Switch>
          </Content>
          <Footer />
        </div>
      )}
    </QueryClientProvider>
  )
}
