import { getImage } from '../../helpers/product'
import { createSelector } from 'reselect'
import { DELIVERY_OPTION_DELIVERY } from '../../constants'

const getRawCart = (state) => state.cart

export const getCart = createSelector([getRawCart], (cart) => {
  const clientName = [
    cart.customer?.result?.surname,
    cart.customer?.result?.given_name,
    cart.customer?.result?.middle_name,
  ]
    .filter(Boolean)
    .join(' ')

  return {
    ...cart,
    items: (cart.items ?? []).map((item) => {
      return {
        ...item,
        total: item.special_price > 0 ? item.special_price : item.price,
        mainImage: getImage(item),
      }
    }),
    clientName,
  }
})

/**
 * Чистим корзину от параметров, которые не принимает бэк
 */
export const getCartForFetch = createSelector(
  [getRawCart],
  ({ cartId, customer, correlationId, customer_id, isLoading, error, ...cart }) => {
    return { ...cart, customer_id: customer?.result?.customer_id || null, cart_id: cartId }
  },
)

export const getDeliveryTypes = createSelector(getRawCart, (cart) => {
  let types = cart.items?.map(({ id, delivery_type }) => {
    return [id, delivery_type]
  })
  return types ? Object.fromEntries(types) : {}
})

export const getCartCustomer = createSelector(getRawCart, (cart) => {
  return {
    customer: cart.customer.result,
  }
})

export const selectCartCustomerIsCreated = createSelector(
  getRawCart,
  (state) => state.customer.isCreated,
)

export const selectCartCustomerIsCreating = createSelector(
  getRawCart,
  (state) => state.customer.isCreating,
)

export const selectCartCustomerCreateError = createSelector(
  getRawCart,
  (state) => state.customer.createError,
)

export const getAddress = createSelector(getRawCart, (cart) => {
  return cart.customer?.result?.addresses?.find(({ id }) => id === cart.delivery_address)
})

export const getPhone = createSelector(getRawCart, (cart) => {
  return cart.customer?.result?.phone
})

export const getEmail = createSelector(getRawCart, (cart) => {
  return cart.customer?.result?.email
})

export const getServices = createSelector(getRawCart, (cart) => {
  return cart.services
})

export const getHasDelivery = createSelector(getRawCart, (cart) => {
  return cart.items.some(({ delivery_type }) => delivery_type === DELIVERY_OPTION_DELIVERY)
})
