import { captureException, captureMessage, SentryMessageTags } from './sentryCaptures'
import { HEALTH_URL, paymentAxios } from './paymentAxiosInit'

export async function healthCheck(host: string, tags: SentryMessageTags) {
  try {
    const response = await paymentAxios.get(`${host}:8081${HEALTH_URL}`, {})
    if (response.status === 200) {
      captureMessage(tags)
      return {
        success: true,
        data: response.data,
      }
    }
    captureException(tags)
    return {
      success: false,
      errorMessage: `статус запроса ${response.status}`,
    }
  } catch (error) {
    captureException(tags)
    return {
      success: false,
      errorMessage: error.toString(),
    }
  }
}
