import React, { FC } from 'react'
import classnames from './UpdateErrorMessage.module.scss'
import { ReactComponent as ErrorIcon } from './../../images/icons/update-error.svg'

export const UpdateErrorMessage: FC = () => {
  return (
    <div className={classnames.errorContainer}>
      <div className={classnames.errorIcon}>
        <ErrorIcon />
      </div>
      <div></div>
      <div className={classnames.errorTitle}>Работа POS приостановлена</div>
      <div className={classnames.errorText}>
        В данный момент система обновляется.
        <br /> Вы сможете продолжить работу после установки релиза.
      </div>
    </div>
  )
}
