import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { format, add } from 'date-fns'
import { throttle } from 'lodash-es'
import { Checkbox, EditAddress, Loader, Modal, Select, Switcher, DatePicker } from '../../index'
import { connect } from 'react-redux'
import {
  customerShape,
  newId,
  emptyAddress,
  DELIVERY_OPTION_DELIVERY,
  addressValidationSchema,
} from '../../../constants'
import { customerHelper } from '../../../helpers'
import { cart } from '../../../redux/actions'
import { Input } from '../../Layout/Input/Input'
import { formatPriceRu } from '../../../helpers/currency'
import { Col, Row } from 'reactstrap'
import { Button } from '../../Layout/Button/Button'
import clsx from 'clsx'
import { timeForRequest } from '../../../helpers/time'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'

function CartRegistrationOfDelivery({
  nextStep,
  previousStep,
  customer,
  addAddress,
  customerIsEditing,
  customerEditError,
  setDeliveryAddress,
  postDeliveryPrice,
  requestedDeliveryPrice,
  minRequestedDeliveryPrice,
  deliveryAddressId,
  cartItems,
  deliveryVariants,
  postDeliveryDate,
  requestedDeliveryDate,
  isCartUpdate,
  promocode,
}) {
  const [isLiftToFloor, setIsLiftToFloor] = useState(false)
  const [isNewAddress, setIsNewAddress] = useState(false)
  const [isDateChange, setIsDateChange] = useState(!requestedDeliveryDate)
  const [isDeliveryPriceChanging, setIsDeliveryPriceChanging] = useState(!requestedDeliveryPrice)
  const [deliveryPrice, setDeliveryPrice] = useState()
  const [deliveryPriceError, setDeliveryPriceError] = useState()
  const [isDeliveryPriceLoading, setIsDeliveryPriceLoading] = useState(false)
  const [isDeliveryDateLoading, setIsDeliveryDateLoading] = useState(true)
  // должно приходить с сервера после пересчета корзины
  const [deliveryDate, setDeliveryDate] = useState()
  const [deliveryDateError, setDeliveryDateError] = useState()
  const [minDeliveryDate, setMinDeliveryDate] = useState()
  const [deliveryDateId, setDeliveryDateId] = useState()
  const [isDeliveryPriceSent, setIsDeliveryPriceSent] = useState(Boolean(requestedDeliveryPrice))
  const [showRequestedDeliveryPrice, setShowRequestedDeliveryPrice] = useState(true)

  useEffect(function setCustomerDefaultAddress() {
    if (!deliveryAddressId) {
      const defaultAddressId = (
        customer?.addresses?.find(({ is_default }) => is_default) || customer.addresses?.[0]
      )?.id
      setDeliveryAddress(defaultAddressId)
    }
  }, [])

  useEffect(
    function setDeliveryDateInfosFromDeliveryVariants() {
      setIsDeliveryDateLoading(deliveryVariants?.[0] === undefined)
      if (deliveryVariants?.[0]) {
        const minDate = Date.parse(deliveryVariants[0].total_delivery_date)
        setMinDeliveryDate(minDate)
        setDeliveryDateId(deliveryVariants[0].id)
        if (!requestedDeliveryDate) {
          setDeliveryDate(minDate)
          setDeliveryDateError(undefined)
          setIsDateChange(true)
        }
      }
    },
    [deliveryVariants, requestedDeliveryDate],
  )

  useEffect(() => {
    if (cartItems.some((item) => item.delivery_type === DELIVERY_OPTION_DELIVERY)) {
      setDeliveryDate(undefined)
      setMinDeliveryDate(undefined)
      setDeliveryDateError(undefined)
      setDeliveryPrice(undefined)
      setDeliveryDateId(undefined)
      setShowRequestedDeliveryPrice(false)
      setIsDeliveryPriceChanging(true)
    }
  }, [JSON.stringify(cartItems), deliveryAddressId, promocode])

  useEffect(
    function setIsDateChangeFalse() {
      if (requestedDeliveryDate) {
        setIsDateChange(false)
      }
    },
    [requestedDeliveryDate],
  )

  useEffect(
    function resetDeliveryDate() {
      if (minDeliveryDate && !deliveryVariants) {
        setDeliveryDate(undefined)
        setMinDeliveryDate(undefined)
        setDeliveryDateError('Дата доставки не определена')
        setIsDeliveryDateLoading(false)
        setIsDateChange(true)
      }
      if (!minDeliveryDate && deliveryVariants) {
        setDeliveryDateError(undefined)
      }
    },
    [deliveryVariants, minDeliveryDate],
  )

  useEffect(
    function clearRequestDeliveryDateIfMinDateBigger() {
      if (
        !isCartUpdate &&
        !isDeliveryDateLoading &&
        requestedDeliveryDate &&
        (!minDeliveryDate || Date.parse(requestedDeliveryDate) < minDeliveryDate)
      ) {
        setDeliveryDate(minDeliveryDate ?? undefined)
        ;(async function () {
          setIsDeliveryDateLoading(true)
          await postDeliveryDate(null, deliveryDateId)
          setIsDeliveryDateLoading(false)
          setIsDateChange(true)
        })()
      }
    },
    [
      deliveryDateId,
      isCartUpdate,
      isDeliveryDateLoading,
      minDeliveryDate,
      postDeliveryDate,
      requestedDeliveryDate,
    ],
  )

  const handleClickDateChangeButton = async () => {
    const newDeliveryDate = deliveryDate ? timeForRequest(deliveryDate) : requestedDeliveryDate
    if (newDeliveryDate < minDeliveryDate) {
      setDeliveryDateError(
        `Дата не должна быть меньше чем ${format(minDeliveryDate, 'yyyy-MM-dd')}`,
      )
      return
    }
    setIsDateChange(false)
    if (requestedDeliveryDate !== newDeliveryDate) {
      setIsDeliveryDateLoading(true)
      await postDeliveryDate(newDeliveryDate, deliveryDateId)
      setIsDeliveryDateLoading(false)
    }
  }

  const formName = 'addresses'
  const formId = emptyAddress.localId
  const dataKey = `${formName}[${formId}]`

  const defaultValues = useMemo(() => {
    return {
      [dataKey]: { ...emptyAddress },
      defaultId: formId,
    }
  }, [dataKey, formId])
  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addressValidationSchema),
    defaultValues,
  })
  const { handleSubmit, register, reset, getValues } = form

  useEffect(function resetForm() {
    reset()
  }, [])

  useEffect(
    function setDefaultValues() {
      register(dataKey, defaultValues[dataKey])
      register('defaultId', defaultValues.defaultId)
    },
    [dataKey, defaultValues, register],
  )

  const handleSave = (address) => {
    const data = customerHelper.addressMapping({
      address: address,
      customer,
    })
    addAddress(data)
    setIsNewAddress(false)
  }
  const handleSaveRef = useRef()
  handleSaveRef.current = handleSave

  const handleSaveAddress = useMemo(() => {
    return throttle(() => {
      const { addresses, defaultId } = getValues()
      handleSaveRef.current({
        ...addresses?.[formId],
        is_default: defaultId === addresses?.[formId].localId,
      })
    }, 5000)
  }, [formId, getValues])

  const isSomeItemHasDelivery = cartItems.some(
    ({ delivery_type }) => delivery_type === DELIVERY_OPTION_DELIVERY,
  )

  /**
   * Адреса для селектора
   * с возможностью добавить новый адрес по id: 0
   */
  const addresses = useMemo(
    () => [
      { id: newId, name: <p className="new-address-add">Добавить адрес</p> },
      ...(customer?.addresses || [])
        ?.filter((item) => Boolean(item.address))
        ?.map((a) => ({
          id: a.id,
          name: a.address,
        })),
    ],
    [customer?.addresses],
  )
  const handleSelectAddress = (addressId) => {
    if (addressId === 0) {
      setIsNewAddress(true)
    } else {
      setDeliveryAddress(customer.addresses.find((a) => a.id === addressId)?.id)
    }
  }

  const handleClickPriceButton = async () => {
    const deliveryPriceNumber = Number(deliveryPrice ?? requestedDeliveryPrice)

    if (deliveryPriceNumber < minRequestedDeliveryPrice) {
      setDeliveryPriceError(
        `Стоимость не должна быть ниже чем ${formatPriceRu(minRequestedDeliveryPrice)}`,
      )

      return
    }
    setIsDeliveryPriceChanging(false)
    if (deliveryPriceNumber !== requestedDeliveryPrice) {
      setIsDeliveryPriceLoading(true)
      setDeliveryDate(undefined)
      setIsDeliveryPriceSent(true)
      await postDeliveryPrice(Number(deliveryPrice))
      setIsDeliveryPriceLoading(false)
      setShowRequestedDeliveryPrice(true)
    }
  }

  const isDisabledBecauseOfDeliveryDate =
    (Boolean(deliveryVariants) && isDeliveryDateLoading) ||
    (customer?.addresses?.length > 0 && !deliveryAddressId)
  const hasAddresses = customer?.addresses?.length > 0

  return (
    <div className="cart-wizard-delivery">
      <div className="bg-secondary br-5 cart-wizard-body">
        <h2 className="text-gray d-flex align-items-center">Шаг 2. Оформление доставки</h2>
        <div className="row">
          <div className="col">
            {customerIsEditing && <Loader wrapClassName="transparent-loader" />}
            {isNewAddress || !hasAddresses ? (
              <div className="address">
                <FormProvider {...form}>
                  <EditAddress name={formName} localId={formId} />
                  <div className="col-12 mt-3 text-right">
                    <button
                      className="btn btn-outline-primary height-40"
                      type="submit"
                      onClick={handleSubmit(handleSaveAddress)}
                    >
                      Сохранить
                    </button>
                  </div>
                </FormProvider>
              </div>
            ) : (
              <div>
                <div className="d-flex align-items-center">
                  <div className="col-2">Адрес доставки</div>
                  <div className="col-10">
                    <Select
                      disabled={isCartUpdate}
                      options={addresses}
                      initialValueId={deliveryAddressId}
                      dropdownClass={`bg-white align-items-start ${
                        addresses?.length > 4 ? 'dropdown-with-scroll' : ''
                      }`}
                      dropdownItemClass="height-48 dropdown-item"
                      placeholderClass="pl-3 align-self-start justify-content-between"
                      selectClass="bg-white height-40 pr-3 w-100"
                      onChange={handleSelectAddress}
                      cartLine
                      withRotateIcon
                    />
                  </div>
                </div>

                <div className="d-flex align-items-start mt-2">
                  <div>
                    <Checkbox
                      checked={isLiftToFloor}
                      name="isLiftToFloor"
                      onChange={() => setIsLiftToFloor(!isLiftToFloor)}
                      wrapperClassName="need-lift ml-3 mb-1"
                    />
                  </div>
                  <div className="col-12">Требуется подъем на этаж?</div>
                </div>

                <div className="divider" />

                {isSomeItemHasDelivery && (
                  <div className="d-flex align-items-center mt-4 date-change-block">
                    <div className="col-2">
                      Стоимость доставки (минимум {formatPriceRu(minRequestedDeliveryPrice)})
                    </div>
                    {requestedDeliveryPrice !== 0 && (
                      <>
                        {showRequestedDeliveryPrice &&
                          Number(deliveryPrice) === Number(requestedDeliveryPrice) && (
                            <div className="col-2">{formatPriceRu(requestedDeliveryPrice)}</div>
                          )}
                        <div className="col-2">
                          <Switcher
                            checked={isDeliveryPriceChanging}
                            onChange={({ target }) => setIsDeliveryPriceChanging(target.checked)}
                            id="changeDeliveryPrice"
                            labelChildren={<>Изменить стоимость</>}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-6 mt-3">
                      {isDeliveryPriceChanging && !isCartUpdate ? (
                        <Row>
                          <Col xs={4}>
                            <Input
                              errorMessage={deliveryPriceError}
                              className={clsx('height-40', { 'border-danger': deliveryPriceError })}
                              placeholder="Введите сумму"
                              required
                              min={minRequestedDeliveryPrice}
                              step={1}
                              label=""
                              name="changeDeliveryPrice"
                              type="number"
                              onChange={(e) => {
                                setDeliveryPriceError(undefined)
                                setDeliveryPrice(e.currentTarget.value)
                              }}
                            />
                          </Col>
                          <Col xs={4}>
                            <Button
                              className="height-40"
                              style={{ width: 80 }}
                              color="primary"
                              disabled={isDisabledBecauseOfDeliveryDate}
                              onClick={() => {
                                handleClickPriceButton()
                              }}
                            >
                              Ок
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        isCartUpdate && (
                          <div className="w-25 load-icon_small-size">
                            <Loader />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                {isDeliveryPriceSent &&
                  deliveryPrice &&
                  Number(deliveryPrice) === Number(requestedDeliveryPrice) && (
                    <div
                      className={clsx(
                        'd-flex align-items-center mt-4 mb-5 date-change-block--tall',
                        {
                          'pb-5 pt-2': deliveryDateError,
                        },
                      )}
                    >
                      <div className="col-2">Дата исполнения заказа</div>

                      {deliveryVariants && requestedDeliveryDate && !isCartUpdate && (
                        <>
                          <div className="col-2">
                            {!isNaN(Date.parse(requestedDeliveryDate)) &&
                              format(new Date(requestedDeliveryDate), 'dd.MM.yyyy')}
                          </div>
                          <div className="col-2">
                            <Switcher
                              checked={isDateChange}
                              onChange={({ target }) => setIsDateChange(target.checked)}
                              id="changeDate"
                              labelChildren={<>Изменить дату</>}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-6">
                        {(isDeliveryDateLoading || isCartUpdate) && (
                          <div className="w-25 load-icon_small-size">
                            <Loader />
                          </div>
                        )}
                        {isDateChange && !isDeliveryDateLoading && !isCartUpdate && (
                          <Row wrap="nowrap">
                            <Col xs={4}>
                              <div className="date-picker-wrapper">
                                <DatePicker
                                  errorMessage={deliveryDateError}
                                  className={clsx('form__form-group-datepicker w-100 height-40', {
                                    'border-danger': deliveryDateError,
                                  })}
                                  placeholderText="Введите дату"
                                  selected={deliveryDate}
                                  minDate={minDeliveryDate}
                                  maxDate={
                                    minDeliveryDate ? add(minDeliveryDate, { days: 90 }) : undefined
                                  }
                                  onChange={(date) => {
                                    setDeliveryDateError(undefined)
                                    setDeliveryDate(date)
                                  }}
                                  disabled={!minDeliveryDate || requestedDeliveryPrice === 0}
                                />
                              </div>
                            </Col>
                            <Col xc={4}>
                              <Button
                                className="height-40"
                                style={{ width: 80 }}
                                color="primary"
                                onClick={handleClickDateChangeButton}
                                disabled={isDeliveryPriceLoading || !minDeliveryDate}
                              >
                                Ок
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
        {customerEditError && (
          <Modal
            color="warning"
            renderIcon={() => {}}
            isOpenExternal
            title="Ошибка сохранения данных!"
            message={customerEditError}
            buttonToolbar={(toggle) => (
              <button className="btn btn-secondary modal_cancel height-40" onClick={toggle}>
                Ок
              </button>
            )}
          />
        )}
      </div>

      <div className="mt-3 cart-wizard-footer">
        <button
          className="btn btn-primary form-control float-left height-40"
          onClick={previousStep}
        >
          Вернуться назад
        </button>
        <button
          className="btn btn-primary form-control float-right height-40"
          disabled={
            !deliveryPrice ||
            !deliveryDate ||
            !deliveryAddressId ||
            isDeliveryPriceLoading ||
            isDeliveryDateLoading ||
            isDateChange ||
            isCartUpdate ||
            (isSomeItemHasDelivery
              ? requestedDeliveryPrice === 0 || isDeliveryPriceChanging
              : false)
          }
          onClick={nextStep}
        >
          Ввести дополнительную информацию
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  customer: state.cart.customer.result,
  customerIsEditing: state.cart.customer.isEditing,
  customerEditError: state.cart.customer.editError,
  deliveryAddressId: state.cart.delivery_address,
  requestedDeliveryPrice: state.cart.requested_delivery_price,
  minRequestedDeliveryPrice: state.cart.min_requested_delivery_price,
  cartItems: state.cart.items,
  deliveryVariants: state.cart?.delivery_variants,
  requestedDeliveryDate: state.cart?.desired_date,
  isCartUpdate: state.cart.isLoading,
  promocode: state.cart.promocode,
})

const mapDispatchToProps = {
  addAddress: cart.addNewAddress,
  setDeliveryAddress: cart.setDeliveryAddress,
  postDeliveryPrice: cart.postDeliveryPrice,
  postDeliveryDate: cart.postDeliveryDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartRegistrationOfDelivery)

CartRegistrationOfDelivery.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  customer: PropTypes.shape(customerShape).isRequired,
  addAddress: PropTypes.func.isRequired,
  customerIsEditing: PropTypes.bool.isRequired,
  customerEditError: PropTypes.string.isRequired,
  setDeliveryAddress: PropTypes.func.isRequired,
  setDeliveryPrice: PropTypes.func.isRequired,
  cartItems: PropTypes.array.isRequired,
  deliveryAddressId: PropTypes.string,
  requestedDeliveryPrice: PropTypes.number,
  minRequestedDeliveryPrice: PropTypes.number,
  deliveryVariants: PropTypes.array,
  requestedDeliveryDate: PropTypes.string,
  isCartUpdate: PropTypes.bool.isRequired,
}

CartRegistrationOfDelivery.defaultProps = {
  deliveryAddressId: null,
}
