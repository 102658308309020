import React, { useState, useEffect, ReactNode, FC, ReactElement } from 'react'
import clsx from 'clsx'
import { Button, ButtonToolbar, Modal as ModalComponent } from 'reactstrap'

type ToggleFunction = (toggle: () => void) => ReactElement | null

interface Props {
  title?: string
  message?: ReactNode
  btnClasses?: string
  color?: 'primary' | 'success' | 'warning' | 'danger'
  btnText?: string
  onSubmit?: () => void
  renderIcon?: ToggleFunction
  needRenderIcon?: boolean
  buttonToolbar?: ToggleFunction
  isOpenExternal?: boolean
  onToggle?: () => void
  withIcon?: boolean
  size?: 'large' | 'full-screen'
  titleAlign?: 'left' | 'center' | 'right'
}

const Modal: FC<Props> = ({
  color = 'primary',
  btnText,
  title,
  message,
  btnClasses,
  onSubmit,
  buttonToolbar,
  renderIcon,
  needRenderIcon = true,
  isOpenExternal = false,
  onToggle,
  withIcon = true,
  size,
  titleAlign,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenExternal)

  const toggle = () => {
    setIsOpen(!isOpen)
    if (onToggle) {
      onToggle()
    }
  }

  useEffect(() => {
    setIsOpen(isOpenExternal)
  }, [isOpenExternal])

  const icons = {
    primary: 'pushpin',
    success: 'thumbs-up',
    warning: 'flag',
    danger: 'cross-circle',
  }
  const renderButtonToolbar = () => (
    <>
      <Button className="modal_cancel height-40" onClick={toggle}>
        Отмена
      </Button>
      <Button
        className="modal_ok height-40"
        color={color}
        onClick={() => {
          toggle()
          onSubmit && onSubmit()
        }}
      >
        Продолжить
      </Button>
    </>
  )
  let alignTitleClass = ''
  if (titleAlign === 'left') {
    alignTitleClass = 'text-left'
  }
  if (titleAlign === 'right') {
    alignTitleClass = 'text-right'
  }
  return (
    <div>
      {needRenderIcon &&
        (renderIcon ? (
          renderIcon(toggle)
        ) : btnText ? (
          <button className={btnClasses} onClick={toggle}>
            {btnText}
          </button>
        ) : null)}
      <ModalComponent
        isOpen={isOpen}
        toggle={toggle}
        className={clsx(`modal-dialog--${color}`, 'modal-dialog--top', {
          'modal-dialog--full-width': size === 'full-screen',
          'modal-dialog--large-size': size === 'large',
        })}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
          {withIcon && (
            <span className={`lnr lnr-${icons[color] || 'pushpin'} modal__title-icon`} />
          )}
          <h4 className={`text-modal  modal__title ${alignTitleClass}`}>{title}</h4>
        </div>
        <div className="modal__body">{message}</div>
        {buttonToolbar !== null && (
          <ButtonToolbar className="modal__footer">
            {buttonToolbar ? buttonToolbar(toggle) : renderButtonToolbar()}
          </ButtonToolbar>
        )}
      </ModalComponent>
    </div>
  )
}

export default Modal
