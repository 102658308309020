import { createSelector } from 'reselect'
import { getItemFromMap } from '../thunks/product'

const productState = (state) => state.product

const getRawProduct = (state) => state.product.result

export const getProduct = createSelector(getRawProduct, ({ simples = [], ...product }) => {
  return {
    ...product,
    simples,
  }
})

// TODO materials should be inside product attributes
export const getMaterialsMap = createSelector(getRawProduct, () => {})

export const productParametersDataSelector = createSelector(
  productState,
  ({ parametersData }) => parametersData,
)

export const productPriceCacheSelector = createSelector(
  productState,
  ({ priceCache }) => priceCache,
)

export const productWaitingItemSelector = createSelector(
  productState,
  ({ result, parametersData, attributesObject, priceCache }) => {
    const productPrice = getItemFromMap(attributesObject, priceCache)

    if (result && parametersData) {
      return {
        name: result.name,
        price: productPrice?.price ? productPrice.price : null,
        special_price: productPrice?.special_price ? productPrice.special_price : null,
        master_product_name: result.masterProductName,
        master_product_sku: result.masterProductSku,
        configurable_attributes: [...parametersData].map(([key, value]) => ({
          code: key.attribute_code,
          value: value.unit ? value.label : value.value,
          attribute_id: String(key.attribute_id),
          option_id: String(value.option_id),
        })),
      }
    }
  },
)

export const productPriceSelector = createSelector(
  productState,
  ({ attributesObject, priceCache }) => getItemFromMap(attributesObject, priceCache),
)

export const productStocksSelector = createSelector(
  productState,
  ({ attributesObject, stocksMap }) => getItemFromMap(attributesObject, stocksMap),
)

export const getMainOffer = createSelector(
  productState,
  ({ offers }) => offers?.sort((a, b) => a.priority - b.priority)[0],
)
