import { api } from './api'

export const fetchCategories = () =>
  api.get({
    path: `/catalog/constructor/tree`,
    requireBody: true,
  })

export const fetchParameters = (sku: string) =>
  api.get({
    path: `/catalog/constructor/product/${sku}`,
    requireBody: true,
  })
