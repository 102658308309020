import React, { FC } from 'react'
import { Col, Row } from 'reactstrap'
import { Button } from '../../../components/Layout/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  isNextStepButtonActiveSelector,
  isPreviousStepButtonActiveSelector,
} from '../../../redux/slices/constructor/selectors'
import { actions } from '../../../redux/redusers'

export const NavigationButtons: FC = () => {
  const dispatch = useDispatch()
  const isNextStepButtonActive = useSelector(isNextStepButtonActiveSelector)
  const isPreviousStepButtonActive = useSelector(isPreviousStepButtonActiveSelector)

  const handlePreviousStep = () => {
    dispatch(actions.constructor.previousStep())
  }

  const handleNextStep = () => {
    dispatch(actions.constructor.nextStep())
  }

  return (
    <Row className="justify-content-end">
      <Col xs="2" className="p-0 mr-auto">
        {isPreviousStepButtonActive && (
          <Button
            color="outline-primary-grey"
            className="height-40"
            onClick={handlePreviousStep}
            block
          >
            Назад
          </Button>
        )}
      </Col>
      <Col xs="2" className="p-0">
        <Button
          color="primary"
          disabled={!isNextStepButtonActive}
          className="height-40"
          onClick={handleNextStep}
          block
        >
          Дальше
        </Button>
      </Col>
    </Row>
  )
}
