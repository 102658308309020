import { DadataState } from './model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as thunks from './thunks'

const initialState: DadataState = {
  result: {},
  isLoading: false,
  error: false,
}

const dadataSlice = createSlice({
  name: 'dadata',
  initialState,
  reducers: {
    clearDadataSuggestions: (state, action: PayloadAction<string | undefined | null>) => {
      if (action.payload && state.result[action.payload]) {
        delete state.result[action.payload]
      } else {
        state.result = {}
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getDadataResult.pending, (state) => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(thunks.getDadataResult.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.error = false
      state.result = {
        ...state.result,
        [payload.result?.addressId || '']: {
          ...(state.result[payload.result?.addressId || ''] || {}),
          [payload.result?.type || '']: payload.result?.suggestions || {},
        },
      }
    })
    builder.addCase(thunks.getDadataResult.rejected, (state) => {
      state.error = true
      state.isLoading = false
    })
  },
})

export const dadata = {
  ...dadataSlice,
  thunks,
}
