import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Layout/Modal'
import style from './Product.module.scss'
import clsx from 'clsx'
import { Button } from '../Layout/Button/Button'

export function ChooseMaterialPopup({
  isOpen = false,
  toggle,
  materials,
  activeMaterial: defaultActiveMaterial,
  onChangeMaterial,
  title,
}) {
  const [activeMaterial, setActiveMaterial] = useState(defaultActiveMaterial)

  function selectMaterial() {
    onChangeMaterial(activeMaterial)
    toggle()
  }

  return (
    <Modal
      renderIcon={() => {}}
      size="full-screen"
      withIcon={false}
      isOpenExternal={isOpen}
      onToggle={toggle}
      title={title}
      buttonToolbar={() => {}}
      message={
        <div>
          <div className="d-flex flex-wrap">
            {materials.map(({ name, link, shortname }) => {
              const isActive = activeMaterial === shortname
              return (
                <div
                  onDoubleClick={selectMaterial}
                  key={name}
                  className={clsx('mr-4 object-fit-cover cursor-pointer', style.materialItem, {
                    'font-weight-bold': isActive,
                  })}
                  onClick={() => setActiveMaterial(shortname)}
                >
                  <div
                    className={clsx('p-1', style.materialImgWrapper, {
                      [style.materialActive]: isActive,
                    })}
                  >
                    <img src={link} alt={name} className="w-100" />
                  </div>
                  <div>{name}</div>
                </div>
              )
            })}
          </div>
          <div className="d-flex">
            <Button
              disabled={!activeMaterial}
              size="lg"
              type="submit"
              color="primary"
              className="ml-auto height-40"
              onClick={selectMaterial}
            >
              Сохранить
            </Button>
          </div>
        </div>
      }
    />
  )
}

ChooseMaterialPopup.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  activeMaterial: PropTypes.string,
  title: PropTypes.string,
  onChangeMaterial: PropTypes.func,
  materials: PropTypes.arrayOf(PropTypes.any),
}
