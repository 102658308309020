import React from 'react'
import PropTypes from 'prop-types'

import { Carousel } from '../index'

import 'react-image-lightbox/style.css'

function ProductImageGallery({ images, onClickItem }) {
  if (!images.length) {
    return null
  }

  return (
    <div>
      <Carousel
        mini
        onClickItem={(index) => {
          onClickItem({ isOpen: true, imageIndex: index })
        }}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img className="cursor-pointer" src={image} alt="" />
          </div>
        ))}
      </Carousel>
    </div>
  )
}
export default ProductImageGallery

ProductImageGallery.propTypes = {
  images: PropTypes.array.isRequired,
  onClickItem: PropTypes.func.isRequired,
}
