import React from 'react'
import { useSwitcher } from '../../hooks/useSwitcher'

interface Props {
  additionalPanelTitle: string
  isCloseOnStart?: boolean
  blockClassName?: string
  titleClassName?: string
  innerClassName?: string
  disabled?: boolean
  children: React.ReactNode
}

export function AdditionalPanelSwitcher({
  additionalPanelTitle,
  isCloseOnStart,
  blockClassName,
  titleClassName,
  innerClassName,
  disabled,
  children,
}: Props) {
  const additionalPanelSwitcher = useSwitcher(!isCloseOnStart)

  const handleClick = () => {
    if (!disabled) {
      additionalPanelSwitcher.toggle()
    }
  }

  return (
    <div className={blockClassName}>
      <span className={titleClassName} onClick={handleClick}>
        {!disabled && <span className="mr-2">{additionalPanelSwitcher.isOn ? '-' : '+'}</span>}
        <span>{additionalPanelTitle}</span>
      </span>
      <div className={innerClassName}>{additionalPanelSwitcher.isOn && children}</div>
    </div>
  )
}
