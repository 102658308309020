import { useContext } from 'react'
import WidgetContext from '../Context'

export const useReceiver = (callback) => {
  const { selfHosted, widgetId } = useContext(WidgetContext)
  if (selfHosted) {
    window.asconaWidgets = window.asconaWidgets || {}
    window.asconaWidgets[widgetId] = window.asconaWidgets[widgetId] || {}
    window.asconaWidgets[widgetId].notify = (options) => {
      if (callback) {
        callback(options)
      }
    }
  }
}
