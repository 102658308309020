import React, { ChangeEventHandler, FC, LegacyRef, ReactText } from 'react'

export interface Props {
  checked?: boolean
  disabled?: boolean
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  inputClassName?: string
  wrapperClassName?: string
  labelClassName?: string
  labelName?: string
  innerRef?: LegacyRef<HTMLInputElement>
  value?: ReactText
}

const RadioButton: FC<Props> = ({
  checked,
  name,
  onChange,
  inputClassName = 'bg-primary',
  wrapperClassName = '',
  labelClassName = '',
  labelName = '',
  disabled = false,
  innerRef,
  value,
}) => {
  return (
    <label className={`input-radio-container ${wrapperClassName}`}>
      <span className={labelClassName}>{labelName}</span>
      <input
        value={value}
        ref={innerRef}
        type="radio"
        checked={checked}
        name={name}
        onChange={onChange}
        className={inputClassName}
        disabled={disabled}
      />
      <span className="checkmark-radio" />
    </label>
  )
}

export default RadioButton
