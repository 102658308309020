import React, { FC, ReactNode, ReactText } from 'react'
import clsx from 'clsx'

interface Props {
  title?: ReactNode
  className?: string
  mb?: ReactText
  pt?: ReactText
}

export const Block: FC<Props> = ({ children, title, className, mb = 4, pt = 2 }) => {
  return (
    <div className={clsx(`bg-secondary pl-4 pr-4 pt-${pt} pb-2 br-5 mb-${mb}`, className)}>
      {title && <div className="mb-2">{title}</div>}
      {children}
    </div>
  )
}
