export const STATUS_SUCCESS = 'success'
export const STATUS_ERROR = 'error'
export const STATUS_LOADING = 'loading'

export const PAYMENT_TYPE_CASH = 0
export const PAYMENT_TYPE_CARD = 3

export const CASH_DESK = String(PAYMENT_TYPE_CASH)
export const TERMINAL = '1'
export const CARD = String(PAYMENT_TYPE_CARD)
