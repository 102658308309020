import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { actions } from '../../../redusers'
import { Category } from '../model/dto'
import * as api from '../../../../api/constructor'

export const fetchCategories = createAsyncThunk<void, void, AppThunkApiConfig>(
  'constructor/getCategories',
  async (_, { dispatch }) => {
    const response = await api.fetchCategories() as Category
    dispatch(actions.constructor.setCategoryTree(response))
  },
)
