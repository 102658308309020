import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../model'
import { thunks, actions } from '../../redusers'
import { AttributesObject } from '../../slices/product/model'
import { Attribute, AttributeOption } from '../../slices/constructor/model/dto'
import { HandleParametersDataPayload } from '../../slices/constructor/model'

const getAttributesObjectFromParametersData = (
  parametersData: Map<Attribute, AttributeOption>,
): AttributesObject => ({
  attributes: [...parametersData]
    .map(([key, value]) => ({
      code: key.attribute_code,
      value: value.unit ? (value.label as string) : value.value,
      attribute_id: String(key.attribute_id),
      option_id: String(value.option_id),
    }))
    .sort((a, b) => Number(a.attribute_id) - Number(b.attribute_id)),
})

export const handleParametersData = createAsyncThunk<
  void,
  HandleParametersDataPayload,
  AppThunkApiConfig
>('product/handleAttribute', async (payload, { dispatch, getState }) => {
  const state = getState().product
  const { attribute, attributeOption } = payload

  const parametersData = new Map(state.parametersData) as Map<Attribute, AttributeOption>
  parametersData.set(attribute, attributeOption)
  const attributesObject = getAttributesObjectFromParametersData(parametersData)

  await dispatch(actions.product.setParametersData(parametersData))
  await dispatch(actions.product.setAttributesObject(attributesObject))
  dispatch(thunks.product.refreshPrice())
})
