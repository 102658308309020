import { submitForm } from '../helpers'
import { useRuntimeConfig as getRuntimeConfig } from '../hooks/useRuntimeConfig'
import { find, remove, update } from './storage'
import { api } from './api'
import { cartId } from '../constants/localStorageItems'
import { isLocalhost } from '../helpers/isLocalhost'

const username = 'jenny'
const password = 's123456'
const userId = 4

const tokenKey = 'token'
const usernameKey = 'username'
const useridKey = 'userid'
const subscribers = []
const notify = () => {
  subscribers.forEach((callback) => {
    callback()
  })
}

const getAuth = () => {
  const token = find(tokenKey)
  const username = find(usernameKey)
  const userId = find(useridKey)
  return {
    isUserLogged: Boolean(token),
    loginError: false,
    token,
    username,
    userId,
  }
}

export const useAuth = () => getAuth()

export const updateAuth = ({ token, username, user_id }) => {
  update(tokenKey, token)
  update(usernameKey, username)
  update(useridKey, user_id)
  notify()
}

const clearAuth = () => {
  remove(tokenKey)
  remove(usernameKey)
  remove(cartId)
  notify()
}

export const subscribe = (callback) => {
  subscribers.push(callback)
}

function magentoAuth(callback) {
  return api
    .post({
      path: '/integration/admin/token',
      data: {
        username,
        password,
      },
    })
    .then((token) => {
      updateAuth({
        token,
        username,
        user_id: userId,
      })
      if (callback) {
        callback(token)
      }
    })
}

function adfsAuth() {
  const { relyingPartyId, authUrl } = getRuntimeConfig()
  if (!window.location.pathname.startsWith(authUrl)) {
    submitForm('https://fs.askona.ru/adfs/ls/idpinitiatedsignon.htm', {
      SignInOtherSite: 'SignInOtherSite',
      RelyingParty: relyingPartyId,
      SignInSubmit: 'Sign in',
      SingleSignOut: 'SingleSignOut',
    })
  }
}

function adfsSignOut() {
  const { relyingPartyId } = getRuntimeConfig()
  submitForm('https://fs.askona.ru/adfs/ls/idpinitiatedsignon.htm', {
    SignInOtherSite: 'SignInOtherSite',
    RelyingParty: relyingPartyId,
    SignOut: 'Sign Out',
    SingleSignOut: 'SingleSignOut',
  })
}

export const ensureAuth = (callback) => {
  clearAuth()
  if (isLocalhost()) {
    return magentoAuth(callback)
  } else {
    adfsAuth()
  }
}

export const signOut = () => {
  clearAuth()
  if (!isLocalhost()) {
    adfsSignOut()
  } else {
    window.location.reload()
  }
}

export function loyaltyProgramAuth() {
  return api.get({ path: '/pos/lp/auth' })
}

export const getAppParams = () =>
  api.get({
    path: '/auth/additional/info',
  })
