import { useRef, useCallback, KeyboardEvent } from 'react'

export const useFocusNext = () => {
  const controls = useRef<HTMLInputElement[]>([])

  const handler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      controls.current = controls.current
        .filter((control) => document.body.contains(control))
        .sort((a, b) => (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1))

      const index = controls.current.indexOf(event.currentTarget)
      const next = controls.current[index + 1]
      next && next.focus()
    }
  }

  return useCallback((element: HTMLInputElement) => {
    if (element && !controls.current.includes(element)) {
      controls.current.push(element)
      element.addEventListener('keydown', (e) =>
        handler(<KeyboardEvent<HTMLInputElement>>(<unknown>e)),
      )
    }
  }, [])
}
