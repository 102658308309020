import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { CartProductLine } from '../index'
import { cart } from '../../redux/actions'
import { getDeliveryTypes } from '../../redux/selectors/cart'
import { AppState } from '../../redux/model'
import { CartWaitingProductLine } from './CartWaitingProductLine'
import { DELIVERY_OPTION_DELIVERY } from '../../constants'

type Props = ConnectedProps<typeof connector> & {
  resetSteps: () => number
}

function CartProductList({
  count,
  items,
  isLoading,
  isUpdateRequestInProgress,
  isFetchCustomerInProgress,
  removeItemFromCart,
  removeWaitingItemFromCart,
  onChangeCount,
  onChangeServices,
  onChangeDeliveryType,
  deliveryTypes,
  waiting_items = [],
  resetSteps,
}: Props) {
  useEffect(() => {
    if (items.every((item) => item.delivery_type !== DELIVERY_OPTION_DELIVERY)) resetSteps()
  }, [items])

  return (
    <>
      <div className="cart-product-list">
        <h2 className="text-gray d-flex align-items-center m-0">Состав корзины ({count})</h2>
        {items?.map((item) => (
          <CartProductLine
            key={item.id}
            item={item}
            isLoading={isLoading}
            deliveryType={deliveryTypes[item.id]}
            onDelete={removeItemFromCart}
            onChangeCount={onChangeCount}
            onChangeDeliveryType={onChangeDeliveryType}
            isUpdateRequestInProgress={
              isUpdateRequestInProgress || isFetchCustomerInProgress || isLoading
            }
            onChangeServices={onChangeServices}
          />
        ))}
      </div>
      <div className="cart-waiting-list">
        {waiting_items?.map((item) => (
          <CartWaitingProductLine key={item.id} item={item} onDelete={removeWaitingItemFromCart} />
        ))}
      </div>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  count: state.cart.count,
  items: state.cart.items,
  isLoading: state.cart.isLoading,
  isUpdateRequestInProgress: state.cart.isUpdateRequestInProgress,
  isFetchCustomerInProgress: state.search.isLoading,
  waiting_items: state.cart.waiting_items,
  deliveryTypes: getDeliveryTypes(state),
})

const mapDispatchToProps = {
  removeItemFromCart: cart.removeItem,
  onChangeCount: cart.setItemAttr,
  onChangeDeliveryType: cart.setItemAttr,
  onChangeServices: cart.setItemAttr,
  removeWaitingItemFromCart: cart.removeWaitingItem,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CartProductList)
