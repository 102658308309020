import { StartPayRequest } from './dto/StartPayRequest'
import { BASE_PAYMENT_API_URL } from './paymentAxiosInit'
import { SentryMessageTags } from './sentryCaptures'
import { startPay } from './startPay'
import { getPayStatus } from './getPayStatus'
import { confirmPayStatus } from './confirmPayStatus'
import { getPayInfo } from './getPayInfo'
import { healthCheck } from './healthCheck'

export function getPaymentApi(host: string, tags: SentryMessageTags) {
  const apiUrl = `${host}${BASE_PAYMENT_API_URL}`

  const startPayRequest = async (data: StartPayRequest) => {
    return await startPay(apiUrl, data, tags)
  }

  const payStatusRequest = async (requestId: string) => {
    return await getPayStatus(apiUrl, requestId, tags)
  }

  const payInfoRequest = async (requestId: string) => {
    return await getPayInfo(apiUrl, requestId, tags)
  }

  const confirmPayStatusRequest = async (requestId: string) => {
    return await confirmPayStatus(apiUrl, requestId, tags)
  }

  const healthCheckRequest = async () => {
    const { protocol, hostname } = window.location
    return await healthCheck(`${protocol}//${hostname}/`, tags)
  }

  return {
    startPay: startPayRequest,
    getPayStatus: payStatusRequest,
    getPayInfo: payInfoRequest,
    confirmPayStatus: confirmPayStatusRequest,
    healthCheck: healthCheckRequest,
  }
}
