import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

import { customerHelper } from '../../../helpers'
import { CustomerSuggestions, Modal } from '../../index'
import { cartMode, customerSchema } from '../../../constants'
import { Button } from '../../Layout/Button/Button'
import { useBlockRoute } from '../../../hooks/useBlockRoute'
import { useSwitcher } from '../../../hooks/useSwitcher'
import { Col, Row } from 'reactstrap'
import {
  getCartCustomer,
  selectCartCustomerCreateError,
  selectCartCustomerIsCreated,
  selectCartCustomerIsCreating,
} from '../../../redux/selectors/cart'
import { resetCreateError } from '../../../redux/actions/cart'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditCartField } from './EditCartField'
import { InputField } from '../../Layout/form/InputField'
import { CheckboxField } from '../../Layout/form/CheckboxField'
import { DatePickerField } from '../../Layout/form/DatePickerField'
import { Form } from '../../Layout/form/Form'
import { PhoneInputField } from '../../Layout/form/PhoneInputField'
import { api } from '../../../api/api'
import { CustomerType } from '../../Search/SearchSuggestions/CustomerSuggestions'

type Props = {
  createCustomer: any
  saveButtonStyle?: string
  mode: any
  hasRelationTypeId?: boolean
  cancel?: () => void
  showBorder?: boolean
}

export const CreateNewCustomer: FC<Props> = ({
  createCustomer,
  saveButtonStyle = '',
  mode,
  hasRelationTypeId = true,
  cancel,
  showBorder = true,
}) => {
  const dispatch = useDispatch()

  const { customer } = useSelector(getCartCustomer)
  const customerIsCreated = useSelector(selectCartCustomerIsCreated)
  const customerIsCreating = useSelector(selectCartCustomerIsCreating)
  const customerCreateError = useSelector(selectCartCustomerCreateError)

  const [contactMatchesPhone, setContactMatchesPhone] = useState<CustomerType | null>(null)
  const [contactMatchesEmail, setContactMatchesEmail] = useState<CustomerType | null>(null)
  const [contactLoading, setContactLoading] = useState<Boolean>(false)

  const isModalOpen = useSwitcher()
  const { blockRoute, unblockRoute, leavePage } = useBlockRoute(isModalOpen.on, false)

  const defaultValues = {
    // extra_phone: '',
    birth_date: '',
    email: '',
    is_fake_email: false,
    phone: '',
    middle_name: '',
    given_name: '',
    surname: '',
  }

  const { control, clearErrors, ...form } = useForm({
    resolver: yupResolver(customerSchema),
    defaultValues,
    mode: 'all',
  })

  const { isDirty, isValid } = form.formState

  const { is_fake_email } = useWatch<typeof defaultValues>({
    control,
  })

  const onSubmit = (values: typeof defaultValues) => {
    const data = {
      ...customerHelper.customerMapping({ ...customer, ...values }),
      birth_date: values.birth_date ? format(new Date(values.birth_date), 'yyyy-MM-dd') : '',
    }
    createCustomer(data)
  }

  const handleToggleModal = () => {
    isModalOpen.toggle()
  }

  useEffect(() => {
    if (is_fake_email) {
      clearErrors('email')
      setContactMatchesEmail(null)
      form.setValue('email', '')
    }
  }, [clearErrors, is_fake_email, form.setValue])

  useEffect(() => {
    if (!customerIsCreated && isDirty) {
      blockRoute()
    } else {
      unblockRoute()
    }
  }, [blockRoute, customerIsCreated, isDirty, unblockRoute])

  const handlePhone = async (event: ChangeEvent<HTMLInputElement>) => {
    const currentNumber = event.target.value.replace(/[^\d;]/g, '')
    if (currentNumber.length === 11) {
      setContactLoading(true)
      let res = await api.get({
        path: `/customers/search?searchCriteria[filterGroups][0][filters][0][field]=phone&searchCriteria[filterGroups][0][filters][0][value]=${currentNumber}`,
      })

      if (res?.items?.length) {
        setContactMatchesPhone(res.items[0])
      } else {
        setContactMatchesPhone(null)
      }
      setContactLoading(false)
    } else {
      setContactMatchesPhone(null)
    }
  }
  const handleEmail = async (event: ChangeEvent<HTMLInputElement>) => {
    if (/\w[\w\d_.-]+@[\w\d_.-]+\.[\w\d_.-]/gi.test(event.target.value)) {
      setContactLoading(true)
      const res = await api.get({
        path: `/customers/search?searchCriteria[filterGroups][0][filters][0][field]=email&searchCriteria[filterGroups][0][filters][0][value]=${event.target.value}`,
      })

      if (res?.items?.length) {
        setContactMatchesEmail(res.items[0])
      } else {
        setContactMatchesEmail(null)
      }
      setContactLoading(false)
    } else {
      setContactMatchesEmail(null)
    }
  }

  return (
    <>
      <Form form={{ control, clearErrors, ...form }} onSubmit={onSubmit}>
        <Row className="pt-3 customer-container">
          <Col>
            <div className={showBorder ? 'col br-5 p-3 pt-4 pb-4 customer-info' : ''}>
              <EditCartField name="Фамилия">
                <InputField
                  name="surname"
                  className="border-dark square"
                  placeholder="Введите фамилию"
                />
              </EditCartField>

              <EditCartField name="Имя*">
                <InputField
                  name="given_name"
                  className="form-control border-dark square"
                  placeholder="Введите имя"
                />
              </EditCartField>

              <EditCartField name="Отчество">
                <InputField
                  name="middle_name"
                  className="form-control border-dark square"
                  placeholder="Введите отчество"
                />
              </EditCartField>

              <EditCartField name="Телефон*">
                <PhoneInputField
                  type="tel"
                  name="phone"
                  className="form-control border-dark square"
                  placeholder="+7(___)___-__-__"
                  handleBlur={handlePhone}
                />
              </EditCartField>
              {contactMatchesPhone && (
                <div className="ml-5 mt-2 mb-2">
                  <p>
                    Внимание! Пользователь с указанным номером телефона уже зарегистрирован. Вы
                    можете перейти на его страницу.
                  </p>
                  <CustomerSuggestions items={[{ ...contactMatchesPhone }]} />
                </div>
              )}

              <EditCartField name="E-mail*">
                <InputField
                  name="email"
                  className="form-control border-dark square"
                  placeholder="example@gmail.com"
                  disabled={is_fake_email}
                  handleBlur={handleEmail}
                />
              </EditCartField>
              {contactMatchesEmail && (
                <div className="ml-5 mt-2 mb-2">
                  <p>
                    Внимание! Пользователь с указанным e-mail уже зарегистрирован. Вы можете перейти
                    на его страницу.
                  </p>
                  <CustomerSuggestions items={[{ ...contactMatchesEmail }]} />
                </div>
              )}
              <EditCartField name="">
                <CheckboxField
                  wrapperClassName="mr-5 mb-3"
                  name="is_fake_email"
                  labelName="Не указывать e-mail"
                />
              </EditCartField>

              <EditCartField name="Дата рождения">
                <DatePickerField
                  name="birth_date"
                  className="form-control border-dark square col title"
                  placeholderText="__-__-___"
                  showMonthDropdown
                  showYearDropdown
                />
              </EditCartField>

              {/*<EditCartField name="Доп. телефон">*/}
              {/*  <PhoneInput*/}
              {/*    isErrorMessageHide*/}
              {/*    type="tel"*/}
              {/*    name="extra_phone"*/}
              {/*    className="form-control border-dark square"*/}
              {/*    placeholder="Доп. телефон"*/}
              {/*    useForm*/}
              {/*  />*/}
              {/*</EditCartField>*/}
            </div>
          </Col>
        </Row>

        <Row className="pt-3 justify-content-end">
          <Col xs="2">
            <Button
              className={`form-control ${saveButtonStyle} height-40`}
              color="primary"
              type="submit"
              disabled={
                !hasRelationTypeId ||
                !isDirty ||
                !isValid ||
                (mode === cartMode &&
                  !!(customerIsCreating || (customerIsCreated && customer?.customer_id))) ||
                Boolean(contactMatchesEmail) ||
                Boolean(contactMatchesPhone) ||
                Boolean(contactLoading)
              }
            >
              Сохранить
            </Button>
          </Col>
          <Col xs="2">
            <Button
              type="button"
              className="form-control height-40"
              color="outline-primary"
              onClick={cancel}
            >
              Отменить
            </Button>
          </Col>
        </Row>
      </Form>

      {customerCreateError && (
        <Modal
          color="warning"
          renderIcon={() => <></>}
          isOpenExternal
          title="Ошибка сохранения данных!"
          message={customerCreateError}
          onToggle={() => {
            dispatch(resetCreateError())
          }}
          buttonToolbar={(toggle) => (
            <button className="btn btn-secondary modal_cancel" onClick={toggle}>
              Ок
            </button>
          )}
        />
      )}
      {isModalOpen.isOn && (
        <Modal
          color="warning"
          onToggle={handleToggleModal}
          isOpenExternal
          title="Внимание"
          needRenderIcon={false}
          buttonToolbar={(toggle) => {
            return (
              <>
                <Button
                  className="modal_cancel height-40"
                  color="outline-primary"
                  onClick={() => {
                    leavePage()
                  }}
                >
                  Покинуть страницу
                </Button>
                <Button
                  className="modal_ok height-40"
                  color="primary"
                  onClick={() => {
                    toggle()
                  }}
                >
                  Остаться на странице
                </Button>
              </>
            )
          }}
          message={
            <>
              <span className="m-0">На странице есть несохранённые изменения.</span>
              <br />
              <span className="m-0">Вы уверены, что хотите покинуть страницу?</span>
            </>
          }
          size="large"
        />
      )}
    </>
  )
}
