import { apiPrefix, apiVersion } from '../constants'
import { isLocalhost } from '../helpers/isLocalhost'

/**
 *
 * @returns {{apiHost: string, apiUrl: string, wsHost: string, adfs: string, authUrl: string, priceServiceUrl: string, cleanApiUrl: string, loggerUrl: string}}
 */
export const useRuntimeConfig = () => {
  const config = window.asconaRuntimeConfig ?? {}
  const apiUrl = (isLocalhost() ? '' : config.apiHost) + apiPrefix + apiVersion
  const cleanApiUrl = isLocalhost() ? '' : config.apiHost
  return { ...config, apiUrl, cleanApiUrl }
}
