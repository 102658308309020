import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { format, isValid } from 'date-fns'
import { customerHelper, formatPhoneNumber } from '../../../helpers'
import { customer } from '../../../redux/actions'
import { Button } from '../../Layout/Button/Button'
import { AppState } from '../../../redux/model'

type Props = ConnectedProps<typeof connector> & {
  showEditCartCustomer: () => void
}

function CartCustomer({ customer, registrationInLoyaltyProgram, showEditCartCustomer }: Props) {
  const handleRegistrationInLoyaltyProgram = () => {
    const customerData = customerHelper.customerMapping(customer)
    const data = {
      ...customerData,
      ...customerHelper.registrationInLoyaltyProgramMapping,
    }
    registrationInLoyaltyProgram(data)
  }
  const renderModalButtonRegister = () => (
    <Button
      className="btn btn-outline-primary form-control registration-btn height-40"
      onClick={handleRegistrationInLoyaltyProgram}
    >
      Регистрация в ПЛ
    </Button>
  )

  const birthDate = new Date(customer.birth_date)
  const birthDateFormatted =
    customer.birth_date && isValid(birthDate) ? format(birthDate, 'dd.MM.yyyy') : 'Не указана'
  return (
    <div className="row pt-3">
      <div className="col">
        <div className="br-5 customer-info">
          <div className="d-flex align-items-center">
            <div className="col-2">Фамилия</div>
            <div className="col-8">{customer.surname}</div>
            <div className="col-2 btn-edit-client">
              <button
                className="btn form-control btn-outline-primary height-40"
                onClick={showEditCartCustomer}
              >
                Редактировать
              </button>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="col-2">Имя*</div>
            <div className="col-10">{customer.given_name}</div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="col-2">Отчество</div>
            <div className="col-10">{customer.middle_name}</div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="col-2">Телефон*</div>
            <div className="col-10">{formatPhoneNumber(customer.phone)}</div>
          </div>

          {!customer.is_fake_email && (
            <div className="d-flex align-items-center mt-2">
              <div className="col-2">E-mail*</div>
              <div className="col-4">{customer.email}</div>
            </div>
          )}

          <div className="d-flex align-items-center mt-2">
            <div className="col-2">Дата рождения</div>
            <div className="col-10">{birthDateFormatted}</div>
          </div>

          {/*<div className="d-flex align-items-center mt-2">*/}
          {/*  <div className="col-2">Доп. телефон</div>*/}
          {/*  <div className="col-10">{formatPhoneNumber(customer.extra_phone)}</div>*/}
          {/*</div>*/}

          <div className="d-flex align-items-center mt-2">
            <div className="col-2">Активный баланс</div>
            <div className="col-2">
              {customer?.is_status_pl
                ? Number(customer?.bonus_balance?.current_amount ?? 0).toFixed(0) || 0
                : renderModalButtonRegister()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  customer: state.cart.customer.result,
})

const mapDispatchToProps = {
  registrationInLoyaltyProgram: customer.registrationInLoyaltyProgram,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CartCustomer)
