import React, { useEffect, useMemo, useRef } from 'react'
import classnames from './NotificationMessage.module.scss'
import countClassName from '../../styles/components/countElement.module.scss'
import { NotificationMessage } from './NotificationMessage'
import useOutsideClick from '../../hooks/useOutsideClick'
import { useNotification } from './NotificationProvider'
import { debounce } from 'lodash-es'
import clsx from 'clsx'
import { useSwitcher } from '../../hooks/useSwitcher'

const DEFAULT_NUMBER_NOTIFICATION_TO_SHOW = 3

export const NotificationHistory = () => {
  const {
    isOn: isNotificationsHistoryOpen,
    toggle: toggleNotificationHistory,
    off: closeNotificationHistory,
  } = useSwitcher()
  const { isOn: isShowALl, on: openAllNotifications, off: offShowAll } = useSwitcher()
  const {
    notificationsHistory,
    removeNotificationFromHistory,
    markNotificationAsRead,
  } = useNotification()
  const count = useMemo(
    function getNewNotificationNumber() {
      return notificationsHistory.filter((item) => item.isNew).length
    },
    [notificationsHistory],
  )

  useEffect(
    function hiddenScroll() {
      if (
        notificationsHistory.length <= DEFAULT_NUMBER_NOTIFICATION_TO_SHOW ||
        !isNotificationsHistoryOpen
      ) {
        offShowAll()
      }
    },
    [isNotificationsHistoryOpen, notificationsHistory, offShowAll],
  )

  useEffect(
    function markNotificationAsReadAfterOpen() {
      const shownNotifications = isShowALl
        ? notificationsHistory
        : notificationsHistory.slice(0, DEFAULT_NUMBER_NOTIFICATION_TO_SHOW)
      const notificationsToRead = shownNotifications.filter((item) => item.isNew)
      if (isNotificationsHistoryOpen && notificationsToRead.length) {
        markNotificationAsRead(notificationsToRead.map((item) => item.id))
      }
    },
    [isNotificationsHistoryOpen, isShowALl, markNotificationAsRead, notificationsHistory],
  )

  const historyBlockRef = useRef<HTMLDivElement>(null)
  useOutsideClick(historyBlockRef, () => {
    if (isNotificationsHistoryOpen) {
      closeNotificationHistory()
    }
  })

  return (
    <>
      <button
        className={clsx('header-button d-flex justify-content-center align-items-center p-0 pt-2', {
          'bg-secondary': isNotificationsHistoryOpen,
        })}
        type="button"
        onClick={toggleNotificationHistory}
      >
        <div className="position-relative">
          <span className="lnr lnr-fs-24 lnr-alarm header-color-gray" />
          {count > 0 && (
            <span
              className={clsx(
                'bg-primary d-flex align-items-center justify-content-center',
                countClassName.countElement,
                classnames.countPosition,
              )}
            >
              {count}
            </span>
          )}
        </div>
      </button>
      <div
        className={clsx('d-flex flex-column', classnames.notificationHistory, {
          ['border border-dark']: isNotificationsHistoryOpen && notificationsHistory.length > 0,
          [classnames.scrolled]: isShowALl,
          [classnames.scrollBar]: isShowALl,
        })}
        ref={historyBlockRef}
      >
        {isNotificationsHistoryOpen && (
          <div>
            {notificationsHistory
              .filter((item, index) => index < DEFAULT_NUMBER_NOTIFICATION_TO_SHOW || isShowALl)
              .map((item) => {
                return (
                  <NotificationMessage
                    key={item.id}
                    className={clsx('border-bottom border-dark')}
                    defaultStyle={item.isNew ? 'isNew' : 'light'}
                    isOpen
                    title={item.message.title}
                    message={item.message.body}
                    severity={item.severity}
                    //debounce нужен, чтобы не успел стригериться useOutsideClick
                    onClose={debounce(() => removeNotificationFromHistory(item.id), 100)}
                  />
                )
              })}
            {notificationsHistory.length > 3 && !isShowALl && (
              <div
                className="text-primary d-flex justify-content-center align-items-center bg-white py-4"
                //debounce нужен, чтобы не успел стригериться useOutsideClick
                onClick={debounce(openAllNotifications, 100)}
                role="button"
              >
                Показать все
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
