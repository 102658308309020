import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { productHelper } from '../../helpers'
import { formatPriceRu } from '../../helpers/currency'
import { productShape } from '../../constants'

import { productPriceSelector } from '../../redux/selectors/product'

function ProductPrice({ item, isConstructorAllowed }) {
  const price = productHelper.getPrice(item)
  const productPrice = useSelector(productPriceSelector)

  if (isConstructorAllowed) {
    return (
      <div className="d-flex justify-content-between">
        <div className="name">Цена:</div>
        <div className="value d-flex">
          <div className="d-flex flex-column">
            <span className="price text-danger d-flex align-items-center">
              {!productPrice && (
                <>
                  {!item.price && 'от '}
                  {formatPriceRu(price)}
                </>
              )}
              {productPrice && !productPrice.errorMessage && (
                <>
                  {formatPriceRu(
                    productPrice.special_price ? productPrice.special_price : productPrice.price,
                  )}
                </>
              )}
              {productPrice?.errorMessage && <>{productPrice.errorMessage}</>}
            </span>
            {productPrice?.special_price > 0 &&
              productPrice?.special_price !== productPrice?.price && (
                <span className="old-price">{formatPriceRu(productPrice.price)}</span>
              )}
          </div>
          <div className="price-info">
            {productPrice?.profit > 0 && <div>Выгода: {formatPriceRu(productPrice.profit)}</div>}
            {/* {
              item.instalment_payment?.available &&
                <div>Рассрочка от {productHelper.formatPrice(item.instalment_payment.instalment)} р/мес <br /> на {item.instalment_payment.term} мес</div>
            }
            {item.special_offer && <div className='text-danger'>{item.special_offer}</div>} */}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="d-flex justify-content-between">
      <div className="name">Цена:</div>
      <div className="value d-flex">
        <div className="d-flex flex-column">
          <span className="price text-danger d-flex align-items-center">
            {!item.price && 'от '}
            {productHelper.formatPrice(item.special_price ? item.special_price : price)}{' '}
            <span className="currency" />
          </span>
          {item.special_price > 0 && item.special_price !== item.price && (
            <span className="old-price">{productHelper.formatPrice(price)}</span>
          )}
        </div>
        <div className="price-info">
          {item.profit > 0 && <div>Выгода: {productHelper.formatPrice(item.profit)}</div>}
        </div>
      </div>
    </div>
  )
}

export default ProductPrice

ProductPrice.propTypes = {
  item: PropTypes.shape(productShape),
}
