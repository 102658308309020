import { CustomerSuggestions, Loader } from '../../index'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { customer } from '../../../redux/actions'
import { connect, ConnectedProps } from 'react-redux'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { debounce, searchHelper } from '../../../helpers'
import { useSwitcher } from '../../../hooks/useSwitcher'
import { actions, thunks } from '../../../redux/redusers'
import { AppState } from '../../../redux/model'

type Props = ConnectedProps<typeof connector> & {
  onSetCustomer?: (item: any) => void
  onSetNewCustomer?: () => void
  needButton: boolean
}

function CustomerSearch({
  query,
  setSearchQuery,
  customerId,
  clearSearch,
  searchResult,
  getSearchResult,
  searchIsLoading,
  onSetCustomer,
  onSetNewCustomer,
  needButton,
}: Props) {
  const suggestionsOpenSwitcher = useSwitcher()
  const [isFieldFocused, setIsFieldFocused] = useState(false)
  const suggestionsRef = useRef(null)

  useEffect(() => {
    clearSearch()
  }, [clearSearch])

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const query = target.value
    setSearchQuery(query)
    if (query.length > 0) {
      search(query)
    }
  }

  const clear = () => {
    if (query.length > 0) {
      clearSearch()
    }
  }

  useOutsideClick(suggestionsRef, () => {
    if (!isFieldFocused) {
      suggestionsOpenSwitcher.off()
    }
  })

  const handleSetCartCustomer = (item: any) => {
    suggestionsOpenSwitcher.off()
    if (onSetCustomer) {
      onSetCustomer(item)
    }
  }

  const handleSetNewCustomer = () => {
    clear()
    if (onSetNewCustomer) {
      onSetNewCustomer()
    }
  }

  const search = useCallback<(query: string) => void>(
    // @ts-ignore
    debounce((query: string) => getSearchResult({ query, group: 'customers' }), 500),
    [getSearchResult],
  )

  const suggestions: { customers?: any[]; isNotUndefined: boolean } = {
    ...searchHelper.getSuggestions(searchResult),
    isNotUndefined: true,
  }

  return (
    <div className="search">
      <div className="search-form form-row mt-4">
        <div className="col">
          <input
            type="text"
            value={query}
            onChange={handleChange}
            onFocus={() => {
              setIsFieldFocused(true)
              suggestionsOpenSwitcher.on()
            }}
            onBlur={() => setIsFieldFocused(false)}
            className="form-control border-dark"
            placeholder="Введите ФИО, телефон или e-mail клиента"
          />
          <span className="search-icon" onClick={clear}>
            {query.length > 0 ? (
              <img src={require('../../../images/icons/clear-icon.svg')} alt="очистить поиск" />
            ) : (
              <span className="lnr lnr-magnifier" />
            )}
          </span>
        </div>

        {needButton && (
          <div className="col-2">
            <button
              className="btn btn-primary form-control height-40"
              onClick={handleSetNewCustomer}
            >
              Создать
            </button>
          </div>
        )}
      </div>
      {needButton && searchIsLoading && <Loader />}
      {suggestionsOpenSwitcher.isOn && (suggestions?.customers?.length ?? 0) > 0 && (
        <div className="search-suggestions col-10 border border-dark rounded" ref={suggestionsRef}>
          <CustomerSuggestions
            items={suggestions.customers ?? []}
            withoutRedirect
            onClick={handleSetCartCustomer}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  query: state.search.query,
  customerId: state.cart.customer_id,
  searchIsLoading: state.search.isLoading,
  searchResult: state.search.result,
})

const mapDispatchToProps = {
  createCustomer: customer.create,
  setSearchQuery: actions.search.setSearchQuery,
  clearSearch: actions.search.clearSearch,
  getSearchResult: thunks.search.getSearchResult,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CustomerSearch)
