import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Props as InputProps, Input } from './Input'
import { phoneMask } from '../../../constants'

type Props = {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
} & InputProps

export const PhoneInput: FC<Props> = ({
  name,
  placeholder = '',
  onChange,
  isShortInput = false,
  ...props
}) => {
  const [value, setValue] = useState('')
  const [lastKey, setLastKey] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const mask = useMemo(() => {
    return phoneMask.map((symbol) => typeof symbol === 'string')
  }, [])

  const getPosition = (s: string): number => {
    if (s.length <= 1) {
      return 4
    }
    if (s.length < 4) {
      return 3 + s.length
    }
    if (s.length < 7) {
      return 5 + s.length
    }
    return 6 + s.length
  }

  const setPosition = useCallback((s: string) => {
    const position = getPosition(s.replace(/\D/g, ''))
    if (position < (inputRef.current?.selectionStart ?? 1000)) {
      inputRef.current?.setSelectionRange(position, position)
    }
  }, [])

  useEffect(
    function maskedInput() {
      const phone = props.value.replace(/\D/g, '')
      let k = 1
      const t = phoneMask.map((s, i) => {
        if (mask[i]) {
          return s
        }
        if (k >= phone.length) {
          return '_'
        }
        return phone[k++]
      })
      if (t.join('') !== value) setValue(t.join(''))
      setPosition(phone)
    },
    [mask, props.value, setPosition, value],
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value.replace(/\D/g, '')
    if (inputValue === value.replace(/\D/g, '') && lastKey === 'Backspace') {
      inputValue = inputValue.slice(0, inputValue.length - 1)
    }
    setPosition(inputValue)
    onChange({
      ...event,
      target: { ...event.target, value: inputValue, name },
    })
  }

  return (
    <Input
      {...props}
      innerRef={inputRef}
      value={value}
      onChange={handleChange}
      name={name}
      onFocus={() => setPosition(props.value)}
      onMouseUp={() => setPosition(props.value)}
      onKeyDown={(e) => {
        if (!/[0-9]|(Backspace)|(Delete)|(Right)|(Left)|(Tab)/gi.test(e.key)) {
          e.preventDefault()
        }
        setLastKey(e.key)
      }}
      isShortInput
    />
  )
}
