import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ProductVariant } from '../../components'
import {
  getPossibleVariantsFromSimples,
  materialVariantKeys,
  sizeVariantKeys,
  getSuitableSimples,
  getMaterials,
  getPossibleVariantValues,
  filterSelectedVariants,
  textileVariantsKeys,
} from '../../helpers/product'
import { productShape } from '../../constants'
import { useFocusNext } from '../../hooks/useFocusNext'
import { actions } from '../../redux/redusers'
import { getMaterialsMap, getProduct } from '../../redux/selectors/product'
import { useSwitcher } from '../../hooks/useSwitcher'
import { ChooseMaterialPopup } from './ChooseMaterialPopup'
import {
  ProductAttributeGroupComponent,
  ProductAttributeType,
} from './ProductAttributeGroupComponent'

function ProductVariants({ item, setProductSimple, materialsMap = {} }) {
  const materialUpPopupSwitcher = useSwitcher(false)
  const materialDownPopupSwitcher = useSwitcher(false)
  const materialSidePopupSwitcher = useSwitcher(false)
  const focusNextRef = useFocusNext()
  const [selectedVariantsMap, setSelectedVariantsMap] = useState({})

  const variants = useMemo(() => getPossibleVariantsFromSimples(item.simples), [item.simples])
  const isAllVariantsSelected = variants.length === Object.keys(selectedVariantsMap).length

  const filterPriorityMap = useMemo(
    () =>
      variants.reduce((priorityAcc, { key }, index) => {
        return { ...priorityAcc, [key]: variants.length - index }
      }, {}),
    [variants],
  )

  const suitableSimples = useMemo(() => getSuitableSimples(item.simples, selectedVariantsMap), [
    selectedVariantsMap,
    item.simples,
  ])

  useEffect(() => {
    if (suitableSimples.length > 0 && isAllVariantsSelected) {
      setProductSimple(suitableSimples[0])
    }
  }, [isAllVariantsSelected, setProductSimple, suitableSimples])

  const selectedVariantsMapWithOneVariant = useMemo(() => {
    return variants.reduce((newSelectedVariantsMapAcc, { key, values }) => {
      if (values.length !== 1) {
        return newSelectedVariantsMapAcc
      }

      return { ...newSelectedVariantsMapAcc, [key]: values[0].option_id }
    }, {})
  }, [variants])

  function changeVariant(key, value) {
    if (selectedVariantsMap[key] === value) {
      return
    }

    setSelectedVariantsMap(({ ...prevVariants }) => {
      if (!value) {
        delete prevVariants[key]

        return prevVariants
      }
      const newSelectedVariants = {
        ...selectedVariantsMapWithOneVariant,
        ...prevVariants,
        [key]: value,
      }

      const suitableSimples = getSuitableSimples(item.simples, newSelectedVariants)

      if (suitableSimples.length === 0) {
        return {
          ...selectedVariantsMapWithOneVariant,
          rigidity: newSelectedVariants.rigidity,
          [key]: value,
        }
      }

      return newSelectedVariants
    })
  }

  if (!item?.simples?.length) {
    return null
  }

  const sizeVariants = variants.filter(({ key }) => sizeVariantKeys.includes(key))
  const textileVariants = variants.filter(({ key }) => textileVariantsKeys.includes(key))
  const materialVariants = variants.filter(({ key }) => materialVariantKeys.includes(key))
  const otherVariants = variants.filter(
    ({ key }) =>
      !sizeVariantKeys.includes(key) &&
      !textileVariantsKeys.includes(key) &&
      !materialVariants.includes(key),
  )

  const handleChange = ({ key, value }) => {
    changeVariant(key, value)
  }

  return (
    <>
      <div className="variants">
        {!!sizeVariants.length && (
          <ProductAttributeGroupComponent
            group={{
              groupName: 'Размер',
              attributes: sizeVariants,
            }}
            onChange={handleChange}
            frontendType={ProductAttributeType.Slider}
            selectedVariantsMap={selectedVariantsMap}
            filterPriorityMap={filterPriorityMap}
            itemSimples={item.simples}
            focusNextRef={focusNextRef}
          />
        )}
        {otherVariants.map((variantItem) => (
          <ProductAttributeGroupComponent
            key={variantItem.key}
            group={{
              groupName: variantItem.label,
              attributes: [variantItem],
            }}
            onChange={handleChange}
            selectedVariantsMap={selectedVariantsMap}
            filterPriorityMap={filterPriorityMap}
            itemSimples={item.simples}
          />
        ))}
        {!!textileVariants.length && (
          <ProductAttributeGroupComponent
            group={{
              groupName: 'Ткань',
              attributes: textileVariants,
            }}
            onChange={handleChange}
            frontendType={ProductAttributeType.Swatch}
            selectedVariantsMap={selectedVariantsMap}
            filterPriorityMap={filterPriorityMap}
            itemSimples={item.simples}
          />
        )}

        {materialVariants.map(({ key, values, label }) => {
          const selectedVariantsMapWithHigherPriority = filterSelectedVariants(
            selectedVariantsMap,
            filterPriorityMap,
            key,
          )
          const suitableSimples = getSuitableSimples(
            item.simples,
            selectedVariantsMapWithHigherPriority,
            key,
          )
          const options = getPossibleVariantValues(values, suitableSimples, key).map(
            ({ value, option_id }) => {
              return {
                id: option_id,
                name: value || option_id,
              }
            },
          )

          if (options.length === 1) {
            changeVariant(key, options[0].id)
          }

          const material = materialVariantKeys.includes(key)
            ? materialsMap[selectedVariantsMapWithHigherPriority[key]]
            : null

          return (
            <ProductVariant
              activeVariant={selectedVariantsMapWithHigherPriority[key]}
              key={key}
              label={label}
              options={options}
              material={material}
              valiantKey={key}
              item={item}
              onSelect={(value) => changeVariant(key, value)}
              onClickChange={() => {
                switch (key) {
                  case 'material_up': {
                    materialUpPopupSwitcher.on()

                    break
                  }
                  case 'material_bottom': {
                    materialDownPopupSwitcher.on()

                    break
                  }
                  case 'material_side': {
                    materialSidePopupSwitcher.on()

                    break
                  }
                }
              }}
            />
          )
        })}
      </div>
      {materialUpPopupSwitcher.isOn && (
        <ChooseMaterialPopup
          title="Выберите материал верха:"
          activeMaterial={selectedVariantsMap['material_up']}
          materials={getMaterials({
            simples: item.simples,
            variantKey: 'material_up',
            materialsMap,
            variants,
            selectedVariantsMap,
          })}
          isOpen
          toggle={materialUpPopupSwitcher.toggle}
          onChangeMaterial={(value) => changeVariant('material_up', value)}
        />
      )}
      {materialDownPopupSwitcher.isOn && (
        <ChooseMaterialPopup
          title="Выберите материал низа:"
          activeMaterial={selectedVariantsMap['material_bottom']}
          materials={getMaterials({
            simples: item.simples,
            variantKey: 'material_bottom',
            materialsMap,
            variants,
            selectedVariantsMap,
          })}
          isOpen
          toggle={materialDownPopupSwitcher.toggle}
          onChangeMaterial={(value) => changeVariant('material_bottom', value)}
        />
      )}
      {materialSidePopupSwitcher.isOn && (
        <ChooseMaterialPopup
          title="Выберите материал боковины:"
          activeMaterial={selectedVariantsMap['material_side']}
          materials={getMaterials({
            simples: item.simples,
            variantKey: 'material_side',
            materialsMap,
            variants,
            selectedVariantsMap,
          })}
          isOpen
          toggle={materialSidePopupSwitcher.toggle}
          onChangeMaterial={(value) => changeVariant('material_side', value)}
        />
      )}
    </>
  )
}

ProductVariants.propTypes = {
  item: PropTypes.shape(productShape).isRequired,
  setProductSimple: PropTypes.func.isRequired,
  materialsMap: PropTypes.object,
}

const mapStateToProps = (state) => ({
  item: getProduct(state),
  materialsMap: getMaterialsMap(state),
})

const mapDispatchToProps = {
  setProductSimple: actions.product.setSimple,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariants)
