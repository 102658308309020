import React, { FC, ReactNode } from 'react'
import { Badge } from 'reactstrap'
import clsx from 'clsx'

interface Props {
  children: ReactNode
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
  isPill?: boolean
}

export const TextBadge: FC<Props> = ({ children, color, isPill = true }) => {
  return (
    <Badge
      className={clsx('p-2 text-sm font-weight-normal', {
        'text-white': color === 'success' || color === 'dark',
      })}
      color={color}
      pill={isPill}
    >
      {children}
    </Badge>
  )
}
