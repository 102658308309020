import axios from 'axios'
import { dadataToken, dadataUrl } from '../constants'

const dadataAxios = axios.create()

export const search = (request) => {
  const options = {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + dadataToken,
    },
  }
  return dadataAxios.post(dadataUrl, request, options).then((result) => result.data)
}
