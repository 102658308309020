import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useParams, useLocation, Link } from 'react-router-dom'
import { actions, thunks } from '../redux/redusers'
import clsx from 'clsx'

import { cart } from '../redux/actions'
import {
  Tabs,
  DescriptionTab,
  ProductVariants,
  ProductParameters,
  ProductPrice,
  ProductStock,
  // ProductOffers,
  // ProductServices,
  ProductDelivery,
  // ProductItem,
  PropertiesTab,
  MediaTab,
  Loader,
  Modal,
  // Carousel
} from '../components'
import { productShape } from '../constants'
import { useNotification } from '../contexts/NotificationProvider/NotificationProvider'
import { productWaitingItemSelector, productPriceSelector } from '../redux/selectors/product'

const Product = ({
  item,
  isLoading,
  error,
  getProductBySku,
  clearProduct,
  addItemToCart,
  addWaitingItemToCart,
  cartItems,
  cartWaitingItems,
  cartError,
  isCartLoading,
  resetCartError,
}) => {
  const { sku } = useParams()
  const location = useLocation()
  const waitingItem = useSelector(productWaitingItemSelector)
  const productPrice = useSelector(productPriceSelector)

  const sameProduct = sku === item.masterProductSku

  const getProduct = useCallback(() => {
    if (!sameProduct) {
      getProductBySku(sku)
    }
  }, [sameProduct, getProductBySku, sku])

  const cartItem = cartItems?.find((ci) => ci.id === item.id)
  const cartWaitingItem = cartWaitingItems?.find((wi) => wi.name === item.name)
  let cartFound = cartItem ? cartItem : cartWaitingItem

  const [addedProduct, setAddedProduct] = useState(null)

  const currentProductIsAdded = useMemo(
    () => addedProduct?.name === item.name || addedProduct?.id === item.id,
    [addedProduct, item],
  )
  const { showSuccess } = useNotification()

  useEffect(() => getProduct(), [location, getProduct])
  useEffect(() => {
    if (cartFound && currentProductIsAdded && !isCartLoading) {
      showSuccess({ body: <>Товар добавлен в корзину</> })
      setAddedProduct(null)
    }
  }, [currentProductIsAdded, cartFound, isCartLoading, showSuccess])

  useEffect(() => {
    return clearProduct
  }, [clearProduct])

  const handleAddToCart = () => {
    if (item.is_constructor_allowed) {
      setAddedProduct(waitingItem)
      addWaitingItemToCart(waitingItem)
      return
    }
    setAddedProduct(item)
    addItemToCart({ ...item })
  }

  if (isLoading) {
    return <Loader />
  }

  /**
   * @Todo: превратить в нормальную страницу с ошибкой
   */
  if (error) {
    return (
      <div className="product-card">
        Ничего не найдено. Попробуйте <Link to="/">воспользоваться поиском.</Link>
      </div>
    )
  }

  return (
    <div className="product-card">
      <div className="col">
        <h1 className="mb-0">{item.masterProductName}</h1>
        <div className="article">Арт. {item.masterProductSku}</div>
        <div className="breadcrumbs">
          {Array.isArray(item.breadcrumbs) ? item.breadcrumbs?.join(' / ') : ''}
        </div>
      </div>
      <div className="bg-secondary br-5 pb-2">
        <div className="d-flex">
          <div className="col pt-2">
            {/* TODO: Временно убираем блок по просьбе Константина
            <div className="stickers d-flex">
              {item.stickers?.map((sticker) => (
                <div key={sticker} className={sticker} />
              ))}
            </div>*/}
            {!item.is_constructor_allowed && <ProductVariants />}
            {item.is_constructor_allowed && <ProductParameters />}
            {/*<ProductOffers item={item} /> TODO: временно скрыли по просьбе Константина*/}
          </div>
          {cartError && (
            <Modal
              color="warning"
              renderIcon={() => {}}
              isOpenExternal
              onToggle={resetCartError}
              title="Не удалось добавить товар!"
              message="Произошла ошибка"
              buttonToolbar={(toggle) => (
                <button className="btn btn-secondary modal_cancel" onClick={toggle}>
                  Ок
                </button>
              )}
            />
          )}
          <div className="col tabs pr-0 mb-4 product-card__column--right">
            <div className="sticky-container">
              <div className="mb-4">
                <Tabs
                  initialActiveTabId="media"
                  fill
                  tabs={[
                    {
                      id: 'description',
                      title: 'Описание',
                    },
                    {
                      id: 'properties',
                      title: 'Характеристики',
                    },
                    {
                      id: 'media',
                      title: 'Медиа',
                    },
                  ]}
                  content={[
                    <DescriptionTab key="description" item={item} />,
                    <PropertiesTab key="properties" item={item} />,
                    <MediaTab key="media" item={item} />,
                  ]}
                />
              </div>
              <div className="product-card__info">
                <ProductStock
                  item={item}
                  isConstructorAllowed={item.is_constructor_allowed}
                  isFromSimples
                />
                <ProductPrice item={item} isConstructorAllowed={item.is_constructor_allowed} />
                {/*<ProductServices item={item} /> todo: временно скрыли, переносится в корзину*/}
                <ProductDelivery item={item} />
                <div className="text-center mt-4 d-flex align-items-center cart-button-container">
                  {isCartLoading && <Loader wrapClassName="w-25 ml-auto" />}
                  <button
                    className={clsx('btn btn-primary font-weight-bold cart-button height-48', {
                      'cart-button--loading': isCartLoading,
                    })}
                    onClick={handleAddToCart}
                    disabled={item.is_constructor_allowed ? !productPrice?.price : !item.price}
                  >
                    Добавить в корзину
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TODO: Временно убираем блок по просьбе Константина {
          item.similar_products?.length > 0 && (
            <div className='similar mt-4 col'>
              <div className='name'>Похожие товары:</div>
              <div className='d-flex'>
                <Carousel>
                  {
                    item.similar_products?.map(product => (
                      <ProductItem key={product.id} item={product} />
                    ))
                  }
                </Carousel>
              </div>
            </div>
          )
        } */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.product.isLoading,
  error: state.product.error,
  item: state.product.result,
  isCartLoading: state.cart.isLoading,
  cartError: state.cart.error,
  cartItems: state.cart.items,
  cartWaitingItems: state.cart.waiting_items,
})

const mapDispatchToProps = {
  getProductBySku: thunks.product.getProductBySku,
  addItemToCart: cart.addItem,
  clearProduct: actions.product.clear,
  resetCartError: cart.resetCartError,
  addWaitingItemToCart: cart.addWaitingItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)

Product.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  item: PropTypes.shape(productShape).isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
  cartError: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  getProductBySku: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
  clearProduct: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  resetCartError: PropTypes.func.isRequired,
}
