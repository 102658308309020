import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { customer } from '../../../redux/actions'
import { CreateNewCustomer, CustomerSearch, CustomerSuggestions, Select } from '../../index'
import PropTypes from 'prop-types'
import { contactMode } from '../../../constants'
import { customerHelper } from '../../../helpers'
import { setCustomerContact, setCustomerLinkType } from '../../../redux/types'

function CreateNewCustomerContact({ types, createCustomerContact, getRelationTypes }) {
  const [areTypesLoaded, setAreTypesLoaded] = useState(false)
  const [customer, setCustomer] = useState()
  const [relationTypeId, setRelationTypeId] = useState()
  const [selectedExistingCustomer, setSelectedExistingCustomer] = useState(false)
  const handleSetCustomer = (item) => setCustomer(item)
  const handleCreateCustomerContact = (customer) => {
    const relationTypeGuid = types.find(({ id }) => relationTypeId === id)?.guid
    createCustomerContact(customer, relationTypeGuid)
    setCustomer(customer)
  }
  const dispatch = useDispatch()

  const handleSetCustomerWithMapping = (item) => {
    setCustomer(customerHelper.searchToCustomerMapping(item))
    dispatch({
      type: setCustomerContact,
      data: item.guid,
    })
  }

  const handleSetCustomerLinkType = (guid) => {
    const relationTypeGuid = types.find(({ id }) => guid === id)?.guid
    dispatch({
      type: setCustomerLinkType,
      data: relationTypeGuid,
    })
  }

  useEffect(
    function updateRelationTypes() {
      if ((!types || types.length === 0) && !areTypesLoaded) {
        getRelationTypes()
        setAreTypesLoaded(true)
      }
    },
    [types, getRelationTypes, areTypesLoaded],
  )

  return (
    <>
      <CustomerSearch
        needButton={false}
        onSetCustomer={(item) => {
          handleSetCustomerWithMapping(item)
          setSelectedExistingCustomer(true)
        }}
      />
      {selectedExistingCustomer && customer && (
        <CustomerSuggestions items={[{ ...customer }]} withoutRedirect />
      )}
      {!selectedExistingCustomer && !customer && (
        <CreateNewCustomer
          saveButtonStyle="save-button-on-top"
          selectedCustomer={customer}
          hasRelationTypeId={Boolean(relationTypeId)}
          createCustomer={handleCreateCustomerContact}
          mode={contactMode}
        />
      )}
      {!selectedExistingCustomer && customer && (
        <CustomerSuggestions
          items={[
            {
              email: customer.email,
              phone: customer.phone,
              firstname: customer.given_name,
              lastname: customer.surname,
              middlename: customer.middle_name,
            },
          ]}
          withoutRedirect
        />
      )}
      <div className="row pt-3">
        <div className="col">
          <div className="br-5 customer-info">
            <div className="d-flex align-items-center">
              <div className="col-2">Тип связи</div>
              <div className="col-10">
                <Select
                  options={types}
                  initialValueId={relationTypeId}
                  dropdownClass="bg-white align-items-start"
                  placeholderClass="pl-3 align-self-start w-100 justify-content-between"
                  selectClass="bg-white relation-types height-40 pr-3"
                  onChange={(typeId) => {
                    handleSetCustomerLinkType(typeId)
                    setRelationTypeId(typeId)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {customer && (
        <div className={'col-12 mt-4 text-right'}>
          <button
            className="btn btn-outline-primary height-40"
            onClick={() => {
              handleSetCustomer(undefined)
              setSelectedExistingCustomer(false)
              dispatch({
                type: setCustomerContact,
                data: undefined,
              })
            }}
          >
            Очистить выбор
          </button>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  types: state.customer.relationTypes,
})

const mapDispatchToProps = {
  createCustomerContact: customer.createCustomerContact,
  getRelationTypes: customer.getRelationTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCustomerContact)

CreateNewCustomerContact.propTypes = {
  types: PropTypes.array,
  createCustomerContact: PropTypes.func,
  getRelationTypes: PropTypes.func,
  addContactLinkTypeToCart: PropTypes.func,
}
