import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../redusers'
import thunk from 'redux-thunk'
import { updateCartFromLocalStorage } from '../listeners'
import { isDev } from '../../helpers/isDev'

const composeEnhancers =
  (isDev() && (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

store.subscribe(updateCartFromLocalStorage)

export default store
