import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

export function NotFound({ mobilePhone, onRegister }) {
  return (
    <div className="askonaWidget-loyaltyProgram-notFound">
      <div className="askonaWidget-loyaltyProgram-notFound-info">
        Участник Программы Лояльности
        <br />
        {mobilePhone}
        <br />
        не определен.
        <br />
        Выберите другую Программу Лояльности из списка или вернитесь к регистрации клиента.
      </div>
      <Button color="primary" className="height-40" onClick={() => onRegister()}>
        Зарегистрировать
      </Button>
    </div>
  )
}

NotFound.propTypes = {
  mobilePhone: PropTypes.string,
  onRegister: PropTypes.func,
}
