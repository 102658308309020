import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { productHelper } from '../../helpers'
import { LinkWrapper } from '../'
import { AppState } from '../../redux/model'

const toLinkWrapper = true

function CartIcon({ total, count }: Props) {
  return (
    // total > 0 временно изменен на true, пока при добавлении waiting_items из конструктора,
    // не будет обновляться total (после подключения api для определения цены в конструкторе)
    // eslint-disable-next-line no-constant-condition
    <LinkWrapper to={toLinkWrapper ? '/cart' : undefined} className="text-decoration-none">
      <div className="cart-icon d-flex align-items-center justify-content-center">
        {total > 0 && (
          <span className="text-primary d-flex">
            {productHelper.formatPrice(total)} <span className="currency" />
          </span>
        )}
        <div className="position-relative">
          <span className="lnr lnr-cart" style={{ fontSize: '30px' }} />
          {count > 0 && (
            <span className="count bg-primary d-flex align-items-center justify-content-center">
              {count}
            </span>
          )}
        </div>
      </div>
    </LinkWrapper>
  )
}

const mapStateToProps = (state: AppState) => ({
  total: state.cart.total,
  count: state.cart.count,
})

const connector = connect(mapStateToProps)

type Props = ConnectedProps<typeof connector> & {}

export default connector(CartIcon)
