import React, { FC, useCallback, useEffect } from 'react'
import { ImageInput } from '../Input/ImageInput'
import { useFormContext } from 'react-hook-form'

interface Props {
  name: string
}

export const ImageInputField: FC<Props> = ({ name, children }) => {
  const { register, setValue, clearErrors, errors } = useFormContext()

  useEffect(() => {
    register(name)
  }, [name, register])

  const onChange = useCallback(
    (files) => {
      setValue(name, files)
      clearErrors(name)
    },
    [name, setValue, clearErrors],
  )

  return (
    <ImageInput name={name} onChange={onChange} errorMessage={errors[name]?.message}>
      {children}
    </ImageInput>
  )
}
