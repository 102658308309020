import { v4 as uuidv4 } from 'uuid'
import { fetchSearch } from '../../../../api/search'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { actions } from '../../../redusers'
import { GetSearchResultPayload, GetSearchResultReturned } from '../model'
import { AppThunkApiConfig } from '../../../model'

export const getSearchResult = createAsyncThunk<
  GetSearchResultReturned,
  GetSearchResultPayload,
  AppThunkApiConfig
>('search/getSearchResult', async (payload, { dispatch }) => {
  const requestId = uuidv4()
  dispatch(actions.search.setSearchRequestId(requestId))

  const result = await fetchSearch(payload, requestId)

  return {
    result,
    additional: {
      requestId,
    },
  }
})
