import { AttributesObject, Price, ProductState } from './model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SearchProductItem, Simple, Stocks } from '../../../api/dto'
import * as thunks from './thunks'
import { ParametersData } from '../constructor/model'

const initialState: ProductState = {
  result: {},
  isLoading: false,
  error: false,
  parametersData: new Map(),
  priceCache: new Map(),
  attributesObject: {
    attributes: [],
  },
  stocksMap: new Map(),
}

const mapPrice = ({
  price,
  special_price,
  profit,
  specialFromDate,
  specialToDate,
}: Simple): Price => {
  return {
    price,
    special_price,
    profit,
    specialFromDate,
    specialToDate,
  }
}

const mapAttributes = ({ attributes }: Simple): AttributesObject => {
  return {
    attributes: attributes.map((item) => {
      return {
        ...item,
        attribute_id: String(item.attribute_id),
        option_id: String(item.option_id),
      }
    }),
  }
}

const sortAttributes = ({ attributes }: AttributesObject) => {
  return {
    attributes: attributes.sort((a, b) => Number(a.attribute_id) - Number(b.attribute_id)),
  }
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (state, action: PayloadAction<SearchProductItem>) => {
      state.result = {
        ...(action.payload || {}),
        id: String(action.payload.id),
        /**
         * TODO: временное решение для симплов с нулевой ценой
         */
        simples: action.payload.simples?.filter((simple: Simple) => simple.price > 0) || [],
        /**
         * Имя и артикул товара всегда должны браться с основного
         */
        masterProductName: action.payload.name,
        masterProductSku: action.payload.sku,
      }
      state.isLoading = false
      state.error = false
    },
    setSimple: (state, action: PayloadAction<Simple>) => {
      state.result = {
        ...state.result,
        ...(action.payload || {}),
        id: String(action.payload.id),
      }
    },
    clear: (state) => {
      resetState(state)
    },
    setParametersData: (state, action: PayloadAction<ParametersData>) => {
      state.parametersData = action.payload
    },
    setAttributesObject: (state, action: PayloadAction<AttributesObject>) => {
      state.attributesObject = action.payload
    },
    addPriceToCache: (
      state,
      action: PayloadAction<{ attributesObject: AttributesObject; price: Price }>,
    ) => {
      state.priceCache.set(action.payload.attributesObject, action.payload.price)
    },
    addStocks: (
      state,
      action: PayloadAction<{ attributesObject: AttributesObject; stocks: Stocks }>,
    ) => {
      state.stocksMap.set(action.payload.attributesObject, action.payload.stocks)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getProductBySku.pending, (state) => {
      resetState(state)
      state.isLoading = true
    })
    builder.addCase(thunks.getProductBySku.fulfilled, (state, { payload }) => {
      const { result } = payload
      result?.simples?.forEach((value) => {
        state.priceCache.set(sortAttributes(mapAttributes(value)), mapPrice(value))
        state.stocksMap.set(mapAttributes(value), value.stocks)
      })
      state.result = {
        ...(result || {}),
        id: String(result.id),
        /**
         * TODO: временное решение для симплов с нулевой ценой
         */
        simples: result.simples?.filter((simple: Simple) => simple.price > 0) || [],
        /**
         * Имя и артикул товара всегда должны браться с основного
         */
        masterProductName: result.name,
        masterProductSku: result.sku,
      }
      state.isLoading = false
      state.error = false
    })
    builder.addCase(thunks.getProductBySku.rejected, (state) => {
      resetState(state)
      state.isLoading = false
      state.error = true
    })
  },
})

export const product = {
  ...productSlice,
  thunks,
}

function resetState(state: ProductState) {
  state.result = {}
  state.isLoading = false
  state.error = false
  state.parametersData = new Map()
  state.priceCache = new Map()
  state.attributesObject = {
    attributes: [],
  }
  state.stocksMap = new Map()
}
