import React, { ChangeEvent, FC, useState, useEffect, useMemo } from 'react'
import { Col, Container, Input, Row } from 'reactstrap'
import { Attribute, AttributeOption, OnChangeArgs } from '../ProductAttributeGroupComponent'
import { getSortedNumbers } from '../../../utils/numbers'

interface Props {
  attribute: Attribute
  options: AttributeOption[]
  onChange: (args: OnChangeArgs) => void
  focusNextRef?: (element: HTMLInputElement) => void
  activeVariant?: string
}

export const ProductAttributeSlider: FC<Props> = ({
  attribute,
  options,
  onChange,
  focusNextRef,
  activeVariant,
}) => {
  const values = useMemo(() => {
    return getSortedNumbers(options.map((item) => Number(item.value.split(' ')[0])))
  }, [options])
  const allValues = useMemo(
    () => getSortedNumbers(attribute.values.map((item) => Number(item.value.split(' ')[0]))),
    [attribute],
  )
  const marks: Record<number, number> = {}
  allValues.forEach((item) => (marks[item] = item))
  const min = values[0]
  const minValue = allValues[0]
  const maxValue = allValues[allValues.length - 1]
  const activeVariantValue = options
    .find((item) => item.option_id === activeVariant)
    ?.value.split(' ')[0]
  const initValue = activeVariantValue ?? min

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const attributeOption = options.find(
      (item) => Number(item.value.split(' ')[0]) === Number(inputValue),
    )
    if (attributeOption) {
      onChange({ key: attributeOption.code, value: attributeOption.option_id })
    }
  }, [options[0]?.value, inputValue])

  useEffect(() => {
    if (activeVariantValue) {
      setInputValue(activeVariantValue)
    }
    if (!activeVariantValue) {
      setInputValue(String(initValue))
    }
  }, [activeVariantValue, initValue])

  const handleInputOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value)
    const closestValue = getClosestValue(value, values)
    setInputValue(String(closestValue))
  }

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    setInputValue(value)
  }

  return (
    <Container fluid>
      <Row className="mb-2">
        <p className="mb-0 font-weight-bold">{attribute.label}</p>
      </Row>
      <Row className="align-items-center">
        <Col xs="3" className="pl-0">
          <div>
            {minValue}-{maxValue}
          </div>
        </Col>
        <Col xs="3" className="pl-0">
          <Input
            type="number"
            value={inputValue}
            onBlur={handleInputOnBlur}
            onChange={handleInputOnChange}
            innerRef={focusNextRef}
            disabled={values.length === 1}
          />
        </Col>
      </Row>
    </Container>
  )
}

const getClosestValue = (value: number, values: number[]) => {
  return values.reduce((acc, cur) => (Math.abs(cur - value) < Math.abs(acc - value) ? cur : acc))
}
