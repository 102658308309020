import React, { FC, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { actions } from '../../redux/redusers'
import useOutsideClick from '../../hooks/useOutsideClick'
import { AppState } from '../../redux/model'
import { Cube, AddOne } from '@icon-park/react'
import clsx from 'clsx'

interface Props {
  sidebarIsOpened: boolean
  toggleSidebar: () => void
}

const Sidebar: FC<Props> = ({ sidebarIsOpened, toggleSidebar }) => {
  const sidebar = useRef(null)
  const [openedTab, setOpenedTab] = useState('')

  const handleMenuClick = (tabName: string) => {
    setOpenedTab(tabName)
    setTimeout(() => setOpenedTab(''), 1000)
  }

  useOutsideClick(sidebar, () => {
    if (sidebarIsOpened) {
      toggleSidebar()
    }
  })

  return (
    <aside className={`sidebar bg-light ${sidebarIsOpened ? 'open' : ''}`} ref={sidebar}>
      <ul className="p-0 d-flex flex-column">
        <li className={clsx('mb-3', openedTab === 'main' ? 'open' : '')}>
          <Link to="/" className="w-100 h-100 d-flex" onClick={() => handleMenuClick('main')}>
            <span className="lnr lnr-fs-24 lnr-magnifier" />
            <p>Поиск</p>
          </Link>
        </li>
        <li className={clsx('mb-3', openedTab === 'reports' ? 'open' : '')}>
          <Link
            to="/reports"
            className="w-100 h-100 d-flex"
            onClick={() => handleMenuClick('reports')}
          >
            <span className="lnr lnr-fs-24 lnr-chart-bars" />
            <p>Отчёты</p>
          </Link>
        </li>
        <li className={clsx('mb-3', openedTab === 'constructor' ? 'open' : '')}>
          <Link
            to="/constructor"
            className="w-100 h-100 d-flex"
            onClick={() => handleMenuClick('constructor')}
          >
            {/** TODO: нужно придумать как использовать иконки из IconPark совместно с LinearIcons.
             Пока что вручную подогнал размеры*/}
            <Cube size="26" strokeWidth={2} style={{ marginTop: -5, marginLeft: -2 }} />
            <p>Конструктор</p>
          </Link>
        </li>
        <li className={clsx('mb-3', openedTab === 'add-client' ? 'open' : '')}>
          <Link
            to="/add-client"
            className="w-100 h-100 d-flex"
            onClick={() => handleMenuClick('add-client')}
          >
            <AddOne size="26" strokeWidth={2} style={{ marginTop: -5, marginLeft: -2 }} />
            <p>Добавление клиента</p>
          </Link>
        </li>
      </ul>
    </aside>
  )
}

const mapStateToProps = (state: AppState) => ({
  sidebarIsOpened: state.app.sidebarIsOpened,
})

const mapDispatchToProps = {
  toggleSidebar: actions.app.toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
