import axios from 'axios'

import { useRuntimeConfig as getRuntimeConfig } from '../hooks/useRuntimeConfig'
import { ensureAuth, useAuth as getAuth } from './auth'
import { getCorrelationId } from '../helpers'
import { errorHandler } from '../helpers/errorHandler'
import { Cookies } from 'react-cookie'
import store from '../redux/store'
const apiAxios = axios.create()
const cookies = new Cookies()

apiAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.error('API error:', error)
    const request = error.config

    if (error.response && error.response.status === 401 && !request._retry) {
      request._retry = true
      return new Promise((resolve) => {
        return ensureAuth((token: string) => {
          request.headers.Authorization = `Bearer ${token}`
          return resolve(apiAxios(request))
        })
      })
    }
    return Promise.reject(error)
  },
)

const getHeaders = (
  appId?: string,
  correlationId?: string,
  customHeaders?: { [key: string]: string },
) => {
  if (!appId) {
    appId = store.getState().app?.instanceId
  }
  return {
    Authorization: `Bearer ${getAuth().token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Correlation-ID': correlationId ? correlationId : getCorrelationId(),
    ...(appId && { 'X-Application-ID': appId }),
    ...(cookies.get('test_mode_key') && { 'X-Test-Mode-Key': cookies.get('test_mode_key') }),
    ...(customHeaders && { ...customHeaders }),
  }
}

export const { apiUrl, apiHost, priceServiceUrl, cleanApiUrl, loggerUrl } = getRuntimeConfig()

type BaseApiProps = {
  path: string
  data?: string | unknown
  appId?: string
  customHeaders?: { [key: string]: string }
  requireBody?: boolean
  withStatusCode?: boolean
  correlationId?: string
  url?: string
}

type RequestProps = {
  method: 'POST' | 'GET' | 'PUT' | 'DELETE'
} & BaseApiProps

const get = ({
  path,
  data,
  appId,
  requireBody,
  customHeaders,
  withStatusCode = false,
}: BaseApiProps) =>
  makeRequest({ method: 'GET', path, data, appId, requireBody, withStatusCode, customHeaders })
const post = ({
  path,
  data,
  appId,
  requireBody,
  withStatusCode = false,
  correlationId,
  url,
}: BaseApiProps) =>
  makeRequest({
    method: 'POST',
    path,
    data,
    appId,
    requireBody,
    withStatusCode,
    correlationId,
    url,
  })
const put = ({
  path,
  data,
  appId,
  requireBody,
  withStatusCode = false,
  correlationId,
}: BaseApiProps) =>
  makeRequest({ method: 'PUT', path, data, appId, requireBody, withStatusCode, correlationId })
const del = ({ path, data, appId, requireBody, withStatusCode = false }: BaseApiProps) =>
  makeRequest({ method: 'DELETE', path, data, appId, requireBody, withStatusCode })

const makeRequest = ({
  method,
  path,
  data,
  appId,
  requireBody,
  customHeaders,
  withStatusCode,
  correlationId,
  url,
}: RequestProps) =>
  apiAxios
    .request({
      url: url ? url : apiUrl + path,
      method,
      data,
      headers: getHeaders(appId, correlationId, customHeaders),
    })
    .then((res) => {
      if (!requireBody || Object.keys(res?.data || {}).length > 0) {
        if (typeof res.data === 'string') {
          return res.data
        } else {
          return {
            ...res.data,
            ...(withStatusCode && {
              responseStatus: res.status,
              responseStatusText: res.statusText,
            }),
            correlationId: res.config.headers['X-Correlation-ID'],
            correlation_id: res.config.headers['X-Correlation-ID'],
          }
        }
      }
      throw new Error('empty data')
    })
    .catch((e) => {
      errorHandler(e)
    })

const downloadBlob = ({
  path,
  appId,
  correlationId,
}: {
  path: string
  appId: string
  correlationId: string
}) =>
  apiAxios
    .request({
      url: cleanApiUrl + path,
      headers: getHeaders(appId, correlationId),
      responseType: 'blob',
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      console.error(e)
    })

export const api = {
  get,
  post,
  put,
  del,
  downloadBlob,
}
