import React, { FC } from 'react'
import {
  Attribute,
  OnChangeArgs,
  AttributeOption,
  ProductAttributeType,
} from '../ProductAttributeGroupComponent'
import { ProductAttributeSelect } from './ProductAttributeSelect'
import { ProductAttributeSlider } from './ProductAttributeSlider'
import { ProductSwatchAttribute } from './Swatch/ProductSwatchAttribute'

interface Props {
  attribute: Attribute
  frontendType?: ProductAttributeType
  onChange: (args: OnChangeArgs) => void
  isLabelHidden?: boolean
  options: AttributeOption[]
  focusNextRef?: (element: HTMLInputElement) => void
  activeVariant?: string
}

export const ProductAttribute: FC<Props> = ({
  attribute,
  frontendType,
  options,
  onChange,
  focusNextRef,
  activeVariant,
  isLabelHidden,
}) => {
  if (frontendType === ProductAttributeType.Slider && attribute.key !== 'size') {
    return (
      <ProductAttributeSlider
        attribute={attribute}
        options={options}
        onChange={onChange}
        focusNextRef={focusNextRef}
        activeVariant={activeVariant}
      />
    )
  }
  if (frontendType === ProductAttributeType.Swatch) {
    return (
      <ProductSwatchAttribute
        attribute={attribute}
        options={options}
        onChange={onChange}
        activeVariant={activeVariant}
      />
    )
  }
  return (
    <ProductAttributeSelect
      attribute={attribute}
      options={options}
      onChange={onChange}
      activeVariant={activeVariant}
      isLabelHidden={isLabelHidden}
    />
  )
}
