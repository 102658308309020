import { useEffect, useState } from 'react'
import axios from 'axios'
import { commonErrorText } from '../constants'

export function useLoyaltyPrograms({
  token,
  gateway,
  shopCode,
  terminalId,
  purchaseDate,
  onError,
  refreshToken,
}) {
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]) // AskonaBonus, AeroflotBonus
  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] = useState({})

  useEffect(() => {
    if (token && shopCode && terminalId && !loyaltyPrograms?.length) {
      axios
        .post(
          `${gateway}/api/LoyaltyService/check`,
          {
            shopCode,
            terminalId,
            date: purchaseDate,
          },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((result) => {
          if (result.status === 401) {
            refreshToken()
          } else {
            return result.data
          }
        })
        .then((result) => {
          if (result.errors?.length) {
            onError(result.errors[0])
            setLoyaltyPrograms([])
            setSelectedLoyaltyProgram({})
          } else {
            const programs = (result.loyaltyPrograms ?? []).filter(
              // TODO: для запуска убираем Аэрофлот
              (program) => program.name !== 'Aeroflot Bonus',
            )

            if (programs.length === 0) {
              onError('Списание бонусов недоступно')
              setLoyaltyPrograms([])
              setSelectedLoyaltyProgram({})

              return
            }

            onError(null)
            setLoyaltyPrograms(programs)
            setSelectedLoyaltyProgram(programs[0])
          }
        })
        .catch((e) => {
          console.error(e)
          onError(commonErrorText)
        })
    }
  }, [token, gateway, purchaseDate, loyaltyPrograms, shopCode, terminalId, refreshToken, onError])

  return {
    loyaltyPrograms,
    selectedLoyaltyProgram,
    setSelectedLoyaltyProgram,
  }
}
