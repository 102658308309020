import React, { FC, ReactNode } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'

SwiperCore.use([Navigation])

interface Props {
  children: ReactNode
  onClickItem?: (index: number) => void
  mini?: boolean
}

const Carousel: FC<Props> = ({ children, onClickItem, mini }) => {
  if (!children) {
    return null
  }

  return (
    <div className={`w-100 carousel ${mini ? 'mini' : ''}`}>
      <Swiper
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 18,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 18,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 48,
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 48,
          },
        }}
        navigation
      >
        {React.Children.map(children, (item, index) => (
          <SwiperSlide
            key={index}
            onClick={() => {
              if (onClickItem) {
                onClickItem(index)
              }
            }}
          >
            {React.isValidElement(item) && React.cloneElement(item, { className: 'item' })}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Carousel
