import React, { FC } from 'react'
import style from './Input.module.scss'
import clsx from 'clsx'
import { Image } from './types'

interface Props {
  images: Image[]
  onRemove: (id: string) => void
}

export const ImagesPreview: FC<Props> = ({ images = [], onRemove }) => {
  return (
    <>
      {images.map(({ base64, id, value }, index) => {
        return (
          <div key={id} className="d-flex mb-4">
            <img
              className={clsx(style.img, 'object-fit-cover mr-1')}
              src={base64}
              alt={`Изображение ${index + 1}`}
            />
            <div>{value.name}</div>
            <div className="cursor-pointer ml-auto" onClick={() => onRemove(id)}>
              <span className="lnr lnr-cross" />
            </div>
          </div>
        )
      })}
    </>
  )
}
