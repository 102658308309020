import { createSlice, original, PayloadAction } from '@reduxjs/toolkit'
import { ParametersData, State, StepsData } from './model'
import * as thunks from './thunks'
import { Category, Parameters } from './model/dto'

const initialState: State = {
  parametersTree: null,
  steps: [],
  currentStepIndex: 0,
  stepsData: new Map(),
  parametersData: new Map(),
  priceCache: new Map(),
}

const constructorSlice = createSlice({
  name: 'constructor',
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
    setCategoryTree: (state, { payload: categoryTree }: PayloadAction<Category>) => {
      state.steps[0] = categoryTree
    },
    setParametersTree: (state, { payload: parametersTree }: PayloadAction<Parameters>) => {
      state.parametersTree = parametersTree
    },
    addPriceToCache: (
      state,
      {
        payload: { price, parametersData },
      }: PayloadAction<{ price: number; parametersData: ParametersData }>,
    ) => {
      const originalState = original(state)!
      const priceCache = new Map(originalState.priceCache)
      priceCache.set(parametersData, price)
      state.priceCache = priceCache
    },
    setStepsData: (state, { payload: stepsData }: PayloadAction<StepsData>) => {
      state.stepsData = stepsData
    },
    setParametersData: (state, { payload: parametersData }: PayloadAction<ParametersData>) => {
      state.parametersData = parametersData
    },
    setStep: (state, { payload: stepIndex }: PayloadAction<number>) => {
      state.currentStepIndex = stepIndex
    },
    previousStep: (state) => {
      state.currentStepIndex--
    },
    nextStep: (state) => {
      const originalState = original(state)!
      const value = originalState.steps[originalState.currentStepIndex]

      const selectedEntity = originalState.stepsData.get(value)
      if (selectedEntity) {
        state.steps[originalState.currentStepIndex + 1] = selectedEntity
        state.currentStepIndex++
      }
    },
    clearInvalidSteps: (state, { payload: afterIndex }: PayloadAction<number>) => {
      const originalState = original(state)!
      const stepsData = new Map(originalState.stepsData)

      state.steps = originalState.steps.filter((item, index) => {
        if (index > afterIndex) {
          stepsData.delete(item)
          return false
        }
        return true
      })

      state.parametersTree = null
      state.parametersData = new Map()
      state.priceCache = new Map()
      state.stepsData = stepsData
    },
  },
})

export default {
  ...constructorSlice,
  thunks,
}
