import { api } from './api'
import { useMutation } from 'react-query'

export const postFeedback = (data) => {
  return api.post({ path: '/pos/feedback', data })
}

export function usePostFeedbackMutation() {
  const { isSuccess, isError, ...postFeedbackMutation } = useMutation(postFeedback)

  return {
    ...postFeedbackMutation,
    isSuccess: isSuccess && postFeedbackMutation.data?.status === true,
    isError: isError || postFeedbackMutation.data?.status === false,
  }
}
