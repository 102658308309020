import React, { useState, useEffect, useRef, FC, ReactText, CSSProperties } from 'react'
import { Label } from 'reactstrap'
import clsx from 'clsx'

interface Option {
  id: string
  name: ReactText
}

export interface Props {
  options: Option[]
  initialValueId?: string
  placeholder?: string
  onChange?: (id: string) => void
  disabled?: boolean
  required?: boolean
  selectClass?: string
  placeholderClass?: string
  dropdownClass?: string
  dropdownItemClass?: string
  withoutSelectionClass?: string
  label?: string
  errorMessage?: string
  name?: string
  selectContainerClass?: string
  withoutWidthCustomization?: boolean
  withRotateIcon?: boolean
  cartLine?: boolean
}

const Select: FC<Props> = ({
  options,
  initialValueId,
  onChange,
  placeholder = 'Выберите',
  selectClass,
  dropdownClass,
  dropdownItemClass,
  placeholderClass,
  selectContainerClass,
  withoutSelectionClass,
  disabled,
  required,
  name,
  label,
  errorMessage,
  withoutWidthCustomization,
  withRotateIcon,
  cartLine,
}) => {
  const dropdown = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [width, setWidth] = useState<CSSProperties['width']>('auto')
  const [selectedId, setSelectedId] = useState(initialValueId)

  const selectedOption = options.find((option) => option.id === selectedId)

  const handleClick = () => {
    if (disabled) {
      return false
    }
    setIsOpen(!isOpen)
  }
  /**
   * Автоматическая подстройка ширины селекта под ширину options
   */
  useEffect(() => {
    if (dropdown.current?.offsetWidth) {
      setWidth(dropdown.current.offsetWidth)
    }
  }, [dropdown])

  useEffect(() => {
    if (initialValueId) {
      setSelectedId(initialValueId)
    }
  }, [initialValueId])

  return (
    <div className={clsx(selectContainerClass)}>
      {label && (
        <Label for={name}>
          {label} {required ? <span className="text-danger">*</span> : ''}
        </Label>
      )}
      <div
        className={`select d-flex flex-column cursor-pointer ${isOpen ? 'open' : ''} ${
          disabled ? 'disabled' : ''
        } ${selectClass} ${!selectedOption?.name && withoutSelectionClass}`}
        onClick={handleClick}
        style={!withoutWidthCustomization ? { width } : {}}
      >
        <div className={`border-dark selected d-flex align-items-center ${placeholderClass}`}>
          {selectedOption?.name || placeholder}
          {cartLine ? (
            <div className={clsx('arrow', { rotatable: withRotateIcon })} />
          ) : isOpen ? (
            <img src={require('../../images/icons/arrow-up.svg')} alt="раскрыто" />
          ) : (
            <img src={require('../../images/icons/arrow-down.svg')} alt="скрыто" />
          )}
        </div>
        <div className={`dropdown border border-dark ${dropdownClass}`} ref={dropdown}>
          {options?.map((option) => (
            <div
              key={option.id}
              onClick={() => {
                if (onChange) {
                  onChange(option.id)
                }
                setSelectedId(option.id)
                setIsOpen(false)
              }}
              className={`${option.id === selectedId ? 'selected ' : ''}${dropdownItemClass}`}
            >
              {option.name}
            </div>
          ))}
        </div>
        {isOpen && <button className="back" onClick={() => setIsOpen(false)} />}
      </div>
      {errorMessage && <span className="text-danger">{errorMessage}</span>}
    </div>
  )
}

export default Select
