import React, { useCallback, useEffect, useState } from 'react'

import { useReceiver } from '../hooks/useReceiver'

import { Loading } from './components/Loading'
import { Error } from './components/Error'
import { Success } from './components/Success'
import { Start } from './components/Start'

import './Payment.scss'
import closeIcon from './icons/lnr-close.svg'
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from './constants'

Payment.defaultProps = {
  status: '',
  amount: 0,
  isOpen: false,
}

type PaymentProps = {
  orderId?: string
  status?: string
  amount?: number
  amountWithBonuses?: number
  orderTotalSum: number
  isOpen?: boolean
  formatSum?: (value: number, withCurrency?: boolean) => string
  onPaymentStart?: () => void
  onClose: () => void
  onSuccess?: (params: any) => { success: boolean }
  transactionId?: string
  paymentApiHost?: string
  operatorName: string
  discountSum?: number
  orderLines: any[]
  defaultPhone?: string
  defaultEmail?: string
  updateDraftPayContact: (params: { newClientPhone?: string; newClientEmail?: string }) => void
  paymentErrorReset: () => void
}

export function Payment(props: PaymentProps) {
  const [error, setError] = useState('')
  const [result, setResult] = useState({})
  const [paymentType, setPaymentType] = useState('0')
  const [paymentStatus, setPaymentStatus] = useState(STATUS_LOADING)
  const [parameters, setParameters] = useState(props)
  const { status, isOpen, onSuccess, paymentErrorReset } = parameters
  // FIXME: Нужно либо все перевести на пропсы, либо на useReceiver
  useEffect(() => {
    setParameters((prevState) => ({
      ...prevState,
      onSuccess: props.onSuccess,
      transactionId: props.transactionId,
    }))
  }, [props.onSuccess, props.transactionId])

  useReceiver((opts: PaymentProps) => setParameters({ ...parameters, ...opts }))

  const submitPayment = useCallback(async () => {
    if (onSuccess) {
      setPaymentStatus(STATUS_LOADING)
      const requestResult = await onSuccess({ ...result, paymentType })
      if (requestResult.success) {
        setPaymentStatus(STATUS_SUCCESS)
      } else {
        setPaymentStatus(STATUS_ERROR)
      }
    }
  }, [onSuccess, paymentType, result])

  useEffect(() => {
    if (status === STATUS_SUCCESS && result && Object.keys(result).length > 0) {
      submitPayment()
    }
  }, [status, result, submitPayment])

  if (!isOpen) {
    return null
  }

  const setStatus = (status: string) => {
    setParameters((prevParameters) => ({
      ...prevParameters,
      status,
    }))
  }

  const getContent = () => {
    switch (status) {
      case STATUS_LOADING:
        return <Loading />
      case STATUS_ERROR:
        return (
          <Error
            repeatOperation={() => {
              setStatus('')
              paymentErrorReset()
            }}
            title="Операция прервана"
            errorComment={
              <>
                Ошибка: {error}.
                <br /> Производится откат всех операций.
              </>
            }
            buttonText="Начать заново процедуру оплаты"
          />
        )
      case STATUS_SUCCESS:
        switch (paymentStatus) {
          case STATUS_LOADING:
            return <Loading isPayment={false} />
          case STATUS_SUCCESS:
            return (
              <Success formatSum={parameters?.formatSum} amount={props?.amountWithBonuses || 0} />
            )
          case STATUS_ERROR:
            return (
              <Error
                repeatOperation={submitPayment}
                title="Ошибка регистрации оплаты в системе"
                errorComment={
                  <>
                    Произошла ошибка регистрации оплаты в системе. <br />
                    Свяжитесь с администрацией или попробуйте начать процедуру оплаты с начала
                  </>
                }
                buttonText="Повторить отправку данных об оплате"
              />
            )
          default:
            // Не должно возникать такой ситуации
            return null
        }
      default:
        return (
          <Start
            {...parameters}
            setStatus={setStatus}
            setResult={setResult}
            setPaymentType={setPaymentType}
            onError={setError}
          />
        )
    }
  }

  return (
    <div className="askona-widget-payment">
      {status !== STATUS_LOADING && (
        <div
          className="close"
          onClick={() => {
            setParameters((prevParameters) => ({
              ...prevParameters,
              isOpen: false,
            }))
            props.onClose()
          }}
        >
          <img src={closeIcon} alt="" />
        </div>
      )}
      <div className="askona-widget-payment__title">Оплата заказа</div>
      {getContent()}
    </div>
  )
}
