import React from 'react'
import PropTypes from 'prop-types'

import { numberDeclension } from '../../helpers'
import { bonusBalanceShape } from '../../constants'
import { formatTime } from '../../helpers/time'

function BalanceInfo({ bonusBalance }) {
  const currentAmount = Number(bonusBalance.current_amount ?? 0)
  const totalAmount = Number(bonusBalance.total_amount ?? 0)

  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <div>Активный баланс</div>
        <div className="font-weight-bold">
          {currentAmount} {numberDeclension(currentAmount, ['балл', 'балла', 'баллов'])}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div>Общий баланс</div>
        <div>
          {totalAmount} {numberDeclension(totalAmount, ['балл', 'балла', 'баллов'])}
        </div>
      </div>
      {bonusBalance.due_date && (
        <div className="d-flex justify-content-between mt-3">
          <div>Дата сгорания бонусов</div>
          <div>{formatTime(new Date(bonusBalance.due_date))}</div>
        </div>
      )}
      {bonusBalance.reg_date && (
        <div className="d-flex justify-content-between mt-3">
          <div>Дата регистрации</div>
          <div>{formatTime(new Date(bonusBalance.reg_date))}</div>
        </div>
      )}
    </>
  )
}

BalanceInfo.propTypes = {
  bonusBalance: PropTypes.shape(bonusBalanceShape).isRequired,
}

export default BalanceInfo
