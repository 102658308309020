import React, { useEffect, useState } from 'react'

function UpButton() {
  const [isShow, setIsShow] = useState(false)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const checkScrollTop = () => {
      if (!isShow && window.pageYOffset > window.innerHeight) {
        setIsShow(true)
      } else if (isShow && window.pageYOffset <= window.innerHeight) {
        setIsShow(false)
      }
    }
    window.addEventListener('scroll', checkScrollTop)
    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [isShow])

  if (!isShow) {
    return null
  }

  return (
    <button className="btn btn-secondary button-up d-flex align-items-center" onClick={scrollTop}>
      <span className="lnr lnr-arrow-up mr-1" />
      Наверх
    </button>
  )
}

export default UpButton
