import React, { FC, useCallback, useEffect } from 'react'
import '../../styles/screens/constructor.scss'
import { CreateNewCustomer, Loader } from '../../components'
import { cartMode } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  CUSTOMER_UPDATED_STATE,
  CUSTOMER_WAIT_STATE,
  STATUS_SUCCESS,
} from '../../api/ws/customer/constants'
import { useNotification } from '../../contexts/NotificationProvider/NotificationProvider'
import { useHistory } from 'react-router-dom'
import { resetCreateCustomer } from '../../redux/types'
import { getCartCustomer, selectCartCustomerIsCreated } from '../../redux/selectors/cart'
import { selectCustomerState, selectCustomerStatus } from '../../redux/selectors/customer'
import { create, resetLastResponseStatus, setCustomer } from '../../redux/actions/customer'
import { Customer } from '../../api/dto/CustomerBasicType'
import { useGetCustomerQuery } from '../../api/customer'

export const AddClient: FC = () => {
  const dispatch = useDispatch()

  const { customer } = useSelector(getCartCustomer)
  const customerIsCreated = useSelector(selectCartCustomerIsCreated)
  const customerState = useSelector(selectCustomerState)
  const responseStatus = useSelector(selectCustomerStatus)

  const history = useHistory()
  const { showWarning, showSuccess } = useNotification()

  const fetchByIdQuery = useGetCustomerQuery(customer?.customer_id)
  const { refetch } = fetchByIdQuery

  const handleCreateCustomer = useCallback(
    (customer: Customer) => {
      dispatch(create(customer))
    },
    [dispatch],
  )

  const cancel = useCallback(() => {
    history.push(`/`)
  }, [history])

  const customerQuery = useCallback(async () => {
    if (customerIsCreated && customer?.customer_id && customer?.version_id) {
      try {
        dispatch({ type: resetCreateCustomer })

        const data = await refetch()
        if (data.isSuccess) {
          dispatch(setCustomer(data.data))
        }
      } catch (e) {
        console.error(e)
      } finally {
        history.push(`/customer/${customer?.customer_id}`)
      }
    }
  }, [customer?.customer_id, customer?.version_id, customerIsCreated, dispatch, history])

  useEffect(() => {
    if (customerIsCreated && !customer?.customer_id) {
      const message = (
        <>
          Новый клиент{' '}
          <b>
            {[customer.surname, customer.given_name, customer.middle_name]
              .filter(Boolean)
              .join(' ')}
          </b>{' '}
          принят к сохранению
        </>
      )

      showSuccess({
        body: message,
      })
    }
  }, [
    customer?.surname,
    customer?.given_name,
    customer?.middle_name,
    customer?.customer_id,
    customerIsCreated,
    showSuccess,
  ])

  useEffect(() => {
    customerQuery()
  }, [customerQuery])

  useEffect(() => {
    if (responseStatus === STATUS_SUCCESS) {
      dispatch(resetLastResponseStatus())

      const message =
        'Внесенные изменения приняты к сохранению. ' +
        'Вы получите дополнительное уведомление после успешного сохранения данных в CRM.'

      showWarning({
        title: 'Внимание',
        body: message,
      })
    }
  }, [resetLastResponseStatus, responseStatus, showWarning])

  useEffect(() => {
    if (customerState === CUSTOMER_UPDATED_STATE && customerIsCreated && customer?.customer_id) {
      showSuccess({
        title: 'Внимание',
        body: 'Данные успешно сохранены',
      })
    }
  }, [customer?.customer_id, customerIsCreated, customerState, showSuccess])

  if (customerState === CUSTOMER_WAIT_STATE || (customerIsCreated && !customer?.customer_id)) {
    return <Loader />
  }

  return (
    <div className="constructor ml-0">
      <div className="mb-4">
        <h1>Добавление потенциального клиента</h1>
      </div>

      <div className="cart-wizard-select-customer">
        <div className="bg-secondary br-5 cart-wizard-body">
          <CreateNewCustomer
            mode={cartMode}
            createCustomer={handleCreateCustomer}
            cancel={cancel}
            showBorder={false}
          />
        </div>
      </div>
    </div>
  )
}
