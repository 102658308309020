import React from 'react'
import PropTypes from 'prop-types'
import Error from './Error'
import { Button } from 'reactstrap'

const pinCodeErrorTextEn = 'PIN-code not correct'
const pinCodeErrorTextRu = 'Введен неверный код'

export function Fail({ onAction, errorMessage }) {
  const isPinCodeError =
    errorMessage &&
    (errorMessage.includes(pinCodeErrorTextEn) || errorMessage.includes(pinCodeErrorTextRu))
  return (
    <>
      <Error text={isPinCodeError ? 'Введен неверный код' : 'Произошла ошибка списания'} />
      <div className="askonaWidget-loyaltyProgram-withdrawal">
        <div>Продолжить оформление заказа без списания бонусов?</div>
        <div className="askonaWidget-loyaltyProgram-withdrawal-form">
          <div>
            <Button color="primary" onClick={() => onAction({ continue: true })}>
              Да
            </Button>
            <Button color="light" onClick={() => onAction({ continue: false })}>
              Нет
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

Fail.propTypes = {
  onAction: PropTypes.func,
  errorMessage: PropTypes.string,
}
