import { PayStatus, PayStatusResult } from './dto/PayStatusResult'
import { captureException, SentryMessageTags } from './sentryCaptures'
import { GET_PAY_STATUS_URL, paymentAxios } from './paymentAxiosInit'

export async function getPayStatus(apiUrl: string, requestId: string, tags: SentryMessageTags) {
  let status = PayStatus.PROGRESSING
  while (status === PayStatus.PROGRESSING) {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1500))
      const { data } = await paymentAxios.post<PayStatusResult>(apiUrl + GET_PAY_STATUS_URL, {
        requestId,
      })
      if (data.data.status !== PayStatus.PROGRESSING) {
        const { data: response } = data
        if (response.status === PayStatus.SUCCESS) {
          return {
            success: true,
          }
        } else {
          captureException(tags)
          return {
            success: false,
            errorMessage: `${response?.errorCode} ${response?.errorDescription}`,
          }
        }
      }
    } catch (error) {
      captureException(tags)
      return {
        success: false,
        errorMessage: error.toString(),
      }
    }
  }
}
