import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import RadioButton, { Props as RadioButtonProps } from '../RadioButton'

interface Props extends RadioButtonProps {
  name: string
}

export const RadioField: FC<Props> = ({ name, ...props }) => {
  const { register } = useFormContext()

  return <RadioButton {...props} name={name} innerRef={register} />
}
