import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { actions } from '../../../redusers'
import { ParametersData } from '../model'
import { random } from 'lodash-es'

export const fetchPrice = createAsyncThunk<void, ParametersData, AppThunkApiConfig>(
  'constructor/fetchPrice',
  async (parametersData, { dispatch }) => {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const price = random(20000, 100000, true)
    dispatch(
      actions.constructor.addPriceToCache({
        price,
        parametersData,
      }),
    )
  },
)
