import React, { FC, useEffect, useState, useMemo } from 'react'
import { Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { Button } from '../../../../../components/Layout/Button/Button'
import { AttributeOption } from '../../../../../redux/slices/constructor/model/dto'
import { SwatchModalCategory } from './SwatchModalCategory'

interface Props {
  open: boolean
  title: string
  toggle: () => void
  options: AttributeOption[]
  selectedOptionId?: number
  onSubmit: (optionId: number) => void
}

export const SwatchModal: FC<Props> = ({
  open,
  title,
  toggle,
  options,
  selectedOptionId: optionId,
  onSubmit,
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState(optionId)

  useEffect(() => {
    setSelectedOptionId(optionId)
  }, [open])

  const isRecommndedColors = useMemo(() => options.find((item) => item.recommended === '0'), [
    options,
  ])

  const handleModalToggle = () => toggle()

  const handleClick = (optionId: number) => () => setSelectedOptionId(optionId)

  const handleSubmit = () => {
    if (selectedOptionId) {
      onSubmit(selectedOptionId)
      toggle()
    }
  }

  const categories = options.reduce<Record<string, AttributeOption[]>>((acc, value) => {
    if (value.category) {
      if (!acc[value.category]) {
        acc[value.category] = []
      }
      acc[value.category].push(value)
    }
    return acc
  }, {})

  const elements = Object.entries(categories).map(([key, value]) => (
    <SwatchModalCategory
      key={key}
      category={key}
      options={value}
      selectedOptionId={selectedOptionId}
      handleClick={handleClick}
    />
  ))

  return (
    <Modal isOpen={open} toggle={handleModalToggle} className="constructor__swatch-modal">
      <ModalHeader tag="h1" toggle={handleModalToggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            {isRecommndedColors && (
              <div className="unrecommended">
                <img
                  src={require('../../../../../images/icons/unrecommended.svg')}
                  alt="unrecommended"
                />
                <span className="unrecommended__dash">&ndash;</span>
                <span className="unrecommended__clarification">
                  данный тип ткани не рекомендован для товара
                </span>
              </div>
            )}
          </Row>
          <Row>{elements}</Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!selectedOptionId}
          className="height-40"
          color="primary"
          onClick={handleSubmit}
        >
          Выбрать
        </Button>
      </ModalFooter>
    </Modal>
  )
}
