import React from 'react'
import clsx from 'clsx'
import { AdditionalProduct, CartAdditionalProductLine } from './CartAdditionalProductLine'
import { useDispatch, useSelector } from 'react-redux'
import { changeService } from '../../redux/actions/cart'
import { getServices } from '../../redux/selectors/cart'
import { AdditionalPanelSwitcher } from '../Layout/AdditionalPanelSwitcher'

type Props = {
  isUpdateRequestInProgress: boolean
}

export function CartAdditionalServices({ isUpdateRequestInProgress }: Props) {
  const additionalServices = (useSelector(getServices) ?? []) as AdditionalProduct[]
  const dispatch = useDispatch()
  const additionalProductTitle = 'Дополнительные сервисы'

  const onChangeCount = (serviceSku: string, qty: number) => {
    const checked = qty > 0
    dispatch(changeService({ sku: serviceSku, qty, checked }))
  }

  return (
    <AdditionalPanelSwitcher
      additionalPanelTitle={additionalProductTitle}
      blockClassName="cart-totals services"
      titleClassName={clsx('cursor-pointer mx-2 font-weight-bold pt-3')}
    >
      <div className="cart-product-list">
        {additionalServices.map((item) => {
          return (
            <CartAdditionalProductLine
              key={item.sku}
              item={item}
              isDisabled={isUpdateRequestInProgress}
              onChangeCount={(value: number) => {
                onChangeCount(item.sku, value)
              }}
            />
          )
        })}
      </div>
    </AdditionalPanelSwitcher>
  )
}
