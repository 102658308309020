export const CUSTOMER_WAIT_STATE = 'WaitState'
export const CUSTOMER_ERROR_STATE = 'Error'
export const CUSTOMER_DEFAULT_STATE = 'Default'
export const CUSTOMER_UPDATED_STATE = 'Updated'
export const CUSTOMER_CREATED_STATE = 'Created'
export const CUSTOMER_NEED_VERIFY_STATE = 'VerificationRequested'
export const CUSTOMER_RELOADED_STATE = 'Reloaded'

// TODO refactoring for constants
export const STATUS_CHANGED_EXTERNAL = 200
export const STATUS_SUCCESS = 201
