import { isSentryEnable } from '../../../helpers/isSentryEnable'
import * as Sentry from '@sentry/react'

export type SentryMessageTags = {
  shopName: string
  shopCode: string
  terminalId: number
}

export const captureMessage = (tags: SentryMessageTags) => {
  if (isSentryEnable()) {
    Sentry.captureMessage('Transaction was ended with success', {
      tags,
    })
  }
}

export const captureException = (tags: SentryMessageTags) => {
  if (isSentryEnable()) {
    Sentry.captureException('Transaction was ended with error', {
      tags,
    })
  }
}
