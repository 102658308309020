import { createSelector } from 'reselect'
import { AppState } from '../../../model'
import { getPriceFromCache } from '../thunks'
import { Category, Level } from '../model/dto'

const state = (state: AppState) => state.itemConstructor

export const parametersTreeSelector = createSelector(state, ({ parametersTree }) => parametersTree)

export const levelsSelector = createSelector(state, ({ steps }) => {
  if (steps[1] && steps[1].entity_type === 'category') {
    const levelsStep = steps[1] as Category
    if (levelsStep.levels) {
      return levelsStep.levels.reduce<Record<string, Pick<Level, 'action_label' | 'button_type'>>>(
        (acc, value) => {
          if (value.action_label) {
            acc[value.level] = {
              action_label: value.action_label,
              button_type: value.button_type,
            }
          }
          return acc
        },
        {},
      )
    }
  }
})

export const currentStepIndexSelector = createSelector(
  state,
  ({ currentStepIndex }) => currentStepIndex,
)

export const stepsSelector = createSelector(state, ({ steps }) => steps)

export const stepsDataSelector = createSelector(state, ({ stepsData }) => stepsData)

export const parametersDataSelector = createSelector(state, ({ parametersData }) => parametersData)

export const priceSelector = createSelector(state, ({ parametersData, priceCache }) =>
  getPriceFromCache(parametersData, priceCache),
)

export const isNextStepButtonActiveSelector = createSelector(
  state,
  ({ currentStepIndex, steps, stepsData }) => stepsData.has(steps[currentStepIndex]),
)

export const isPreviousStepButtonActiveSelector = createSelector(
  state,
  ({ currentStepIndex }) => currentStepIndex !== 0,
)

export const isSummaryActiveSelector = createSelector(
  state,
  ({ parametersTree, parametersData }) => {
    if (!parametersTree) return false

    const optionsLength = parametersTree.configurable_attribute_groups.reduce((acc, val) => {
      return acc + val.configurable_attributes.length
    }, 0)

    return optionsLength === parametersData.size
  },
)

export const waitingItemSelector = createSelector(state, ({ parametersTree, parametersData }) => {
  if (parametersData && parametersTree) {
    return {
      name: parametersTree.name,
      price: null,
      special_price: null,
      master_product_name: parametersTree.name,
      master_product_sku: parametersTree.sku,
      configurable_attributes: [...parametersData].map(([key, value]) => ({
        code: key.attribute_code,
        value: value.value,
        attribute_id: String(key.attribute_id),
        option_id: String(value.option_id),
      })),
    }
  }
})
