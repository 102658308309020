import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import clsx from 'clsx'

export function TableList({
  items,
  isTitleBold = true,
  titleColWidth = 6,
  contentColWidth = 6,
  titleColumnClass,
  contentColumnClass,
}) {
  return items
    .filter(({ content }) => Boolean(content))
    .map(({ title, content, id, contentClass }) => {
      return (
        <Row key={id ?? title} className="mb-2">
          <Col xs={titleColWidth} className={clsx({ [titleColumnClass]: titleColumnClass })}>
            <div>{isTitleBold ? <b>{title}</b> : title}</div>
          </Col>
          <Col xs={contentColWidth} className={clsx({ [contentColumnClass]: contentColumnClass })}>
            <div className={clsx({ [contentClass]: contentClass })}>{content}</div>
          </Col>
        </Row>
      )
    })
}

TableList.propTypes = {
  isTitleBold: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      content: PropTypes.node,
      contentClass: PropTypes.string,
    }),
  ).isRequired,
  titleColWidth: PropTypes.number,
  contentColWidth: PropTypes.number,
  titleColumnClass: PropTypes.string,
  contentColumnClass: PropTypes.string,
}
