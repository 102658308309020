import { api } from './api'
import { useQuery } from 'react-query'

const CUSTOMER_KEY = 'CUSTOMER'

export const fetchCustomerById = (id) =>
  api.get({ path: `/customer/${id}/version`, requireBody: true, withStatusCode: true })

export const fetchAddressesByCustomerId = (customerId) =>
  api.get({ path: `/customer/${customerId}/shippingAddresses`, requireBody: false })

export const fetchBonusBalanceByCustomerId = (customerId) =>
  api.get({ path: `/pos/customer/${customerId}/bonusBalance`, requireBody: true })

export function useCustomerQuery(id) {
  return useQuery(id && [CUSTOMER_KEY, id], () => fetchCustomerById(id))
}

export const invalidateCustomerById = (id) =>
  api.put({ path: `/customer/${id}/version/invalidation`, requireBody: true, withStatusCode: true })

export const fetchUpdateCustomer = (customerVersion, appId) =>
  api.post({
    path: `/customer/${customerVersion.customer_id}/version`,
    data: { customerVersion },
    appId,
    requireBody: true,
    withStatusCode: true,
    correlationId: customerVersion.correlationId,
  })

export const fetchCreateCustomer = (customerVersion, appId) =>
  api.post({
    path: '/customer/version/',
    data: { customerVersion },
    appId,
    requireBody: true,
    withStatusCode: true,
  })

export const getCustomerRelationTypes = () =>
  api.get({ path: '/pos/customer/contacts/types', requireBody: true })

export const addToContact = (contact, appId) =>
  api.post({
    path: `/pos/customer/contacts`,
    data: { contact },
    appId,
  })

export const addAddress = (address, appId) => {
  return api.post({
    path: `/customer/address/create`,
    data: { address },
    appId,
  })
}

export const updateAddress = (id, address, appId) => {
  return api.post({
    path: `/customer/address/${id}/update`,
    data: { address },
    appId,
  })
}

export const deleteAddress = (id, appId) => {
  api.del({
    path: `/customer/address/${id}/delete`,
    data: {},
    appId,
  })
}

export function useGetCustomerQuery(id) {
  return useQuery(id && [CUSTOMER_KEY, id], () => fetchCustomerById(id), { enabled: false })
}
