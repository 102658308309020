import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { formatPriceRu } from '../../../../helpers/currency'
import { SearchOrderItem } from '../../../../api/dto'
import { formatTime } from '../../../../helpers/time'
import { TextBadge } from '../../../Layout/TextBadge/TextBadge'
import { searchHelper } from '../../../../helpers'
import { getQuerySelector } from '../../../../redux/slices/search/selectors'

interface Props {
  items: SearchOrderItem[]
}

export const OrdersTab: FC<Props> = ({ items }) => {
  const history = useHistory()
  const query = useSelector(getQuerySelector)

  if (!items.length) {
    return null
  }

  const handleClick = (item: SearchOrderItem) => {
    history.push(`/order/${item.id}`)
  }

  return (
    <div className="table-responsive">
      <table className="table orders">
        <thead>
          <tr className="d-flex">
            <th className="id border-dark">Номер заказа</th>
            <th className="date-1 border-dark">Дата оформления</th>
            <th className="fullname border-dark">Клиент</th>
            <th className="items-length border-dark">Кол-во позиций</th>
            <th className="grand-total border-dark">Стоимость</th>
            <th className="date-2 border-dark">Дата исполнения</th>
            <th className="status border-dark">Статус</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr className="d-flex cursor-pointer" key={item.id} onClick={() => handleClick(item)}>
              <td className="id border-dark">
                <div>{searchHelper.markMatch(item.quote_id, query)}</div>
              </td>
              <td className="date-1 border-dark">{formatTime(new Date(item.created_at))}</td>
              <td className="fullname border-dark">
                <div>{searchHelper.markMatch(item.fullname, query)}</div>
              </td>
              <td className="items-length border-dark">{item.items?.length}</td>
              <td className="grand-total border-dark">{formatPriceRu(item.grand_total)}</td>
              <td className="date-2 border-dark">
                <span>{item.due_date ? formatTime(new Date(item.due_date)) : '-'}</span>
              </td>
              <td className="status pl-2 pr-2 border-dark">
                <TextBadge color={badgeColorMap[item.state]}>{item.status_label}</TextBadge>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const badgeColorMap: Record<SearchOrderItem['state'], 'primary' | 'dark' | 'info'> = {
  new: 'primary',
  processing: 'primary',
  canceled: 'dark',
  complete: 'info',
}
