import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Carousel } from '../../../components'
import { productShape } from '../../../constants'
import { getMainOffer } from '../../../redux/selectors/product'
import { useSelector } from 'react-redux'

function DescriptionTab({ item }) {
  const mainOffer = useSelector(getMainOffer)

  return (
    <div className="pr-3 w-100">
      <div style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: item.description }} />
      {mainOffer?.products?.length > 0 && (
        <div>
          <div className="offer-name my-2">{mainOffer.title}</div>
          <Carousel mini>
            {mainOffer.products.map((item, index) => (
              <div key={index}>
                <img src={item.image_url} alt={item.title} />
                <div className="product-name">{item.title}</div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
      {item.related_categories?.length > 0 && (
        <div>
          <div className="offer-name mb-2 mt-3">Сопутствующие товары</div>
          <Carousel mini>
            {item.related_categories.map((item, index) => (
              <div key={index}>
                <Link to={`/product/${item.sku}`}>
                  <img src={item.image_url} alt="" />
                  <div className="product-name">{item.name}</div>
                </Link>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  )
}

export default DescriptionTab

DescriptionTab.propTypes = {
  item: PropTypes.shape(productShape),
}
