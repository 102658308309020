import React, { ChangeEventHandler, FC, LegacyRef } from 'react'

export interface Props {
  checked?: boolean
  disabled?: boolean
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  inputClassName?: string
  wrapperClassName?: string
  labelClassName?: string
  labelName?: string
  innerRef?: LegacyRef<HTMLInputElement>
}

const Checkbox: FC<Props> = ({
  checked,
  name,
  onChange,
  inputClassName = 'bg-primary',
  wrapperClassName,
  labelClassName,
  labelName,
  disabled = false,
  innerRef,
}) => {
  return (
    <label className={`input-checkbox-container ${wrapperClassName}`}>
      <span className={labelClassName}>{labelName}</span>
      <input
        ref={innerRef}
        type="checkbox"
        checked={checked}
        name={name}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
        className={inputClassName}
        disabled={disabled}
      />
      <span className="checkmark" />
    </label>
  )
}

export default Checkbox
