import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../model'
import { ParametersData } from '../../slices/constructor/model/'
import { thunks } from '../../redusers'
import { debounce } from 'lodash-es'
import { AttributesObject } from '../../slices/product/model'
import { isEqual } from 'lodash'
import { ProductResult } from '../../model/ProductResult'

export const refreshPrice = createAsyncThunk<void, void, AppThunkApiConfig>(
  'product/refreshPrice',
  debounce<AsyncThunkPayloadCreator<void, void, AppThunkApiConfig>>(
    async (_, { dispatch, getState }) => {
      const state = getState().product

      if (
        state.attributesObject &&
        !isAllOptionsSettled(state.attributesObject, state.parametersData)
      ) {
        return
      }

      let price = getItemFromMap(state.attributesObject, state.priceCache)
      if (!price) {
        dispatch(
          thunks.product.fetchPrice({
            parametersData: state.parametersData,
            attributesObject: state.attributesObject,
            result: state.result as ProductResult,
          }),
        )
      }
    },
    3000,
  ),
)

const isAllOptionsSettled = (
  attributesObject: AttributesObject,
  parametersData: ParametersData,
) => {
  return attributesObject.attributes?.length === parametersData.size
}

export const getItemFromMap = (
  attributesObject: AttributesObject,
  cache: Map<AttributesObject, any>,
) => {
  for (const [key, value] of [...cache]) {
    if (isEqual(key, attributesObject)) {
      return value
    }
  }
}
