import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ProductItem } from '../../../../components'
import { productShape } from '../../../../constants'
import { useHistory } from 'react-router-dom'

function ProductsTab({ items, query }) {
  const history = useHistory()

  if (!items.length) {
    return null
  }

  return (
    <>
      {items.map((item) => (
        <ProductItem
          onClick={() => history.push(`/product/${item.sku}`)}
          key={item.id}
          item={item}
          query={query}
          className="col-4"
        />
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  query: state.search.query,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTab)

ProductsTab.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(productShape)).isRequired,
  query: PropTypes.string.isRequired,
}
