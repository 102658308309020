import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Loader, SearchSuggestions } from '../../components'
import useOutsideClick from '../../hooks/useOutsideClick'
import { debounce } from '../../helpers'
import { actions, thunks } from '../../redux/redusers'
import { groupSearchCriteria } from '../../screens/Search'

function SearchForm({
  query,
  page,
  isLoading,
  setSearchQuery,
  getSearchResult,
  clearSearch,
  setIsInStock,
}) {
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)
  const [isFieldFocused, setIsFieldFocused] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const suggestions = useRef(null)

  useOutsideClick(suggestions, () => {
    if (!isFieldFocused) {
      setIsSuggestionsOpen(false)
    }
  })

  useEffect(() => {
    if (location.search || location.pathname !== '/') {
      setIsSuggestionsOpen(false)
    }
  }, [location])

  const clear = () => {
    if (query.length > 0) {
      clearSearch()
      history.push('/')
    }
  }

  const handleChange = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    if (query.length > 0) {
      setIsInStock(false)
      search(query, page)
    }
  }

  const search = useCallback(
    debounce(
      (query, page) =>
        getSearchResult({ query, page, groupSearchCriteria: groupSearchCriteria(false) }),
      500,
    ),
    [getSearchResult],
  )

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsSuggestionsOpen(false)
      history.push(`/?q=${query}`)
    }
  }

  return (
    <div className="search-form form-row">
      <div className="col-7 d-flex flex-column">
        <div className="position-relative">
          <input
            type="text"
            value={query}
            onChange={handleChange}
            onFocus={() => {
              setIsFieldFocused(true)
              setIsSuggestionsOpen(true)
            }}
            onBlur={() => setIsFieldFocused(false)}
            onKeyDown={handleKeyDown}
            className="form-control bg-secondary border-dark"
            placeholder="Найти продукты, клиентов, заказы..."
          />
          <span className="search-icon" onClick={clear}>
            {query.length > 0 ? (
              <img src={require('../../images/icons/clear-icon.svg')} alt="очистить поиск" />
            ) : (
              <span className="lnr lnr-magnifier" />
            )}
          </span>
        </div>
        {isLoading && !location.search && <Loader />}
      </div>
      {/*<div className="col ml-4">*/}
      {/*  <button className="btn btn-primary btn-lg form-control">Отсканировать QR код</button>*/}
      {/*</div>*/}
      <SearchSuggestions forwardedRef={suggestions} isOpen={isSuggestionsOpen && !isLoading} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  query: state.search.query,
  page: state.search.page,
  isLoading: state.search.isLoading,
})

const mapDispatchToProps = {
  setSearchQuery: actions.search.setSearchQuery,
  clearSearch: actions.search.clearSearch,
  getSearchResult: thunks.search.getSearchResult,
  setIsInStock: actions.search.setIsInStock,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm)

SearchForm.propTypes = {
  query: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  getSearchResult: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
}
