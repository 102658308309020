import React, { useRef, useState } from 'react'
import { Popover, PopoverProps } from 'reactstrap'
import { Placement } from 'popper.js'

export interface Props {
  popoverEnabled?: boolean
  popoverText?: string
  placement?: Placement
  trigger?: string
  children: React.ReactNode
}

export const PopoverWrapper = ({
  popoverEnabled,
  popoverText,
  placement = 'top-start',
  trigger = 'click hover blur',
  children,
  ...props
}: Props & Omit<PopoverProps, 'target'>) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const checkboxRef = useRef<HTMLDivElement>(null)

  const toggle = () => setTooltipOpen((prev) => !prev && !!popoverEnabled)
  return (
    <div className="d-inline-block w-100">
      <div ref={checkboxRef} className="d-inline-block w-100">
        {children}
      </div>
      <Popover
        placement={placement}
        isOpen={tooltipOpen && !!popoverText}
        target={checkboxRef}
        toggle={toggle}
        trigger={trigger}
        {...props}
      >
        <div className="text-danger p-3">{popoverText}</div>
      </Popover>
    </div>
  )
}
