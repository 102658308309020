import React, { FC, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Button } from '../../../../../components/Layout/Button/Button'
import { AttributeProps } from '../../../model'
import { SwatchModal } from './SwatchModal'
import { SwatchElement } from './SwatchElement'

export const SwatchAttribute: FC<AttributeProps> = ({ attribute, data, handler }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const selectedOptionId = attribute.options.find((item) => item === data.get(attribute))?.option_id

  const handleToggleModal = () => setIsModalOpen(!isModalOpen)

  const handleSubmitModal = (optionId: number) => {
    const selectedAttribute = attribute.options.find((item) => item.option_id === optionId)
    if (selectedAttribute) {
      handler(attribute, selectedAttribute)
    }
  }

  const selectedOption = data.get(attribute)

  return (
    <>
      <Container className="swatch-attribute" fluid>
        <Row>
          <Col className="pl-0 mb-3">
            <p className="mb-0">{attribute.frontend_label}</p>
          </Col>
        </Row>
        <Row>
          <Col className="p-0 mr-auto d-flex align-items-center">
            {selectedOption ? (
              <SwatchElement option={selectedOption} size="small" />
            ) : (
              <p className="mb-0 text-muted">Не выбрано</p>
            )}
          </Col>
          <Col xs="4" className="pl-0">
            {selectedOption ? (
              <Button
                color="outline-primary-grey"
                className="height-40"
                onClick={handleToggleModal}
                block
              >
                Изменить
              </Button>
            ) : (
              <Button color="primary" className="height-40" onClick={handleToggleModal} block>
                Выбрать
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      <SwatchModal
        open={isModalOpen}
        toggle={handleToggleModal}
        options={attribute.options}
        onSubmit={handleSubmitModal}
        selectedOptionId={selectedOptionId}
        title={attribute.frontend_label}
      />
    </>
  )
}
