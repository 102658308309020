import React from 'react'
import icon from '../icons/lnr-sync.svg'

type Props = {
  isPayment?: boolean
}

export function Loading({ isPayment = true }: Props) {
  return (
    <div className="askona-widget-payment__notify big">
      <div className="askona-widget-payment__title">Операция выполняется</div>
      <div className="icon spin">
        <img src={icon} alt="loading" />
      </div>
      <div>
        {isPayment ? 'Операция выполняется.' : 'Регистрация платежа в системе'}
        <br /> Пожалуйста, подождите несколько секунд.
      </div>
    </div>
  )
}
