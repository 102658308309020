import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { actions } from '../../../redusers'
import { HandleStepsDataPayload } from '../model'

export const handleStepsData = createAsyncThunk<void, HandleStepsDataPayload, AppThunkApiConfig>(
  'constructor/handleStepsData',
  async (payload, { dispatch, getState }) => {
    let state = getState().itemConstructor
    const { entity, entityValue } = payload

    const nextStep = state.steps[state.currentStepIndex + 1]
    if (nextStep && entityValue !== nextStep) {
      await dispatch(actions.constructor.clearInvalidSteps(state.currentStepIndex))
      state = getState().itemConstructor
    }

    const stepsData = new Map(state.stepsData)
    stepsData.set(entity, entityValue)

    dispatch(actions.constructor.setStepsData(stepsData))
  },
)
