import * as searchHelper from './search'
import * as productHelper from './product'
import * as customerHelper from './customer'
import { phoneRegexp } from '../constants'

export { searchHelper, productHelper, customerHelper }

export * from './getCorrelationId'

export const divideArrayByProperty = (array, propertyName) =>
  array.reduce((result, item) => {
    if (!result?.[item?.[propertyName]]) {
      result[item[propertyName]] = []
    }

    result[item[propertyName]].push(item)
    return result
  }, {})

export const uniqueArrayOfObjects = (array, uniqueField) => {
  const uniqueFields = []
  const result = []
  for (const value of array) {
    if (!uniqueFields.includes(value[uniqueField])) {
      uniqueFields.push(value[uniqueField])
      result.push(value)
    }
  }
  return result
}
/**
 * @param {number} number
 * @param {array} [words = ["яблоко", "яблока", "яблок"]]
 * @return {string}
 */
export const numberDeclension = (number, words) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '')

  // Check if the input is of correct
  const match = cleaned.match(phoneRegexp)

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? '+7 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('')
  }

  return ''
}

/**
 * Generates eight-symbols id, i.e. 73cc88c5
 * @returns {string}
 */
export const randomId = () => Math.random().toString(16).substr(2, 8)

/**
 * @param func
 * @param wait
 * @returns {function(...[*]): void}
 */
export const debounce = (func, wait) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

/**
 * Проверка Map на идентичность ссылок ключей и их значений
 * @param {Map} firstMap
 * @param {Map} secondMap
 * @returns {boolean}
 */
export const isMapsEqual = (firstMap, secondMap) => {
  if (firstMap.size !== secondMap.size) return false

  for (const [value] of firstMap) {
    if (firstMap.get(value) !== secondMap.get(value)) return false
  }

  return true
}

export const submitForm = (path, params, method = 'post') => {
  const form = document.createElement('form')
  form.method = method
  form.action = path

  for (const [key, value] of Object.entries(params)) {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = key
    hiddenField.value = value

    form.appendChild(hiddenField)
  }
  document.body.appendChild(form)
  form.submit()
}
