import React, { FC } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Button } from '../../../components/Layout/Button/Button'
import { StepButton } from '../model'
import clsx from 'clsx'

interface Props {
  buttons: StepButton[]
}

export const StepButtons: FC<Props> = ({ buttons }) => {
  const buttonsComponents = buttons.map((item, index, { length }) => (
    <Col key={`${item.label}-${index}`} className={clsx('pl-0', index + 1 === length && 'pr-0')}>
      <Button
        className="height-40"
        onClick={item.action}
        disabled={!item.action || item.active}
        color={!item.action ? undefined : item.active ? 'outline-primary' : 'primary'}
        size="lg"
        block
      >
        {`${index + 1}. ${item.label}`}
      </Button>
    </Col>
  ))

  return (
    <Container className="constructor__step-buttons" fluid>
      <Row>{buttonsComponents}</Row>
    </Container>
  )
}
