import { STATE_REGISTRATION, STATE_VERIFICATION } from '../constants/customerStateName'
import { format, isValid } from 'date-fns'

export const customerMapping = ({
  customer_id,
  birth_date,
  email,
  is_fake_email,
  given_name,
  surname,
  middle_name,
  phone,
  extra_phone,
  version_id,
  subscriptions,
  status,
}) => ({
  customer_id,
  birth_date:
    birth_date && isValid(new Date(birth_date))
      ? format(new Date(birth_date), 'yyyy-MM-dd')
      : birth_date,
  email: is_fake_email ? '' : email,
  given_name: given_name ?? '',
  surname: surname ?? '',
  middle_name: middle_name ?? '',
  phone: phone?.replace(/\D/g, ''),
  extra_phone: extra_phone?.replace(/\D/g, '') || '',
  version_id: version_id || null,
  subscriptions,
  status,
})

export const searchToCustomerMapping = ({
  id,
  firstname,
  lastname,
  middlename,
  subscriptions,
  dob,
  ...data
}) => ({
  customer_id: id,
  birth_date: dob,
  given_name: firstname,
  surname: lastname,
  middle_name: middlename,
  ...data,
})

const getFiasAddressId = ({
  house: fiasHouseId,
  street: fiasStreetId,
  city: fiasCityId,
  fiasAddressId,
}) => fiasHouseId || fiasStreetId || fiasCityId || fiasAddressId || ''

export const addressMapping = ({ address, customer }) => {
  const mappedAddress = {
    default_billing: address.is_default,
    customer_id: customer.customer_id,
    region: address.region,
    country_id: 'RU',
    street: [address.street],
    telephone: customer.phone?.replace(/\D/g, ''),
    postcode: address.zip,
    city: address.city,
    given_name: customer.given_name,
    surname: customer.surname,
    address_attributes: [
      {
        attribute_code: 'fias_address_id',
        value: getFiasAddressId({
          ...(address?.fias || {}),
          fiasAddressId: address?.fias_address_id,
        }),
      },
      {
        attribute_code: 'fias_region_id',
        value: address?.fias?.region || address?.fias_region_id || '',
      },
      {
        attribute_code: 'fias_city_id',
        value: address?.fias?.city || address?.fias_city_id || '',
      },
      {
        attribute_code: 'fias_settlement_id',
        value: address?.fias?.city || address?.fias_city_id || '',
      },
      {
        attribute_code: 'fias_street_id',
        value: address?.fias?.street || address?.fias_street_id || '',
      },
      {
        attribute_code: 'house',
        value: address.house,
      },
      {
        attribute_code: 'block',
        value: [address.block, address.building].join('/'),
      },
      {
        attribute_code: 'apartment',
        value: address.apartment,
      },
      {
        attribute_code: 'floor',
        value: address.floor,
      },
      {
        attribute_code: 'lift_availability',
        value: Number(address.lift_availability) || 0,
      },
      {
        attribute_code: 'comment',
        value: address.comment,
      },
    ],
  }
  if (address.intercom_code) {
    mappedAddress.address_attributes.push({
      attribute_code: 'intercom_code',
      value: address.intercom_code,
    })
  }
  if (address.entrance_number) {
    mappedAddress.address_attributes.push({
      attribute_code: 'entrance_number',
      value: address.entrance_number,
    })
  }
  return mappedAddress
}

export const registrationInLoyaltyProgramMapping = {
  contact_state_name: STATE_REGISTRATION,
}

export const smsCodeInLoyaltyProgramMapping = (code) => ({
  contact_state_name: STATE_VERIFICATION,
  contactState: {
    type: 'sms',
    message: code, // sms код
  },
})

export const getFullName = (customer) =>
  `${customer?.surname || ''} ${customer?.given_name || ''} ${customer?.middle_name || ''}`

export const getBirthDate = (customer) => {
  if (!customer?.birth_date || customer.birth_date === '0000-00-00 00:00:00') {
    return null
  }
  const birthDate = new Date(customer?.birth_date)
  return birthDate ? birthDate : null
}
