/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup'
import { phoneRegexpWithPlus, emailRegexp, phoneOrEmptyStringRegexp } from '../constants'

yup.setLocale({
  mixed: {
    required: 'Значение обязательно',
  },
  string: {
    min: 'Минимальная длина ${min}',
    max: 'Максимальная длина ${min}',
  },
})

yup.addMethod(yup.string, 'price', function () {
  return this.matches(/^\d*\.?\d*$/, 'Некорректная цена')
})

yup.addMethod(yup.string, 'phone', function () {
  return this.matches(phoneRegexpWithPlus, 'Некорректный телефон')
})

yup.addMethod(yup.string, 'email', function () {
  return this.matches(emailRegexp, 'Некорректный e-mail')
})

yup.addMethod(yup.string, 'phoneOrEmpty', function () {
  return this.matches(phoneOrEmptyStringRegexp, 'Некорректный телефон')
})

yup.addMethod(yup.string, 'or', function (schemas, msg) {
  return this.test({
    name: 'or',
    message: msg,
    test: (value) => {
      if (Array.isArray(schemas) && schemas.length > 1) {
        return schemas.map((schema) => schema.isValidSync(value)).some((res) => res)
      } else {
        throw new TypeError('Schemas is not correct array schema')
      }
    },
    exclusive: false,
  })
})
export { yup }
