import React from 'react'
import PropTypes from 'prop-types'
import { NotFound } from './NotFound'
import Withdrawal from './Withdrawal'

export default function AskonaProgram({
  mobilePhone,
  bonusInfo,
  onRegister,
  onWithdraw,
  onConfirmLoyaltyProgram,
  onPaymentChange,
}) {
  const {
    bonusBalance: { availableBonusAmount, minAvailableBonusAmount, bonusBalance },
    isNotFound,
  } = bonusInfo

  if (isNotFound) {
    return <NotFound mobilePhone={mobilePhone} onRegister={onRegister} />
  }

  return (
    <>
      {mobilePhone && (
        <div className="askonaWidget-loyaltyProgram-phone">
          <div className="text-thin">Номер участника</div>
          <div>{mobilePhone}</div>
        </div>
      )}
      {bonusBalance > 0 && (
        <div className="askonaWidget-loyaltyProgram-balance">
          <div className="askonaWidget-loyaltyProgram-balance-bonus">{bonusBalance}</div>
          <div className="text-thin">АКТИВНЫЙ БАЛАНС</div>
        </div>
      )}
      {availableBonusAmount >= 0 && (
        <div className="askonaWidget-loyaltyProgram-balance">
          <div className="askonaWidget-loyaltyProgram-balance-amount">{availableBonusAmount}</div>
          <div className="text-thin">БОНУСОВ ДОСТУПНО ДЛЯ СПИСАНИЯ</div>
        </div>
      )}
      <Withdrawal
        label="Введите количество бонусов, которое требуется списать"
        onWithdraw={onWithdraw}
        onConfirmLoyaltyProgram={onConfirmLoyaltyProgram}
        onPaymentChange={onPaymentChange}
        isSumValid={(value) => value <= availableBonusAmount && value >= minAvailableBonusAmount}
      />
    </>
  )
}

AskonaProgram.propTypes = {
  mobilePhone: PropTypes.string,
  bonusInfo: PropTypes.shape({
    bonusBalance: PropTypes.shape({
      availableBonusAmount: PropTypes.number,
      bonusBalance: PropTypes.number,
      minAvailableBonusAmount: PropTypes.number,
    }),
    isNotFound: PropTypes.bool,
  }),
  onRegister: PropTypes.func,
  onPaymentChange: PropTypes.func,
  onWithdraw: PropTypes.func,
  onConfirmLoyaltyProgram: PropTypes.func,
}
