import PropTypes from 'prop-types'

export const addressShape = {
  id: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  region: PropTypes.string,
  city: PropTypes.string,
  street: PropTypes.string,
  house: PropTypes.string,
  block: PropTypes.string,
  apartment: PropTypes.string,
  floor: PropTypes.string,
  lift_availability: PropTypes.number,
  intercom_code: PropTypes.number,
  entrance_number: PropTypes.number,
  comment: PropTypes.string,
  is_default: PropTypes.bool,
}

export const bonusBalanceShape = {
  id: PropTypes.string,
  current_amount: PropTypes.string,
  due_date: PropTypes.string,
  reg_date: PropTypes.string,
  total_amount: PropTypes.string,
}

export const customerShape = {
  customer_id: PropTypes.number,
  version_id: PropTypes.number,
  surname: PropTypes.string,
  given_name: PropTypes.string,
  middle_name: PropTypes.string,
  birth_date: PropTypes.string,
  status: PropTypes.oneOf([
    'Подписка',
    'Покупатель',
    'Потенциальный клиент',
    'Участник Программы Лояльности',
  ]),
  contacts: PropTypes.array,
  isConfirmedPhone: PropTypes.oneOf([0, 1]),
  isConfirmedEmail: PropTypes.oneOf([0, 1]),
  phone: PropTypes.string,
  extra_phone: PropTypes.string,
  email: PropTypes.string,
  is_fake_email: PropTypes.bool,
  subscriptions: PropTypes.shape({
    email: PropTypes.bool,
    phone: PropTypes.bool,
    sms: PropTypes.bool,
  }),
  addresses: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  bonus_balance: PropTypes.shape(bonusBalanceShape),
}

export const productItemShape = {
  id: PropTypes.string,
  title: PropTypes.string,
  image_url: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  price: PropTypes.number,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

const simpleShape = {
  additional_info: PropTypes.string,
  color: PropTypes.string,
  comment: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  size: PropTypes.string,
  sku: PropTypes.string,
  special_price: PropTypes.number,
  stocks: PropTypes.shape({
    outlet: PropTypes.number,
    warehouse: PropTypes.number,
    central_warehouse: PropTypes.number,
    outlets: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
  }),
}

export const productShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  sku: PropTypes.string,
  /**
   * Имя и артикул товара всегда должны браться с основного
   * поля выставляются в редьюсере
   */
  masterProductName: PropTypes.string,
  masterProductSku: PropTypes.string,

  breadcrumbs: PropTypes.arrayOf(PropTypes.string),
  material: PropTypes.arrayOf(
    PropTypes.shape({
      collection: PropTypes.string,
      name: PropTypes.string,
      shortname: PropTypes.string,
    }),
  ),
  stickers: PropTypes.arrayOf(PropTypes.string),
  simples: PropTypes.arrayOf(PropTypes.shape(simpleShape)),
  price: PropTypes.number,
  special_price: PropTypes.number,
  profit: PropTypes.number,
  bonuses: PropTypes.number,
  instalment_payment: PropTypes.shape({
    available: PropTypes.bool,
    instalment: PropTypes.number,
    term: PropTypes.number,
  }),
  special_offer: PropTypes.string,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
      priority: PropTypes.number,
      condition: PropTypes.string,
    }),
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.number,
    }),
  ),
  delivery: PropTypes.shape({
    cost: PropTypes.number,
    term: PropTypes.string,
  }),
  description: PropTypes.string,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  media: PropTypes.shape({
    photogallery: PropTypes.arrayOf(PropTypes.string),
    video: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  similar_products: PropTypes.arrayOf(PropTypes.shape(productItemShape)),
  related_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      image_url: PropTypes.string,
    }),
  ),
}

export const productVariantShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  additionalInfo: PropTypes.string,
  comment: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      simpleId: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selected: PropTypes.string,
}

// фронт ожидает такой формат ответ
// сейчас будем и на бэк отдавать такое же полотно - а в будущем сократим запрос до нужных полей
export const cart = {
  count: PropTypes.number, // количество позиций
  price: PropTypes.number, // общая цена без скидок
  service_price: PropTypes.number, // общая стоимость сервисов
  delivery_price: PropTypes.number, // оющая стоимость доставки
  promo_price: PropTypes.number, // ожидает реализации - общая скидка по промокоду
  total: PropTypes.number, // конечная сумма с учетом всех скидок доставок итд
  offers: PropTypes.arrayOf(PropTypes.shape), // ожидает реализации - скидки по акциям

  delivery_address: PropTypes.string, // 'Guid'
  cartId: PropTypes.string,
  items: [
    // симплы целиком
    /**
     *    (смешанные с данными мастер продукта) возможно вместо обьединения стоит в ответе расширить симпл
     *    полем (product | masterProduct) в котором будет мастер продукт
     */
    {
      ...productShape,
      ...simpleShape,
      qty: PropTypes.number, // количество
      deliveryType: PropTypes.string, // тип доставки
      services: [
        {
          ...productShape.services,
          checked: true, // сервис выбран
        },
      ],
      masterProductName: PropTypes.string,
      masterProductSku: PropTypes.string,
    },
  ],
}
