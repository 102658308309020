import React, { FC } from 'react'
import DatePickerBase, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import { Label } from 'reactstrap'
import ru from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'
import MaskedInput from 'react-text-mask'
import { dobMask } from '../../constants'

registerLocale('ru', ru)

export interface Props extends ReactDatePickerProps {
  label?: string
  required?: boolean
  errorMessage?: string
  name?: string
}

const DatePicker: FC<Props> = ({
  className,
  selected,
  dateFormat = 'dd.MM.yyyy',
  onChange = () => {},
  dropdownMode = 'select',
  popperPlacement = 'bottom-end',
  errorMessage,
  label,
  required = false,
  name,
  ...props
}) => {
  return (
    <div className="date-picker">
      {label && (
        <Label for={name}>
          {label} {required ? <span className="text-danger">*</span> : ''}
        </Label>
      )}
      <DatePickerBase
        {...props}
        className={`${className} form-control`}
        selected={selected}
        onChange={onChange}
        dateFormat={dateFormat}
        dropdownMode={dropdownMode}
        popperPlacement={popperPlacement}
        locale="ru"
        customInput={<MaskedInput mask={dobMask} placeholder="ДД.ММ.ГГГГ" />}
      />
      {errorMessage && <span className="text-danger">{errorMessage}</span>}
    </div>
  )
}

export default DatePicker
