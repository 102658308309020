import React, { ChangeEventHandler, FC, ReactNode } from 'react'

interface Props {
  checked: boolean
  disabled?: boolean
  id?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  inputClassName?: string
  wrapperClassName?: string
  labelClassName?: string
  labelChildren?: ReactNode
}

const Switcher: FC<Props> = ({
  checked,
  id = 'switcher',
  onChange,
  inputClassName,
  wrapperClassName,
  labelClassName,
  labelChildren,
  disabled = false,
}) => {
  return (
    <div className={`custom-control custom-switch ${wrapperClassName}`}>
      <input
        type="checkbox"
        className={`custom-control-input ${inputClassName}`}
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className={`custom-control-label ${labelClassName}`} htmlFor={id}>
        {labelChildren}
      </label>
    </div>
  )
}

export default Switcher
