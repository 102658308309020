import * as actions from '../types'
import {
  fetchCustomerById,
  fetchUpdateCustomer,
  getCustomerRelationTypes,
  fetchCreateCustomer,
  addAddress,
  fetchAddressesByCustomerId,
  fetchBonusBalanceByCustomerId,
  deleteAddress,
  invalidateCustomerById,
} from '../../api/customer'
import { STATE_UPDATE } from '../../constants/customerStateName'
import { omit } from 'lodash-es'

const fetchOrInvalidateCustomerByIdAndFetchAddresses = ({
  apiMethod,
  endAction,
  value,
  dispatch,
}) => {
  apiMethod(value)
    .then(async (data) => {
      const { responseStatus, ...customerData } = data
      if (responseStatus === 200) {
        const bonusBalance = customerData?.is_status_pl
          ? await fetchBonusBalanceByCustomerId(value)
          : {}

        const payload = {
          ...customerData,
          bonus_balance: {
            id: bonusBalance.bonus_balance_id,
            current_amount: bonusBalance.active_balance,
            total_amount: bonusBalance.total_balance,
            due_date: bonusBalance.due_date,
            reg_date: bonusBalance.reg_date,
          },
        }
        try {
          const addressesData = await fetchAddressesByCustomerId(value)
          const { correlationId, correlation_id, ...addresses } = addressesData
          dispatch({
            type: endAction,
            data: {
              ...payload,
              addresses: Object.values(addresses),
            },
          })
        } catch (e) {
          dispatch({
            type: endAction,
            data: { ...payload, addresses: [] },
          })
        }
      } else {
        dispatch({
          type: endAction,
          data: {},
          error: true,
        })
      }
    })
    .catch((e) => {
      console.error(e)
      dispatch({
        type: endAction,
        data: {},
        error: true,
      })
    })
}

export const getById = (value) => (dispatch) => {
  dispatch({ type: actions.beginGetCustomer })

  fetchOrInvalidateCustomerByIdAndFetchAddresses({
    apiMethod: fetchCustomerById,
    endAction: actions.endGetCustomer,
    value,
    dispatch,
  })
}

const createOrUpdate = ({
  dispatch,
  getState,
  value,
  actionBegin,
  actionEnd,
  errorMessage = 'Пожалуйста, проверьте правильность заполнения всех полей.',
}) => {
  dispatch({ type: actionBegin })
  const method = value?.customer_id ? fetchUpdateCustomer : fetchCreateCustomer
  if (!value?.customer_id) {
    value['contact_state_name'] = STATE_UPDATE
  }
  method(value, getState().app.instanceId)
    .then((resp) => {
      const { responseStatus, ...data } = resp
      dispatch({
        type: actionEnd,
        data,
        responseStatus,
      })
      // После успешного апдейта чистим подсказки адреса
      dispatch({ type: actions.clearDadataSuggestions })
    })
    .catch((e) => {
      dispatch({
        type: actionEnd,
        data: {},
        error: e?.message || errorMessage,
      })
    })
}

export const update = (value) => (dispatch, getState) => {
  createOrUpdate({
    dispatch,
    getState,
    value,
    actionBegin: actions.beginUpdateCustomer,
    actionEnd: actions.endUpdateCustomer,
  })
}

export const setDefaultState = () => (dispatch) => {
  dispatch({
    type: actions.setDefaultState,
  })
}

export const create = (value) => (dispatch, getState) => {
  const newValue = omit(value, ['customer_id', 'version_id'])
  createOrUpdate({
    dispatch,
    getState,
    value: newValue,
    actionBegin: actions.beginCreateCustomer,
    actionEnd: actions.endCreateCustomer,
  })
}

export const createCustomerContact = (data, relationType) => async (dispatch, getState) => {
  try {
    const newData = omit(data, ['customer_id', 'version_id'])
    await fetchCreateCustomer(newData, getState().app.instanceId)
  } catch (e) {
    dispatch({
      type: actions.setCartCustomer,
      data: {},
      error: e?.message,
    })
  }
}

export const setCustomer = (data) => (dispatch, getState) => {
  const { addresses, is_status_pl, bonus_balance } = getState().customer.result
  dispatch({
    type: actions.endGetCustomer,
    data: { ...data, addresses: data.addresses ?? addresses ?? [], is_status_pl, bonus_balance },
    error: false,
  })
}

export const resetCustomerEdited = () => (dispatch) => {
  dispatch({
    type: actions.resetCustomerEdited,
  })
}

export const resetVersionMismatch = () => (dispatch) => {
  dispatch({
    type: actions.resetCustomerVersionMismatch,
  })
}

export const registrationInLoyaltyProgram = (value) => (dispatch, getState) => {
  createOrUpdate({
    dispatch,
    getState,
    value,
    actionBegin: actions.beginRegistrationInLoyaltyProgram,
    actionEnd: actions.endRegistrationInLoyaltyProgram,
    errorMessage: 'Не удалось зарегистрироваться в Программе Лояльности.',
  })
}

export const sendSmsCodeInLoyaltyProgram = (value) => (dispatch, getState) => {
  createOrUpdate({
    dispatch,
    getState,
    value,
    actionBegin: actions.beginSendSmsCodeInLoyaltyProgram,
    actionEnd: actions.endSendSmsCodeInLoyaltyProgram,
    errorMessage: 'Не удалось подтверждить регистрацию в Программе Лояльности.',
  })
}

export const getRelationTypes = () => async (dispatch) => {
  try {
    const { correlationId, ...data } = await getCustomerRelationTypes()
    dispatch({
      type: actions.getCustomerRelationTypes,
      data: Object.values(data),
    })
  } catch (e) {
    dispatch({
      type: actions.getCustomerRelationTypes,
      data: [],
    })
  }
}

export const updateCartCustomer = (value) => (dispatch, getState) => {
  const customer = {
    ...value,
    addresses: value.addresses,
  }
  createOrUpdate({
    dispatch,
    getState,
    value: customer,
    actionBegin: actions.beginUpdateCartCustomer,
    actionEnd: actions.endUpdateCartCustomer,
  })
}

export const setRegistrationInLoyaltyProgramTimeoutError = () => (dispatch) => {
  dispatch({
    type: actions.endRegistrationInLoyaltyProgram,
    data: {},
    error: 'Превышено время ожидания ответа. Попробуйте позже.',
  })
}

export const resetLastResponseStatus = () => (dispatch) => {
  dispatch({
    type: actions.resetLastResponseStatus,
  })
}

export const cancelConfirmUpdate = (value) => (dispatch) => {
  dispatch({
    type: actions.cancelConfirmUpdate,
    data: {},
  })
  dispatch({ type: actions.beginGetCustomer })

  fetchOrInvalidateCustomerByIdAndFetchAddresses({
    apiMethod: invalidateCustomerById,
    endAction: actions.endInvalidateCustomer,
    value,
    dispatch,
  })
}

export const addNewAddress = (value) => async (dispatch, getState) => {
  try {
    await addAddress(value)
    const { customer_id } = value
    const addressesData = await fetchAddressesByCustomerId(customer_id)
    const { correlationId, correlation_id, ...addresses } = addressesData
    const customer = getState().customer.result
    dispatch({
      type: actions.endGetCustomer,
      data: { ...customer, addresses: Object.values(addresses) },
      error: false,
    })
  } catch (e) {
    dispatch({
      type: actions.endGetCustomer,
      data: {},
      error: true,
    })
  }
}

export const removeAddress = (value) => async (dispatch, getState) => {
  try {
    await deleteAddress(value)
    let {
      customer: {
        result: { addresses, ...customerData },
      },
    } = getState()
    addresses = addresses.filter(({ id }) => id !== value)
    dispatch({
      type: actions.endGetCustomer,
      data: { ...customerData, addresses: addresses },
      error: false,
    })
  } catch (e) {
    dispatch({
      type: actions.endGetCustomer,
      data: {},
      error: true,
    })
  }
}
