import React, { FC, useCallback, useEffect } from 'react'
import Modal from '../Layout/Modal'
import { Button } from '../Layout/Button/Button'
import Loader from '../Layout/Loader'
import { Form } from '../Layout/form/Form'
import { InputField } from '../Layout/form/InputField'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePostOrderEmailMutation } from '../../api/order'
import { yup } from '../../yup'
import { SuccessCircleCheck } from '../Layout/icons/SuccessCircleCheck'
import { ButtonToolbar } from 'reactstrap'

const schema = yup.object().shape({
  email: yup.string().required().email(),
})

type Props = {
  isOpen: boolean
  toggle: () => void
  closeModal: () => void
  email: string
  orderId: number
}

export const EmailSendModal: FC<Props> = ({ isOpen, toggle, email, orderId, closeModal }) => {
  const { mutate, isSuccess, isLoading, isError, data, reset } = usePostOrderEmailMutation()

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email,
    },
  })

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        closeModal()
        reset()
      }, 1000)
    }
  }, [closeModal, isSuccess, reset])

  const onSubmit = useCallback(
    ({ email }) => {
      mutate({
        order_id: orderId,
        user_email: email,
      })
    },
    [mutate, orderId],
  )

  const handleMessage = useCallback(() => {
    if (isLoading) {
      return <Loader />
    }

    if (isSuccess) {
      return (
        <div>
          <SuccessCircleCheck />
          <h1 className="mt-2">{data?.content ?? 'Сообщение отправлено'}</h1>
        </div>
      )
    }

    return (
      <Form form={form} onSubmit={onSubmit}>
        <InputField required type="text" name="email" />

        {isError && (
          <div className="text-danger mb-2">
            {data?.content ?? 'Ошибка сервера, повторите запрос'}
          </div>
        )}

        <ButtonToolbar className="modal__footer">
          <Button color="primary" className="modal_cancel height-40" onClick={toggle}>
            Перейти в заказ
          </Button>
          <Button type="submit" className="modal_ok height-40" color="primary">
            {isError ? 'Повторить' : 'Отправить'}
          </Button>
        </ButtonToolbar>
      </Form>
    )
  }, [data?.content, form, isError, isSuccess, isLoading, onSubmit, toggle])

  return (
    <Modal
      size="large"
      withIcon={false}
      onToggle={toggle}
      isOpenExternal={isOpen}
      title="Отправить на e-mail"
      message={handleMessage()}
      buttonToolbar={() => <></>}
    />
  )
}
