import React, { useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { cart } from '../../redux/actions'
import clsx from 'clsx'
import { AppState } from '../../redux/model'
import { CartTotalLine } from './CartTotalLine'
import { AdditionalPanelSwitcher } from '../Layout/AdditionalPanelSwitcher'
import classnames from './Cart.module.scss'
import { Spinner } from 'reactstrap'

type Props = ConnectedProps<typeof connector> & {}

function CartTotals({ cart, setCartPromocode, isFetchCustomerInProgress }: Props) {
  const backPromo = useMemo(() => {
    const { promo } = { ...cart }
    if (promo.length > 0) {
      return promo[0].code
    }
    return ''
  }, [cart?.promo])

  const [promocode, setPromocode] = useState(backPromo || '')

  const isPromocodeActive = cart.promo.find(item => (item.code))
  const isPromocodeChanged = cart.promo[0]?.code !== promocode

  useEffect(() => {
    setPromocode(backPromo)
  }, [cart.items, cart.customer, backPromo])
  if (!cart.total && !cart.waiting_items_total) return null

  if (!cart.total && cart.waiting_items_total) {
    return (
      <div className="cart-totals">
        <div className="d-flex">
          <div className="totals d-flex flex-column ml-auto pr-0">
            <CartTotalLine
              title="Создаваемые товары"
              price={cart.waiting_items_total}
              className={classnames.cartTotalWaitingItems}
            />
          </div>
        </div>
      </div>
    )
  }

  const sendPromocode = () => {
    setCartPromocode(promocode)
  }

  const clearPromocode = () => {
    setCartPromocode('')
  }

  const { promo } = cart
  const hasPromoError = promo.some(({ is_valid, code }) => code && !is_valid)
  const requestedDeliveryPrice = cart.requested_delivery_price

  return (
    <div className="cart-totals">
      <div className="d-flex">
        <div className="col pl-0 promo d-flex align-items-start">
          <div className="mx-2 text-lg">Промокод</div>

          <div className="flex-column">
            <div className="input-group ml-3">
              <input
                name="promocode"
                value={promocode}
                onChange={({ target }) => {
                  setPromocode(target.value)
                }}
                className={clsx(
                  'px-2 border',
                  { 'border-dark': !hasPromoError },
                  { 'border-red': hasPromoError },
                )}
                type="text"
                disabled={cart.isLoading}
              />
              <div className="input-group-append">
                {isPromocodeActive && !isPromocodeChanged ? (
                  <button
                    className={clsx(
                      'btn border',
                      { 'border-dark': !hasPromoError },
                      { 'border-red': hasPromoError },
                    )}
                    onClick={clearPromocode}
                    disabled={cart.isLoading}
                  >
                    <img
                      style={{ marginBottom: '10px' }}
                      src={require('../../images/icons/promocode-trash.svg')}
                      alt="очистить промокод"
                    />
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={sendPromocode}
                    disabled={cart.isLoading}
                  >
                    <span className="lnr lnr-arrow-right" />
                  </button>
                )}
              </div>
            </div>
            {hasPromoError && (
              <div className="text-red ml-3 text-sm promoError">
                Промокод указан неверно или уже был использован ранее
              </div>
            )}
          </div>
        </div>
        <div className="totals d-flex flex-column pr-0">
          {cart.isLoading || cart.isServicesLoading ? (
            <div className="text-right mx-3">
              <Spinner style={{ color: '#27979D' }} />
            </div>
          ) : (
            <>
              <CartTotalLine title="Товары:" price={cart.price} />
              {false && <CartTotalLine title="Сервисы:" price={120000} />}
              {false && <CartTotalLine title="Сервисы:" price={3000} />}
              {false && (
                <CartTotalLine title="Товар недели!" price={-11000} className="text-danger" />
              )}
              {cart.promo_price !== cart.total && (
                <>
                  <CartTotalLine
                    title="в т.ч. скидка"
                    price={cart.total - cart.promo_price}
                    className="text-danger"
                  />
                  <AdditionalPanelSwitcher
                    additionalPanelTitle="Подробнее"
                    titleClassName="text-primary font-weight-bold"
                    innerClassName="pl-3 my-2"
                    isCloseOnStart
                    disabled={!cart.promo.filter((promo) => promo.is_valid)}
                  >
                    {cart?.promo
                      .filter((promo) => promo.is_valid)
                      .map((promo, index) => (
                        <CartTotalLine
                          key={index}
                          title={promo.description}
                          price={-promo.amount}
                          isTitleBold={false}
                        />
                      ))}
                  </AdditionalPanelSwitcher>
                </>
              )}
              {!!requestedDeliveryPrice && (
                <CartTotalLine title="Доставка:" price={requestedDeliveryPrice} />
              )}
              <CartTotalLine
                title="Итого:"
                price={cart.total_with_discount}
                className="text-lg font-weight-bold"
              />
              {Boolean(cart.waiting_items_total) && (
                <CartTotalLine
                  title="Создаваемые товары"
                  price={cart.waiting_items_total}
                  className={classnames.cartTotalWaitingItems}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  cart: state.cart,
  isFetchCustomerInProgress: state.search.isLoading,
})

const mapDispatchToProps = {
  setCartPromocode: cart.setPromocode,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CartTotals)
