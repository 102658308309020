import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  to?: string
  className?: string
  onClick?: () => void
  children: ReactNode
}

/**
 * Обертка для ссылок которые не должны зудиректить в определенных условиях
 * если передан параметр 'to' то оборачивает контент в <Link /> в противном случае в тэг <a />
 *
 * @param props
 * @param to
 * @returns {*}
 * @constructor
 * @example:
 *   <LinkWrapper to={disabled ? null : `/item/${id}`} className='item'>
 *      item content
 *   </LinkWrapper>
 */
const LinkWrapper: FC<Props> = ({ to, ...props }) => {
  if (to) {
    return (
      <Link {...props} to={to}>
        {props.children}
      </Link>
    )
  }
  return <a {...props}>{props.children}</a>
}

export default LinkWrapper
