import React, { useMemo } from 'react'
import { yup } from '../../../yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '../../../components/Layout/form/Form'
import { InputField } from '../../../components/Layout/form/InputField'
import { Button } from '../../../components/Layout/Button/Button'
import { SelectField } from '../../../components/Layout/form/SelectField'
import { CheckboxField } from '../../../components/Layout/form/CheckboxField'
import { STATUS_LOADING, STATUS_ERROR, STATUS_SUCCESS, CARD, CASH_DESK } from '../constants'
import { RadioField } from '../../../components/Layout/form/RadioField'
import { clearPhoneMask } from '../../../helpers/strings'
import { getPaymentApi } from '../api/getPaymentApi'
import { ReceiptType, StartPayRequest } from '../api/dto/StartPayRequest'
import { getAppData } from '../../../redux/selectors/app'
import { useSelector } from 'react-redux'
import { PhoneInputField } from '../../../components/Layout/form/PhoneInputField'

const PHONE_CONTACT_TYPE = 'phone'
const EMAIL_CONTACT_TYPE = 'email'

type Props = {
  paymentApiHost?: string
  transactionId?: string
  orderId?: string
  amount?: number | string
  amountWithBonuses?: number | string
  isAdvancePayment?: boolean
  advancedPaySum?: number
  formatSum?: (value: number, withCurrency?: boolean) => string
  onPaymentStart?: (params: any) => void
  onSuccess?: (params: any) => { success: boolean }
  setStatus: (status: string) => void
  setResult: (params: any) => void
  setPaymentType: (type: string) => void
  operatorName: string
  discountSum?: number
  orderLines: any[]
  defaultPhone?: string
  defaultEmail?: string
  updateDraftPayContact: (params: { newClientPhone?: string; newClientEmail?: string }) => void
  onError: (error: string) => void
}

function getAdvancedPaymentPrintDetails(amount: number) {
  return [
    {
      name: 'АВАНС',
      price: amount,
      count: 1,
      sum: amount,
      declarations: [],
      taxType: 1,
      markCode: null,
    },
  ]
}

export function Start({
  transactionId = '',
  orderId,
  paymentApiHost = '',
  amount = 0,
  amountWithBonuses = 0,
  isAdvancePayment = false,
  advancedPaySum = 0,
  formatSum,
  onPaymentStart,
  setStatus,
  operatorName,
  discountSum = 0,
  orderLines,
  setResult,
  setPaymentType,
  defaultPhone,
  defaultEmail,
  updateDraftPayContact,
  onError,
}: Props) {
  // TODO pass as props
  const { shopName, shopCode, terminalId } = useSelector(getAppData)
  const amountNumber = Number(amount)
  const amountWithBonusesNumber = Number(amountWithBonuses)

  const schema = useMemo(
    () =>
      yup.object().shape({
        isElectronically: yup.boolean().required(),
        contactType: yup.string().when('isElectronically', {
          is: true,
          then: yup.string().required(),
        }),
        method: yup.string().required(),
        phoneNumber: yup.string().when('isElectronically', {
          is: true,
          then: yup.string().when('contactType', {
            is: PHONE_CONTACT_TYPE,
            then: yup.string().phone('Введите корректный номер телефона').required(),
          }),
        }),
        email: yup.string().when('isElectronically', {
          is: true,
          then: yup.string().when('contactType', {
            is: EMAIL_CONTACT_TYPE,
            then: yup.string().email('Введите корректный email').required(),
          }),
        }),
      }),
    [],
  )

  const format = (sum: number) => (formatSum ? formatSum(sum) : sum)

  const defaultValues = {
    isElectronically: true,
    contactType: !!defaultEmail && !defaultPhone ? EMAIL_CONTACT_TYPE : PHONE_CONTACT_TYPE,
    phoneNumber: defaultPhone,
    method: '',
    email: defaultEmail,
  }

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const isElectronically = form.watch('isElectronically', true)
  const contactType = form.watch('contactType', PHONE_CONTACT_TYPE)

  async function onSubmit(values: typeof defaultValues) {
    if (onPaymentStart) {
      onPaymentStart({
        ...values,
        transactionId,
      })
    }
    setStatus(STATUS_LOADING)

    const chequeTarget =
      values.contactType === PHONE_CONTACT_TYPE
        ? clearPhoneMask(values.phoneNumber ?? '')
        : values.email

    const isElectronicallyTargetContactChanged =
      isElectronically && values.contactType === PHONE_CONTACT_TYPE
        ? chequeTarget !== defaultPhone
        : chequeTarget !== defaultEmail

    if (isElectronicallyTargetContactChanged) {
      updateDraftPayContact({
        newClientPhone: values.contactType === PHONE_CONTACT_TYPE ? chequeTarget : undefined,
        newClientEmail: values.contactType === EMAIL_CONTACT_TYPE ? chequeTarget : undefined,
      })
    }

    const printDetails = (orderLines ?? []).map((orderLine) => ({
      ...orderLine,
      declarations: [], //TODO: сертификаты соответсвия, мы сейчас их ниоткуда не получаем
      taxType: 0, //TODO: ставка НДС, получать от бэка
    }))

    const paymentOptions: StartPayRequest = {
      additionalInfo: `Заказ: ${orderId}`,
      requestId: transactionId,
      sum: values.method === CASH_DESK ? 0 : amountWithBonusesNumber,
      operatorName,
      discountSum,
      advanceSum: isAdvancePayment ? 0 : advancedPaySum,
      paymentType: +values.method,
      receiptType: ReceiptType.PAYMENT,
      isElectronically: values.isElectronically,
      target: values.isElectronically ? chequeTarget : undefined,
      printDetails: isAdvancePayment ? getAdvancedPaymentPrintDetails(amountNumber) : printDetails,
    }

    const { startPay, getPayStatus, confirmPayStatus, getPayInfo } = getPaymentApi(paymentApiHost, {
      shopName,
      shopCode,
      terminalId,
    })
    let startPayData = await startPay(paymentOptions)
    if (!startPayData.success) {
      setStatus(STATUS_ERROR)
      onError(startPayData.errorMessage)
    }

    if (startPayData.success) {
      const payStatusData = await getPayStatus(transactionId)
      if (payStatusData && payStatusData.success) {
        setStatus(STATUS_SUCCESS)

        const payInfo = await getPayInfo(transactionId)
        if (payInfo.data) {
          const payStatus = await confirmPayStatus(transactionId)
          if (payStatus.success) {
            setResult(payInfo.data)
          } else {
            setStatus(STATUS_ERROR)
            onError('Не удалось подтвердить статус платежа')
          }
        } else {
          setStatus(STATUS_ERROR)
          onError('Не удалось получить информацию о платеже')
        }
      } else {
        setStatus(STATUS_ERROR)
        onError(payStatusData?.errorMessage)
      }
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <div className="askona-widget-payment__block">
        <div>Общая сумма платежа</div>
        <div className="amount">{format(amountWithBonusesNumber)}</div>
      </div>
      <div className="askona-widget-payment__block">
        <div>Выберите платёжный метод</div>
        <div>
          <SelectField
            name="method"
            options={[
              {
                id: CARD,
                name: 'банковская карта',
              },
              {
                id: CASH_DESK,
                name: 'наличные',
              },
            ]}
            onChange={setPaymentType}
          />
        </div>
      </div>
      <div className={`askona-widget-payment__block ${!isElectronically ? 'margin' : ''}`}>
        <CheckboxField
          name="isElectronically"
          labelName="Отправить электронный чек (вместо бумажного)"
        />
        {isElectronically && (
          <>
            <RadioField
              name="contactType"
              value={PHONE_CONTACT_TYPE}
              labelName="Отправить на телефон"
            />
            <PhoneInputField
              name="phoneNumber"
              defaultValue={defaultPhone?.slice(1)}
              placeholder="+7 (___) ___-__-__"
              disabled={String(contactType) !== PHONE_CONTACT_TYPE}
            />
            <RadioField
              name="contactType"
              value={EMAIL_CONTACT_TYPE}
              labelName="Отправить на e-mail"
            />
            <InputField
              name="email"
              defaultValue={defaultEmail}
              placeholder="example@mail.ru"
              disabled={String(contactType) !== EMAIL_CONTACT_TYPE}
            />
          </>
        )}
      </div>
      <div className="button">
        <Button color="primary" className="height-40" type="submit">
          Оплатить
        </Button>
      </div>
    </Form>
  )
}
