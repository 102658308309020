import React, { FC, ReactNode } from 'react'
import { FormGroup, Input as InputStrap, Label } from 'reactstrap'
import clsx from 'clsx'
import classnames from './Input.module.scss'
import { InputProps } from 'reactstrap/lib/Input'

const NON_NUMERIC_SYMBOLS = ['e', 'E', '+']

export interface Props extends InputProps {
  name: string
  label?: ReactNode
  required?: boolean
  isErrorMessageHide?: boolean
  errorMessage?: string
  className?: string
  isShortInput?: boolean
}

export const Input: FC<Props> = ({
  label,
  required = false,
  type,
  step,
  min,
  errorMessage,
  innerRef,
  name,
  color,
  placeholder = '',
  className,
  onChange,
  onBlur,
  value,
  isErrorMessageHide = false,
  isShortInput = false,
  ...props
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number') {
      if (Number(min) >= 0 && e.key === '-') {
        e.preventDefault()
        return
      }
      if (e.currentTarget.value === '' && (e.key === '.' || e.key === ',')) {
        e.preventDefault()
        return
      }
      if (Number(step) % 1 === 0 && (e.key === '.' || e.key === ',')) {
        e.preventDefault()
        return
      }
      if (NON_NUMERIC_SYMBOLS.includes(e.key)) {
        e.preventDefault()
      }
    }
  }

  return (
    <FormGroup
      className={clsx('text-left mb-0', {
        [classnames.input]: !isErrorMessageHide && !isShortInput,
        [classnames.shortInput]: isShortInput,
        [classnames.withError]: !!errorMessage,
      })}
    >
      {label && (
        <Label for={name}>
          {label} {required ? <span className="text-red">*</span> : ''}
        </Label>
      )}
      <InputStrap
        min={min}
        innerRef={innerRef}
        color={color}
        type={type}
        name={name}
        id={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={clsx(className, { error: Boolean(errorMessage) })}
        onKeyDown={handleKeyDown}
        step={step}
        onBlur={onBlur}
        {...props}
      />
      {errorMessage && !isErrorMessageHide && (
        <span className={clsx('text-red', classnames.errorMessage)}>{errorMessage}</span>
      )}
    </FormGroup>
  )
}
