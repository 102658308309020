import React, { createContext, useContext, useState, useRef, useEffect } from 'react'
import { getPaymentApi } from '../../widgets/Payment/api/getPaymentApi'
import { useSelector } from 'react-redux'
import { getAppData, getPaymentApiHost } from '../../redux/selectors/app'
import { useNotification } from '../NotificationProvider/NotificationProvider'
interface TerminalContextType {
  isTerminalActive: boolean
}

export const TerminalContext = createContext<TerminalContextType>({} as TerminalContextType)

type TerminalProviderProps = {
  children: React.ReactNode
}

const NON_ACTIVE_CHECK_INTERVAL = 15000
const ACTIVE_CHECK_INTERVAL = 600000

export const TerminalProvider = ({ children }: TerminalProviderProps) => {
  const [isTerminalActive, setIsTerminalActive] = useState<boolean>(false)
  const checkInterval = useRef<number | undefined>()
  const { showSuccess, showWarning } = useNotification()

  const paymentApiHost = useSelector(getPaymentApiHost)
  const { shopName, shopCode, terminalId } = useSelector(getAppData)
  const { healthCheck } = getPaymentApi(paymentApiHost, {
    shopName,
    shopCode,
    terminalId,
  })
  const checkTerminalActive = async () => {
    const resp = await healthCheck()
    if (!isTerminalActive && resp.success) {
      showSuccess({
        body: 'Кассовый терминал доступен',
      })
    }
    if (isTerminalActive && !resp.success) {
      showWarning({
        body: 'Кассовый терминал недоступен',
      })
    }
    setIsTerminalActive(resp.success)
  }

  useEffect(() => {
    checkTerminalActive()
  }, [])

  useEffect(() => {
    if (checkInterval.current) {
      clearInterval(checkInterval.current)
    }
    // @ts-ignore
    checkInterval.current = setInterval(
      checkTerminalActive,
      isTerminalActive ? ACTIVE_CHECK_INTERVAL : NON_ACTIVE_CHECK_INTERVAL,
    )
  }, [isTerminalActive])

  return (
    <TerminalContext.Provider
      value={{
        isTerminalActive,
      }}
    >
      {children}
    </TerminalContext.Provider>
  )
}

export function useTerminal() {
  return useContext(TerminalContext)
}
