import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { customerShape } from '../constants'
import { CustomerProfile, CustomerEditForm, Loader, Modal } from '../components'
import { customer } from '../redux/actions'
import { useGetCustomerQuery } from '../api/customer'
import { Button } from '../components/Layout/Button/Button'
import { useNotification } from '../contexts/NotificationProvider/NotificationProvider'

function Customer({ getCustomerById, customerIsLoading, customerError, customer, setCustomer }) {
  const [isEditActive, setEditActive] = useState(false)
  const [isQuerySend, setIsQuerySend] = React.useState(false)
  const [hasVersionMismatch, setHasVersionMismatch] = useState(false)
  const { showSuccess } = useNotification()
  const { id } = useParams()
  const location = useLocation()

  const sameCustomer = '' + customer.customer_id === id

  const getCustomer = useCallback(() => {
    if (!sameCustomer) {
      getCustomerById(id)
    }
  }, [sameCustomer, getCustomerById, id])

  useEffect(() => getCustomer(), [location, getCustomer])

  const fetchByIdQuery = useGetCustomerQuery(id)
  const { isFetching, isSuccess, refetch } = fetchByIdQuery

  useEffect(() => {
    if (isSuccess && !isFetching && isQuerySend) {
      const { version_id: newVersionId } = fetchByIdQuery.data
      if (!hasVersionMismatch && !isEditActive) {
        setIsQuerySend(false)
        if (newVersionId !== customer.version_id) {
          setHasVersionMismatch(true)
          setCustomer(fetchByIdQuery.data)
        } else {
          if (!isEditActive) {
            setEditActive(true)
          }
        }
      }
    }
  }, [
    isSuccess,
    isEditActive,
    isFetching,
    isQuerySend,
    fetchByIdQuery.data,
    hasVersionMismatch,
    setHasVersionMismatch,
    setIsQuerySend,
    setCustomer,
    setEditActive,
  ])

  useEffect(
    function showEditResult() {
      if (isEditActive && isSuccess && !isFetching && isQuerySend) {
        showSuccess({
          title: 'Внимание',
          body: 'Данные успешно сохранены',
        })
        setEditActive(false)
        setIsQuerySend(false)
      }
    },
    [
      fetchByIdQuery.data,
      isEditActive,
      isFetching,
      isQuerySend,
      isSuccess,
      setCustomer,
      showSuccess,
    ],
  )

  if (customerIsLoading) {
    return <Loader />
  }

  /**
   * Если ошибка или ничего не найдено
   */
  if (customerError) {
    return (
      <div className="customer">
        Ничего не найдено. Попробуйте <Link to="/">воспользоваться поиском.</Link>
      </div>
    )
  }

  const updateCustomerVersion = async () => {
    setIsQuerySend(true)
    try {
      const data = await refetch()
      if (data.isSuccess) {
        setCustomer(data.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleEditProfile = () => {
    updateCustomerVersion()
  }

  const handleSaveProfile = (isChanged = true) => {
    if (isChanged) {
      setTimeout(async () => {
        updateCustomerVersion()
      }, 3000)
    } else {
      setEditActive(false)
    }
  }

  const handleCancelProfile = () => {
    setEditActive(false)
  }

  return (
    <div className="customer h-100">
      {isEditActive ? (
        <CustomerEditForm onSave={handleSaveProfile} cancelButton={handleCancelProfile} />
      ) : (
        <CustomerProfile onEdit={handleEditProfile} />
      )}
      <Modal
        color="warning"
        renderIcon={() => {}}
        isOpenExternal={hasVersionMismatch}
        title="Внимание"
        message={
          <div>
            В карточку клиента были внесены изменения другими пользователями.
            <br /> <br />
            Пожалуйста, посмотрите измененные данные и при необходимости отредактируйте их повторно.
          </div>
        }
        onToggle={() => {
          updateCustomerVersion()
          setHasVersionMismatch(false)
        }}
        buttonToolbar={(toggle) => (
          <Button className="btn-primary" onClick={toggle} color="primary">
            Перейти к клиенту
          </Button>
        )}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  customerIsLoading: state.customer.isLoading,
  customerError: state.customer.error,
  customer: state.customer.result,
})

const mapDispatchToProps = {
  getCustomerById: customer.getById,
  setCustomer: customer.setCustomer,
}

Customer.propTypes = {
  customer: PropTypes.shape(customerShape).isRequired,
  customerIsLoading: PropTypes.bool.isRequired,
  customerError: PropTypes.string.isRequired,
  getCustomerById: PropTypes.func.isRequired,
  setCustomer: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer)
