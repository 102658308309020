import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '../index'
import { productStocksSelector } from '../../redux/selectors/product'

function ProductStock({ isConstructorAllowed, item }) {
  const stocks = useSelector(productStocksSelector)
  const source = isConstructorAllowed ? stocks : item.stock_data
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!Object.keys(source || {}).length) {
    return null
  }

  const currentWarehouseQty = item.simples.reduce(
    (sum, item) => sum + item.stock_data?.current_warehouse?.qty || 0,
  )
  const isOutletsTooltipActive = item.simples.some((item) =>
    (item.stock_data?.region_warehouses || []).some((regionWarehous) => regionWarehous?.qty),
  )

  const isOutletsActive = [source.warehouse, currentWarehouseQty].some(Boolean)

  const regionWarehouses = Object.values(
    item.simples
      .filter((simple) => simple.id === item?.id)
      .map((simple) => {
        return simple.stock_data?.region_warehouses
      })
      .flat()
      .filter((item) => Boolean(item)),
  )
  return (
    <div className="d-flex justify-content-between position-relative">
      {isOutletsActive && isOutletsTooltipActive && isModalOpen && (
        <Modal
          buttonToolbar={() => {}}
          size="large"
          onToggle={() => setIsModalOpen((prev) => !prev)}
          needRenderIcon={false}
          withIcon={false}
          isOpenExternal
          message={
            <>
              <div className="text-left">
                <h2 className="name text-xxl">Остатки</h2>
                <hr />
                {Boolean(source.central_warehouse) && (
                  <div>
                    <h3 className="name text-lg font-weight-bold">На центральном складе</h3>
                    <span className="text-black-50">{source.central_warehouse}&nbsp;шт.</span>
                    <hr />
                  </div>
                )}
              </div>
              <div className="text-left">
                <h3 className="name text-lg mb-4 font-weight-bold">На торговых точках</h3>
              </div>

              {regionWarehouses.map((regionWarehous) => {
                return (
                  <div className="outlet-item text-left mb-4" key={regionWarehous.name}>
                    <div className="text-lg">{regionWarehous.name}</div>
                    <div className="text-black-50 text-lg">
                      на складе: {regionWarehous.qty}&nbsp;шт.
                    </div>
                  </div>
                )
              })}
            </>
          }
        />
      )}

      <>
        <div className="name text-lg">Остатки:</div>
        {isOutletsActive && (
          <div className="value text-right">
            {Boolean(source.current_warehouse) && (
              <div>На складе ТТ: &nbsp; {source.current_warehouse.qty} шт.</div>
            )}
            {/* {Boolean(source.warehouse) && <div>На складе: {source.warehouse} шт.</div>} */}
            {/* {Boolean(source.centralWarehouse) && (
              <div>На центральном складе: {source.centralWarehouse} шт.</div>
            )} */}
            {isOutletsTooltipActive && (
              <div
                className="cursor-pointer product-stock__details"
                id="tooltipTarget"
                onClick={() => setIsModalOpen((prev) => !prev)}
              >
                Подробнее
              </div>
            )}
          </div>
        )}
        {!isOutletsActive && <div className="value text-right">0 шт.</div>}
      </>
    </div>
  )
}

export default ProductStock
