import React from 'react'
import PropTypes from 'prop-types'
import { productHelper } from '../../helpers'
import { productShape } from '../../constants'

function ProductDelivery({ item }) {
  if (!item?.delivery) {
    return null
  }
  return (
    <div className="d-flex justify-content-between delivery">
      <div className="name">Доставка:</div>
      <div className="value d-flex flex-column align-items-center justify-content-end">
        <div className="d-flex align-items-center">
          от {productHelper.formatPrice(item.delivery.cost)} <span className="currency" />{' '}
        </div>
      </div>
    </div>
  )
}

export default ProductDelivery

ProductDelivery.propTypes = {
  item: PropTypes.shape(productShape),
}
