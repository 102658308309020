import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { TableList } from '../../components/TableList/TableList'
import { formatPriceRu, formatToString, formatStringNumber } from '../../helpers/currency'
import { Block } from '../../components/Layout/Block/Block'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { yup } from '../../yup'
import { Form } from '../../components/Layout/form/Form'
import { InputField } from '../../components/Layout/form/InputField'
import { Button } from '../../components/Layout/Button/Button'
import { Col, Row } from 'reactstrap'
import { CheckboxField } from '../../components/Layout/form/CheckboxField'
import LoyaltyProgram from '../../widgets/LoyaltyProgram/LoyaltyProgram'
import { loyaltyProgramAuth, useAuth } from '../../api/auth'
import { Payment as PaymentWidget } from '../../widgets/Payment/Payment'
import { useSwitcher } from '../../hooks/useSwitcher'
import { formatPhoneNumber } from '../../helpers'
import { draftPay, payBonuses, payMoney } from '../../api/order'
import { useSelector } from 'react-redux'
import { getPaymentApiHost, getShopCode } from '../../redux/selectors/app'
import { useRuntimeConfig } from '../../hooks/useRuntimeConfig'
import { loyaltyProgramRequest } from '../../api/loyaltyProgram'
import { useNotification } from '../../contexts/NotificationProvider/NotificationProvider'
import { v4 as uuidv4 } from 'uuid'
import { ASKONA, commonErrorText } from '../../widgets/LoyaltyProgram/constants'

export function Payment({
  onClickBack,
  clientPhone,
  clientEmail,
  clientId,
  products = [],
  total,
  total_due: due,
  subtotal: productTotal,
  orderId,
  publicOrderId,
  isOrderWithDelivery,
  minPaySum,
  isLoyaltyProgramAvailable,
}) {
  const [phoneForNotify, setPhoneForNotify] = useState()
  const [emailForNotify, setEmailForNotify] = useState()
  const [isFinal, setIsFinal] = useState(false)

  const subtotal = due ?? total
  const { loyaltyProgram } = useRuntimeConfig()
  const { username } = useAuth()

  const [paymentGuid, setPaymentGuid] = useState(uuidv4)
  const [transactionId, setTransactionId] = useState(uuidv4)
  const { showError, showSuccess, showWarning } = useNotification()

  const history = useHistory()
  const [isHomePayment, setIsHomePayment] = useState(false)

  const loyaltyProgramSwitcher = useSwitcher()
  const paymentSwitcher = useSwitcher()
  const [paySum, setPaySum] = useState(subtotal)
  const [paySumWithBonuses, setPaySumWithBonuses] = useState()
  const [homePaySum, setHomePaySum] = useState('0')
  const [bonusesAmount, setBonusesAmount] = useState(0)

  useEffect(
    function resetPayment() {
      if (!paymentSwitcher.isOn && !loyaltyProgramSwitcher.isOn) {
        setPaymentGuid(uuidv4())
        setTransactionId(uuidv4())
      }
    },
    [paymentSwitcher, loyaltyProgramSwitcher],
  )
  const resetTransactionId = () => setTransactionId(uuidv4())

  const shopCode = useSelector(getShopCode)
  const paymentApiHost = useSelector(getPaymentApiHost)

  const isFirstPaid = due === total

  const maxPaySum = isFirstPaid ? minPaySum : subtotal

  const handleClickBack = () => onClickBack()

  const schema = useMemo(
    () =>
      yup.object().shape({
        payment: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : parseFloat(value)))
          .min(minPaySum, 'Сумма не должна быть меньше ' + formatPriceRu(minPaySum))
          .when([], {
            is: () => isOrderWithDelivery,
            then: yup.number().required(),
          }),
        isHomePayment: yup.boolean().when([], {
          is: () => isOrderWithDelivery,
          then: yup.boolean().required(),
        }),
        homePayment: yup.number().when('isHomePayment', {
          is: true,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : parseFloat(value)))
            .max(maxPaySum, 'Сумма не должна быть больше ' + formatPriceRu(maxPaySum))
            .required(),
        }),
      }),
    [minPaySum, maxPaySum],
  )

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      payment: subtotal,
      homePayment: '',
      isHomePayment,
    },
  })

  const handleSubmit = useCallback(
    async (values) => {
      showWarning({ body: 'Отправлен запрос на создание черновика платежа' })
      const sum = values.payment ? values.payment : paySum
      try {
        const res = await draftPay({
          guid: paymentGuid,
          orderId: orderId,
          sum,
          bonusesAmount: bonusesAmount,
          phone: clientPhone,
          email: clientEmail,
          username,
          transactionId,
        })
        if (res.success) {
          showSuccess({ body: 'Черновик платежа успешно создан' })
          setIsFinal(res.payment?.is_final ?? false)
          if (isLoyaltyProgramAvailable) {
            loyaltyProgramSwitcher.on()
          } else {
            paymentSwitcher.on()
          }
        } else {
          showError({ body: 'Что-то пошло не так при создании черновика платежа' })
        }
      } catch (ex) {
        showError({ body: 'Что-то пошло не так при создании черновика платежа' })
      }
    },
    [
      bonusesAmount,
      clientEmail,
      clientPhone,
      isLoyaltyProgramAvailable,
      loyaltyProgramSwitcher,
      orderId,
      paySum,
      paymentGuid,
      paymentSwitcher,
      showError,
      showSuccess,
      showWarning,
      transactionId,
      username,
    ],
  )

  const updateDraftPayContact = useCallback(
    function updateDraftContact({ newClientPhone, newClientEmail }) {
      const phone = newClientPhone ?? clientPhone
      const email = newClientEmail ?? clientEmail

      draftPay({
        guid: paymentGuid,
        orderId: orderId,
        sum: paySum,
        bonusesAmount: bonusesAmount,
        phone,
        email,
        username,
        transactionId,
      })
      setPhoneForNotify(phone)
      setEmailForNotify(email)
    },
    [
      clientPhone,
      clientEmail,
      paymentGuid,
      orderId,
      paySum,
      bonusesAmount,
      username,
      transactionId,
    ],
  )

  const handleSuccessPayment = useCallback(
    (result) => {
      return payMoney({
        ...result,
        guid: paymentGuid,
        isFinal,
        orderId: orderId,
        amount: paySum,
        bonusesAmount,
        phone: phoneForNotify ?? clientPhone,
        email: emailForNotify ?? clientEmail,
        username,
        transactionId: transactionId,
      })
    },
    [
      isFinal,
      bonusesAmount,
      clientEmail,
      clientPhone,
      emailForNotify,
      orderId,
      paySum,
      paymentGuid,
      phoneForNotify,
      username,
      transactionId,
    ],
  )

  const handleRegister = () => history.push(`/customer/${clientId}`)

  const firstUpdate = useRef(true)
  const firstPaySumUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if (paySum === subtotal) {
      setHomePaySum('0')
      setIsHomePayment(false)
    }
    if (paySum < subtotal) {
      setIsHomePayment(true)
    } else if (paySum > subtotal) {
      const formattedSubtotal = formatToString(subtotal)
      setPaySum(formattedSubtotal)
    }
  }, [subtotal, paySum])

  useEffect(() => {
    if (homePaySum >= 0 && homePaySum < subtotal) {
      if (firstPaySumUpdate.current) {
        firstPaySumUpdate.current = false
        const formattedPaySum = formatToString(subtotal - homePaySum)
        return setPaySum(formatStringNumber(formattedPaySum))
      }
      setPaySum(formatToString(subtotal - homePaySum))
    }
  }, [subtotal, homePaySum])

  useEffect(() => {
    if (isHomePayment) {
      const homePaymentSum = formatToString(subtotal - paySum)
      setHomePaySum(formatStringNumber(homePaymentSum))
    }
  }, [isHomePayment, subtotal, paySum])

  const resetSums = () => {
    setPaySum(formatToString(subtotal))
    setHomePaySum('0')
  }

  const handleChangeValue = (value, callback) => {
    const regExp = /^\d*(\.\d{0,2})?$/
    if (isNaN(parseFloat(value))) {
      return callback(parseFloat(value).toString())
    }
    if (regExp.test(value)) {
      callback(value)
    }
  }

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <Block title={<h1>Оплата заказа</h1>}>
          <div className="w-25">
            <TableList
              items={[
                {
                  id: 'orderId',
                  title: <h5 className="font-weight-bold">Номер заказа:</h5>,
                  content: <h5 className="text-primary">{publicOrderId}</h5>,
                },
                {
                  id: 'total',
                  title: <h5 className="font-weight-bold">Сумма заказа:</h5>,
                  content: <h5>{formatPriceRu(total)}</h5>,
                },
                ...(isFirstPaid
                  ? []
                  : [
                      {
                        id: 'due',
                        title: <h5 className="font-weight-bold">Осталось к оплате:</h5>,
                        content: <h5>{formatPriceRu(subtotal)}</h5>,
                      },
                    ]),
              ]}
            />
          </div>
          {isFirstPaid && isOrderWithDelivery && minPaySum && (
            <p className="font-weight-light">
              Для передачи заказа на исполнение необходимо оплатить не менее&nbsp;
              {formatPriceRu(minPaySum)}.
            </p>
          )}
          <Row className="align-items-center pb-3">
            <Col xs={6}>
              <Row className="align-items-center">
                <Col xs={6}>
                  <h4 className="font-weight-light mb-4">Сумма к оплате</h4>
                </Col>
                <Col xs={6}>
                  <InputField
                    disabled={
                      !isOrderWithDelivery || loyaltyProgramSwitcher.isOn || paymentSwitcher.isOn
                    }
                    step=".01"
                    type="number"
                    name="payment"
                    onChange={(e) => handleChangeValue(e.target.value, setPaySum)}
                    value={paySum}
                    onBlur={(e) => setPaySum(formatStringNumber(e.target.value))}
                  />
                </Col>
              </Row>
            </Col>
            {isOrderWithDelivery && (
              <Col xs={5}>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="mb-4">
                      <CheckboxField
                        disabled={loyaltyProgramSwitcher.isOn || paymentSwitcher.isOn}
                        name="isHomePayment"
                        labelName="Доплата"
                        checked={isHomePayment}
                        onChange={(event) => {
                          const checked = event.target.checked
                          setIsHomePayment(checked)
                          if (!checked) {
                            resetSums()
                          }
                        }}
                      />
                    </div>
                  </Col>
                  {isHomePayment && (
                    <Col xs={6}>
                      <InputField
                        disabled={loyaltyProgramSwitcher.isOn || paymentSwitcher.isOn}
                        placeholder="Введите сумму"
                        step=".01"
                        type="number"
                        name="homePayment"
                        value={homePaySum}
                        onChange={(e) => handleChangeValue(e.target.value, setHomePaySum)}
                        onBlur={(e) => setHomePaySum(formatStringNumber(e.target.value))}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
          <div className="mt-2">
            <Button
              name="lou"
              type="submit"
              disabled={paymentSwitcher.isOn || loyaltyProgramSwitcher.isOn}
              color="primary"
              className="mr-3 height-40"
            >
              Оплатить заказ
            </Button>
            {/*<Button color="primary" className="mr-3">*/}
            {/*  Оформить кредит*/}
            {/*</Button>*/}
            <Button color="primary" className="height-40" onClick={handleClickBack}>
              Вернуться к заказу
            </Button>
          </div>
        </Block>
      </Form>
      {loyaltyProgramSwitcher.isOn && (
        <LoyaltyProgram
          paySum={paySum}
          gateway={loyaltyProgram?.gateway || ''}
          shopCode={shopCode}
          terminalId="appId"
          mobilePhone={formatPhoneNumber(clientPhone)}
          cardNumber=""
          purchaseDate={new Date()}
          amount={paySum === subtotal ? productTotal : paySum}
          products={products
            .filter((el) => !el.isService && !el.isDelivery)
            .map((item, index) => ({
              index,
              productCode: item.sku,
              price: item.price,
              quantity: item.qty,
            }))}
          onWithdraw={(result) => {
            payBonuses({
              guid: paymentGuid,
              orderId: orderId,
              bonusesAmount: result.amount,
              phone: clientPhone,
              email: clientEmail,
              sum: paySum,
              smsCode: result.smsCode,
            })
              .then((res) => {
                if (res.success && res?.payment?.calculated_sum) {
                  setBonusesAmount(result.amount)
                  setPaySumWithBonuses(res.payment.calculated_sum)

                  if (res.payment?.sum) {
                    setPaySum(res.payment.sum)
                  }

                  result.onSuccess()
                } else {
                  result.onFail(commonErrorText)
                }
              })
              .catch((error) => {
                result.onFail(error?.response?.data?.message)
              })
          }}
          onConfirmLoyaltyProgram={(result) => {
            loyaltyProgramRequest({
              shop_code: shopCode,
              terminal_id: result.terminalId,
              loyalty_program: ASKONA,
              channel: 'SMS',
              client: {
                mobile_phone: clientPhone,
                card_number: result.cardNumber,
              },
            })
              .then((res) => {
                if (res.success) {
                  result.onSuccess()
                } else {
                  result.onFail()
                }
              })
              .catch((error) => {
                result.onFail(error?.response?.data?.message)
              })
          }}
          onRegister={handleRegister}
          onPaymentChange={() => {
            loyaltyProgramSwitcher.off()
          }}
          onAuth={loyaltyProgramAuth}
          onClose={() => {
            if (paySumWithBonuses) {
              paymentSwitcher.on()
            } else {
              paymentSwitcher.off()
            }
            loyaltyProgramSwitcher.off()
          }}
        />
      )}
      {paymentSwitcher.isOn && (
        <PaymentWidget
          orderId={publicOrderId}
          paymentApiHost={paymentApiHost}
          transactionId={transactionId}
          amount={paySum}
          amountWithBonuses={paySumWithBonuses}
          isAdvancePayment={subtotal - paySum > 0}
          advancedPaySum={total - subtotal}
          orderTotalSum={subtotal}
          discountSum={bonusesAmount}
          orderLines={products.map((item) => ({
            name: item.name,
            price: item.total,
            count: item.qty,
            sum: item.qty * item.total,
          }))}
          operatorName={username}
          isOpen={true}
          formatSum={formatPriceRu}
          onPaymentStart={(values) => console.log('payment', values)}
          defaultPhone={clientPhone}
          defaultEmail={clientEmail}
          updateDraftPayContact={updateDraftPayContact}
          onSuccess={handleSuccessPayment}
          onClose={() => {
            paymentSwitcher.off()
          }}
          paymentErrorReset={resetTransactionId}
        />
      )}
    </>
  )
}

Payment.propTypes = {
  products: PropTypes.array,
  total: PropTypes.string,
  total_due: PropTypes.string,
  orderId: PropTypes.string,
  publicOrderId: PropTypes.string,
  clientPhone: PropTypes.string.isRequired,
  clientEmail: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  onClickBack: PropTypes.func,
  isOrderWithDelivery: PropTypes.bool.isRequired,
  minPaySum: PropTypes.number,
}
