export const beginSearch = 'BEGIN_SEARCH'

export const endGetToken = 'END_GET_USER_TOKEN'

export const beginGetCustomer = 'BEGIN_GET_CUSTOMER'
export const endGetCustomer = 'END_GET_CUSTOMER'
export const beginUpdateCustomer = 'BEGIN_UPDATE_CUSTOMER'
export const endUpdateCustomer = 'END_UPDATE_CUSTOMER'
export const resetCreateCustomer = 'RESET_CREATE_CUSTOMER'
export const beginCreateCustomer = 'BEGIN_CREATE_CUSTOMER'
export const endCreateCustomer = 'END_CREATE_CUSTOMER'
export const resetCustomerEdited = 'RESET_CUSTOMER_EDITED'
export const resetCustomerVersionMismatch = 'RESET_CUSTOMER_VERSION_MISMATCH'
export const setDefaultState = 'SET_DEFAULT_STATE'
export const getCustomerRelationTypes = 'GET_CUSTOMER_RELATION_TYPES'
export const beginInvalidateCustomer = 'BEGIN_INVALIDATE_CUSTOMER'
export const endInvalidateCustomer = 'END_INVALIDATE_CUSTOMER'

export const setCart = 'SET_CART'
export const startCartUpdateRequest = 'START_CART_UPDATE_REQUEST'
export const endCartUpdateRequest = 'END_CART_UPDATE_REQUEST'
export const startCartUpdate = 'START_CART_UPDATE'
export const startServicesUpdate = 'START_SERVICES_UPDATE'
export const endCartUpdate = 'END_CART_UPDATE'
export const startCartInit = 'START_CART_INIT'
export const endCartInit = 'END_CART_INIT'
export const endCartSubmit = 'END_CART_SUBMIT'
export const startAddItemToCart = 'START_ADD_ITEM_TO_CART'
export const endAddItemToCart = 'END_ADD_ITEM_TO_CART'
export const startAddWaitingItemToCart = 'START_ADD_WAITING_ITEM_TO_CART'
export const endAddWaitingItemToCart = 'END_ADD_WAITING_ITEM_TO_CART'
export const resetCreateError = 'RESET_CREATE_ERROR'
export const resetCartError = 'RESET_CART_ERROR'
export const removeItemFromCart = 'REMOVE_ITEM_FROM_CART'
export const removeWaitingItemFromCart = 'REMOVE_WAITING_ITEM_FROM_CART'
export const removeAllWaitingItemsFromCart = 'REMOVE_ALL_WAITING_ITEMS_FROM_CART'
export const removeCart = 'REMOVE_CART'

export const setCartCustomer = 'SET_CART_CUSTOMER'
export const beginUpdateCartCustomer = 'BEGIN_UPDATE_CART_CUSTOMER'
export const endUpdateCartCustomer = 'END_UPDATE_CART_CUSTOMER'
export const setCustomerContact = 'SET_CUSTOMER_CONTACT'
export const setCustomerLinkType = 'SET_CUSTOMER_LINK_TYPE'

export const beginRegistrationInLoyaltyProgram = 'BEGIN_REGISTRATION_IN_LOYALTY_PROGRAM'
export const cancelConfirmUpdate = 'CANCEL_CONFIRM_UPDATE'
export const endRegistrationInLoyaltyProgram = 'END_REGISTRATION_IN_LOYALTY_PROGRAM'
export const beginSendSmsCodeInLoyaltyProgram = 'BEGIN_SEND_SMS_CODE_IN_LOYALTY_PROGRAM'
export const endSendSmsCodeInLoyaltyProgram = 'END_SEND_SMS_CODE_IN_LOYALTY_PROGRAM'
export const resetLastResponseStatus = 'RESET_LAST_RESPONSE_STATUS'

export const serverMessageApp = 'SERVER_MESSAGE_APP'
export const serverMessageUser = 'SERVER_MESSAGE_USER'
export const serverMessageAll = 'SERVER_MESSAGE_ALL'

export const beginDadataSearch = 'BEGIN_DADATA_SEARCH'
export const endDadataSearch = 'END_DADATA_SEARCH'
export const clearDadataSuggestions = 'CLEAR_DADATA_SUGGESTIONS'
