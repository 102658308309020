import { yup } from '../../../yup'

export const fullMainInfoStepSchema = yup.object().shape({
  staffId: yup.string().optional(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  middlename: yup.string().when('missingMiddlename', {
    is: false,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  missingMiddlename: yup.boolean().required(),
  prevLastname: yup.string().optional(),
  prevFirstname: yup.string().optional(),
  prevMiddlename: yup.string().optional(),
  countryBirthPlace: yup.string().required(),
  birthPlace: yup.string().required(),
  birthday: yup.date().typeError('Укажите дату').required(),
  sex: yup.string().required(),
  education: yup.string().required(),
  familyStatus: yup.string().required(),
  dateOfMarriage: yup.date().when('familyStatus', {
    is: '1',
    then: yup.date().typeError('Укажите дату').required(),
    otherwise: yup.date().nullable().optional(),
  }),
  residence: yup.string().required(),
  dependentsCount: yup.string().optional(),
  socialStatus: yup.string().required(),
  // закомментированно в ходе задачи ASK-1037 - поле отсутствует в постановке процесса на confluence
  // code: yup.string().optional(),
  passportSeries: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Серия состоит из 4 цифр'),
  passportNumber: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Номер состоит из 6 цифр'),
  passportIssuedBy: yup.string().required(),
  passportDate: yup.date().typeError('Укажите дату').required(),
  passportCode: yup.string().required(),
  passportPrevSeries: yup
    .string()
    .optional()
    .matches(/^$|^[0-9]+$/, 'Серия состоит из 4 цифр'),
  passportPrevNumber: yup
    .string()
    .optional()
    .matches(/^$|^[0-9]+$/, 'Номер состоит из 6 цифр'),
  secondDocumentType: yup.string().required(),
  secondDocumentSeries: yup.string().required(),
  secondDocumentNumber: yup.string().required(),
  secondDocumentDate: yup.date().typeError('Укажите дату').required(),
  secondDocumentCode: yup.string().required(),
  secondDocumentIssuedBy: yup.string().required(),
  mobilePhone: yup.string().required().phone(),
  workPhone: yup.string().required().phoneOrEmpty(),
  homePhone: yup.string().optional().phoneOrEmpty(),
  email: yup.string().required().email(),
  addressRegistration: yup.string().required(),
  registrationDate: yup.date().typeError('Укажите дату').required(),
  addressLiving: yup.string().when('isSameWithAddressRegistration', {
    is: false,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  isSameWithAddressRegistration: yup.boolean().required(),
  propertyType2: yup.string().optional(),
  property: yup.string().optional(),
  propertyType3: yup.string().optional(),
  //TODO изменить обязательность полей доп контакта в зависимости от суммы кредита (после подключения формы к api)
  additionalContactLastname: yup.string().required(),
  additionalContactFirstname: yup.string().required(),
  additionalContactMiddlename: yup.string().when('additionalContactMissingMiddlename', {
    is: false,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  additionalContactMissingMiddlename: yup.boolean().required(),
  additionalContactBirthday: yup.date().required(),
  additionalContactSocialStatus: yup.string().required(),
  additionalContactPhone: yup.string().required().phone(),
  incomeType: yup.string().required(),
  incomeCount: yup.string().price().required(),
  additionalIncomeType: yup.string().required(),
  additionalIncome: yup.string().price().required(),
  familyIncome: yup.string().required(),
  familyExpense: yup.string().price().required(),
  employmentName: yup.string().required(),
  employmentIndustry: yup.string().required(),
  employmentActivitiesType: yup.string().required(),
  employmentOwnershipType: yup.string().required(),
  employmentPositionType: yup.string().required(),
  employmentPosition: yup.string().required(),
  employmentStartDate: yup.date().typeError('Укажите дату').required(),
  employmentAddress: yup.string().required(),
  carCountry: yup.string().required(),
  carMark: yup.string().required(),
  carModel: yup.string().required(),
  carNumber: yup.string().required(),
  carProductionYear: yup
    .string()
    .required()
    .matches(/^(19|20)\d\d/, 'Неверно указан год'),
  realEstateAddress: yup.string().optional(),
})