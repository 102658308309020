import React, { FC } from 'react'
import { Container, Row } from 'reactstrap'
import { RadioButton } from '../../../../components'
import { AttributeOption } from '../../../../redux/slices/constructor/model/dto'
import { AttributeProps } from '../../model'

export const RadioAttribute: FC<AttributeProps> = ({ attribute, data, handler }) => {
  const handleOnChange = (attributeOption: AttributeOption) => () => {
    handler(attribute, attributeOption)
  }

  const options = attribute.options.map((item, index, { length }) => (
    <Row key={item.option_id} className={index + 1 !== length ? 'mb-4' : undefined}>
      <RadioButton
        labelName={item.value}
        checked={data.get(attribute) === item}
        onChange={handleOnChange(item)}
      />
    </Row>
  ))

  return (
    <Container fluid>
      <Row className="mb-2">
        <p className="mb-0 font-weight-bold">{attribute.frontend_label}</p>
      </Row>
      {options}
    </Container>
  )
}
