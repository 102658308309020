import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { actions, thunks } from '../../../redusers'
import { HandleParametersDataPayload } from '../model'

export const handleParametersData = createAsyncThunk<
  void,
  HandleParametersDataPayload,
  AppThunkApiConfig
>('constructor/handleParametersData', async (payload, { dispatch, getState }) => {
  const state = getState().itemConstructor
  const { attribute, attributeOption } = payload

  const parametersData = new Map(state.parametersData)
  parametersData.set(attribute, attributeOption)

  await dispatch(actions.constructor.setParametersData(parametersData))
  dispatch(thunks.constructor.refreshPrice())
})
