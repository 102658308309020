import React, { FC, useMemo } from 'react'
import { Block } from '../../../components/Layout/Block/Block'
import { Form } from '../../../components/Layout/form/Form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yup } from '../../../yup'
import { Button } from '../../../components/Layout/Button/Button'
import { ImageInputField } from '../../../components/Layout/form/ImageInputField'
import { ImageInput } from '../model'

const getSchema = () =>
  yup.object().shape({
    statement: yup.array().min(1, 'Добавьте файл').required(),
    schedule: yup.array().min(1, 'Добавьте файл').required(),
  })

interface Props {
  onNext: () => void
  onBack: () => void
}

interface FieldValues {
  schedule: ImageInput[]
  statement: ImageInput[]
}

export const DocumentsCreditStep: FC<Props> = ({ onBack, onNext }) => {
  const schema = useMemo(getSchema, [])
  const form = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  function onSubmit(values: FieldValues) {
    onNext()
  }

  return (
    <Form<FieldValues> form={form} onSubmit={onSubmit}>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>} />
      <Block mb={0} title="ДОКУМЕНТЫ ПО КРЕДИТУ">
        <div className="mb-2">Заявление</div>
        <div className="d-flex align-items-start">
          <Button
            color="outline-primary"
            className="mr-4 height-40"
            icon={<span className="lnr lnr-eye" />}
          >
            Просмотр
          </Button>
          <Button
            color="outline-primary"
            className="mr-4 height-40"
            icon={<span className="lnr lnr-printer" />}
          >
            Печать
          </Button>
          <ImageInputField name="statement">
            <Button
              color="outline-primary"
              className="mr-4 pointer-events-none height-40"
              icon={<span className="lnr lnr-download" />}
            >
              Вложить файл
            </Button>
          </ImageInputField>
        </div>
      </Block>
      <Block mb={0}>
        <div className="mb-2">Платежный график</div>
        <div className="d-flex align-items-start">
          <Button
            color="outline-primary"
            className="mr-4 height-40"
            icon={<span className="lnr lnr-eye" />}
          >
            Просмотр
          </Button>
          <Button
            color="outline-primary"
            className="mr-4 height-40"
            icon={<span className="lnr lnr-printer" />}
          >
            Печать
          </Button>
          <ImageInputField name="schedule">
            <Button
              color="outline-primary"
              className="mr-4 pointer-events-none height-40"
              icon={<span className="lnr lnr-download" />}
            >
              Вложить файл
            </Button>
          </ImageInputField>
        </div>
      </Block>
      <Block mb={0}>
        <div className="mb-2">QR-код для оплаты</div>
        <Button
          color="outline-primary"
          className="mr-4 height-40"
          icon={<span className="lnr lnr-eye" />}
        >
          Просмотр
        </Button>
        <Button
          color="outline-primary"
          className="mr-4 height-40"
          icon={<span className="lnr lnr-printer" />}
        >
          Печать
        </Button>
      </Block>
      <Block pt={4}>
        <div className="d-flex">
          <Button
            size="lg"
            type="submit"
            className="height-40"
            color="outline-dark"
            onClick={onBack}
          >
            Смена типа подписания
          </Button>
          <Button size="lg" type="submit" color="primary" className="ml-auto height-40">
            Далее
          </Button>
        </div>
      </Block>
    </Form>
  )
}
