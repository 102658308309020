import React, { createContext, useCallback, useContext, useState } from 'react'
import { NotificationMessage, SeverityType } from './NotificationMessage'
import { v4 as uuidV4 } from 'uuid'
import clsx from 'clsx'
import classnames from './NotificationMessage.module.scss'

type MessageType = {
  title?: string
  body: React.ReactNode
}

interface NotificationContextType {
  showNotify: (message: MessageType, severity: SeverityType) => void
  showWarning: (message: MessageType) => void
  showSuccess: (message: MessageType) => void
  showError: (message: MessageType) => void
  notificationsHistory: NotificationHistoryItem[]
  removeNotificationFromHistory: (id: string) => void
  markNotificationAsRead: (id: string | string[]) => void
}

export const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType,
)

type NotificationMessageData = {
  id: string
  message: MessageType
  severity: SeverityType
}

type NotificationHistoryItem = NotificationMessageData & { isNew: boolean }

type NotificationProviderProps = {
  children: React.ReactNode
}

function isOneOfIds(id: string, ids: string | string[]) {
  if (typeof ids === 'string') {
    return id === ids
  }
  return !!ids.find((item) => item === id)
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [sidebarNotifications, setSidebarNotifications] = useState<NotificationMessageData[]>([])
  const [notificationsHistory, setNotificationsHistory] = useState<NotificationHistoryItem[]>([])

  const showNotify = useCallback((message: MessageType, severity: SeverityType) => {
    setSidebarNotifications((prevData) => [...prevData, { id: uuidV4(), message, severity }])
  }, [])

  const showWarning = useCallback((message: MessageType) => {
    setSidebarNotifications((prevData) => [
      ...prevData,
      { id: uuidV4(), message, severity: SeverityType.WARNING },
    ])
  }, [])

  const showSuccess = useCallback((message: MessageType) => {
    setSidebarNotifications((prevData) => [
      ...prevData,
      { id: uuidV4(), message, severity: SeverityType.SUCCESS },
    ])
  }, [])

  const showError = useCallback((message: MessageType) => {
    setSidebarNotifications((prevData) => [
      ...prevData,
      { id: uuidV4(), message, severity: SeverityType.ERROR },
    ])
  }, [])

  const removeNotificationFromHistory = useCallback((id: string) => {
    setNotificationsHistory((prevState) => prevState.filter((item) => item.id !== id))
  }, [])

  const markNotificationAsRead = useCallback((ids: string | string[]) => {
    setNotificationsHistory((prevState) =>
      prevState.map((item) => (isOneOfIds(item.id, ids) ? { ...item, isNew: false } : item)),
    )
  }, [])

  const saveHistory = (notification: NotificationMessageData) => {
    setNotificationsHistory((prevState) => [{ ...notification, isNew: true }, ...prevState])
  }

  return (
    <NotificationContext.Provider
      value={{
        showNotify,
        showWarning,
        showSuccess,
        showError,
        notificationsHistory,
        removeNotificationFromHistory,
        markNotificationAsRead,
      }}
    >
      <div className={clsx('d-flex flex-column', classnames.notificationList)}>
        {sidebarNotifications.map((item, index) => {
          return (
            <NotificationMessage
              className={index >= 1 ? 'mt-2' : undefined}
              key={item.id}
              isOpen
              title={item.message.title}
              message={item.message.body}
              severity={item.severity}
              onClose={(isAutoClose?: boolean) => {
                setSidebarNotifications((prevState) =>
                  prevState.filter((value) => value.id !== item.id),
                )
                if (isAutoClose) {
                  saveHistory(item)
                }
              }}
              autoClose
            />
          )
        })}
      </div>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotification() {
  return useContext(NotificationContext)
}
