import React from 'react'
import PropTypes from 'prop-types'
import { searchTypes } from '../../../../constants'
import { CustomersTab, OrdersTab, ProductsTab } from '../../../../components'

function SearchTab({ result }) {
  /**
   * Оберег от чепухи с бэка
   */
  const items = result?.items.filter((item) => Object.keys(item || {}).length > 0) || []

  switch (result?.metadata?.group_id) {
    case searchTypes.products:
      return <ProductsTab key={searchTypes.products} items={items} />
    case searchTypes.orders:
      return <OrdersTab key={searchTypes.orders} items={items} />
    case searchTypes.customers:
      return <CustomersTab key={searchTypes.customers} items={items} />
    default:
      return null
  }
}

export default SearchTab

SearchTab.propTypes = {
  result: PropTypes.object.isRequired,
}
