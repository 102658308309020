import React, { FC, useEffect } from 'react'
import Select, { Props as SelectProps } from '../Select'
import { useFormContext } from 'react-hook-form'

interface Props extends SelectProps {
  name: string
}

export const SelectField: FC<Props> = ({ name, ...props }) => {
  const { register, setValue, getValues, errors, clearErrors } = useFormContext()

  const value = getValues(name)

  useEffect(() => {
    register(name)
  }, [name, register])

  function handleChange(value: string) {
    setValue(name, value)
    clearErrors(name)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <Select
      {...props}
      onChange={handleChange}
      initialValueId={value}
      errorMessage={errors[name]?.message}
      cartLine
    />
  )
}
