import React from 'react'

import { CartProductCount, Checkbox, Modal, Select } from '../../components'
import { Col, Row, Spinner } from 'reactstrap'
import { formatPriceRu } from '../../helpers/currency'
import { deliveryOptions, deliveryOptionPickup } from '../../constants'
import { Link } from 'react-router-dom'
import { Item, Service } from '../../api/dto/CartBasicTypes'
import classnames from './Cart.module.scss'
import clsx from 'clsx'
import { CartProductAttributes } from './CartProductAttributes'

type Props = {
  item: Item
  onDelete: (item: Item) => void
  onChangeCount?: (item: { id: string; qty: number }) => void
  onChangeServices: (item: { id: string; services: Service[] }) => void
  onChangeDeliveryType?: (item: { id: string; delivery_type: any }) => void
  deliveryType?: string
  isUpdateRequestInProgress?: boolean
  isLoading?: boolean
}

export function CartProductLine({
  item,
  onDelete,
  onChangeCount,
  onChangeServices,
  onChangeDeliveryType,
  deliveryType,
  isUpdateRequestInProgress,
  isLoading,
}: Props) {
  const services = item.services ?? []
  let filteredDeliveryOptions = [...deliveryOptions]
  if (!item?.is_in_stock) {
    filteredDeliveryOptions = filteredDeliveryOptions.filter(
      ({ value }) => value !== deliveryOptionPickup,
    )
  }
  const toggleService = (serviceId: string) => {
    const newServices = services.map((s) => {
      if (s.sku !== serviceId) return s
      return { ...s, checked: !s.checked }
    })
    onChangeServices({ id: item.id, services: newServices })
  }

  const setDeliveryType = (deliveryTypeId: string) => {
    const deliveryType = filteredDeliveryOptions.find(({ id }) => id === deliveryTypeId)?.value
    if (onChangeDeliveryType) {
      onChangeDeliveryType({ id: item.id, delivery_type: deliveryType })
    }
  }

  const deliveryTypeId = deliveryType
    ? filteredDeliveryOptions.find(({ value }) => value === deliveryType)?.id
    : undefined

  const price = item.special_price ? item.special_price : item.price
  const hasDiscountAmount = item.discount_amount > 0
  const topMarginPrice = hasDiscountAmount ? 'mt-3' : 'mt-0'
  return (
    <div className="cart-product-list-line">
      <div className="main d-flex align-items-center justify-content-between">
        <div className="col-5 d-flex align-items-start pr-0">
          <img src={item.image} alt={item.name} />
          <div className="ml-md-4 pr-2 info ellipsis">
            <Link
              to={`/product/${item.master_product_sku}`}
              className="text-decoration-none text-regular"
            >
              {item.name}
            </Link>
            {false && <div>{item.configurable_attributes.map((o) => o.value).join(', ')}</div>}
            <CartProductAttributes item={item} />
          </div>
        </div>
        <div className="col-7 d-flex align-items-center justify-content-end px-0">
          <div
            className={clsx({
              [classnames.countAndPrice]: !hasDiscountAmount,
              [classnames.countAndSpecialPrice]: hasDiscountAmount,
              [classnames.countAndSpecialPriceOnLoading]: hasDiscountAmount && isLoading,
            })}
          >
            {onChangeCount && (
              <CartProductCount
                item={item}
                onChangeCount={onChangeCount}
                isDisabled={isUpdateRequestInProgress}
              />
            )}
            {onChangeCount && !isLoading && item.qty > 1 && (
              <div className={`text-sm ${classnames.cartProductPrice}`}>
                {formatPriceRu(price, true)}/шт
              </div>
            )}
          </div>
          <div className="ml-lg-5 ml-md-3 ml-sm-1 mr-auto">
            <Select
              options={filteredDeliveryOptions}
              initialValueId={deliveryTypeId}
              disabled={isUpdateRequestInProgress}
              placeholder="Выберите тип доставки"
              selectClass="border delivery-select align-items-start pl-3"
              dropdownClass="bg-white"
              withoutSelectionClass="delivery-placeholder"
              onChange={setDeliveryType}
              cartLine
              withRotateIcon
              withoutWidthCustomization
            />
          </div>
          <div
            className={clsx(
              'price d-flex flex-column align-items-end col-4 pr-0 mt-0 text-right',
              topMarginPrice,
            )}
          >
            {isLoading ? (
              <div className="text-right mx-3" style={{ padding: hasDiscountAmount ? 16 : 0 }}>
                <Spinner style={{ color: '#27979D' }} />
              </div>
            ) : hasDiscountAmount ? (
              <>
                <div className="d-flex">{formatPriceRu(item.amount)}</div>
                <div className="text-danger text-sm">
                  Скидка&#160;
                  <span>{formatPriceRu(item.discount_amount)}</span>
                </div>
                <div className="d-flex">
                  <b>
                    Итого&#160;
                    {formatPriceRu(item.final_amount)}
                  </b>
                </div>
              </>
            ) : (
              <div className="d-flex">{formatPriceRu(item.final_amount)}</div>
            )}
          </div>
          <div className="delete ml-4 text-right">
            {onDelete && (
              <Modal
                color="warning"
                renderIcon={(toggle) => <span className="lnr lnr-trash" onClick={toggle} />}
                btnClasses="btn btn-primary form-control"
                title="Требуется подтверждение!"
                message="Выбранный товар будет удален из корзины. Вы уверены, что хотите продолжить?"
                buttonToolbar={(toggle) => (
                  <>
                    <button className="btn btn-secondary modal_cancel height-40" onClick={toggle}>
                      Отмена
                    </button>
                    <button
                      className="btn btn-warning modal_ok height-40"
                      color="warning"
                      onClick={() => {
                        toggle()
                        onDelete(item)
                      }}
                    >
                      Удалить
                    </button>
                  </>
                )}
              />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-nowrap mb-2">
        {services.filter(Boolean).map((service) => {
          return (
            <Row key={service.sku} className="my-1 mr-4 flex-nowrap justify-content-between ml-0">
              <Col xs={6}>
                <Checkbox
                  name={`service${service.sku}`}
                  onChange={() => toggleService(service.sku)}
                  labelName={service.title}
                  checked={service.checked}
                  wrapperClassName="checkmark_big-size"
                  labelClassName="ml-1"
                />
              </Col>
              <Col xs={2} className="mr-4 d-flex justify-content-end">
                {formatPriceRu(service.price, true)}
              </Col>
            </Row>
          )
        })}
      </div>
    </div>
  )
}
