import { yup } from '../../../yup'

export const mainInfoStepSchema = yup.object().shape({
  staffId: yup.string().optional(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  middlename: yup.string().when('missingMiddlename', {
    is: false,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  missingMiddlename: yup.boolean().required(),
  birthDate: yup.date().typeError('Укажите дату').required(),
  birthPlace: yup.string().required(),
  passportSeries: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Серия состоит из 4 цифр'),
  passportNumber: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Номер состоит из 6 цифр'),
  passportIssuedBy: yup.string().required(),
  passportDate: yup.date().typeError('Укажите дату').required(),
  passportCode: yup.string().required(),
  passportPrevSeries: yup
    .string()
    .optional()
    .matches(/^$|^[0-9]+$/, 'Серия состоит из 4 цифр'),
  passportPrevNumber: yup
    .string()
    .optional()
    .matches(/^$|^[0-9]+$/, 'Номер состоит из 6 цифр'),
  mobilePhone: yup.string().required().phone(),
  workPhone: yup.string().required().phone(),
  homePhone: yup.string().optional().phoneOrEmpty(),
  email: yup.string().required().email(),
  addressRegistration: yup.string().required(),
  registrationDate: yup.date().typeError('Укажите дату').required(),
  addressLiving: yup.string().when('isSameWithAddressRegistration', {
    is: false,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  incomeType: yup.string().required(),
  incomeAmount: yup.string().price().required(),
})
