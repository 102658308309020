import React, { FC } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { AttributeOption } from '../../ProductAttributeGroupComponent'
import { ProductSwatchElement } from './ProductSwatchElement'
import clsx from 'clsx'

interface Props {
  category: string
  options: AttributeOption[]
  selectedOptionId?: string
  handleClick: (optionId: string) => () => void
}

export const ProductSwatchModalCategory: FC<Props> = ({
  category,
  options,
  selectedOptionId,
  handleClick,
}) => {
  const elements = options.map((item, index, { length }) => (
    <Col
      key={item.option_id}
      xs="auto-fit"
      className={clsx('mb-3', index + 1 !== length && 'pr-2')}
    >
      <ProductSwatchElement
        option={item}
        selected={item.option_id === selectedOptionId}
        onClick={handleClick(item.option_id)}
      />
    </Col>
  ))

  return (
    <Container>
      <Row>
        <h2>{category}</h2>
      </Row>
      <Row>{elements}</Row>
    </Container>
  )
}
