import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { customerShape } from '../../constants'
import {
  CUSTOMER_NEED_VERIFY_STATE,
  CUSTOMER_UPDATED_STATE,
  CUSTOMER_ERROR_STATE,
  STATUS_SUCCESS,
  CUSTOMER_RELOADED_STATE,
} from '../../api/ws/customer/constants'
import { customerHelper } from '../../helpers'
import { Modal, CheckSmsCode, ErrorServiceModal, CustomerSuggestions } from '../'
import { customer } from '../../redux/actions'
import { Button } from '../Layout/Button/Button'
import { TYPE_CUSTOMER } from '../../constants/transactionTypes'

function CustomerModals({
  appId,
  customerState,
  updateCustomer,
  customer,
  setDefaultState,
  resetVersionMismatch,
  hasVersionMismatch,
  cancelConfirmUpdate,
  transactionsMap,
  responseStatus,
  getCustomerById,
}) {
  const [isConfirmModalActive, setIsConfirmModalActive] = useState(false)
  useEffect(() => {
    if (customerState === CUSTOMER_ERROR_STATE || customerState === CUSTOMER_UPDATED_STATE) {
      setIsConfirmModalActive(false)
    }
  }, [customerState])

  const handleSave = (custom = {}) => {
    const data = {
      ...customerHelper.customerMapping(customer),
      ...custom,
      correlationId: customer.correlation_id,
    }
    updateCustomer(data)
  }

  const handleConfirmationSmsRequest = () => {
    setIsConfirmModalActive(true)
  }

  const handleCheckSms = (smsCode) => {
    handleSave(customerHelper.smsCodeInLoyaltyProgramMapping(smsCode))
  }

  return (
    <>
      {customerState === CUSTOMER_ERROR_STATE && (
        <ErrorServiceModal
          customer={customer}
          onToggle={() => {
            setDefaultState()
            if (customer.customer_id) {
              getCustomerById(customer.customer_id)
            }
          }}
          appId={appId}
        />
      )}
      <Modal
        renderIcon={() => {}}
        isOpenExternal={customerState === CUSTOMER_NEED_VERIFY_STATE}
        onToggle={() => cancelConfirmUpdate(customer.customer_id)}
        buttonToolbar={() => (
          <>
            <Button
              className="modal_cancel height-40"
              onClick={() => {
                cancelConfirmUpdate(customer.customer_id)
              }}
            >
              Отмена
            </Button>
            <Button
              className="modal_ok height-40"
              color="primary"
              onClick={handleConfirmationSmsRequest}
            >
              Продолжить
            </Button>
          </>
        )}
        color="primary"
        title="Требуется подтверждение!"
        message="Были внесены изменения в данные Клиента. Для их сохранения может потребоваться ввести SMS-код, отправленный на телефон."
      />
      <Modal
        renderIcon={() => {}}
        isOpenExternal={customerState === CUSTOMER_RELOADED_STATE}
        onToggle={() => {
          setDefaultState()
        }}
        color="primary"
        title="Внимание!"
        buttonToolbar={() => (
          <>
            <Button className="modal_cancel height-40" onClick={() => setDefaultState()}>
              Закрыть
            </Button>
          </>
        )}
        message={
          <div className="ml-1 mr-1 mt-2 mb-2">
            <p>
              Пользователь с указанными данными уже зарегистрирован.
              <br /> Вы можете перейти на его страницу:
            </p>
            <CustomerSuggestions
              items={[
                {
                  id: customer?.customer_id,
                  phone: customer?.phone,
                  email: customer?.email,
                  firstname: customer?.given_name,
                  middlename: customer?.middle_name,
                  lastname: customer?.surname,
                },
              ]}
            />
          </div>
        }
      />
      <CheckSmsCode
        isOpen={isConfirmModalActive}
        checkSmsCode={(code) => {
          handleCheckSms(code)
          setIsConfirmModalActive(false)
        }}
        retrySending={handleConfirmationSmsRequest}
        close={() => {
          setIsConfirmModalActive(false)
        }}
      />
      <Modal
        color="danger"
        renderIcon={() => {}}
        isOpenExternal={hasVersionMismatch}
        title="Ошибка!"
        message={
          <>
            Ваши изменения по клиенту{' '}
            <b>
              {[customer.surname, customer.given_name, customer.middle_name]
                .filter(Boolean)
                .join(' ')}
            </b>{' '}
            не были сохранены, так как в системе имеются более актуальные данные. <br /> <br />
            Пожалуйста, посмотрите карточку клиента и при необходимости отредактируйте её повторно.
          </>
        }
        onToggle={resetVersionMismatch}
        buttonToolbar={(toggle) => (
          <Button className="btn-primary height-40" onClick={toggle} color="primary">
            Перейти к клиенту
          </Button>
        )}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  appId: state.app.instanceId,
  customer: state.customer.result,
  customerState: state.customer.state,
  hasVersionMismatch: state.customer.hasVersionMismatch,
  transactionsMap: state.correlationIds.transactions,
  responseStatus: state.customer.responseStatus,
})

const mapDispatchToProps = {
  updateCustomer: customer.update,
  setDefaultState: customer.setDefaultState,
  getCustomerById: customer.getById,
  resetCustomerEdited: customer.resetCustomerEdited,
  resetVersionMismatch: customer.resetVersionMismatch,
  cancelConfirmUpdate: customer.cancelConfirmUpdate,
}

CustomerModals.propTypes = {
  appId: PropTypes.string.isRequired,
  customer: PropTypes.shape(customerShape).isRequired,
  updateCustomer: PropTypes.func.isRequired,
  customerState: PropTypes.string.isRequired,
  setDefaultState: PropTypes.func.isRequired,
  resetCustomerEdited: PropTypes.func.isRequired,
  resetVersionMismatch: PropTypes.func.isRequired,
  cancelConfirmUpdate: PropTypes.func.isRequired,
  hasVersionMismatch: PropTypes.bool.isRequired,
  transactionsMap: PropTypes.instanceOf(Map).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModals)
