import React, { ChangeEvent, FC, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '../../../components/Layout/form/Form'
import { Block } from '../../../components/Layout/Block/Block'
import { Button } from '../../../components/Layout/Button/Button'
import { Col, Row } from 'reactstrap'
import { InputField } from '../../../components/Layout/form/InputField'
import { DatePickerField } from '../../../components/Layout/form/DatePickerField'
import { CheckboxField } from '../../../components/Layout/form/CheckboxField'
import { SelectField } from '../../../components/Layout/form/SelectField'
import { RadioField } from '../../../components/Layout/form/RadioField'
import classnames from '../Credit.module.scss'
import { fullMainInfoStepSchema } from '../schemas'
import { passportNumberMask, passportSeriesMask, phoneMask, yearMask } from '../../../constants'

interface Props {
  onNext: () => void
  onBack: () => void
}

interface FieldValues {
  staffId: string
  lastname: string
  firstname: string
  middlename: string
  missingMiddlename: boolean
  prevLastname: string
  prevFirstname: string
  prevMiddlename: string
  birthPlace: string
  sex: 'm' | 'w'
  dependentsCount: string
  passportIssuedBy: string
  passportCode: string
  secondDocumentSeries: string
  secondDocumentNumber: string
  secondDocumentCode: string
  secondDocumentIssuedBy: string
  email: string
  addressRegistration: string
  addressLiving: string
  isSameWithAddressRegistration: boolean
  additionalContactLastname: string
  additionalContactFirstname: string
  additionalContactMiddlename: string
  additionalContactMissingMiddlename: boolean
  incomeCount: string
  additionalIncome: string
  familyIncome: string
  familyExpense: string
  employmentName: string
  employmentAddress: string
  carMark: string
  carModel: string
  carNumber: string
  realEstateAddress: string
  countryBirthPlace: string
  birthday: Date
  education: string
  familyStatus: string
  dateOfMarriage: Date
  residence: string
  socialStatus: string
  passportSeries: string
  passportNumber: string
  passportDate: Date
  passportPrevSeries: string
  passportPrevNumber: string
  secondDocumentType: string
  secondDocumentDate: Date
  mobilePhone: string
  workPhone: string
  homePhone: string
  registrationDate: Date
  additionalContactBirthday: Date
  additionalContactSocialStatus: string
  additionalContactPhone: string
  incomeType: string
  additionalIncomeType: string
  employmentIndustry: string
  employmentActivitiesType: string
  employmentOwnershipType: string
  employmentPositionType: string
  employmentPosition: string
  employmentStartDate: Date
  carCountry: string
  carProductionYear: string
}

export const FullMainInfoStep: FC<Props> = ({ onNext, onBack }) => {
  const [isLivingAddressRequired, setIsLivingAddressRequired] = useState(true)
  const [isDateOfMarriageRequired, setIsDateOfMarriageRequired] = useState(false)
  const [isMiddlenameMissing, setIsMiddlenameMissing] = useState(false)
  const [isContactMiddlenameMissing, setIsContactMiddlenameMissing] = useState(false)

  const form = useForm<FieldValues>({
    resolver: yupResolver(fullMainInfoStepSchema),
    defaultValues: {
      birthday: new Date('1992-06-15'),
      sex: 'm',
      passportSeries: '',
      passportNumber: '',
      passportDate: '',
      passportPrevSeries: '',
      passportPrevNumber: '',
      mobilePhone: '',
      workPhone: '',
      homePhone: '',
      additionalContactPhone: '',
      carProductionYear: '',
    },
  })

  const clearError = (name: keyof FieldValues) => {
    form.clearErrors(name)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'missingMiddlename') {
      form.setValue('middlename', undefined)
      setIsMiddlenameMissing(event.target.checked)
      clearError('middlename')
      return
    }
    if (event.target.name === 'isSameWithAddressRegistration') {
      form.setValue('addressLiving', undefined)
      setIsLivingAddressRequired(!event.target.checked)
      clearError('addressLiving')
      return
    }
    if (event.target.name === 'additionalContactMissingMiddlename') {
      form.setValue('additionalContactMiddlename', undefined)
      setIsContactMiddlenameMissing(event.target.checked)
      clearError('additionalContactMiddlename')
      return
    }
  }

  const handleFamilyStatusChange = (value: string) => {
    setIsDateOfMarriageRequired(value === '1')
    if (value === '0') {
      form.setValue('dateOfMarriage', null)
      clearError('dateOfMarriage')
    }
    clearError('familyStatus')
  }

  function onSubmit(values: FieldValues) {
    onNext()
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>} />
      <Block mb={0} title="ОСНОВНЫЕ СВЕДЕНИЯ">
        <Row className="mb-2">
          <Col xs={4}>
            <InputField name="staffId" label="Код сотрудника" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <InputField required name="lastname" label="Фамилия" />
          </Col>
          <Col xs={4}>
            <InputField required name="firstname" label="Имя" />
          </Col>
          <Col xs={4}>
            <InputField
              required={!isMiddlenameMissing}
              name="middlename"
              label="Отчество"
              disabled={isMiddlenameMissing}
            />
          </Col>
          <Col xs={4} className="ml-auto">
            <CheckboxField
              name="missingMiddlename"
              labelName="Отчество отсутствует"
              onChange={handleCheckboxChange}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <InputField name="prevLastname" label="Прежняя фамилия" />
          </Col>
          <Col xs={4}>
            <InputField name="prevFirstname" label="Прежнее имя" />
          </Col>
          <Col xs={4}>
            <InputField name="prevMiddlename" label="Прежнее отчество" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={6}>
            <SelectField
              required
              name="countryBirthPlace"
              label="Страна рождения"
              options={[
                { name: 'Страна 1', id: '1' },
                { name: 'Страна 2', id: '2' },
                { name: 'Страна 3', id: '3' },
                { name: 'Страна 4', id: '4' },
              ]}
            />
          </Col>
          <Col xs={6}>
            <DatePickerField required label="Дата рождения" showYearDropdown name="birthday" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={6}>
            <InputField required name="birthPlace" label="Место рождения" />
          </Col>
          <Col xs={6}>
            <div className={classnames.radioLabel}>Пол</div>
            <div className="d-flex">
              <div className="mr-4">
                <RadioField name="sex" value="m" labelName="Мужчина" />
              </div>
              <div>
                <RadioField name="sex" value="w" labelName="Женщина " />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <SelectField
              required
              name="education"
              label="Образование"
              options={[
                { name: 'Среднее', id: '1' },
                { name: 'Неполное высшее', id: '2' },
                { name: 'Высшее', id: '3' },
                { name: 'Ученая степень', id: '4' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <SelectField
              required
              name="familyStatus"
              label="Семейное положение"
              options={[
                { name: 'Не женат / не замужем', id: '0' },
                { name: 'Женат / замужем', id: '1' },
              ]}
              onChange={handleFamilyStatusChange}
            />
          </Col>
          <Col xs={4}>
            <DatePickerField
              required={isDateOfMarriageRequired}
              disabled={!isDateOfMarriageRequired}
              showYearDropdown
              name="dateOfMarriage"
              label="Дата вступления в брак"
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <SelectField
              required
              name="residence"
              label="Проживание"
              options={[
                { name: 'Проживание 1', id: '1' },
                { name: 'Проживание 2', id: '2' },
                { name: 'Проживание 3', id: '3' },
                { name: 'Проживание 4', id: '4' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <InputField
              type="number"
              name="dependentsCount"
              label="Кол-во иждивенцев"
              step="0"
              min="0"
            />
          </Col>
          <Col xs={4}>
            {/* TODO заменить опции на опции из соответствующего справочника  */}
            <SelectField
              required
              name="socialStatus"
              label="Социальный статус"
              options={[
                { name: 'Социальный статус 0', id: '0' },
                { name: 'Социальный статус 1', id: '1' },
                { name: 'Социальный статус 2', id: '2' },
              ]}
            />
          </Col>
        </Row>
        {/*  закомментированно в ходе задачи ASK-1037 - поле отсутствует в постановке */}
        {/* <Row className="mb-2">
          <Col xs={4}>
            <InputField name="code" label="Кодовое слово" />
          </Col>
        </Row> */}
      </Block>
      <Block mb={0} title="ПАСПОРТ">
        <Row>
          <Col xs={4}>
            <InputField
              name="passportSeries"
              required
              label="Серия паспорта"
              mask={passportSeriesMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              name="passportNumber"
              required
              label="Номер паспорта"
              mask={passportNumberMask}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <InputField name="passportIssuedBy" required label="Кем выдан" />
          </Col>
          <Col xs={4}>
            <InputField name="passportCode" required label="Код подразделения" />
          </Col>
          <Col xs={4}>
            <DatePickerField required label="Дата выдачи" showYearDropdown name="passportDate" />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <InputField
              name="passportPrevSeries"
              label="Серия предыдущего паспорта"
              mask={passportSeriesMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              name="passportPrevNumber"
              label="Номер предыдущего паспорта"
              mask={passportNumberMask}
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="ВТОРОЙ ДОКУМЕНТ">
        <Row className="mb-2">
          <Col xs={4}>
            <SelectField
              required
              name="secondDocumentType"
              label="Тип документа"
              options={[
                { name: 'Паспорт', id: '1' },
                { name: 'Права', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <InputField name="secondDocumentSeries" required label="Серия" />
          </Col>
          <Col xs={4}>
            <InputField name="secondDocumentNumber" required label="Номер" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <DatePickerField
              required
              label="Дата выдачи"
              showYearDropdown
              name="secondDocumentDate"
            />
          </Col>
          <Col xs={4}>
            <InputField name="secondDocumentCode" required label="Код подразделения" />
          </Col>
          <Col xs={4}>
            <InputField required name="secondDocumentIssuedBy" label="Кем выдан" />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="КОНТАКТЫ">
        <Row>
          <Col xs={4}>
            <InputField required label="E-mail" type="text" name="email" />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Мобильный номер телефона"
              type="tel"
              name="mobilePhone"
              mask={phoneMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Рабочий номер телефона"
              type="tel"
              name="workPhone"
              mask={phoneMask}
            />
          </Col>
          <Col xs={4}>
            <InputField
              label="Домашний номер телефона"
              type="tel"
              name="homePhone"
              mask={phoneMask}
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="АДРЕСА">
        <Row>
          <Col xs={8}>
            <InputField required name="addressRegistration" label="Адрес регистрации" />
          </Col>
          <Col xs={4}>
            <DatePickerField required name="registrationDate" label="Дата регистрации" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputField
              required={isLivingAddressRequired}
              disabled={!isLivingAddressRequired}
              name="addressLiving"
              label="Место жительства"
            />
          </Col>
          <Col xs={4} className="mr-auto">
            <CheckboxField
              name="isSameWithAddressRegistration"
              labelName="Совпадает с адресом регистрации"
              onChange={handleCheckboxChange}
            />
          </Col>
        </Row>
      </Block>

      {/* Закомментировано в ходе задачи ASK-1037, так как не соответствует постановке процесса Оформения кредита на confluence, после принятия задачи удалить */}
      {/* <Block mb={0} title="КОНТАКТНАЯ ИНФОРМАЦИЯ">
        <Row className="mb-2">
          <Col xs={12}>
            <InputField required name="addressRegistration" label="Адрес регистрации" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <DatePickerField
              showYearDropdown
              required
              name="dateRegistration"
              label="Дата регистрации"
            />
          </Col>
          <Col xs={4}>
            <SelectField
              options={[
                { name: 'Тип 1', id: '1' },
                { name: 'Тип 2', id: '2' },
              ]}
              name="propertyType"
              label="Тип имущества"
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4} className="ml-auto">
            <CheckboxField
              name="isSameWithAddressRegistration"
              labelName="Совпадает с адресом регистрации"
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12}>
            <InputField required name="addressLiving" label="Место жительства" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <DatePickerField
              showYearDropdown
              required
              name="dateRegistration2"
              label="Дата регистрации"
            />
          </Col>
          <Col xs={4}>
            <SelectField
              options={[
                { name: 'Тип 1', id: '1' },
                { name: 'Тип 2', id: '2' },
              ]}
              name="propertyType2"
              label="Тип имущества"
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={8}>
            <SelectField
              options={[
                { name: 'Тип 1', id: '1' },
                { name: 'Тип 2', id: '2' },
              ]}
              name="property"
              label="Имущество"
            />
          </Col>
          <Col xs={4}>
            <SelectField
              options={[
                { name: 'Тип 1', id: '1' },
                { name: 'Тип 2', id: '2' },
              ]}
              name="propertyType3"
              label="Тип имущества"
            />
          </Col>
        </Row>
      </Block> */}
      {/* <Block mb={0} title="ТЕЛЕФОН">
        <Row className="mb-2">
          <Col xs={4}>
            <InputField required label="Мобильный телефон" type="phone" name="mobilePhone" />
          </Col>
          <Col xs={4}>
            <InputField label="Домашний телефон" type="phone" name="homePhone" />
          </Col>
          <Col xs={4}>
            <InputField label="Рабочий телефон" type="phone" name="workPhone" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <InputField label="Email" name="email" />
          </Col>
        </Row>
      </Block> */}
      <Block mb={0} title="ДОПОЛНИТЕЛЬНЫЙ КОНТАКТ">
        <Row className="mb-2">
          <Col xs={4}>
            <InputField required label="Фамилия" name="additionalContactLastname" />
          </Col>
          <Col xs={4}>
            <InputField required label="Имя" name="additionalContactFirstname" />
          </Col>
          <Col xs={4}>
            <InputField
              required={!isContactMiddlenameMissing}
              name="additionalContactMiddlename"
              label="Отчество"
              disabled={isContactMiddlenameMissing}
            />
          </Col>
          <Col xs={4} className="ml-auto">
            <CheckboxField
              name="additionalContactMissingMiddlename"
              labelName="Отчество отсутствует"
              onChange={handleCheckboxChange}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <DatePickerField
              required
              label="Дата рождения"
              showYearDropdown
              name="additionalContactBirthday"
            />
          </Col>
          <Col xs={4}>
            <SelectField
              required
              name="additionalContactSocialStatus"
              label="Социальный статус"
              options={[
                { name: 'Тип 1', id: '1' },
                { name: 'Тип 2', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Номер телефона"
              type="tel"
              name="additionalContactPhone"
              mask={phoneMask}
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="ДОХОДЫ">
        <Row className="mb-2">
          <Col xs={4}>
            <SelectField
              required
              name="incomeType"
              label="Тип основного дохода"
              options={[
                { name: 'Зарплата', id: '1' },
                { name: 'Казино', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Основной доход в месяц"
              type="number"
              name="incomeCount"
              step="0.01"
              min="0"
            />
          </Col>
          <Col xs={4}>
            <SelectField
              required
              name="additionalIncomeType"
              label="Тип доп. дохода"
              options={[
                { name: 'Зарплата', id: '1' },
                { name: 'Казино', id: '2' },
              ]}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <InputField
              required
              label="Доп. доход в месяц"
              type="number"
              name="additionalIncome"
              step="0.01"
              min="0"
            />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Семейный доход в месяц"
              type="number"
              name="familyIncome"
              step="0.01"
              min="0"
            />
          </Col>
          <Col xs={4}>
            <InputField
              required
              label="Семейный расход в месяц"
              type="number"
              name="familyExpense"
              step="0.01"
              min="0"
            />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="МЕСТО РАБОТЫ">
        <Row className="mb-2">
          <Col xs={4}>
            <InputField required label="Название организации" name="employmentName" />
          </Col>
          <Col xs={4}>
            <SelectField
              required
              name="employmentIndustry"
              label="Отрасль производства"
              options={[
                { name: 'Отрасль 1', id: '1' },
                { name: 'Отрасль 2', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <SelectField
              required
              name="employmentActivitiesType"
              label="Вид деятельности"
              options={[
                { name: 'Деятельность 1', id: '1' },
                { name: 'Деятельность 2', id: '2' },
              ]}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <SelectField
              required
              name="employmentOwnershipType"
              label="Форма собственности"
              options={[
                { name: 'Форма 1', id: '1' },
                { name: 'Форма 2', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <SelectField
              required
              name="employmentPositionType"
              label="Тип должности"
              options={[
                { name: 'Тип должности 1', id: '1' },
                { name: 'Тип должности 2', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <SelectField
              required
              name="employmentPosition"
              label="Должность"
              options={[
                { name: 'Должность 1', id: '1' },
                { name: 'Должность 2', id: '2' },
              ]}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <DatePickerField
              required
              showYearDropdown
              name="employmentStartDate"
              label="Дата начала работы"
            />
          </Col>
          <Col xs={8}>
            <InputField required name="employmentAddress" label="Адрес места работы" />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="ТРАНСПОРТНОЕ СРЕДСТВО">
        <Row className="mb-2">
          <Col xs={4}>
            <SelectField
              required
              name="carCountry"
              label="Страна-производитель"
              options={[
                { name: 'Страна 1', id: '1' },
                { name: 'Страна 2', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <InputField required name="carMark" label="Марка" />
          </Col>
          <Col xs={4}>
            <InputField required name="carModel" label="Модель" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <InputField required name="carNumber" label="Гос. номер" />
          </Col>
          <Col xs={4}>
            <InputField required name="carProductionYear" label="Год выпуска" mask={yearMask} />
          </Col>
        </Row>
      </Block>
      <Block mb={0} title="НЕДВИЖИМОСТЬ">
        <Row className="mb-2">
          <Col xs={12}>
            <InputField label="Адрес недвижимости" name="realEstateAddress" />
          </Col>
        </Row>
      </Block>
      <Block pt={4}>
        <div className="d-flex">
          <Button size="lg" type="submit" color="secondary" className="height-40" onClick={onBack}>
            Назад
          </Button>
          <Button size="lg" type="submit" color="primary" className="ml-auto height-40">
            Далее
          </Button>
        </div>
      </Block>
    </Form>
  )
}

FullMainInfoStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}
