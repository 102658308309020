import * as actions from '../types'
import { TYPE_CUSTOMER, TYPE_LOYALTY_PROGRAM, TYPE_CART } from '../../constants/transactionTypes'
import { STATUS_UPDATED, STATUS_ERROR } from '../../constants/transactionStatuses'

const initState = {
  transactions: new Map(),
}

export default function (state = initState, action) {
  if (
    action.type === actions.endUpdateCustomer ||
    action.type === actions.endCreateCustomer ||
    action.type === actions.beginUpdateCartCustomer
  ) {
    const { correlationId, contact_state_name: status } = action.data
    const { transactions } = state
    transactions.set(correlationId, {
      type: TYPE_CUSTOMER,
      createdAt: new Date(),
      status,
    })
    return {
      transactions,
    }
  }
  if (
    action.type === actions.endRegistrationInLoyaltyProgram ||
    action.type === actions.endSendSmsCodeInLoyaltyProgram
  ) {
    const { correlation_id, contact_state_name: status } = action.data
    const { transactions } = state
    transactions.set(correlation_id, {
      type: TYPE_LOYALTY_PROGRAM,
      createdAt: new Date(),
      status,
    })
    return {
      transactions,
    }
  }

  if (
    [actions.startCartUpdate, actions.endAddItemToCart, actions.endAddWaitingItemToCart].includes(
      action.type,
    )
  ) {
    const { correlationId } = action.data
    const { transactions } = state
    transactions.set(correlationId, {
      type: TYPE_CART,
      createdAt: new Date(),
      status: undefined,
    })
    return {
      transactions,
    }
  }

  if (action.type === actions.serverMessageApp) {
    const { correlation_id: currentCorrelationId } = action.data
    if (currentCorrelationId) {
      const {
        payload: {
          data: { contact_state_name: status },
        },
      } = action.data
      const { transactions } = state
      if (status === STATUS_UPDATED || status === STATUS_ERROR) {
        transactions.delete(currentCorrelationId)
      } else {
        let transaction = transactions.get(currentCorrelationId)
        if (!transaction?.type) {
          return { transactions }
        }
        transactions.set(currentCorrelationId, {
          type: transaction.type,
          createdAt: new Date(),
          status,
        })
        return { transactions }
      }
    }
  }

  return { ...state }
}
