import React, { FC, MouseEventHandler, ReactNode } from 'react'
import { Button as ButtonStrap } from 'reactstrap'
import clsx from 'clsx'
import style from './Button.module.scss'
import './Button.scss'
import { ButtonProps } from 'reactstrap/lib/Button'

interface Props extends ButtonProps {
  children: ReactNode
  color?: 'outline-primary' | 'primary' | 'outline-dark' | 'secondary' | 'outline-primary-grey'
  onClick?: MouseEventHandler<HTMLButtonElement>
  size?: 'lg'
  className?: string
  icon?: ReactNode
}

export const Button: FC<Props> = ({ children, icon, ...props }) => {
  const content = (() => {
    if (icon) {
      return (
        <div className="d-flex align-items-center">
          <span className={clsx(style.icon, 'mr-2')}>{icon}</span> {children}
        </div>
      )
    }

    return children
  })()

  return <ButtonStrap {...props}>{content}</ButtonStrap>
}
