import React, { FC } from 'react'
import { Block } from '../../../components/Layout/Block/Block'
import { Button } from '../../../components/Layout/Button/Button'
import style from '../Credit.module.scss'
import clsx from 'clsx'
import { TextBadge } from '../../../components/Layout/TextBadge/TextBadge'

interface Props {
  onBack: () => void
  onNext: () => void
}

export const BanksStep: FC<Props> = ({ onBack, onNext }) => {
  return (
    <div>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>}>
        <table className={clsx('w-100', style.table)}>
          <thead>
            <tr>
              <th>Приоритет</th>
              <th>Банк</th>
              <th>Кредитный продукт</th>
              <th>Статус</th>
              <th>Сообщение</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Сбербанк</td>
              <td>Потребительский кредит</td>
              <td>
                <TextBadge color="info">Отправка в банк</TextBadge>
              </td>
              <td>—</td>
            </tr>
            <tr>
              <td>2</td>
              <td>ВТБ</td>
              <td>10% годовых</td>
              <td>
                <TextBadge color="dark">Черновик</TextBadge>
              </td>
              <td>—</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Альфа-банк</td>
              <td>Кредит без предоплат</td>
              <td>
                <TextBadge color="danger">Заявка отклонена</TextBadge>
              </td>
              <td>—</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Восточный банк</td>
              <td>Кредит 10-10</td>
              <td>
                <TextBadge color="warning">Требуются данные</TextBadge>
              </td>
              <td>Необходимо заполнить полную анкету</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Тинькофф</td>
              <td>10% годовых</td>
              <td>
                <TextBadge color="success">Заявка одобрена</TextBadge>
              </td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </Block>
      <Block pt={4}>
        <div className="d-flex">
          <Button
            size="lg"
            type="submit"
            color="outline-dark"
            className="height-40"
            onClick={onBack}
          >
            Отменить
          </Button>
          <Button
            size="lg"
            type="submit"
            color="primary"
            className="ml-auto height-40"
            onClick={onNext}
          >
            Далее
          </Button>
        </div>
      </Block>
    </div>
  )
}
