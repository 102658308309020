import { api } from './api'

const loyaltyProgramRequestPath = '/pos/lp/sms/request'

export enum DiscountCard {
  DISCOUNT_CARD_TYPE_VOID = 0,
  DISCOUNT_CARD_TYPE_SBERBANK = 2,
  DISCOUNT_CARD_TYPE_AEROFLOT = 4,
  DISCOUNT_CARD_TYPE_ASKONA = 5,
}

type RequestType = {
  shop_code: string
  terminal_id: string
  loyalty_program: DiscountCard
  channel: string
  client: {
    mobile_phone: string
    card_number: string
  }
}

export const loyaltyProgramRequest = (data: RequestType) => {
  return api.post({
    path: loyaltyProgramRequestPath,
    data,
  })
}
