import React from 'react'
import { Row, Col } from 'reactstrap'

type EditCartFieldProps = {
  children: React.ReactNode
  name: string
}

export const EditCartField = ({ children, name }: EditCartFieldProps) => {
  return (
    <Row className="d-flex mb-2">
      <Col xs="2">{name}</Col>
      <Col xs="10">{children}</Col>
    </Row>
  )
}
