import React from 'react'
import PropTypes from 'prop-types'
import { productShape } from '../../../constants'

function PropertiesTab({ item }) {
  if (!item.properties) {
    return null
  }
  return (
    <div className="w-100">
      {item.properties.map((property) => (
        <div key={property.name} className="mb-3">
          <div className="border-dark mb-1 propertyName">{property.name}</div>
          <div className="border-dark propertyValue">{property.value}</div>
        </div>
      ))}
    </div>
  )
}

export default PropertiesTab

PropertiesTab.propTypes = {
  item: PropTypes.shape(productShape),
}
