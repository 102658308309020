import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TechSupport } from '../TechSupport/TechSupport'
import { useSwitcher } from '../../hooks/useSwitcher'
import posPdf from './POS_01.02.2022.pdf'

function Footer({ sidebarIsOpened }) {
  const { isOn, toggle, off } = useSwitcher(false)

  return (
    <>
      <TechSupport isOpen={isOn} toggle={toggle} close={off} />
      <footer className={`footer bg-light ${sidebarIsOpened ? 'open' : ''}`}>
        <div className="footer-top d-flex justify-content-around align-items-center">
          <a target="_blank" rel="noreferrer" href="https://www.askona.ru" className="text-primary">
            Интернет-магазин
          </a>
          <a target="_blank" rel="noreferrer" href={posPdf}>
            Инструкция по работе с POS
          </a>
          {/*<span className="text-primary cursor-pointer" onClick={on}>*/}
          {/*  Написать в техподдержку:*/}
          {/*  <br />*/}
          {/*  support_pos@askona.ru*/}
          {/*</span>*/}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={require('../../images/logo.svg')} alt="Askona" height={60} />
          </div>
          <div className="ml-4 copy">
            &copy; 1990-{new Date().getFullYear()}
            <br />
            Компания &quot;Аскона&quot;
          </div>
        </div>
      </footer>
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarIsOpened: state.app.sidebarIsOpened,
})

export default connect(mapStateToProps)(Footer)

Footer.propTypes = {
  sidebarIsOpened: PropTypes.bool.isRequired,
}
