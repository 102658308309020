import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Block } from '../../components/Layout/Block/Block'
import { TableList } from '../../components/TableList/TableList'
import { Row, Col, Button } from 'reactstrap'
import classnames from './Order.module.scss'
import { formatPriceRu } from '../../helpers/currency'
import { OrderProduct } from './OrderProduct'
import { Address } from './Address'
import { addressShape } from '../../constants'
import { useSwitcher } from '../../hooks/useSwitcher'
import { DocumentModal } from '../../components/DocumentModal/DocumentModal'
import {
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_TERMINAL,
} from '../../constants/paymentType'
import { SYSTEM_TYPE_POS } from '../../constants/systemType'
import { OrderSevices } from './OrderServices'
import { CartTotalLine } from '../../components/Cart/CartTotalLine'
import { AdditionalPanelSwitcher } from '../../components/Layout/AdditionalPanelSwitcher'
import { EmailSendModal } from '../../components/EmailSendModal/EmailSendModal'

const TYPE_PRE_CONTRACT = 'pre_contract'

const TYPE_PART_CONTRACT = 'part_contract'

const TYPE_FULL_CONTRACT = 'full_contract'

export function OrderPage({
  entity_id,
  address,
  discountAmount,
  clientName,
  clientEmail,
  orderId,
  status,
  statusPayment,
  createdAt,
  dueDate,
  products = [],
  promo,
  grandTotal,
  deliveryPrice,
  productTotal,
  shopName,
  // onClickChange,
  servicePrice,
  onClickPay,
  staff,
  isCompleted = false,
  additionalServices,
  payments = [],
  comment,
  contract,
  cartItems,
}) {
  const documentModalSwitcher = useSwitcher()
  const emailSendModalSwitcher = useSwitcher()
  const withDiscount = discountAmount > 0
  const withAllServices = servicePrice > 0
  const withAdditionalServices = Boolean(additionalServices.length)
  const hasComments = Boolean(comment)
  const paymentTotal = payments.reduce(
    (paymentTotalAcc, { sum }) => Number(sum) + paymentTotalAcc,
    0,
  )

  const isDeliveryPrice = deliveryPrice > 0

  const documentTitle = useMemo(() => {
    if (contract?.contract_type === TYPE_PRE_CONTRACT) {
      return 'Cоглашение о намерениях (предзаказ).pdf'
    }
    if (contract?.contract_type === TYPE_PART_CONTRACT) {
      return 'Договор розница Аскона 50%.pdf'
    }
    if (contract?.contract_type === TYPE_FULL_CONTRACT) {
      return 'Договор розница Аскона 100%.pdf'
    }
    return ''
  }, [contract?.contract_type])

  return (
    <>
      <div className="order">
        <h1>Заказ {orderId}</h1>
        <div className="mb-4">
          <Row>
            <Col xs={9}>
              <Block title="ИНФОРМАЦИЯ О ЗАКАЗЕ">
                <TableList
                  items={[
                    {
                      title: 'Статус',
                      content: status,
                      contentClass: classnames.contentInMarkedContainer,
                    },
                    {
                      title: 'Статус оплаты',
                      content: statusPayment,
                    },
                    dueDate
                      ? {
                          title: 'Срок исполнения заказа',
                          content: dueDate,
                        }
                      : {},
                    {
                      title: 'Дата оформления',
                      content: createdAt,
                    },
                    {
                      title: 'Место оформления',
                      content: shopName,
                    },
                    {
                      title: 'Сотрудник',
                      // TODO ASK-407 hardcode
                      content: staff ?? 'Анохин Василий Артурович',
                    },
                    {
                      title: 'Клиент',
                      content: clientName,
                    },
                  ].filter(Boolean)}
                />
              </Block>
            </Col>
            <Col xs={3}>
              {/*<Button color="primary" block onClick={onClickChange}>*/}
              {/*  Изменить*/}
              {/*</Button>*/}
              <Button
                className="height-40"
                color="primary"
                block
                onClick={onClickPay}
                disabled={isCompleted}
              >
                Оплатить
              </Button>
              <Button
                className="height-40"
                color="primary"
                block
                onClick={documentModalSwitcher.on}
                disabled={!(address && isDeliveryPrice)}
              >
                Сформировать документы
              </Button>
            </Col>
          </Row>
        </div>
        <Block title="СОСТАВ ЗАКАЗА">
          {products.map((product) => {
            return (
              <OrderProduct
                services={product.services}
                img={product.img}
                masterProductName={product.masterProductName}
                paramsString={product.paramsString}
                qty={product.qty}
                total={product.total}
                totalWithoutDiscount={product.totalWithoutDiscount}
                key={product.id}
                deliveryType={product.deliveryType}
                discount={product.discount}
                price={product.price}
                product={product}
              />
            )
          })}
          {withAdditionalServices && (
            <Row className="pt-3">
              <Col>
                <OrderSevices services={additionalServices} />
              </Col>
            </Row>
          )}
          <Row className="pt-3">
            <Col xs={9} />
            <Col xs={3}>
              <CartTotalLine title="Товары:" price={productTotal} />
              {withDiscount && (
                <>
                  <CartTotalLine
                    title="в т.ч. скидка"
                    price={discountAmount}
                    className="text-danger"
                  />
                  <AdditionalPanelSwitcher
                    additionalPanelTitle="Подробнее"
                    titleClassName="text-primary font-weight-bold"
                    innerClassName="pl-3 my-2"
                    isCloseOnStart
                  >
                    {promo
                      .filter((promo) => promo.is_valid)
                      .map((promo, index) => (
                        <CartTotalLine
                          key={index}
                          title={promo.description}
                          price={promo.amount}
                          isTitleBold={false}
                        />
                      ))}
                  </AdditionalPanelSwitcher>
                </>
              )}
              {isDeliveryPrice && <CartTotalLine title="Доставка:" price={deliveryPrice} />}
              {withAllServices && <CartTotalLine title="Сервисы:" price={servicePrice} />}
              <CartTotalLine
                title="Итого:"
                price={grandTotal}
                className="text-lg font-weight-bold"
              />
            </Col>
          </Row>
        </Block>
        {address && isDeliveryPrice && (
          <Block title="АДРЕС ДОСТАВКИ">
            <Address
              address={address}
              titleColWidth={3}
              contentColWidth={9}
              titleColumnClass="pr-0"
            />
          </Block>
        )}
        <Block title="ДАННЫЕ ОБ ОПЛАТЕ">
          <div className="mb-4">
            <TableList
              titleColWidth={3}
              titleColumnClass="pr-0"
              items={[
                { title: 'Сумма заказа:', content: formatPriceRu(grandTotal) },
                { title: 'Внесено:', content: formatPriceRu(paymentTotal) },
              ]}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column pb-2">
            <table className="w-100">
              <thead>
                <tr className={classnames.tr}>
                  <th className="w-25">Дата платежа</th>
                  <th className="w-25">Способ платежа</th>
                  <th className="w-25">Система</th>
                  <th className="w-25">Сумма</th>
                </tr>
              </thead>
              {payments.length !== 0 ? (
                <tbody>
                  {payments.map(({ date, sum, payment_type, system_type }) => {
                    const paymentType = (() => {
                      switch (payment_type) {
                        case PAYMENT_TYPE_CARD: {
                          return 'банковская карта'
                        }
                        case PAYMENT_TYPE_CASH: {
                          return 'наличные'
                        }
                        case PAYMENT_TYPE_TERMINAL: {
                          return 'терминал'
                        }
                      }
                    })()

                    const systemType = (() => {
                      switch (system_type) {
                        case SYSTEM_TYPE_POS: {
                          return 'POS'
                        }
                      }
                    })()

                    return (
                      <tr key={date}>
                        <td className="pt-1 pb-1">{date.slice(0, 10)}</td>
                        <td className="pt-1 pb-1">{paymentType}</td>
                        <td className="pt-1 pb-1">{systemType}</td>
                        <td className="pt-1 pb-1">{formatPriceRu(sum)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className="text-left font-weight-light mt-3">
                      Оплата по заказу не производилась
                    </div>
                  </td>
                </tr>
              )}
            </table>
          </div>
        </Block>
        {hasComments && (
          <Block title="ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ">
            <div className={classnames.additionalInfo}>
              <TableList
                titleColWidth={3}
                titleColumnClass="pr-0"
                items={[
                  // TODO раскомментировать, когда в заказ будет приходить информация о контактном лице
                  // {
                  //   title: 'Контактное лицо:',
                  //   content: (
                  //     <>
                  //       <div className={classnames.contactName}>Иванова Ольга Александровна</div>
                  //       <div className={classnames.contactPhoneNumber}>+7 (999) 888 77 66</div>
                  //     </>
                  //   ),
                  // },
                  { title: 'Комментарий к заказу:', content: comment },
                ]}
              />
            </div>
          </Block>
        )}
      </div>
      <DocumentModal
        subtitle="Договор купли-продажи"
        name={documentTitle}
        isOpen={documentModalSwitcher.isOn}
        toggle={documentModalSwitcher.toggle}
        pathname={contract?.path ?? ''}
        onSendEmail={() => {
          documentModalSwitcher.off()
          emailSendModalSwitcher.on()
        }}
      />
      <EmailSendModal
        isOpen={emailSendModalSwitcher.isOn}
        toggle={emailSendModalSwitcher.toggle}
        closeModal={emailSendModalSwitcher.off}
        email={clientEmail}
        orderId={entity_id}
      />
    </>
  )
}

OrderPage.propTypes = {
  address: PropTypes.shape(addressShape),
  discountAmount: PropTypes.string,
  isCompleted: PropTypes.bool,
  clientName: PropTypes.string,
  orderId: PropTypes.string,
  status: PropTypes.string,
  statusPayment: PropTypes.string,
  shopName: PropTypes.string,
  createdAt: PropTypes.string,
  dueDate: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      services: PropTypes.array,
      masterProductName: PropTypes.string,
      paramsString: PropTypes.string,
      qty: PropTypes.number,
      total: PropTypes.string,
      totalWithoutDiscount: PropTypes.string,
      key: PropTypes.string,
      deliveryType: PropTypes.string,
      discount: PropTypes.string,
    }),
  ),
  promo: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      code: PropTypes.string,
      description: PropTypes.string,
      is_valid: PropTypes.bool,
      promo_type: PropTypes.string,
    }),
  ),
  grandTotal: PropTypes.number,
  deliveryPrice: PropTypes.string,
  servicePrice: PropTypes.string,
  productTotal: PropTypes.string,
  // onClickChange: PropTypes.func,
  onClickPay: PropTypes.func,
  staff: PropTypes.string,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      sum: PropTypes.string,
      discount_sum: PropTypes.string,
      payment_type: PropTypes.string,
      system_type: PropTypes.string,
    }),
  ),
  comment: PropTypes.string,
}
