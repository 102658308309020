import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classnames from './Order.module.scss'
import { Col, Row } from 'reactstrap'
import { formatPriceRu } from '../../helpers/currency'
import { getDeliveryOptionName } from '../../constants'
import { CartProductAttributes } from '../../components/Cart/CartProductAttributes'

export function OrderProduct({
  img,
  masterProductName,
  paramsString,
  qty,
  total,
  totalWithoutDiscount,
  services = [],
  deliveryType,
  discount,
  price,
  product,
}) {
  const withDiscount = total !== totalWithoutDiscount
  return (
    <div className={clsx(classnames.product, 'pb-3 pt-3')}>
      <Row className="align-items-start mb-3 px-3">
        <div className={classnames.imgWrapper}>
          {img && <img className={classnames.img} src={img} alt={masterProductName} />}
        </div>
        <div className={classnames.infoRow}>
          <div className="col-4">
            <div>{masterProductName}</div>
            <div className="text-medium-grey">{paramsString}</div>
            <CartProductAttributes item={product} />
          </div>
          <div className="col-2">
            <div>{qty} шт.</div>
            {qty > 1 && <div className="text-medium-grey">{price}/шт</div>}
          </div>
          <div className="col-2">
            <div>Оформляется</div>
            <div className="text-medium-grey">{getDeliveryOptionName(deliveryType)}</div>
          </div>
          <div className="col-3 pr-0">
            {withDiscount && <div className={`text-right text-sm`}>{totalWithoutDiscount}</div>}
            {withDiscount && (
              <div className={`text-right text-danger text-sm`}>
                Скидка <span>{discount}</span>
              </div>
            )}
            <div className={'text-right'}>
              <b>Итого {total}</b>
            </div>
          </div>
        </div>
      </Row>
      {services.map((item) => (
        <Row className="mb-2" key={item.extension_attributes.guid}>
          <Col xs={6}>
            <div className="text-left">{item.name}</div>
          </Col>
          <Col xs={6}>
            <div className="text-right">{formatPriceRu(item.row_total)}</div>
          </Col>
        </Row>
      ))}
    </div>
  )
}

OrderProduct.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.number,
      checked: PropTypes.bool,
    }),
  ),
  img: PropTypes.string,
  total: PropTypes.string.isRequired,
  totalWithoutDiscount: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  masterProductName: PropTypes.string.isRequired,
  paramsString: PropTypes.string.isRequired,
  deliveryType: PropTypes.string,
}
