import * as actions from '../types'

const initState = {
  result: {},
  isLoading: false,
  error: '',
  wasSmsSent: false,
  isRegistered: false,
  correlationId: null,
}

export default function (state = initState, action) {
  if (action.type === actions.beginGetCustomer || action.type === actions.beginSearch) {
    return { ...initState }
  }
  if (action.type === actions.beginRegistrationInLoyaltyProgram) {
    return {
      ...state,
      isLoading: true,
      error: '',
      isRegistered: false,
    }
  }
  if (action.type === actions.cancelConfirmUpdate) {
    return {
      ...state,
      isLoading: false,
      error: '',
      isRegistered: false,
    }
  }
  if (action.type === actions.endRegistrationInLoyaltyProgram) {
    return {
      ...state,
      isLoading: action.error ? false : state.isLoading,
      error: action.error || '',
      correlationId: action.data?.correlationId || null,
    }
  }
  if (action.type === actions.beginSendSmsCodeInLoyaltyProgram) {
    return {
      ...state,
      isLoading: true,
      error: '',
      wasSmsSent: false,
    }
  }
  if (action.type === actions.endSendSmsCodeInLoyaltyProgram) {
    return {
      ...state,
      wasSmsSent: true,
      correlationId: action.data?.correlationId || null,
    }
  }
  if (
    action.type === actions.serverMessageApp &&
    action.data?.payload?.type === 'customer' &&
    action.data?.correlation_id === state.correlationId
  ) {
    return {
      ...state,
      isLoading: false,
      isRegistered: true,
      error:
        action.data?.payload?.data?.state === 'Error' ? action.data?.payload?.data?.message : '',
    }
  }
  return { ...state }
}
