import React, { FC } from 'react'
import { CategoryStep } from '../steps/CategoryStep'
import { ParametersStep } from '../steps/ParametersStep'
import { Category, Entity, Product } from '../../../../redux/slices/constructor/model/dto'
import { EntityType } from '../../model'

interface Props {
  step: Entity
}

export const StepBuilder: FC<Props> = ({ step }) => {
  if (!step) {
    return null
  }

  if (step.entity_type === EntityType.Category || step.entity_type === EntityType.CategoryWithProducts) {
    return <CategoryStep step={step as Category} />
  }

  if (step.entity_type === EntityType.Product) {
    return <ParametersStep step={step as Product} />
  }

  return null
}
