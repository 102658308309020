import React, { FC } from 'react'
import { Block } from '../../../components/Layout/Block/Block'
import { TableList } from '../../../components/TableList/TableList'
import { Col, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SelectField } from '../../../components/Layout/form/SelectField'
import { Button } from '../../../components/Layout/Button/Button'
import { Form } from '../../../components/Layout/form/Form'
import { InputField } from '../../../components/Layout/form/InputField'
import { RadioField } from '../../../components/Layout/form/RadioField'
import { DatePickerField } from '../../../components/Layout/form/DatePickerField'
import { CheckboxField } from '../../../components/Layout/form/CheckboxField'
import { conditionStepSchema } from '../schemas'

interface Props {
  onNext: () => void
}

interface FieldValues {
  count: string
  sex: 'w' | 'm'
  simpleSignature: boolean
  insurance: boolean
  sms: boolean
  flexibleSchedule: boolean
  type: string
  time: string
  birthday: Date
}

export const ConditionStep: FC<Props> = ({ onNext }) => {
  const form = useForm<FieldValues>({
    resolver: yupResolver(conditionStepSchema),
    defaultValues: {
      birthday: new Date('1992-06-15'),
      sex: 'm',
    },
  })

  function onSubmit(values: FieldValues) {
    onNext()
  }

  return (
    <Form<FieldValues> form={form} onSubmit={onSubmit}>
      <Block mb={0} title={<span className="text-lg">ОФОРМЛЕНИЕ КРЕДИТА</span>}>
        <Row>
          <Col xs={6}>
            <TableList
              isTitleBold={false}
              items={[
                {
                  title: 'ЗАКАЗ',
                  content: 'тт93041-10',
                },
                {
                  title: 'СУММА ЗАКАЗА',
                  content: '123 123',
                },
                {
                  title: 'СУММА КРЕДИТА',
                  content: '44 444',
                },
              ]}
            />
          </Col>
          <Col xs={6}>
            <TableList
              isTitleBold={false}
              items={[
                {
                  title: 'МЕНЕДЖЕР',
                  content: 'Иван Круглов',
                },
                {
                  title: 'ПРЕДОПЛАТА',
                  content: '0',
                },
                {
                  title: 'НЕ ВХОДИТ В КРЕДИТ',
                  content: '0',
                },
              ]}
            />
          </Col>
        </Row>
      </Block>
      <Block title="УСЛОВИЕ КРЕДИТА" mb={0}>
        <Row>
          <Col xs={4}>
            <SelectField
              name="type"
              label="Тип кредитного продукта"
              options={[
                { name: 'Кредит', id: '1' },
                { name: 'Кредит 2', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <SelectField
              name="time"
              label="Срок"
              options={[
                { name: 'Кредит', id: '1' },
                { name: 'Кредит 2', id: '2' },
              ]}
            />
          </Col>
          <Col xs={4}>
            <InputField name="count" label="Ежемесячный платеж" type="number" step="0.01" min="0" />
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col xs={4}>
            <div>Пол</div>
            <div className="d-flex">
              <div className="mr-4">
                <RadioField name="sex" value="m" labelName="Мужчина" />
              </div>
              <div>
                <RadioField name="sex" value="w" labelName="Женщина" />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <DatePickerField label="Дата рождения" showYearDropdown name="birthday" />
          </Col>
          <Col xs={4}>
            <CheckboxField name="simpleSignature" labelName="Простая электронная подпись" />
          </Col>
        </Row>
      </Block>
      <Block title="ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ">
        <CheckboxField wrapperClassName="mr-5" name="insurance" labelName="Страхование" />
        <CheckboxField wrapperClassName="mr-5" name="sms" labelName="Услуга СМС-информирования" />
        <CheckboxField wrapperClassName="mr-5" name="flexibleSchedule" labelName="Гибкий график" />
        <div className="d-flex">
          <Button size="lg" type="submit" color="primary" className="ml-auto height-40">
            Далее
          </Button>
        </div>
      </Block>
    </Form>
  )
}
