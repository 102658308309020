import { api, apiHost } from './api'
import { useQueries } from 'react-query'
import { useRuntimeConfig as getRuntimeConfig } from '../hooks/useRuntimeConfig'

const { priceServiceUrl } = getRuntimeConfig()

export const fetchProductBySku = (sku) =>
  api.get({
    path: `/pos/products/${sku}`,
    requireBody: true,
  })

export function fetchProductMedia(sku) {
  return api.get({ path: `/products/${sku}/media` })
}

export function getProductMediaUrl(file) {
  // TODO get full image url from backend

  return `${apiHost}/media/catalog/product${file}`
}

const PRODUCT_MEDIA_KEY = 'product-media'

export function useProductMediaQueries(skus) {
  return useQueries(
    skus.map((sku) => ({
      queryKey: [PRODUCT_MEDIA_KEY, sku],
      queryFn: () => fetchProductMedia(sku),
    })),
  )
}

export const fetchProductPrice = (data) => {
  return api.post({
    data,
    url: priceServiceUrl,
  })
}
