import React, { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useNotification } from '../../../contexts/NotificationProvider/NotificationProvider'
import { useSwitcher } from '../../../hooks/useSwitcher'
import { Col, Row } from 'reactstrap'
import { Button } from '../../Layout/Button/Button'
import { Form } from '../../Layout/form/Form'
import { InputField } from '../../Layout/form/InputField'
import { DatePickerField } from '../../Layout/form/DatePickerField'
import { CheckboxField } from '../../Layout/form/CheckboxField'
import Modal from '../../Layout/Modal'
import { customerSchema } from '../../../constants'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { customerHelper, formatPhoneNumber } from '../../../helpers'
import { cart, customer } from '../../../redux/actions'
import { yupResolver } from '@hookform/resolvers/yup'
import { getCartCustomer } from '../../../redux/selectors/cart'
import { Customer } from '../../../api/dto/CustomerBasicType'
import { getBirthDate } from '../../../helpers/customer'
import { EditCartField } from './EditCartField'
import { useBlockRoute } from '../../../hooks/useBlockRoute'
import { PhoneInputField } from '../../Layout/form/PhoneInputField'

type Props = ConnectedProps<typeof connector> & {
  showEditCartCustomer: () => void
  updateCustomer: (customer: Customer) => void
  customerEditError: string
}

function EditCartCustomer({
  showEditCartCustomer,
  updateCustomer,
  customerEditError,
  setCartCustomer,
}: Props) {
  const { customer } = useSelector(getCartCustomer)
  const isModalOpen = useSwitcher()
  const { showSuccess } = useNotification()
  const { blockRoute, unblockRoute, leavePage } = useBlockRoute(isModalOpen.on, false)

  const initialState = {
    customer: {
      ...customer,
      email: customer.is_fake_email ? '' : customer.email,
      phone: customer.phone ? formatPhoneNumber(customer.phone) : '',
      // extra_phone: customer.extra_phone ? formatPhoneNumber(customer.extra_phone) : '',
    },
  }

  const defaultValues = {
    // extra_phone: initialState.customer.extra_phone,
    birth_date: getBirthDate(initialState.customer),
    email: initialState.customer.email,
    is_fake_email: initialState.customer.is_fake_email,
    phone: initialState.customer.phone,
    middle_name: initialState.customer.middle_name,
    given_name: initialState.customer.given_name,
    surname: initialState.customer.surname,
    subscriptions: { ...initialState.customer.subscriptions },
  }

  const { control, clearErrors, ...form } = useForm({
    resolver: yupResolver(customerSchema),
    defaultValues,
    mode: 'all',
  })

  const { isDirty, isValid } = form.formState

  const { is_fake_email } = useWatch<typeof defaultValues>({
    control,
  })

  useEffect(() => {
    if (is_fake_email) {
      clearErrors('email')
    }
  }, [clearErrors, is_fake_email])

  useEffect(() => {
    if (isDirty) {
      blockRoute()
    } else {
      unblockRoute()
    }
  }, [blockRoute, isDirty, unblockRoute])

  function onSubmit(values: typeof defaultValues) {
    if (!isDirty) {
      const message = 'Нет новых данных для сохранения.'
      showSuccess({
        body: message,
      })
      showEditCartCustomer()
    } else {
      const data = customerHelper.customerMapping({ ...customer, ...values })
      updateCustomer({ ...data, birth_date: data.birth_date || '' })
      setCartCustomer(customer)
    }
  }

  function handleToggleModal(): void {
    isModalOpen.toggle()
  }

  return (
    <>
      <Form form={{ control, clearErrors, ...form }} onSubmit={onSubmit}>
        <Row className="pt-3">
          <Col>
            <Col className="br-5 p-3 pt-4 pb-4 customer-info">
              <EditCartField name="Фамилия">
                <InputField
                  name="surname"
                  className="border-dark square"
                  placeholder="Введите фамилию"
                />
              </EditCartField>

              <EditCartField name="Имя*">
                <InputField
                  name="given_name"
                  className="form-control border-dark square"
                  placeholder="Введите имя"
                />
              </EditCartField>

              <EditCartField name="Отчество">
                <InputField
                  name="middle_name"
                  className="form-control border-dark square"
                  placeholder="Введите отчество"
                />
              </EditCartField>

              <EditCartField name="Телефон*">
                <PhoneInputField
                  type="tel"
                  defaultValue={initialState.customer.phone}
                  name="phone"
                  className="form-control border-dark square"
                  placeholder="+7(___)___-__-__"
                />
              </EditCartField>

              <EditCartField name="E-mail*">
                <InputField
                  defaultValue={initialState.customer.email}
                  name="email"
                  className="form-control border-dark square"
                  placeholder="example@gmail.com"
                  disabled={is_fake_email}
                />
              </EditCartField>

              <EditCartField name="">
                <CheckboxField
                  wrapperClassName="mr-5 mb-4"
                  name="is_fake_email"
                  labelName="Не указывать e-mail"
                />
              </EditCartField>

              <EditCartField name="Дата рождения">
                <DatePickerField
                  name="birth_date"
                  className="form-control border-dark square col title"
                  placeholderText="__-__-___"
                  showMonthDropdown
                  showYearDropdown
                />
              </EditCartField>

              {/*<EditCartField name="Доп. телефон">*/}
              {/*  <PhoneInput*/}
              {/*    isErrorMessageHide*/}
              {/*    type="tel"*/}
              {/*    defaultValue={initialState.customer.extra_phone}*/}
              {/*    name="extra_phone"*/}
              {/*    className="form-control border-dark square"*/}
              {/*    placeholder="Доп. телефон"*/}
              {/*    useForm*/}
              {/*  />*/}
              {/*</EditCartField>*/}

              <EditCartField name="Рассылка">
                <CheckboxField name="subscriptions.sms" labelName="SMS" labelClassName="mr-2" />
                <CheckboxField
                  name="subscriptions.email"
                  labelName="E-mail"
                  labelClassName="mr-2"
                />
                <CheckboxField name="subscriptions.phone" labelName="Звонки" />
              </EditCartField>
            </Col>
          </Col>
        </Row>

        <Row className="pt-3 justify-content-end">
          <Col xs="2">
            <Button
              className="form-control height-40"
              color="primary"
              type="submit"
              disabled={!isValid}
            >
              Сохранить
            </Button>
          </Col>
          <Col xs="2">
            <Button
              type="button"
              className="form-control height-40"
              color="outline-primary"
              onClick={() => showEditCartCustomer()}
            >
              Отменить
            </Button>
          </Col>
        </Row>
      </Form>

      {customerEditError && (
        <Modal
          color="warning"
          renderIcon={() => null}
          isOpenExternal
          title="Ошибка!"
          message={customerEditError}
          buttonToolbar={(toggle) => (
            <Button className="modal_cancel" color="outline-primary" onClick={toggle}>
              Ок
            </Button>
          )}
        />
      )}
      {isModalOpen.isOn && (
        <Modal
          color="warning"
          onToggle={handleToggleModal}
          isOpenExternal
          title="Внимание"
          needRenderIcon={false}
          buttonToolbar={(toggle) => {
            return (
              <>
                <Button
                  className="modal_cancel height-40"
                  color="outline-primary"
                  onClick={() => {
                    leavePage()
                  }}
                >
                  Покинуть страницу
                </Button>
                <Button
                  className="modal_ok height-40"
                  color="primary"
                  onClick={() => {
                    toggle()
                  }}
                >
                  Остаться на странице
                </Button>
              </>
            )
          }}
          message={
            <>
              <span className="m-0">На странице есть несохранённые изменения.</span>
              <br />
              <span className="m-0">Вы уверены, что хотите покинуть страницу?</span>
            </>
          }
          size="large"
        />
      )}
    </>
  )
}

const mapDispatchToProps = {
  updateCustomer: customer.update,
  setCartCustomer: cart.setCartCustomer,
}

const connector = connect(getCartCustomer, mapDispatchToProps)

export default connector(EditCartCustomer)
