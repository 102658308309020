import React, { FC, useEffect } from 'react'
import { Container, Row } from 'reactstrap'
import '../../../styles/screens/constructor.scss'
import { StepBuilder } from './builders/StepBuilder'
import { StepButtons } from './StepButtons'
import { StepButton } from '../model'
import { useDispatch, useSelector } from 'react-redux'
import { currentStepIndexSelector, stepsSelector } from '../../../redux/slices/constructor/selectors'
import { actions, thunks } from '../../../redux/redusers'
import { Category } from '../../../redux/slices/constructor/model/dto'
import { Loader } from '../../../components'

export const Constructor: FC = () => {
  const dispatch = useDispatch()
  const currentStepIndex = useSelector(currentStepIndexSelector)
  const steps = useSelector(stepsSelector)

  useEffect(() => {
    dispatch(thunks.constructor.fetchCategories())
  }, [])

  const levelsStep = steps[1] as Category

  const stepButtons: StepButton[] | undefined = levelsStep?.levels?.map((item, index) => ({
    label: item.name,
    active: index + 1 === currentStepIndex,
    action: steps[index + 1] ? () => dispatch(actions.constructor.setStep(index + 1)) : undefined,
  }))

  return (
    <Container
      className={
        stepButtons && currentStepIndex > 0
          ? 'constructor constructor--with-steps ml-0'
          : 'constructor ml-0'
      }
    >
      <Row className="mb-4">
        <h1>Конструктор товаров</h1>
      </Row>
      {stepButtons && currentStepIndex > 0 && (
        <Row className="mb-4">
          <StepButtons buttons={stepButtons} />
        </Row>
      )}
      {steps[currentStepIndex] ? <StepBuilder step={steps[currentStepIndex]} /> : <Loader />}
    </Container>
  )
}
