import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { formatPhoneNumber, searchHelper } from '../../../../helpers'

function CustomersTab({ items, query }) {
  const history = useHistory()

  if (!items.length) {
    return null
  }

  const handleClick = (item) => {
    history.push(`/customer/${item.id}`)
  }

  return (
    <div className="table-responsive">
      <table className="table customers">
        <thead>
          <tr className="d-flex">
            <th className="col-5 border-dark text-center">ФИО</th>
            <th className="col-3 border-dark text-center">Телефон</th>
            <th className="col-4 border-dark text-center">Email</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr className="d-flex cursor-pointer" key={item.id} onClick={() => handleClick(item)}>
              <td className="col-5 border-dark">
                {searchHelper.markMatch(searchHelper.getFullName(item), query)}
              </td>
              <td className="col-3 border-dark text-center">
                {searchHelper.markMatch(formatPhoneNumber(item.phone), query)}
              </td>
              <td className="col-4 border-dark text-center">
                {searchHelper.markMatch(
                  item.email ? (item.is_fake_email ? '' : item.email) : '',
                  query,
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state) => ({
  query: state.search.query,
})

export default connect(mapStateToProps)(CustomersTab)

CustomersTab.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  query: PropTypes.string.isRequired,
  setCustomer: PropTypes.func.isRequired,
}
