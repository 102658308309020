import React, { FC, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ImagesPreview } from './ImagesPreview'
import clsx from 'clsx'
import { FileWithId, Image } from './types'

const DefaultButton: FC = () => {
  return (
    <>
      <span className="lnr lnr-paperclip text-lg" />
      <span className="ml-1">Прикрепить файлы</span>
    </>
  )
}

interface Props {
  name: string
  className?: string
  previewPosition?: 'top'
  onChange?: (files: Image[]) => void
  errorMessage?: string
}

export const ImageInput: FC<Props> = ({
  name,
  children = <DefaultButton />,
  previewPosition = 'bottom',
  onChange,
  errorMessage,
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<FileWithId[]>([])
  const [filesWithBase64, setFilesWithBase64] = useState<Image[]>([])

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(filesWithBase64)
    }
  }, [filesWithBase64, onChange])

  useEffect(() => {
    async function getImagesPreview() {
      const imageUrlsPromises: Promise<Image>[] = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = () =>
            resolve({ id: file.id, base64: reader.result as string, value: file.value })
          reader.readAsDataURL(file.value)
        })
      })

      const images = await Promise.all(imageUrlsPromises)

      setFilesWithBase64(images)
    }

    getImagesPreview()
  }, [files])

  function removeImage(id: string) {
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.id !== id))
  }

  const id = `file-${name}`

  return (
    <div
      className={clsx('d-flex flex-column', { 'flex-column-reverse': previewPosition === 'top' })}
    >
      <label htmlFor={id} className="cursor-pointer d-flex">
        <input
          id={id}
          ref={ref}
          name={name}
          type="file"
          multiple
          hidden
          accept="image/*"
          onChange={() => {
            const files = ref.current?.files

            if (!files) {
              return
            }

            const filesWithIds = Array.from(files).map((file) => {
              return {
                value: file,
                id: uuidv4(),
              }
            })

            setFiles((prevFilesWithIds) => [...prevFilesWithIds, ...filesWithIds])
          }}
        />
        {children}
      </label>
      <div className="w-100">
        <ImagesPreview images={filesWithBase64} onRemove={removeImage} />
      </div>
      {errorMessage && <span className={clsx('text-danger')}>{errorMessage}</span>}
    </div>
  )
}
