import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../model'
import { ParametersData } from '../../slices/constructor/model'
import { AttributesObject } from '../../slices/product/model'
import { fetchProductPrice } from '../../../api/product'
import { ProductResult } from '../../model/ProductResult'
import { actions } from '../../redusers'

interface FetchPriceData {
  parametersData: ParametersData
  attributesObject: AttributesObject
  result: ProductResult
}

export const fetchPrice = createAsyncThunk<void, FetchPriceData, AppThunkApiConfig>(
  'product/fetchPrice',
  async ({ parametersData, attributesObject, result }, { dispatch }) => {
    try {
      const data = await fetchProductPrice({
        guid: result.guid,
        values: [
          ...[...parametersData].map(([key, value]) => ({
            code: key.combination_code,
            value: value.guid,
          })),
        ],
      })
      if (data) {
        // цены из сервиса цен приходят в копейках
        dispatch(
          actions.product.addPriceToCache({
            price: {
              price: data.price / 100,
              special_price: data.specialPrice / 100,
              profit: data.profit / 100,
              specialFromDate: data.specialFromDate,
              specialToDate: data.specialToDate,
            },
            attributesObject,
          }),
        )
      }
    } catch (e) {
      console.error(e)
      // TODO сделать проверку на сообщение ошибки на отсутствие записи с запрашиваемыми параметрами
      dispatch(
        actions.product.addPriceToCache({
          price: {
            errorMessage: 'Цена не найдена',
          },
          attributesObject,
        }),
      )
    }
  },
)
