import { captureException, SentryMessageTags } from './sentryCaptures'
import { GET_PAY_INFO_URL, paymentAxios } from './paymentAxiosInit'
import { PayInfo } from './dto/PayInfo'

export async function getPayInfo(apiUrl: string, requestId: string, tags: SentryMessageTags) {
  try {
    const { data } = await paymentAxios.post<PayInfo>(apiUrl + GET_PAY_INFO_URL, { requestId })
    if (data.success) {
      return {
        success: true,
        data: data.data,
      }
    } else {
      captureException(tags)
      return {
        success: false,
        errorMessage: `${data?.code} ${data?.description}`,
      }
    }
  } catch (error) {
    captureException(tags)
    return {
      success: false,
      errorMessage: error.toString(),
    }
  }
}
