import React, { ReactNode } from 'react'
import { Form as FormStrap } from 'reactstrap'
import { FieldValues, FormProvider, SubmitHandler, UseFormMethods } from 'react-hook-form'

interface Props<TFieldValues extends FieldValues> {
  onSubmit: SubmitHandler<TFieldValues>
  children: ReactNode
  form: UseFormMethods<TFieldValues>
}

export const Form = <TFieldValues,>({ onSubmit, form, children }: Props<TFieldValues>) => {
  return (
    <FormStrap onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>{children}</FormProvider>
    </FormStrap>
  )
}
