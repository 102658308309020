import React, { useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from '../Layout/Button/Button'
import { addressValidationSchema, customerShape, emptyAddress } from '../../constants'
import { customerHelper, formatPhoneNumber } from '../../helpers'
import { AddressesList, BonusBalance, CustomerSubscriptions, EditAddress, Loader, Modal } from '../'
import { customer } from '../../redux/actions'
import DatePicker from '../Layout/DatePicker'
import { STATE_UPDATE } from '../../constants/customerStateName'
import { useNotification } from '../../contexts/NotificationProvider/NotificationProvider'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { updateAddress } from '../../api/customer'
import { getBirthDate } from '../../helpers/customer'
import Checkbox from '../Layout/Checkbox'
import { useSwitcher } from '../../hooks/useSwitcher'
import { isEqual, omit } from 'lodash-es'
import { Input } from '../Layout/Input/Input'
import { PhoneInput } from '../Layout/Input/PhoneInput'

function reducer(state, { key, value }) {
  if (!key) return state
  let { isChangedSubscriptions } = state
  if (!isChangedSubscriptions) {
    isChangedSubscriptions = key === 'subscriptions'
  }
  return {
    customer: {
      ...state.customer,
      [key]: value,
    },
    wasChanged: true,
    isChangedSubscriptions,
  }
}

/** Статус 200 означает, что версия не совпадает. Успех - статус 201 **/
const STATUS_CHANGED_EXTERNAL = 200

const STATUS_SUCCESS = 201

function EditCustomerProfile({
  onSave,
  customer,
  updateCustomer,
  addAddress,
  customerEditError,
  responseStatus,
  resetLastResponseStatus,
  customerIsEditing,
  removeAddress,
  cancelButton,
}) {
  const initialState = {
    customer: {
      ...customer,
      email: customer.is_fake_email ? '' : customer.email,
      phone: customer.phone ? formatPhoneNumber(customer.phone) : '',
    },
    wasChanged: false,
    isChangedSubscriptions: false,
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [defaultAddressStart] = useState(() =>
    customer.addresses.find((address) => address.is_default),
  )
  const [lastIndex, setLastIndex] = useState(1)
  const [formErrors, setFormErrors] = useState({})
  const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(false)
  const [emailState, setEmailState] = useState(state.customer.is_fake_email || false)

  const isModalOpen = useSwitcher()

  const { showSuccess } = useNotification()
  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addressValidationSchema),
    defaultValues: { defaultId: '' },
  })
  const { trigger, getValues, setValue, register, reset, control } = form
  const defaultId = useWatch({ control, name: 'defaultId' })

  useEffect(function resetForm() {
    reset()
  }, [])

  useEffect(() => {
    if (responseStatus === STATUS_SUCCESS) {
      resetLastResponseStatus()
      const message = (
        <>
          Внесенные изменения клиента{' '}
          <b>
            {[customer.surname, customer.given_name, customer.middle_name]
              .filter(Boolean)
              .join(' ')}
          </b>{' '}
          приняты к сохранению
        </>
      )
      showSuccess({ body: message })
      onSave()
    }
  }, [
    onSave,
    resetLastResponseStatus,
    responseStatus,
    showSuccess,
    customer.surname,
    customer.given_name,
    customer.middle_name,
  ])

  useEffect(
    function setDefaultAddress() {
      if (defaultId) {
        let { addresses } = state.customer
        const newAddresses = addresses.map((addresses) => {
          if (addresses.is_default) addresses.is_default = false
          return addresses
        })
        dispatch({ key: 'addresses', value: newAddresses })
      }
    },
    [defaultId],
  )

  const handleToggleModal = () => {
    isModalOpen.toggle()
  }

  const handleCancelEditProfile = () => {
    if (isEqual(initialState, state)) {
      cancelButton()
    } else {
      isModalOpen.on()
    }
  }

  const handleDispatch = ({ target }) => {
    if (
      target.name === 'surname' ||
      target.name === 'middle_name' ||
      target.name === 'given_name'
    ) {
      validate(target.name, target.value)
    }
    if (target.name === 'phone') {
      validatePhone(target.value.slice(0, 11))
    }
    if (target.name === 'email') {
      validateEmail(target.value)
    }
    return dispatch({
      key: target.name,
      value: target.name === 'phone' ? target.value.slice(0, 11) : target.value,
    })
  }
  const handleDispatchCheck = ({ target }) => {
    const subscriptions = { ...state.customer?.subscriptions }
    if (target.checked) {
      const { email, ...itemsError } = formErrors
      setFormErrors({ itemsError })
      setEmailState(true)
      subscriptions['email'] = false
    } else {
      setEmailState(false)
    }

    dispatch({ key: target.name, value: target.checked })
    dispatch({
      key: 'subscriptions',
      value: subscriptions,
    })
  }

  const handleDateDispatch = (date) => dispatch({ key: 'birth_date', value: date })

  const handleCheckboxDispatch = ({ target }) => {
    //const subscriptions = (state.customer?.subscriptions || [])?.map((item) => item.toLowerCase())
    const subscriptions = { ...state.customer?.subscriptions }
    const subscription = target.name.toLowerCase()
    subscriptions[subscription] = !subscriptions[subscription]

    dispatch({
      key: 'subscriptions',
      value: subscriptions,
    })
  }

  const handleSave = () => {
    const oldDefaultAddress = defaultAddressStart
      ? customer.addresses.find((address) => address.id === defaultAddressStart.id)
      : undefined
    const newDefaultAddress = customer.addresses.find((address) => address.is_default)
    if (oldDefaultAddress && !oldDefaultAddress.is_default) {
      const data = customerHelper.addressMapping({
        address: oldDefaultAddress,
        customer,
      })
      updateAddress(oldDefaultAddress.id, data)
    }
    if (
      newDefaultAddress &&
      (!defaultAddressStart || newDefaultAddress.id !== defaultAddressStart.id)
    ) {
      const data = customerHelper.addressMapping({
        address: newDefaultAddress,
        customer,
      })
      updateAddress(newDefaultAddress.id, data)
    }
    const data = {
      ...customerHelper.customerMapping(state.customer),
      contact_state_name: STATE_UPDATE,
    }
    updateCustomer({ ...data, birth_date: data.birth_date || '' })
  }

  const saveNewAddresses = () => {
    const { addresses } = getValues()
    addresses.forEach((address) => {
      const data = customerHelper.addressMapping({
        address: {
          ...address,
          is_default: defaultId === address.localId,
        },
        customer,
      })
      addAddress(data)
    })
  }

  const validateEmail = React.useCallback(
    (emailValue) => {
      if (state.customer.is_fake_email) {
        setFormErrors((errors) => omit(errors, 'itemsError'))
        return
      }
      let error = undefined
      if (emailValue && !/\w[\w\d_.-]+@[\w\d_.-]+\.[\w\d_.-]/gi.test(emailValue)) {
        error = 'Введите адрес электронной почты в правильном формате'
      }
      if (!emailValue) {
        error = 'Это поля является обязательным'
      }
      setFormErrors((errors) => (error ? { ...errors, email: error } : omit(errors, 'email')))
    },
    [state.customer.is_fake_email],
  )

  useEffect(
    function checkEmail() {
      validateEmail(state.customer.email)
    },
    [state.customer.is_fake_email, validateEmail, state.customer.email],
  )

  useEffect(() => {
    setIsSaveButtonDisable(!!Object.keys(formErrors).length)
  }, [formErrors])

  const validatePhone = React.useCallback((phoneValue) => {
    let error = undefined
    if (!phoneValue || phoneValue.replace(/\D/g, '').length !== 11) {
      error = 'Введите номер телефона полностью'
    }
    setFormErrors((errors) => (error ? { ...errors, phone: error } : omit(errors, 'phone')))
  }, [])

  const validate = (fieldName, fieldValue) => {
    const validatedFields = ['surname', 'given_name', 'middle_name']

    const requiredFields = {
      info: ['given_name', 'phone', 'email'],
    }

    if (state.customer.is_fake_email) {
      requiredFields.info = ['given_name', 'phone']
    }

    const errors = {}
    for (const key of requiredFields.info) {
      if ((key === fieldName ? fieldValue : state.customer[key]).length === 0) {
        errors[key] = 'Это поле является обязательным'
      }
    }

    for (const key of validatedFields) {
      if (state.customer[key]) {
        if (/[^a-zA-ZА-Яа-я\s-]/g.test(key === fieldName ? fieldValue : state.customer[key])) {
          errors[key] = 'Допустимы только буквы, пробелы и тире'
        }

        if ((key === fieldName ? fieldValue : state.customer[key]).length > 70) {
          errors[key] = 'Максимум 70 символов'
        }
      }
    }

    // if (state.customer.extra_phone && state.customer.extra_phone?.replace(/\D/g, '').length < 11) {
    //   errors.extra_phone = 'Введите номер телефона полностью'
    // }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onRemoveAddress = (id) => {
    removeAddress(id)
    dispatch({
      key: 'addresses',
      value: (state?.customer?.addresses || []).filter(({ id: addressId }) => addressId !== id),
    })
  }

  const onRemoveNewAddress = (id) => {
    return () => {
      const { defaultId, addresses } = getValues()
      const newAddresses = [...addresses].filter((item) => item.localId !== id)
      reset({ addresses: newAddresses })

      newAddresses.map((item, index) => {
        register({ name: `addresses[${index}]` })
        const fias = item.fias
        setValue(`addresses[${index}]`, { ...item })
        setValue(`addresses[${index}].fias`, { ...fias })
      })
      register({ name: 'defaultId' }, defaultId)
    }
  }

  const onSetDefaultAddress = (id) => {
    setValue('defaultId', '')

    let { addresses } = state.customer
    addresses = addresses?.map((e) => {
      if (e.is_default) e.is_default = false
      if (e.id === id) e.is_default = true
      return e
    })
    dispatch({ key: 'addresses', value: addresses })
  }

  const handleAddAddress = () => {
    const localId = String(lastIndex)
    setLastIndex((prevState) => prevState + 1)
    const addresses = getValues().addresses ?? []
    const newAddresses = [
      ...addresses,
      {
        ...emptyAddress,
        localId,
      },
    ]
    newAddresses.map((item, index) => {
      register({ name: `addresses[${index}]` })
      setValue(`addresses[${index}]`, item)
    })
    setValue('defaultId', localId)
    // Чтобы поменялось wasChanged
    dispatch({ key: 'customer_id', value: state.customer.customer_id })
  }

  const renderSaveButton = (className = '') => {
    return (
      <button
        className={`mx-2 btn btn-primary form-control common-button height-40 ${className}`}
        onClick={async () => {
          const isValid = await trigger()
          if (!isValid) return
          if (getValues().addresses?.length > 0) {
            saveNewAddresses()
          }
          if (!state.wasChanged) {
            return onSave(false)
          }
          if (validate()) {
            handleSave()
          } else {
            document.getElementsByTagName('html')[0].scrollIntoView()
          }
        }}
        disabled={isSaveButtonDisable}
      >
        Сохранить
      </button>
    )
  }

  const getFieldClass = (name) => {
    return `form-control square ${formErrors[name] ? 'border-danger' : 'border-dark'}`
  }

  if (customerIsEditing) {
    return <Loader />
  }

  return (
    <div className="customer-profile-edit">
      <div className="main-info">
        <div className="row">
          <div className="col-12">
            <Input
              value={state.customer.surname || ''}
              name="surname"
              onChange={handleDispatch}
              className={getFieldClass('surname')}
              placeholder="Введите фамилию"
              errorMessage={formErrors.surname}
              label="Фамилия"
            />
          </div>
        </div>

        <div className="row mt-1">
          <div className="col">
            <Input
              value={state.customer.given_name || ''}
              name="given_name"
              onChange={handleDispatch}
              className={getFieldClass('given_name')}
              placeholder="Введите имя"
              errorMessage={formErrors.given_name}
              label="Имя"
            />
          </div>
          <div className="col">
            <Input
              value={state.customer.middle_name || ''}
              name="middle_name"
              onChange={handleDispatch}
              className={getFieldClass('middle_name')}
              placeholder="Введите отчество"
              errorMessage={formErrors.middle_name}
              label="Отчество"
            />
          </div>
        </div>
      </div>

      <div className="row pt-1">
        <div className="col">
          <div className="bg-secondary info-block">
            <p className="text-uppercase">Контактная информация</p>
            <div className="d-flex justify-content-between">
              <div className="contact-info-edit">Телефон</div>
              <div className="flex-fill">
                <PhoneInput
                  type="tel"
                  value={state.customer.phone || ''}
                  name="phone"
                  onChange={handleDispatch}
                  className={getFieldClass('phone')}
                  placeholder="+7(___)___-__-__"
                  errorMessage={formErrors.phone}
                />
              </div>
            </div>
            {/*<div className="d-flex justify-content-between mt-3">*/}
            {/*  <div className="contact-info-edit">Доп. телефон</div>*/}
            {/*  <div className="flex-fill">*/}
            {/*    <PhoneInput*/}
            {/*      type="tel"*/}
            {/*      value={state.customer.extra_phone || ''}*/}
            {/*      name="extra_phone"*/}
            {/*      onChange={handleDispatch}*/}
            {/*      className={getFieldClass('extra_phone')}*/}
            {/*      placeholder="Доп. телефон"*/}
            {/*      errorMessage={formErrors.extra_phone}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="d-flex justify-content-between mt-3">
              <div className="contact-info-edit">E-mail</div>
              <div className="flex-fill">
                <Input
                  value={state.customer.email || ''}
                  name="email"
                  onChange={handleDispatch}
                  className={getFieldClass('email')}
                  placeholder="example@gmail.com"
                  disabled={state.customer.is_fake_email}
                  errorMessage={formErrors.email}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="contact-info-edit" />
              <div className="flex-fill">
                <Checkbox
                  checked={state.customer.is_fake_email}
                  name="is_fake_email"
                  onChange={handleDispatchCheck}
                  labelName="Не указывать e-mail"
                  labelClassName="ml-1"
                  disabled={!!state.customer.email}
                />
              </div>
            </div>
            <div className="d-flex justify-content-start subscriptions">
              <div className="contact-info-edit">Рассылка</div>
              <CustomerSubscriptions
                emailState={emailState}
                subscriptions={state.customer.subscriptions}
                onChange={handleCheckboxDispatch}
              />
            </div>
          </div>
        </div>
        {Object.keys(customer.bonus_balance || {}).length > 0 && (
          <div className="col">
            <div className="bg-secondary info-block  d-flex flex-column">
              <BonusBalance />
            </div>
          </div>
        )}
      </div>

      <div className="row pt-25">
        <div className="col">
          <div className="bg-secondary info-block">
            <p className="text-uppercase">ИНФОРМАЦИЯ О КЛИЕНТЕ</p>
            <div className="d-flex mt-3">
              <div className="info-block-title birth-date">Дата рождения</div>
              <div>
                <DatePicker
                  selected={getBirthDate(state.customer)}
                  onChange={handleDateDispatch}
                  className="form-control bg-secondary border-dark square col title"
                  placeholderText="__-__-___"
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="info-block-title">Статус клиента</div>
              <div>{customer.status}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-25">
        <div className="col">
          <div className="bg-secondary info-block overflow-auto">
            <AddressesList
              onRemove={onRemoveAddress}
              addresses={state.customer.addresses}
              onSetDefaultAddress={onSetDefaultAddress}
            />
            <FormProvider {...form}>
              {getValues().addresses?.length > 0 && (
                <div className="address">
                  {getValues().addresses.map((address, index) => (
                    <EditAddress
                      key={address.id}
                      name="addresses"
                      localId={index}
                      onRemove={onRemoveNewAddress(address.localId)}
                    />
                  ))}
                </div>
              )}
              <input ref={register} name="defaultId" className="d-none" />
            </FormProvider>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-outline-primary form-control common-button add-address-button height-40"
                onClick={handleAddAddress}
              >
                Добавить адрес
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="customer-profile-edit">
        <div className="row pt-25">
          <div className="col-12 d-flex justify-content-end">
            {renderSaveButton('save-button')}
            <button
              className="ml-2 btn btn-outline-primary form-control common-button cancel-button height-40"
              onClick={handleCancelEditProfile}
            >
              Отменить
            </button>
          </div>
        </div>
      </div>
      {responseStatus === STATUS_CHANGED_EXTERNAL && (
        <Modal
          color="warning"
          renderIcon={() => {}}
          isOpenExternal
          title="Внимание"
          message={
            <div>
              В карточку клиента были внесены изменения другими пользователями.
              <br /> <br />
              Пожалуйста, посмотрите измененные данные и при необходимости отредактируйте их
              повторно.
            </div>
          }
          onToggle={() => {
            resetLastResponseStatus()
            onSave(false)
          }}
          buttonToolbar={(toggle) => (
            <Button className="btn-primary height-40" onClick={toggle} color="primary">
              Перейти к клиенту
            </Button>
          )}
        />
      )}
      {customerEditError && (
        <Modal
          color="warning"
          renderIcon={() => {}}
          isOpenExternal
          title="Ошибка!"
          message={customerEditError}
          buttonToolbar={(toggle) => (
            <button className="btn btn-secondary modal_cancel" onClick={toggle}>
              Ок
            </button>
          )}
        />
      )}
      {isModalOpen.isOn && (
        <Modal
          color="warning"
          onToggle={handleToggleModal}
          isOpenExternal
          title="Внимание"
          needRenderIcon={false}
          buttonToolbar={(toggle) => {
            return (
              <>
                <Button
                  className="modal_cancel height-40"
                  color="outline-primary"
                  onClick={() => {
                    cancelButton()
                  }}
                >
                  Покинуть страницу
                </Button>
                <Button
                  className="modal_ok height-40"
                  color="primary"
                  onClick={() => {
                    toggle()
                  }}
                >
                  Остаться на странице
                </Button>
              </>
            )
          }}
          message={
            <>
              <span className="m-0">На странице есть несохранённые изменения.</span>
              <br />
              <span className="m-0">Вы уверены, что хотите покинуть страницу?</span>
            </>
          }
          size="large"
        />
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  customer: state.customer.result,
  customerIsEdited: state.customer.isEdited,
  customerIsEditing: state.customer.isEditing,
  customerEditError: state.customer.editError,
  responseStatus: state.customer.responseStatus,
})

const mapDispatchToProps = {
  updateCustomer: customer.update,
  addAddress: customer.addNewAddress,
  removeAddress: customer.removeAddress,
  resetCustomerEdited: customer.resetCustomerEdited,
  resetLastResponseStatus: customer.resetLastResponseStatus,
}

EditCustomerProfile.propTypes = {
  customer: PropTypes.shape(customerShape).isRequired,
  updateCustomer: PropTypes.func.isRequired,
  addAddress: PropTypes.func.isRequired,
  resetCustomerEdited: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  customerIsEdited: PropTypes.bool.isRequired,
  customerIsEditing: PropTypes.bool.isRequired,
  customerEditError: PropTypes.string.isRequired,
  responseStatus: PropTypes.number,
  resetLastResponseStatus: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
}

EditCustomerProfile.defaultProps = {
  responseStatus: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerProfile)
