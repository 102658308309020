import React, { FC } from 'react'
import { Container, Row } from 'reactstrap'
import { Select } from '../../../../components'
import { AttributeProps } from '../../model'

export const SelectAttribute: FC<AttributeProps> = ({ attribute, data, handler }) => {
  const handleChange = (id: string) => {
    const attributeOption = attribute.options.find((item) => String(item.option_id) === id)
    if (attributeOption) {
      handler(attribute, attributeOption)
    }
  }

  return (
    <Container fluid>
      <Row className="mb-2">
        <p className="mb-0 font-weight-bold">{attribute.frontend_label}</p>
      </Row>
      <Row>
        <Select
          selectContainerClass="attribute-select-wrapper"
          selectClass="attribute-select"
          placeholderClass="attribute-select__placeholder bg-white"
          dropdownClass="attribute-select__dropdown bg-white"
          key={data.get(attribute)?.option_id}
          initialValueId={String(data.get(attribute)?.option_id)}
          options={attribute.options.map((item) => ({
            id: String(item.option_id),
            name: item.value || item.option_id,
          }))}
          onChange={handleChange}
        />
      </Row>
    </Container>
  )
}
