import React, { useEffect, useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { ensureAuth, updateAuth, useAuth as getAuth } from '../api/auth'
import { useHistory, useLocation } from 'react-router-dom'
import { useRuntimeConfig } from '../hooks/useRuntimeConfig'

const UserContext = createContext({})

export function UserProvider({ children }) {
  const location = useLocation()
  const { push } = useHistory()
  const [user, setUser] = useState(getAuth())
  const isLogged = !!user.isUserLogged
  const { authUrl } = useRuntimeConfig()

  useEffect(() => {
    if (!isLogged) {
      ensureAuth(() => setUser(getAuth()))
    }
  }, [isLogged])

  useEffect(() => {
    if (location.pathname === authUrl + '/') {
      const params = new URLSearchParams(location.search)
      const token = params.get('access_token')
      const username = params.get('uid')
      const user_id = params.get('user_id')
      updateAuth({ token, username, user_id })
      setUser(getAuth())
      push('/')
    }
  }, [authUrl, location.pathname, location.search, push])

  if (!isLogged) {
    return null
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export function useUser() {
  return useContext(UserContext)
}

UserProvider.propTypes = {
  children: PropTypes.node,
}
