import React, { FC } from 'react'
import '../../styles/components/card-button.scss'
import clsx from 'clsx'

interface Props {
  label: string
  selected: boolean
  onClick: () => void
}

const CardButton: FC<Props> = ({ label, selected, onClick }) => {
  return (
    <div className={clsx('card-button', selected && 'selected')} onClick={onClick}>
      <span>{label}</span>
    </div>
  )
}

export default CardButton
