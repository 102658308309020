import React, { FC } from 'react'
import { Col, Container, Row } from 'reactstrap'
import clsx from 'clsx'
import { AttributeOption } from '../../../../../redux/slices/constructor/model/dto'

interface Props {
  option: AttributeOption
  selected?: boolean
  onClick?: () => void
  size?: 'small' | 'large'
}

export const SwatchElement: FC<Props> = ({ option, selected, onClick, size = 'large' }) => {
  if (size === 'small') {
    return (
      <Container className="constructor__swatch-element__small" fluid onClick={onClick}>
        <Row className="flex-nowrap align-items-center">
          <Col xs="auto-fit" className="mr-2">
            <img className="image" src={option.image_url} alt={option.value} />
          </Col>
          <Col xs="auto-fit" className="pl-2">
            <span>{option.value}</span>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className="constructor__swatch-element" fluid onClick={onClick}>
      <Row>
        <Col className={clsx('wrapper', selected && 'selected')}>
          {option?.recommended === '0' && (
            <img
              className="unrecommended-icon"
              src={require('../../../../../images/icons/unrecommended.svg')}
              alt="unrecommended"
            />
          )}
          <img className="image" src={option.image_url} alt={option.value} />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col className="pl-0">
          <span>{option.value}</span>
        </Col>
      </Row>
    </Container>
  )
}
