import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { formatPhoneNumber, searchHelper } from '../../../helpers'
import { customer } from '../../../redux/actions'
import { LinkWrapper } from '../../'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../../redux/model'

export interface CustomerType {
  id: string
  phone: string
  email: string
  lastname: string
  firstname: string
  middlename: string
  fullname: string
  is_fake_email: boolean
}

type Props = ConnectedProps<typeof connector> & {
  items: CustomerType[]
  withoutRedirect?: boolean
  onClick?: (item: CustomerType) => void
}

function CustomerSuggestions({ items, query, withoutRedirect = false, onClick }: Props) {
  const history = useHistory()

  return (
    <div className="search-suggestions-customers">
      {items.map((item) => (
        <LinkWrapper
          key={item.id}
          to={withoutRedirect ? undefined : `/customer/${item.id}`}
          onClick={() => (onClick ? onClick(item) : history.push(`/customer/${item.id}`))}
          className="text-decoration-none suggestion d-flex flex-column h-auto"
        >
          <div className="ellipsis">
            <div className="suggestion-title">
              {searchHelper.markMatch(searchHelper.getFullName(item), query)}
            </div>
            <div className="suggestion-description">
              {searchHelper.markMatch(
                [
                  formatPhoneNumber(item.phone),
                  item.email ? (item.is_fake_email ? '' : item.email) : '',
                ]
                  .filter((value) => Boolean(value))
                  .join(' / '),
                query,
              )}
            </div>
          </div>
        </LinkWrapper>
      ))}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  query: state.search.query,
})

const mapDispatchToProps = {
  setCustomer: customer.setCustomer,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CustomerSuggestions)
