import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { searchHelper } from '../../../helpers'
import { searchTypes } from '../../../constants'
import { ProductSuggestions, CustomerSuggestions, OrderSuggestions } from '../../../components'

function SearchSuggestions({ isOpen, query, result, forwardedRef }) {
  if (!Object.keys(result || {}).length || !query.length || !isOpen) {
    return null
  }

  const suggestions = searchHelper.getSuggestions(result)

  if (!Object.keys(suggestions)?.length && !result?.suggests?.length) {
    return null
  }

  return (
    <div
      className="search-suggestions bg-secondary border border-dark rounded w-100"
      ref={forwardedRef}
    >
      {result.suggests && (
        <div className="search-suggestions-queries">
          {result.suggests.map((item, index) => (
            <Link key={index} to={`/?q=${item}`}>
              <div>{searchHelper.markMatch(item, query)}</div>
            </Link>
          ))}
        </div>
      )}
      {suggestions[searchTypes.products]?.length > 0 && (
        <ProductSuggestions key={searchTypes.products} items={suggestions[searchTypes.products]} />
      )}
      {suggestions[searchTypes.customers]?.length > 0 && (
        <CustomerSuggestions
          key={searchTypes.customers}
          items={suggestions[searchTypes.customers]}
        />
      )}
      {suggestions[searchTypes.orders]?.length > 0 && (
        <OrderSuggestions key={searchTypes.orders} items={suggestions[searchTypes.orders]} />
      )}
      <div className="search-suggestions-all d-flex align-items-center">
        <Link to={`/?q=${query}`}>Показать все результаты</Link>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  query: state.search.query,
  result: state.search.result,
})

export default connect(mapStateToProps)(SearchSuggestions)

SearchSuggestions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  result: PropTypes.object.isRequired,
  forwardedRef: PropTypes.shape({ current: PropTypes.any }),
}

SearchSuggestions.defaultProps = {
  forwardedRef: null,
}
