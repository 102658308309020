import React, { FC } from 'react'
import { Button, ButtonToolbar, Modal as ModalComponent } from 'reactstrap'
import { InputField } from '../../../../components/Layout/form/InputField'
import { useForm, FormProvider } from 'react-hook-form'

import classnames from './SmsConfirm.module.scss'
import { useNotification } from '../../../../contexts/NotificationProvider/NotificationProvider'

interface Props {
  onSubmit: (smsCode: number) => Promise<unknown>
  isOpenExternal?: boolean
  onToggle?: () => void
}

type SmsCodeInputType = {
  smsCode: number
}

export const SmsConfirm: FC<Props> = ({ onSubmit, isOpenExternal = false, onToggle }) => {
  const form = useForm<SmsCodeInputType>()
  const { showError, showSuccess } = useNotification()

  const toggle = () => {
    if (onToggle) {
      onToggle()
    }
  }

  const handleClick = () => {
    const { smsCode } = form.getValues()
    onSubmit(smsCode)
      .then(() => {
        showSuccess({ body: 'Оплата прошла успешно' })
        toggle()
      })
      .catch(() => showError({ body: 'Что-то пошло не так' }))
  }

  const renderButtonToolbar = () => (
    <>
      <Button className="modal_cancel height-40" onClick={toggle}>
        Отмена
      </Button>
      <Button className="modal_ok height-40" color="primary" onClick={handleClick}>
        Подтвердить
      </Button>
    </>
  )

  return (
    <div>
      <ModalComponent isOpen={isOpenExternal} toggle={toggle} className="modal-dialog--large-size">
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
          <span className="lnr lnr-pushpin modal__title-icon text-primary" />
          <h4 className="text-modal  modal__title">Введите код из SMS-сообщения</h4>
        </div>
        <div className={'d-flex justify-content-center'}>
          <FormProvider {...form}>
            <InputField
              name="smsCode"
              label=""
              placeholder="SMS-код"
              className={classnames.smsCodeInput}
            />
          </FormProvider>
        </div>
        <span className="text-primary">Отправить код повторно</span>
        <ButtonToolbar className="modal__footer">{renderButtonToolbar()}</ButtonToolbar>
      </ModalComponent>
    </div>
  )
}
