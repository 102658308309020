import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useReceiver } from '../hooks/useReceiver'
import './LoyaltyProgram.scss'

import AskonaProgram from './components/AskonaProgram'
import AeroflotProgram from './components/AeroflotProgram'
import { useAuth } from './hooks/useAuth'
import { useLoyaltyPrograms } from './hooks/useLoyaltyPrograms'
import { useBalance } from './hooks/useBalance'
import Error from './components/Error'
import { Fail } from './components/Fail'
import { Select } from '../../components'
import { Button } from 'reactstrap'
import { AEROFLOT, ASKONA } from './constants'

export default function LoyaltyProgram(props) {
  const [parameters, setParameters] = useState(props)
  const {
    gateway,
    shopCode,
    terminalId,
    mobilePhone,
    cardNumber,
    purchaseDate,
    products,
    amount,
    onClose,
    onRegister,
    onWithdraw,
    onConfirmLoyaltyProgram,
    onPaymentChange,
    onAuth,
  } = parameters

  const [errorText, setErrorText] = useState()
  const [, setLoading] = useState(false)
  const [failed, setFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  useReceiver((opts) => {
    setParameters({ ...parameters, ...opts })
  })

  const { token, refreshToken } = useAuth({ onAuth })

  const { loyaltyPrograms, selectedLoyaltyProgram, setSelectedLoyaltyProgram } = useLoyaltyPrograms(
    {
      token,
      refreshToken,
      gateway,
      terminalId,
      shopCode,
      purchaseDate,
      onError: setErrorText,
    },
  )

  const isAeroflotSelected = selectedLoyaltyProgram.id === AEROFLOT
  const isAskonaSelected = selectedLoyaltyProgram.id === ASKONA

  const productsWith100Price = useMemo(() => {
    return products.map((product) => {
      return {
        ...product,
        price: product.price * 100,
      }
    })
  }, [products])

  const bonusInfo = useBalance({
    gateway,
    token,
    refreshToken,
    purchaseDate,
    shopCode,
    terminalId,
    mobilePhone,
    cardNumber,
    amount,
    products: productsWith100Price,
    loyaltyProgramId: !isAeroflotSelected ? selectedLoyaltyProgram.id : null, // баллы для Аэрофлота не запрашиваем
    onError: setErrorText,
  })

  const handleWithdrawal = (amount, smsCode) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      onWithdraw({
        amount,
        loyaltyProgram: selectedLoyaltyProgram.id,
        smsCode,
        onSuccess: () => {
          setLoading(false)
          onClose()
          resolve()
        },
        onFail: (message) => {
          setLoading(false)
          setFailed(true)
          setErrorMessage(message)
          reject()
        },
      })
    })
  }

  const handleConfirmLoyaltyProgram = (onSuccess) => {
    setLoading(true)
    onConfirmLoyaltyProgram({
      terminalId,
      loyaltyProgram: selectedLoyaltyProgram.id,
      cardNumber,
      onSuccess: () => {
        setLoading(false)
        onSuccess()
      },
      onFail: (message) => {
        setLoading(false)
        setFailed(true)
        setErrorMessage(message)
      },
    })
  }

  const handleContinue = (action) => {
    if (action.continue) {
      onClose()
    } else {
      setFailed(false)
      setErrorMessage(undefined)
    }
  }

  return (
    <div className="askonaWidget-loyaltyProgram">
      <div className="askonaWidget-loyaltyProgram-close" onClick={onClose}>
        x
      </div>
      <div className="askonaWidget-loyaltyProgram-title">ПРОГРАММА ЛОЯЛЬНОСТИ</div>
      {failed ? (
        <Fail onAction={handleContinue} errorMessage={errorMessage} />
      ) : (
        <>
          {loyaltyPrograms?.length > 0 && (
            <div className="askonaWidget-loyaltyProgram-select">
              <Select
                options={loyaltyPrograms}
                initialValueId={selectedLoyaltyProgram?.id}
                onChange={(id) =>
                  setSelectedLoyaltyProgram(loyaltyPrograms.find((program) => program.id === id))
                }
                dropdownClass="border-0"
              />
            </div>
          )}
          {errorText && <Error text={errorText} />}
          {isAskonaSelected && (
            <AskonaProgram
              bonusInfo={bonusInfo}
              mobilePhone={mobilePhone}
              onRegister={onRegister}
              onWithdraw={handleWithdrawal}
              onConfirmLoyaltyProgram={handleConfirmLoyaltyProgram}
              onPaymentChange={onPaymentChange}
            />
          )}
          {isAeroflotSelected && <AeroflotProgram bonusInfo={bonusInfo} />}
        </>
      )}
      <div className="askonaWidget-loyaltyProgram-skip">
        <Button color="link" className="height-40" onClick={onClose}>
          Пропустить
        </Button>
      </div>
    </div>
  )
}

LoyaltyProgram.propTypes = {
  shopCode: PropTypes.string,
  mobilePhone: PropTypes.string,
  cardNumber: PropTypes.string,
  purchaseDate: PropTypes.instanceOf(Date),
  gateway: PropTypes.string,
  terminalId: PropTypes.string,
  amount: PropTypes.number,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      productCode: PropTypes.string,
      price: PropTypes.number,
      quantity: PropTypes.number,
    }),
  ),
  onClose: PropTypes.func,
  onWithdraw: PropTypes.func,
  onConfirmLoyaltyProgram: PropTypes.func,
  onPaymentChange: PropTypes.func,
  onRegister: PropTypes.func,
  onSkip: PropTypes.func,
}
