import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { EnterCard } from './EnterCard'
import Withdrawal from './Withdrawal'
import { Button, Modal } from 'reactstrap'

export default function AeroflotProgram() {
  const [cardNumber, setCardNumber] = useState(null)
  const [showModal, setShowModal] = useState(false)

  if (!cardNumber) {
    return (
      <EnterCard
        programName="Аэрофлот бонус"
        hint="Номер карты нужно вводить, начиная с цифры, отличной от нуля"
        onSubmit={setCardNumber}
      />
    )
  }
  return (
    <>
      <div className="askonaWidget-loyaltyProgram-phone">
        <div className="text-thin">Номер участника</div>
        <div>{cardNumber}</div>
      </div>
      <div className="askonaWidget-loyaltyProgram-balance">
        <div className="askonaWidget-loyaltyProgram-balance-amount">5000</div>
        <div className="text-thin">МИЛЬ ВОЗМОЖНО СПИСАТЬ ДЛЯ ПОЛУЧЕНИЯ МАКСИМАЛЬНОЙ СКИДКИ</div>
        <div className="text-thin">
          Доступное количество миль клиент может получить в ЛК Аэрофлота
        </div>
      </div>
      <Withdrawal
        label="Введите количество миль, которое требуется списать"
        onWithdraw={() => setShowModal(true)}
      />
      <Modal
        isOpen={showModal}
        backdropClassName="askonaWidget-loyaltyProgram-modal"
        contentClassName="askonaWidget-loyaltyProgram-modal-content"
      >
        <div>
          В данной Программе Лояльности <br /> списанные мили не возвращаются.
          <br />
          Продолжить операцию?
        </div>
        <div>
          <Button color="primary" onClick={() => setShowModal(false)}>
            Да
          </Button>
          <Button onClick={() => setShowModal(false)}>Нет</Button>
        </div>
      </Modal>
    </>
  )
}

AeroflotProgram.propTypes = {
  onWithdraw: PropTypes.func,
}
