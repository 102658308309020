import React from 'react'
import PropTypes from 'prop-types'

import { addressShape } from '../../constants'
import { Address } from '../'

function AddressesList({ addresses, onRemove, onSetDefaultAddress }) {
  return (
    <div className="address">
      <p className="text-uppercase">АДРЕСА ДОСТАВКИ</p>
      {addresses?.length > 0 ? (
        addresses.map((address) => (
          <Address
            key={address.id}
            address={address}
            onRemove={onRemove}
            onSetDefaultAddress={onSetDefaultAddress}
          />
        ))
      ) : (
        <p>Ещё не добавлено ни одного адреса.</p>
      )}
    </div>
  )
}

AddressesList.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  onSetDefaultAddress: PropTypes.func,
  onRemove: PropTypes.func,
}
AddressesList.defaultProps = {
  onRemove: null,
  onSetDefaultAddress: null,
  addresses: [],
}

export default AddressesList
