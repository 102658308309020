import { yup } from '../../../yup'

export const conditionStepSchema = yup.object().shape({
  type: yup.string().required(),
  time: yup.string().required(),
  count: yup.string().required(),
  sex: yup.string().required(),
  birthday: yup.date().typeError('Укажите дату').required(),
  simpleSignature: yup.boolean().required(),
  insurance: yup.boolean().required(),
  sms: yup.boolean().required(),
  flexibleSchedule: yup.boolean().required(),
})
