import React, { FC } from 'react'
import { Container, Row } from 'reactstrap'
import { ProductAttribute } from './attributes/ProductAttribute'
import {
  filterSelectedVariants,
  getSuitableSimples,
  getPossibleVariantValues,
} from '../../helpers/product'

interface ProductAttributeGroup {
  groupName: string
  attributes: Attribute[]
}

export enum ProductAttributeType {
  Slider = 'slider',
  Swatch = 'swatch',
}

export interface AttributeOption {
  attribute_id: string
  code: string
  option_id: string
  value: string
  image_url?: string
  category?: string
}

export interface Attribute {
  key: string
  label: string
  values: AttributeOption[]
}

export type OnChangeArgs = {
  key: string
  value: string
}

interface Props {
  group: ProductAttributeGroup
  onChange: (args: OnChangeArgs) => void
  frontendType?: ProductAttributeType
  selectedVariantsMap: unknown
  filterPriorityMap: unknown
  itemSimples: unknown
  focusNextRef?: (element: HTMLInputElement) => void
}

export const ProductAttributeGroupComponent: FC<Props> = ({
  group,
  onChange,
  frontendType,
  selectedVariantsMap,
  filterPriorityMap,
  itemSimples,
  focusNextRef,
}) => {
  const attributes = group.attributes.map((item, i, arr) => {
    const selectedVariantsMapWithHigherPriority = filterSelectedVariants(
      selectedVariantsMap,
      filterPriorityMap,
      item.key,
    )
    const suitableSimples = getSuitableSimples(
      itemSimples,
      selectedVariantsMapWithHigherPriority,
      item.key,
    )
    const options = getPossibleVariantValues(item.values, suitableSimples, item.key)

    if (options.length === 1) {
      onChange({ key: item.key, value: options[0].option_id })
    }

    return (
      <Row key={item.key} className="mb-4">
        <ProductAttribute
          attribute={item}
          onChange={onChange}
          frontendType={frontendType}
          isLabelHidden={(item.key === 'size' && arr.length === 1) || item.key !== 'size'}
          options={options}
          focusNextRef={focusNextRef}
          activeVariant={selectedVariantsMapWithHigherPriority[item.key]}
        />
      </Row>
    )
  })

  return (
    <Container className="p-3" fluid>
      <Row>
        <h2>{group.groupName}</h2>
      </Row>
      {attributes}
    </Container>
  )
}
