import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { DadataResponse, DadataRequest, DadataBodyRequest, Locations } from '../model'
import * as dadata from '../../../../api/dadata'

export const getDadataResult = createAsyncThunk<DadataResponse, DadataRequest, AppThunkApiConfig>(
  'search/getDadataResult',
  async ({ addressId, query, type, params }) => {
    let body: DadataBodyRequest = { query, locations: [] }

    if (type) {
      body = {
        ...body,
        from_bound: {
          value: type,
        },
        to_bound: {
          value: type === 'city' ? 'settlement' : type,
        },
        restrict_value: true,
      }
    }

    if (type === 'city') {
      body = {
        ...body,
        locations: [
          {
            region_fias_id: params.region,
          },
        ],
      }
    }

    if (type === 'street') {
      const locations: Locations[] = []
      if (params.city) {
        locations.push(
          {
            city_fias_id: params.city,
          },
          {
            settlement_fias_id: params.city,
          },
        )
      } else {
        locations.push({
          region_fias_id: params.region,
        })
      }
      body = {
        ...body,
        restrict_value: params.city?.length > 0,
        locations,
      }
    }
    if (type === 'house') {
      body = {
        ...body,
        locations: [
          {
            street_fias_id: params.street,
          },
        ],
      }
    }

    const result = await dadata.search(body)
    return {
      result: {
        suggestions: result?.suggestions || [],
        addressId,
        type,
      },
      additional: {},
    }
  },
)
