import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from '../../../model'
import { Parameters } from '../model/dto'
import { ParametersData, PriceCache } from '../model'
import { isMapsEqual } from '../../../../helpers'
import { thunks } from '../../../redusers'
import { debounce } from 'lodash-es'

export const refreshPrice = createAsyncThunk<void, void, AppThunkApiConfig>(
  'constructor/refreshPrice',
  debounce<AsyncThunkPayloadCreator<void, void, AppThunkApiConfig>>(
    async (_, { dispatch, getState }) => {
      const state = getState().itemConstructor
      if (state.parametersTree && !isAllOptionsSettled(state.parametersTree, state.parametersData))
        return

      let price = getPriceFromCache(state.parametersData, state.priceCache)
      if (!price) {
        dispatch(thunks.constructor.fetchPrice(state.parametersData))
      }
    },
    3000,
  ),
)

const getOptionsLength = (parametersTree: Parameters) =>
  parametersTree.configurable_attribute_groups.reduce((acc, val) => {
    return acc + val.configurable_attributes.length
  }, 0)

const isAllOptionsSettled = (parametersTree: Parameters, parametersData: ParametersData) => {
  return getOptionsLength(parametersTree) === parametersData.size
}

export const getPriceFromCache = (parametersData: ParametersData, priceCache: PriceCache) => {
  for (const [key, value] of [...priceCache]) {
    if (isMapsEqual(key, parametersData)) {
      return value
    }
  }
}
