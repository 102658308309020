import React, { FC, useEffect, useState } from 'react'
import { Modal } from '../index'
import { postErrorInfo } from '../../api/errorService'
import { ErrorInfo } from '../../api/dto'
import { ErrorServiceMessage } from './ErrorServiceMessage'
import { CustomerErrorType } from '../../api/dto'

interface Props {
  customer: CustomerErrorType
  onToggle?: () => void
  appId: string
}

export const ErrorServiceModal: FC<Props> = ({ customer, onToggle, appId }) => {
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>()

  const { errorCode } = customer?.contact_state ?? {}

  useEffect(() => {
    handleErrorCode()
  }, [errorCode])

  const handleErrorCode = async () => {
    if (!errorCode) return
    const response = await postErrorInfo(customer, appId)
    setErrorInfo(response)
  }

  return (
    <Modal
      color="danger"
      renderIcon={() => <></>}
      isOpenExternal
      onToggle={onToggle}
      title={errorInfo && 'Ошибка сохранения данных!'}
      message={<ErrorServiceMessage errorInfo={errorInfo} />}
      buttonToolbar={(toggle) => (
        <button className="btn btn-secondary modal_cancel" onClick={toggle}>
          Ок
        </button>
      )}
    />
  )
}
