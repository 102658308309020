import * as cart from './cart'
import * as customer from './customer'
import { actions } from '../redusers'
import { getAppParams as getAppParamsApi } from '../../api/auth'

export const getAppParams = () => (dispatch) => {
  getAppParamsApi()
    .then((result) => {
      const data = { ...result, shopCode: result.shop_code }
      dispatch(actions.app.additionalInfo({ data }))
      dispatch(actions.app.setServerStatus(false))
    })
    .catch((error) => {
      if (error?.response?.status === 503) {
        dispatch(actions.app.setServerStatus(true))
      }
    })
}

export { cart, customer }
