import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { searchHelper } from '../../../helpers'
import { Loader, SearchTab, Tabs, UpButton, Checkbox } from '../../../components'
import { searchPageSize, searchTypes } from '../../../constants'
import { actions } from '../../../redux/redusers'
import classes from './SearchResult.module.scss'

function SearchResult({
  query,
  page,
  isLoading,
  isLoadingMore,
  result,
  error,
  onShowMore,
  isInStock,
  setIsInStock,
}) {
  const [activeTab, setActiveTab] = useState('products')

  const location = useLocation()

  if (query.length === 0 || !location.search) {
    return null
  }

  if (isLoading && !isLoadingMore) {
    return <Loader />
  }

  const totals = searchHelper.getTotals(result)
  const results = searchHelper.getSortedResults(result, [
    searchTypes.products,
    searchTypes.orders,
    searchTypes.customers,
  ])
  /**
   * Общее количество items на активной вкладке
   */
  const total = totals[activeTab]
  /**
   * Количество на кнопке "Показать ещё {rest} из {total}" на активной вкладке
   * Определяем, сколько items осталось показать. Если больше размера страницы, то выводим размер страницы, если меньше, то rest
   */
  let rest = total - searchPageSize * page[activeTab]
  if (rest > searchPageSize) {
    rest = searchPageSize
  }

  return (
    <div className="search-result">
      <h1 className="my-4">Результаты поиска</h1>
      {totals.all === 0 || error ? (
        <div className="search-result-text">
          По вашему запросу ничего не найдено.
          <br />
          Проверьте, нет ли опечатки в запросе, или уменьшите количество слов.
        </div>
      ) : (
        <>
          <div className="bg-secondary pt-3 br-5">
            <Tabs
              tabs={results.map((resultItem) => ({
                id: resultItem.metadata?.group_id,
                title: `${resultItem.metadata?.group_name} (${
                  totals[resultItem.metadata?.group_id]
                })`,
              }))}
              initialActiveTabId={activeTab}
              content={results.map((resultItem) => (
                <SearchTab key={resultItem.metadata?.group_id} result={resultItem} />
              ))}
              onSetActiveTab={setActiveTab}
              additionalContentForTabs={{
                products: (
                  <div className={classes.checkbox__container}>
                    <Checkbox
                      checked={isInStock}
                      name="isInStock"
                      onChange={() => setIsInStock(!isInStock)}
                      labelName="Показать только “В наличии”"
                      labelClassName="ml-1"
                    />
                  </div>
                ),
              }}
            />
          </div>
        </>
      )}
      {isLoadingMore && (
        <div className="loader-show-more">
          <Loader />
        </div>
      )}
      {rest > 0 && (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary button-show-more"
            disabled={isLoadingMore}
            onClick={() => onShowMore(activeTab)}
          >
            Показать ещё {rest} из {total - searchPageSize * page[activeTab]}
          </button>
        </div>
      )}
      <div className="d-flex justify-content-end">
        <UpButton />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  query: state.search.query,
  page: state.search.page,
  isLoading: state.search.isLoading,
  isLoadingMore: state.search.isLoadingMore,
  result: state.search.result,
  error: state.search.error,
  isInStock: state.search.isInStock,
})

const mapDispatchToProps = {
  setIsInStock: actions.search.setIsInStock,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult)

SearchResult.propTypes = {
  query: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  result: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  onShowMore: PropTypes.func.isRequired,
}
